import { IIconProps } from '../interface';

const SvgQatar = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M56 28C56 12.5361 43.4639 0 28 0C22.5225 0 17.4132 1.57445 13.0974 4.29308L19.2348 6.90069L10.9565 10.418L19.2348 13.9351L10.9565 17.4522L19.2348 20.9692L10.9565 24.4857L19.2348 28.002L10.9565 31.519L19.2348 35.0359L10.9565 38.5524L19.2348 42.0692L10.9565 45.5863L19.2348 49.1028L13.1002 51.709C17.4154 54.4263 22.5236 56 28 56C43.4639 56 56 43.4639 56 28Z"
      fill="#751A46"
    />
  </svg>
);

export default SvgQatar;

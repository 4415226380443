import { IIconProps } from '../interface';

const SvgChevronDown = ({
  width = 32,
  height = 32,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.865 12H8.13509C7.28725 12 6.8241 12.9889 7.36687 13.6402L15.2318 23.0781C15.6316 23.5579 16.3685 23.5579 16.7683 23.0781L24.6332 13.6402C25.176 12.9889 24.7128 12 23.865 12Z"
            fill={color}
          />
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2928 21.7071L5.29285 11.7071L6.70706 10.2929L16 19.5858L25.2928 10.2929L26.7071 11.7071L16.7071 21.7071C16.3165 22.0976 15.6834 22.0976 15.2928 21.7071Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgChevronDown;

import { IIconProps } from '../interface';

const SvgNato = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M40.0165 29.9534C40.1195 29.3172 40.1739 28.6648 40.1739 28C40.1739 28.6648 40.1195 29.3172 40.0165 29.9534L45.0435 28L40.0165 26.0467L37.2553 24.9738L32.7693 23.2307L31.0262 18.7447C33.9655 19.7082 36.2916 22.0343 37.2552 24.9737L40.0164 26.0466C39.182 20.8954 35.1044 16.8177 29.9533 15.9834L28 10.9565L26.0466 15.9835C20.8954 16.8181 16.8179 20.8956 15.9835 26.0467L10.9565 28L15.9836 29.9534C16.8181 35.1046 20.8956 39.1821 26.0467 40.0165L28 45.0435L29.9534 40.0165C35.1045 39.1821 39.1821 35.1045 40.0165 29.9534ZM30.8884 25.1117L28 28H38.3212L30.8883 30.8884L28 28V38.3213L25.1116 30.8883L28 28H17.6788L25.1116 25.1117L28 28V17.6788L30.8884 25.1117ZM24.9737 18.7448L23.2305 23.2308L18.7447 24.9739C19.7082 22.0343 22.0343 19.7084 24.9737 18.7448ZM18.7448 31.0263L23.2305 32.7694L24.9737 37.2552C22.0343 36.2916 19.7084 33.9658 18.7448 31.0263ZM31.0263 37.2552L32.7694 32.7694L37.2552 31.0263C36.2916 33.9658 33.9658 36.2916 31.0263 37.2552Z"
      fill="#F0F0F0"
    />
    <path d="M38.3212 28H28L30.8884 30.8884L38.3212 28Z" fill="#0052B4" />
    <path d="M17.6787 28H27.9999L25.1115 25.1118L17.6787 28Z" fill="#0052B4" />
    <path
      d="M27.9999 38.3213V28L25.1116 30.8884L27.9999 38.3213Z"
      fill="#0052B4"
    />
    <path d="M28 28L30.8884 25.1117L28 17.6788V28Z" fill="#0052B4" />
    <path
      d="M26.7827 3.65213H29.2175V8.52172H26.7827V3.65213Z"
      fill="#F0F0F0"
    />
    <path d="M3.6521 26.7827H8.52169V29.2175H3.6521V26.7827Z" fill="#F0F0F0" />
    <path
      d="M26.7827 47.4783H29.2175V52.3479H26.7827V47.4783Z"
      fill="#F0F0F0"
    />
    <path
      d="M47.4783 26.7827H52.3479V29.2175H47.4783V26.7827Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgNato;

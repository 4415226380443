import { IIconProps } from '../interface';

const SvgLiberia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15999)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.40015 10.1819H49.5998C48.0042 8.24981 46.1536 6.53646 44.1007 5.09106H11.8992C9.84633 6.53624 7.99582 8.24971 6.40015 10.1819V10.1819Z"
        fill="#F0F0F0"
      />
      <path
        d="M0 28C0 28.8583 0.0407969 29.7068 0.116375 30.5455H55.8836C55.9592 29.7068 56 28.8583 56 28C56 27.1417 55.9592 26.2932 55.8836 25.4546H0.116375C0.0407969 26.2932 0 27.1417 0 28H0Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.05326 40.7272H52.9466C53.7711 39.1144 54.4435 37.4113 54.9456 35.6364H1.05432C1.55624 37.4113 2.2289 39.1145 3.05326 40.7272V40.7272Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.8992 50.9092H44.1006C46.1535 49.4638 48.0041 47.7504 49.5997 45.8183H6.40015C7.99582 47.7502 9.84633 49.4638 11.8992 50.9092V50.9092Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.05432 20.3637H54.9458C54.4437 18.5887 53.7712 16.8855 52.9467 15.2728H3.05337C2.22901 16.8855 1.55635 18.5887 1.05432 20.3637V20.3637Z"
        fill="#F0F0F0"
      />
      <path
        d="M13.3912 5.09086H44.1007C39.5463 1.8842 33.9933 0 27.9999 0C24.3478 0 17.9456 1.8842 13.3912 5.09086Z"
        fill="#D80027"
      />
      <path
        d="M26.7826 15.2728H52.9466C52.0155 13.4514 50.8909 11.7452 49.5998 10.1819H26.7826V15.2728Z"
        fill="#D80027"
      />
      <path
        d="M26.7826 25.4546H55.8836C55.7261 23.7066 55.4095 22.0043 54.9457 20.3638H26.7826V25.4546Z"
        fill="#D80027"
      />
      <path
        d="M1.05433 35.6365H54.9456C55.4096 33.996 55.7261 32.2936 55.8836 30.5456H0.116333C0.273833 32.2936 0.590364 33.9959 1.05433 35.6365V35.6365Z"
        fill="#D80027"
      />
      <path
        d="M6.4001 45.8181H49.5997C50.8909 44.2545 52.0155 42.5485 52.9466 40.7272H3.05322C3.98433 42.5486 5.10893 44.2548 6.4001 45.8181V45.8181Z"
        fill="#D80027"
      />
      <path
        d="M27.9999 56C33.9934 56 39.5463 54.1158 44.1007 50.9091H11.8992C16.4535 54.1158 22.0065 56 27.9999 56Z"
        fill="#D80027"
      />
      <path
        d="M1.05422 20.3637C0.590255 22.0043 0.273833 23.7065 0.116333 25.4545H28V0C15.1834 0 4.37835 8.61131 1.05422 20.3637V20.3637Z"
        fill="#0052B4"
      />
      <path
        d="M16.6674 7.30438L18.4803 12.8842H24.3478L19.6011 16.333L21.4139 21.9131L16.6674 18.4645L11.9206 21.9131L13.7338 16.333L8.98718 12.8842H14.8544L16.6674 7.30438Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15999">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLiberia;

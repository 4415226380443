import { IIconProps } from '../interface';

const SvgChina = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M15.3234 17.0406L17.7406 24.4781H25.5609L19.2391 29.0828L21.6563 36.5203L15.3234 31.9266L8.99063 36.5203L11.4188 29.0828L5.08594 24.4781H12.9063L15.3234 17.0406Z"
      fill="#FFDA44"
    />
    <path
      d="M33.1953 43.3672L31.3469 41.0922L28.6125 42.1531L30.1984 39.6922L28.35 37.4063L31.1828 38.1609L32.7797 35.7L32.9328 38.6313L35.7766 39.3859L33.0312 40.4359L33.1953 43.3672Z"
      fill="#FFDA44"
    />
    <path
      d="M36.8703 36.6953L37.7453 33.8953L35.35 32.2L38.2812 32.1562L39.1453 29.3562L40.0969 32.1344L43.0281 32.1016L40.6765 33.8516L41.6172 36.6297L39.2219 34.9344L36.8703 36.6953Z"
      fill="#FFDA44"
    />
    <path
      d="M41.825 20.5516L40.5344 23.1875L42.6344 25.2328L39.736 24.8172L38.4453 27.4422L37.9422 24.5547L35.0328 24.1391L37.636 22.7719L37.1328 19.8734L39.2328 21.9187L41.825 20.5516Z"
      fill="#FFDA44"
    />
    <path
      d="M33.2719 12.5672L33.0531 15.4875L35.7766 16.5922L32.9219 17.2922L32.7141 20.2234L31.1719 17.7297L28.3172 18.4297L30.2094 16.1875L28.6563 13.7047L31.3797 14.8094L33.2719 12.5672Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgChina;

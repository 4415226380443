import { IIconProps } from '../interface';

const SvgInvestimentAdd = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.11612 4.11612C6.35054 3.8817 6.66848 3.75 7 3.75H19C19.3315 3.75 19.6495 3.8817 19.8839 4.11612C20.1183 4.35054 20.25 4.66848 20.25 5V18C20.25 18.3315 20.1183 18.6495 19.8839 18.8839C19.6495 19.1183 19.3315 19.25 19 19.25H15C14.5858 19.25 14.25 19.5858 14.25 20C14.25 20.4142 14.5858 20.75 15 20.75H19C19.7293 20.75 20.4288 20.4603 20.9445 19.9445C21.4603 19.4288 21.75 18.7293 21.75 18V5C21.75 4.27065 21.4603 3.57118 20.9445 3.05546C20.4288 2.53973 19.7293 2.25 19 2.25H7C6.27065 2.25 5.57118 2.53973 5.05546 3.05546C4.53973 3.57118 4.25 4.27065 4.25 5V9C4.25 9.41421 4.58579 9.75 5 9.75C5.41421 9.75 5.75 9.41421 5.75 9V5C5.75 4.66848 5.8817 4.35054 6.11612 4.11612Z"
            fill={color}
          />
          <path
            d="M14.5 6.25C14.0858 6.25 13.75 6.58579 13.75 7C13.75 7.41421 14.0858 7.75 14.5 7.75H15.1898L13.9451 8.99558L11.9756 7.42003C11.9167 7.37157 11.8521 7.33346 11.7842 7.30572C11.6797 7.2629 11.5683 7.24502 11.4585 7.25114C11.2761 7.26131 11.098 7.33773 10.9633 7.47607L9.46967 8.96967C9.17678 9.26256 9.17678 9.73744 9.46967 10.0303C9.76256 10.3232 10.2374 10.3232 10.5303 10.0303L11.5557 9.005L13.5315 10.5857C13.6729 10.6988 13.8426 10.7524 14.0106 10.7499C14.1996 10.7477 14.3879 10.6744 14.5321 10.5302L16.25 8.81116V9.5C16.25 9.91421 16.5858 10.25 17 10.25C17.4142 10.25 17.75 9.91421 17.75 9.5V7C17.75 6.58579 17.4142 6.25 17 6.25H14.5Z"
            fill={color}
          />
          <path
            d="M15 12.25C14.5858 12.25 14.25 12.5858 14.25 13C14.25 13.4142 14.5858 13.75 15 13.75H17C17.4142 13.75 17.75 13.4142 17.75 13C17.75 12.5858 17.4142 12.25 17 12.25H15Z"
            fill={color}
          />
          <path
            d="M14.25 16C14.25 15.5858 14.5858 15.25 15 15.25H17C17.4142 15.25 17.75 15.5858 17.75 16C17.75 16.4142 17.4142 16.75 17 16.75H15C14.5858 16.75 14.25 16.4142 14.25 16Z"
            fill={color}
          />
          <path
            d="M5.34375 16.5C5.34375 16.0858 5.67954 15.75 6.09375 15.75H6.75V15.0938C6.75 14.6795 7.08579 14.3438 7.5 14.3438C7.91421 14.3438 8.25 14.6795 8.25 15.0938V15.75H8.90625C9.32046 15.75 9.65625 16.0858 9.65625 16.5C9.65625 16.9142 9.32046 17.25 8.90625 17.25H8.25V17.9062C8.25 18.3205 7.91421 18.6562 7.5 18.6562C7.08579 18.6562 6.75 18.3205 6.75 17.9062V17.25H6.09375C5.67954 17.25 5.34375 16.9142 5.34375 16.5Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.78769 12.7877C4.77226 11.8031 6.10761 11.25 7.5 11.25C8.53835 11.25 9.55339 11.5579 10.4167 12.1348C11.2801 12.7117 11.953 13.5316 12.3504 14.4909C12.7477 15.4502 12.8517 16.5058 12.6491 17.5242C12.4466 18.5426 11.9465 19.4781 11.2123 20.2123C10.4781 20.9465 9.54263 21.4466 8.52422 21.6491C7.50582 21.8517 6.45022 21.7477 5.49091 21.3504C4.5316 20.953 3.71166 20.2801 3.13478 19.4167C2.55791 18.5534 2.25 17.5384 2.25 16.5C2.25 15.1076 2.80312 13.7723 3.78769 12.7877ZM4.84835 13.8483C5.55161 13.1451 6.50544 12.75 7.5 12.75C8.24168 12.75 8.9667 12.9699 9.58339 13.382C10.2001 13.794 10.6807 14.3797 10.9645 15.0649C11.2484 15.7502 11.3226 16.5042 11.1779 17.2316C11.0333 17.959 10.6761 18.6272 10.1516 19.1516C9.6272 19.6761 8.95902 20.0333 8.23159 20.1779C7.50416 20.3226 6.75016 20.2484 6.06494 19.9645C5.37971 19.6807 4.79404 19.2001 4.38199 18.5834C3.96993 17.9667 3.75 17.2417 3.75 16.5C3.75 15.5054 4.14509 14.5516 4.84835 13.8483Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgInvestimentAdd;

import { IIconProps } from '../interface';

const SvgCuracao = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#FFDA44"
    />
    <path
      d="M28 56C37.1875 56 45.3413 51.5749 50.4469 44.74H5.5531C10.6587 51.5749 18.8125 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M56 28C56 12.5361 43.4639 0 28 0C12.5361 0 0 12.5361 0 28C0 31.3111 0.576297 34.4873 1.63144 37.4357H54.3685C55.4237 34.4873 56 31.3111 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M19.1649 17.9574L20.6757 22.6072H25.5652L21.6097 25.4813L23.1205 30.1313L19.1649 27.2574L15.2094 30.1313L16.7204 25.4813L12.7648 22.6072H17.6541L19.1649 17.9574Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.7684 13.0878L11.6749 15.8778H14.6087L12.2353 17.6022L13.1418 20.3922L10.7684 18.6678L8.39508 20.3922L9.30169 17.6022L6.92847 15.8778H9.86212L10.7684 13.0878Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgCuracao;

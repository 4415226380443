import { IIconProps } from '../interface';

const SvgLebanon = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15988)">
      <path
        d="M28 56.8359C43.464 56.8359 56 44.2999 56 28.8359C56 13.372 43.464 0.835938 28 0.835938C12.536 0.835938 0 13.372 0 28.8359C0 44.2999 12.536 56.8359 28 56.8359Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 0.835938C16.8991 0.835938 7.30732 7.29617 2.77832 16.6621H53.2215C48.6926 7.29617 39.1008 0.835938 28 0.835938Z"
        fill="#D80027"
      />
      <path
        d="M28 56.836C39.1008 56.836 48.6926 50.3757 53.2216 41.0098H2.77832C7.30732 50.3757 16.8991 56.836 28 56.836Z"
        fill="#D80027"
      />
      <path
        d="M35.3043 33.7055L27.9999 20.3142L20.6956 33.7055H26.1738V37.3577H29.8261V33.7055H35.3043Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15988">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLebanon;

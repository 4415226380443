import { IIconProps } from '../interface';

const SvgUnitedNations = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16940)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#338AF3"
      />
      <path
        d="M27.919 18.6789C22.5401 18.6789 18.1799 23.0392 18.1799 28.418C18.1799 33.7967 22.5402 38.1571 27.919 38.1571C33.2976 38.1571 37.6581 33.7967 37.6581 28.418C37.6581 23.0392 33.2975 18.6789 27.919 18.6789ZM25.4842 28.418C25.4842 27.0733 26.5742 25.9832 27.919 25.9832C29.2637 25.9832 30.3538 27.0733 30.3538 28.418C30.3538 29.7627 29.2637 30.8528 27.919 30.8528C26.5742 30.8528 25.4842 29.7627 25.4842 28.418ZM30.4434 24.2531C30.0655 24.0235 29.6536 23.8452 29.2174 23.7248V21.2311C30.3156 21.4281 31.3282 21.8699 32.1973 22.4993L30.4434 24.2531ZM26.7827 23.6831C26.3259 23.7924 25.8943 23.966 25.4977 24.1938L23.7356 22.4318C24.6264 21.8081 25.6619 21.3773 26.7827 21.202V23.6831ZM25.4976 32.6421C25.8942 32.8699 26.3257 33.0436 26.7826 33.1527V35.6338C25.6617 35.4587 24.6264 35.0276 23.7356 34.404L25.4976 32.6421ZM29.2175 33.1111C29.6536 32.9907 30.0657 32.8125 30.4434 32.583L32.1972 34.3368C31.3281 34.966 30.3156 35.4079 29.2174 35.605V33.1113H29.2175V33.1111ZM32.1431 30.8395C32.3576 30.4662 32.5252 30.0624 32.6352 29.6354H35.1204C34.9375 30.725 34.5138 31.7323 33.9052 32.6013L32.1431 30.8395ZM32.6352 27.2006C32.5252 26.7735 32.3576 26.37 32.1431 25.9966L33.9051 24.2346C34.5137 25.1038 34.9375 26.1109 35.1203 27.2005H32.635V27.2006H32.6352ZM22.0002 24.1398L23.754 25.8936C23.5102 26.295 23.3229 26.7343 23.2028 27.2006H20.7175C20.9071 26.0712 21.3554 25.0302 22.0002 24.1398ZM20.7175 29.6353H23.2028C23.3227 30.1017 23.5101 30.5409 23.754 30.9423L22.0002 32.6962C21.3554 31.8058 20.9071 30.7648 20.7175 29.6353Z"
        fill="#F0F0F0"
      />
      <path
        d="M43.745 28.418C43.745 24.0889 42.0062 20.1666 39.1898 17.3093L36.6073 19.8918C38.7629 22.088 40.0929 25.0976 40.0929 28.418C40.0929 35.1415 34.6424 40.5919 27.919 40.5919C21.1954 40.5919 15.7452 35.1415 15.7452 28.418C15.7452 25.0561 17.1076 22.0127 19.3108 19.8097L16.7283 17.2273C13.8643 20.0913 12.093 24.0478 12.093 28.418C12.093 35.6603 16.9577 41.7663 23.5977 43.6468C22.8697 45.0298 22.9806 46.7709 24.0206 48.0686C25.3865 46.974 26.8006 45.8407 28.0002 44.8795C29.1998 45.8408 30.614 46.974 31.9798 48.0686C33.03 46.758 33.1334 44.9948 32.3812 43.6052C38.9486 41.6785 43.745 35.6089 43.745 28.418Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16940">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgUnitedNations;

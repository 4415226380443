import { IIconProps } from '../interface';

const SvgFrenchPolynesia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M3.40454 14.6087H52.5954C47.8465 5.90505 38.6132 0 28 0C17.3868 0 8.15349 5.90505 3.40454 14.6087Z"
      fill="#D80027"
    />
    <path
      d="M28 56C38.6132 56 47.8465 50.095 52.5955 41.3913H3.40454C8.15349 50.095 17.3868 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M37.739 28C37.739 33.3788 33.3787 36.5217 27.9999 36.5217C22.6212 36.5217 18.2609 33.3788 18.2609 28C18.2609 22.6212 22.6212 18.2609 27.9999 18.2609C33.3787 18.2609 37.739 22.6212 37.739 28Z"
      fill="#FFDA44"
    />
    <path
      d="M37.739 28C37.739 33.3788 33.3787 37.7391 27.9999 37.7391C22.6212 37.7391 18.2609 33.3788 18.2609 28"
      fill="#0052B4"
    />
    <path
      d="M21.9131 25.5652H24.3479V30.4348H21.9131V25.5652Z"
      fill="#D80027"
    />
    <path d="M31.6522 25.5652H34.087V30.4348H31.6522V25.5652Z" fill="#D80027" />
    <path
      d="M26.7827 21.9131H29.2175V30.4348H26.7827V21.9131Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgFrenchPolynesia;

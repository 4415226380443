import { IIconProps } from '../interface';

const SvgWarning = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.9999 11L17 19L15 19L14.9999 11L16.9999 11Z"
            fill={color}
          />
          <path
            d="M14.5 22.5C14.5 21.6716 15.1716 21 16 21C16.8284 21 17.5 21.6716 17.5 22.5C17.5 23.3285 16.8284 24 16 24C15.1716 24 14.5 23.3285 14.5 22.5Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7889 2.34166C17.0518 0.867556 14.9482 0.867564 14.2112 2.34166L1.8292 26.1056C1.1643 27.4354 2.1313 29 3.61806 29H28.382C29.8688 29 30.8357 27.4354 30.1708 26.1056L17.7889 2.34166ZM16 3.23608L28.382 27H3.61806L16 3.23608Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17 22L17 2H15L15 22H17Z" fill={color} />
          <path
            d="M16 26C14.8954 26 14 26.8954 14 28C14 29.1046 14.8954 30 16 30C17.1046 30 18 29.1046 18 28C18 26.8954 17.1046 26 16 26Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17 19L17 8H15L15 19L17 19Z" fill={color} />
          <path
            d="M16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21Z"
            fill={color}
          />
        </svg>
      );
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2112 2.34166C14.9482 0.867564 17.0518 0.867556 17.7889 2.34166L30.1708 26.1056C30.8357 27.4354 29.8688 29 28.382 29H3.61806C2.1313 29 1.1643 27.4354 1.8292 26.1056L14.2112 2.34166ZM15 19H17V9H15V19ZM17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24C16.8284 24 17.5 23.3284 17.5 22.5Z"
            fill={color}
          />
        </svg>
      );
    default:
      console.warn('Need select an option for this icon');
      return null;
  }
};

export default SvgWarning;

import { IIconProps } from '../interface';

const SvgCheck = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7498 20.6616L22.2498 12.1616L20.7502 10.8384L13.9142 18.5858L10.1644 15.2526L8.83564 16.7474L13.3356 20.7474C13.5343 20.924 13.7952 21.0143 14.0606 20.9982C14.3259 20.9821 14.5739 20.861 14.7498 20.6616Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.2709 4.13692L11.6481 27.8909L1.38037 19.7849L2.61965 18.2152L11.352 25.109L29.729 2.86316L31.2709 4.13692Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 9 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4.75L4 6.5L7.5 2"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM22.7649 11.6441L14.0632 21.9774L8.79289 16.7071L10.2071 15.2929L13.9368 19.0226L21.2351 10.3559L22.7649 11.6441Z"
            fill={color}
          />
        </svg>
      );
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1913_41293)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM9.07617 4.48014L9.5563 3.90397L8.40397 2.9437L7.92383 3.51986L4.90868 7.13805L3.48574 5.92855L2.91428 5.44281L1.94281 6.58572L2.51426 7.07145L4.51426 8.77145C4.66679 8.9011 4.86481 8.96439 5.06426 8.94724C5.2637 8.93009 5.44801 8.83392 5.57617 8.68014L9.07617 4.48014Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_1913_41293">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

export default SvgCheck;

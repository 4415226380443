import { IIconProps } from '../interface';

const SvgIsleOfMan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M38.3673 18.77L36.3883 25.8325L30.4503 24.736L26.6281 16.8572L16.3024 20.5214L15.4881 18.2267L12.7865 17.8938L14.8221 23.6304L21.9281 21.8132L23.9475 27.5039L19.0352 34.7535L27.3714 41.8635L25.7914 43.716L26.8539 46.2222L30.8043 41.5908L25.6774 36.3455L29.596 31.7514L38.3305 32.3808L40.3199 21.6062L42.7142 22.0484L44.3537 19.8752L38.3673 18.77Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgIsleOfMan;

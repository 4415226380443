import { IIconProps } from '../interface';

const SvgLock = ({
  width = 32,
  height = 32,
  color = '#323232',
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 10.0508H9.5C8.25736 10.0508 7.25 11.0581 7.25 12.3008V14.5008C7.25 15.7434 8.25736 16.7508 9.5 16.7508H14.5C15.7426 16.7508 16.75 15.7434 16.75 14.5008V12.3008C16.75 11.0581 15.7426 10.0508 14.5 10.0508ZM9.5 11.5508H14.5C14.9142 11.5508 15.25 11.8866 15.25 12.3008V14.5008C15.25 14.915 14.9142 15.2508 14.5 15.2508H9.5C9.08579 15.2508 8.75 14.915 8.75 14.5008V12.3008C8.75 11.8866 9.08579 11.5508 9.5 11.5508Z"
        fill={color}
      />
      <path
        d="M2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12ZM3.75 12C3.75 7.44364 7.44365 3.75 12 3.75C16.5564 3.75 20.25 7.44365 20.25 12C20.25 16.5564 16.5564 20.25 12 20.25C7.44364 20.25 3.75 16.5564 3.75 12Z"
        fill={color}
      />
      <path
        d="M12.25 6.44922H11.75C10.3693 6.44922 9.25 7.56851 9.25 8.94922L9.25088 10.8017C9.251 11.067 9.39091 11.3094 9.61497 11.4442L9.71599 11.4951C9.80698 11.5328 9.90451 11.5522 10.003 11.5522H13.997L14.2139 11.4881C14.4921 11.4008 14.5733 11.3387 14.6574 11.1018L14.7162 10.9158C14.7268 10.8797 14.738 10.841 14.75 10.7992V8.94922C14.75 7.56851 13.6307 6.44922 12.25 6.44922ZM12.25 7.94922L12.3666 7.95595C12.864 8.01371 13.25 8.43638 13.25 8.94922V10.0522H10.75V8.94886C10.75 8.39693 11.1977 7.94922 11.75 7.94922H12.25Z"
        fill={color}
      />
    </svg>
  );
};

export default SvgLock;

import { IIconProps } from '../interface';

const SvgFiji = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#338AF3"
    />
    <path d="M28 13.3913V22.8351L18.2609 13.3913H28Z" fill="#0052B4" />
    <path
      d="M14.6087 28H22.835L14.6087 17.0435L13.3914 25.5652L14.6087 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5335 0.002625 0 12.5375 0 28H14.6087V19.7738L22.835 28H27.9253C27.9502 27.9751 27.9752 27.9501 28.0002 27.9251C28.0002 26.0387 28.0002 24.3555 28.0002 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65213C9.78646 6.14577 6.1458 9.78643 3.65216 14.1657V28H10.9566V10.9567V10.9565H28C28 8.65276 28 6.45804 28 3.65213H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M28 24.5567L18.0521 14.6088H14.6088V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5567Z"
      fill="#D80027"
    />
    <path
      d="M31.6522 18.2609V27.9999C31.6522 34.523 40.1739 36.5217 40.1739 36.5217C40.1739 36.5217 48.6956 34.523 48.6956 27.9999V18.2609L40.1739 15.8261L31.6522 18.2609Z"
      fill="#F3F3F3"
    />
    <path
      d="M48.6956 18.2609V14.6088H31.6522V18.2609H38.9563V25.5654H31.6522V28V28.0002L38.9563 28.0003V36.1485C39.6911 36.4083 40.1739 36.5219 40.1739 36.5219C40.1739 36.5219 40.6565 36.4085 41.3911 36.1485V28.0002H48.6956V28V25.5653H41.3911V18.2608H48.6956V18.2609Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgFiji;

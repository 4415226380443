import { IIconProps } from '../interface';

const SvgAzerbaijan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 24.575 55.384 21.2941 54.2584 18.2609L28 17.0435L1.74158 18.2608C0.616109 21.2941 0 24.575 0 28C0 31.425 0.616109 34.7059 1.74158 37.7391L28 38.9565L54.2584 37.7392C55.384 34.7059 56 31.425 56 28Z"
      fill="#D80027"
    />
    <path
      d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
      fill="#6DA544"
    />
    <path
      d="M1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0C15.961 0 5.69778 7.5985 1.74158 18.2609Z"
      fill="#338AF3"
    />
    <path
      d="M28.6087 35.9131C24.2385 35.9131 20.6957 32.3702 20.6957 28C20.6957 23.6298 24.2385 20.0869 28.6087 20.0869C29.9713 20.0869 31.2535 20.4315 32.3729 21.0381C30.6167 19.3208 28.2153 18.2608 25.5652 18.2608C20.1864 18.2608 15.8262 22.6211 15.8262 27.9999C15.8262 33.3786 20.1865 37.739 25.5652 37.739C28.2154 37.739 30.6168 36.679 32.3729 34.9617C31.2535 35.5685 29.9713 35.9131 28.6087 35.9131Z"
      fill="#F0F0F0"
    />
    <path
      d="M34.6957 22.5217L35.7438 25.4695L38.5694 24.1262L37.2262 26.9517L40.1739 28L37.2262 29.0482L38.5694 31.8738L35.7438 30.5305L34.6957 33.4783L33.6476 30.5305L30.8219 31.8738L32.1653 29.0482L29.2174 28L32.1653 26.9517L30.8219 24.1262L33.6476 25.4695L34.6957 22.5217Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgAzerbaijan;

import { IIconProps } from '../interface';

const SvgAustralia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0066 28 0.00317187 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.9999 0C27.9984 0 27.9968 0.001 27.9952 0.001L27.9999 0Z"
      fill="#F0F0F0"
    />
    <path
      d="M27.925 28H28C28 27.9746 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6088C28 9.68199 28 6.45357 28 0.00012207H27.9953C12.5335 0.00263769 0 12.5376 0 28H14.6087V19.7737L22.835 28H27.9252C27.9502 27.9751 27.9752 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8352L19.7737 14.6088H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65213C9.7864 6.14577 6.14574 9.78643 3.6521 14.1657V28H10.9565V10.9566V10.9565H28C28 8.65276 28 6.45804 28 3.65213H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M28 24.5567L18.052 14.6089H14.6088C14.6088 14.6088 14.6088 14.6089 14.6088 14.6089L27.9999 28H28C28 28 28 25.626 28 24.5567Z"
      fill="#D80027"
    />
    <path
      d="M16.887 32.8696L18.4237 36.0828L21.8939 35.2808L20.3399 38.4857L23.1305 40.6987L19.6559 41.4818L19.6657 45.0435L16.887 42.8152L14.1084 45.0435L14.1182 41.4818L10.6436 40.6987L13.4343 38.4857L11.88 35.2808L15.3504 36.0828L16.887 32.8696Z"
      fill="#F0F0F0"
    />
    <path
      d="M41.9217 38.9565L42.6901 40.5632L44.4252 40.1621L43.6481 41.7645L45.0435 42.8711L43.3062 43.2626L43.311 45.0435L41.9217 43.9293L40.5324 45.0435L40.5373 43.2626L38.8 42.8711L40.1954 41.7645L39.4183 40.1621L41.1534 40.5632L41.9217 38.9565Z"
      fill="#F0F0F0"
    />
    <path
      d="M34.774 21.9131L35.5423 23.5198L37.2774 23.1186L36.5004 24.7211L37.8957 25.8276L36.1585 26.2192L36.1632 28L34.774 26.8858L33.3847 28L33.3894 26.2192L31.6522 25.8276L33.0475 24.7211L32.2705 23.1186L34.0056 23.5198L34.774 21.9131Z"
      fill="#F0F0F0"
    />
    <path
      d="M41.9217 12.1739L42.6901 13.7806L44.4252 13.3795L43.6482 14.982L45.0434 16.0884L43.3062 16.4801L43.311 18.2609L41.9217 17.1467L40.5324 18.2609L40.5373 16.4801L38.8 16.0884L40.1952 14.982L39.4183 13.3795L41.1534 13.7806L41.9217 12.1739Z"
      fill="#F0F0F0"
    />
    <path
      d="M48.1652 19.4783L48.9335 21.085L50.6686 20.6838L49.8916 22.2863L51.2869 23.3928L49.5496 23.7845L49.5544 25.5652L48.1652 24.4511L46.776 25.5652L46.7807 23.7845L45.0435 23.3928L46.4388 22.2863L45.6618 20.6838L47.3968 21.085L48.1652 19.4783Z"
      fill="#F0F0F0"
    />
    <path
      d="M43.7008 28L44.3051 29.86H46.2609L44.6786 31.0097L45.2831 32.8696L43.7008 31.7201L42.1186 32.8696L42.7229 31.0097L41.1406 29.86H43.0964L43.7008 28Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgAustralia;

import { IIconProps } from '../interface';

const SvgBangladesh = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#496E2D"
    />
    <path
      d="M21.9131 40.1739C28.6365 40.1739 34.0869 34.7234 34.0869 28C34.0869 21.2765 28.6365 15.8261 21.9131 15.8261C15.1896 15.8261 9.7392 21.2765 9.7392 28C9.7392 34.7234 15.1896 40.1739 21.9131 40.1739Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgBangladesh;

import { IIconProps } from '../interface';

const SvgSubscription = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            strokeLinecap="round"
            strokeWidth="1.5"
            stroke={color}
            fill="none"
            strokeLinejoin="round"
          >
            <path d="M18.364 5.636A9 9 0 1 1 5.636 18.364 9 9 0 0 1 18.364 5.636" />
            <path d="M15.15 11.898l.875.937.875-.937" />
            <path d="M9.699 8.608A4.067 4.067 0 0 1 12 7.894a4.106 4.106 0 0 1 4.025 4.91M8.85 12.017l-.875-.936-.875.936" />
            <path d="M14.301 15.308a4.063 4.063 0 0 1-2.301.713 4.106 4.106 0 0 1-4.025-4.91" />
          </g>
          <path fill="none" d="M0 0h24v24H0z" />
        </svg>
      );
  }
};

export default SvgSubscription;

import { IIconProps } from '../interface';

const SvgSabaIsland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16469)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 -8.11801e-05C27.9739 -0.0197687 -0.00743602 27.9926 1.48242e-06 27.9999C1.48242e-06 12.536 12.5361 -8.11801e-05 28 -8.11801e-05Z"
        fill="#D80027"
      />
      <path
        d="M28 -8.11801e-05C28.0261 -0.0197687 56.0074 27.9925 56 27.9999C56 12.536 43.4639 -8.11801e-05 28 -8.11801e-05Z"
        fill="#D80027"
      />
      <path
        d="M28 56C27.9739 56.0197 -0.00743602 28.0074 1.48242e-06 28C1.48242e-06 43.4639 12.5361 56 28 56Z"
        fill="#0052B4"
      />
      <path
        d="M28 56C28.0261 56.0197 56.0074 28.0074 56 28C56 43.4639 43.4639 56 28 56Z"
        fill="#0052B4"
      />
      <path
        d="M28.0001 14.6087L31.0219 23.9087H40.8006L32.8894 29.6564L35.9112 38.9565L28.0001 33.2088L20.089 38.9565L23.1108 29.6564L15.1997 23.9087H24.9783L28.0001 14.6087Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16469">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSabaIsland;

import { IIconProps } from '../interface';

const SvgBurgerMenu = ({
  width = 32,
  height = 32,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M26 7.00006L6 7L6 8.99994L26 9L26 7.00006Z" fill={color} />
          <path d="M26 15.0001L6 15L6 16.9999L26 17L26 15.0001Z" fill={color} />
          <path d="M6 23L20 23.0001L20 25L6 24.9999L6 23Z" fill={color} />
        </svg>
      );
  }
};

export default SvgBurgerMenu;

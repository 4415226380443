export const buildByYearToSideBar = ({
  fiscalYear,
}: {
  fiscalYear: string;
}) => {
  const sliced = parseInt(fiscalYear.toString().slice(2, 4));
  return [`${sliced - 1}/${sliced}`, `${sliced}/${sliced + 1}`];
};

export const buildByYearToPayload = ({
  fiscalYear,
}: {
  fiscalYear: string;
}) => {
  const yearParsed = parseInt(fiscalYear);
  return `${yearParsed}/${yearParsed + 1}`;
};

import { IIconProps } from '../interface';

const SvgGrenada = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15750)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FFDA44"
      />
      <path
        d="M12.2186 4.86959C16.7123 1.79769 22.1458 0 28 0C33.8542 0 39.2877 1.79769 43.7814 4.86959L28 6.08694L12.2186 4.86959Z"
        fill="#A2001D"
      />
      <path d="M28 28L26.213 26.213L25.7385 27.063L28 28Z" fill="#0052B4" />
      <path
        d="M8.20099 8.20093C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20093Z"
        fill="#496E2D"
      />
      <path
        d="M47.7991 8.20093C58.7337 19.1356 58.7337 36.8643 47.7991 47.799C43.2805 43.2804 38.9347 38.9346 28 28L47.7991 8.20093Z"
        fill="#496E2D"
      />
      <path
        d="M27.9999 37.7391C33.3787 37.7391 37.739 33.3787 37.739 28C37.739 22.6213 33.3787 18.2609 27.9999 18.2609C22.6212 18.2609 18.2609 22.6213 18.2609 28C18.2609 33.3787 22.6212 37.7391 27.9999 37.7391Z"
        fill="#A2001D"
      />
      <path
        d="M27.9999 18.2609L30.1829 24.9797H37.2483L31.5326 29.133L33.7156 35.8521L27.9999 31.6996L22.284 35.8521L24.4674 29.133L18.7517 24.9797H25.8167L27.9999 18.2609Z"
        fill="#FFDA44"
      />
      <path
        d="M28 0C27.9968 0 27.9938 0.00021875 27.9906 0.00021875H28.0093C28.0062 0.00021875 28.0032 0 28 0Z"
        fill="#FFDA44"
      />
      <path
        d="M28.0094 0H27.9907C20.2631 0.00251562 13.2673 3.13534 8.20251 8.19963H47.7977C42.7327 3.13534 35.737 0.00251562 28.0094 0Z"
        fill="#A2001D"
      />
      <path
        d="M8.20264 47.8005C13.2695 52.8666 20.2689 56 28.0001 56C35.7312 56 42.7306 52.8666 47.7975 47.8005H8.20264Z"
        fill="#A2001D"
      />
      <path
        d="M28 3.04346L28.4534 4.43843H29.9201L28.7335 5.30063L29.1869 6.6956L28 5.8335L26.8134 6.6956L27.2668 5.30063L26.08 4.43843H27.5467L28 3.04346Z"
        fill="#FFDA44"
      />
      <path
        d="M21.2104 3.04346L21.6637 4.43843H23.1304L21.9438 5.30063L22.3972 6.6956L21.2104 5.8335L20.0239 6.6956L20.4771 5.30063L19.2903 4.43843H20.757L21.2104 3.04346Z"
        fill="#FFDA44"
      />
      <path
        d="M34.7897 3.04346L35.2431 4.43843H36.7098L35.5232 5.30063L35.9765 6.6956L34.7897 5.8335L33.6031 6.6956L34.0565 5.30063L32.8696 4.43843H34.3363L34.7897 3.04346Z"
        fill="#FFDA44"
      />
      <path
        d="M28 49.3044L28.4534 50.6994H29.9201L28.7335 51.5616L29.1869 52.9565L28 52.0943L26.8134 52.9565L27.2668 51.5616L26.08 50.6994H27.5467L28 49.3044Z"
        fill="#FFDA44"
      />
      <path
        d="M21.2104 49.3044L21.6637 50.6994H23.1304L21.9438 51.5616L22.3972 52.9565L21.2104 52.0943L20.0239 52.9565L20.4771 51.5616L19.2903 50.6994H20.757L21.2104 49.3044Z"
        fill="#FFDA44"
      />
      <path
        d="M34.7897 49.3044L35.2431 50.6994H36.7098L35.5232 51.5616L35.9765 52.9565L34.7897 52.0943L33.6031 52.9565L34.0565 51.5616L32.8696 50.6994H34.3363L34.7897 49.3044Z"
        fill="#FFDA44"
      />
      <path
        d="M8.53158 27.5375C9.25072 28.6738 8.91275 30.1778 7.77646 30.8971C6.64027 31.6163 5.13614 31.2784 4.41689 30.1421C3.11478 28.085 3.8698 24.7253 3.8698 24.7253C3.8698 24.7253 7.22936 25.4803 8.53158 27.5375Z"
        fill="#FFDA44"
      />
      <path
        d="M7.1253 31.086C7.79762 31.086 8.34265 30.541 8.34265 29.8687C8.34265 29.1964 7.79762 28.6513 7.1253 28.6513C6.45298 28.6513 5.90796 29.1964 5.90796 29.8687C5.90796 30.541 6.45298 31.086 7.1253 31.086Z"
        fill="#A2001D"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15750">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGrenada;

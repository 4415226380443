import { IIconProps } from '../interface';

const SvgAngola = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28C54.7827 28 28 31.6521 28 31.6521L0 28Z"
      fill="#D80027"
    />
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28"
      fill="black"
    />
    <path
      d="M24.1606 22.27L26.5343 23.9927L25.6298 26.7826L28.0017 25.0571L30.3756 26.7798L29.4675 23.9907L31.8392 22.2654L28.9063 22.2672L27.9982 19.4782L27.0935 22.2682L24.1606 22.27Z"
      fill="#FFDA44"
    />
    <path
      d="M35 15.8757C32.7936 14.6018 30.3811 14.0005 28 14.0047V17.6561C29.7599 17.6529 31.543 18.097 33.1738 19.0387C38.1152 21.8916 39.8143 28.2327 36.9613 33.1741C34.1085 38.1154 27.7674 39.8146 22.826 36.9616C21.3864 36.1305 20.2243 35.002 19.3677 33.6996L16.3187 35.7126C17.4778 37.4745 19.0523 38.9999 21 40.1243C27.6854 43.9842 36.2645 41.6854 40.1242 35C43.984 28.3146 41.6854 19.7355 35 15.8757V15.8757Z"
      fill="#FFDA44"
    />
    <path
      d="M19.927 25.5652C18.959 27.3348 19.6089 29.5541 21.3784 30.5221L33.24 37.0036C32.4334 38.4781 32.8618 40.2728 34.3363 41.0795L37.5404 42.8322C39.0149 43.639 40.8645 43.0973 41.6712 41.6227L43.4239 38.4187L19.927 25.5652Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgAngola;

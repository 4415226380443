import { IIconProps } from '../interface';

const SvgAndorra = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_14980)">
      <path
        d="M15.8262 53.2217C19.5073 55.0016 23.637 56 28 56C32.3631 56 36.4928 55.0016 40.1739 53.2217L42.6087 28L40.1739 2.77834C36.4928 0.998375 32.3631 0 28 0C23.637 0 19.5073 0.998375 15.8262 2.77834L13.3914 28L15.8262 53.2217Z"
        fill="#FFDA44"
      />
      <path
        d="M40.1738 53.2207C49.5397 48.6919 56 39.1008 56 28C56 16.8992 49.5397 7.30811 40.1738 2.77933V53.2207Z"
        fill="#D80027"
      />
      <path
        d="M15.8261 53.2207V2.77933C6.46023 7.30811 0 16.8991 0 28C0 39.1009 6.46023 48.6919 15.8261 53.2207Z"
        fill="#0052B4"
      />
      <path
        d="M28 37.7391C28 33.271 28 28 28 28H35.3044V31.6521C35.3044 32.2866 34.087 34.6115 31.0822 36.5217C29.9441 37.2454 28.7661 37.4781 28 37.7391Z"
        fill="#D80027"
      />
      <path d="M20.6956 20.6956H27.9999V28H20.6956V20.6956Z" fill="#D80027" />
      <path
        d="M31.6521 18.2609C31.6521 16.9161 30.5619 15.8261 29.2173 15.8261C28.7732 15.8261 28.3583 15.9469 27.9999 16.1545C27.6415 15.9469 27.2267 15.8261 26.7826 15.8261C25.4379 15.8261 24.3478 16.9161 24.3478 18.2609H18.2609V30.4347C18.2609 34.9667 21.86 37.5877 24.6824 38.9463C24.4712 39.3071 24.3478 39.7256 24.3478 40.174C24.3478 41.5187 25.4379 42.6088 26.7826 42.6088C27.2267 42.6088 27.6416 42.488 27.9999 42.2804C28.3584 42.488 28.7732 42.6088 29.2173 42.6088C30.5619 42.6088 31.6521 41.5187 31.6521 40.174C31.6521 39.7256 31.5287 39.3071 31.3174 38.9463C34.1398 37.5877 37.739 34.9668 37.739 30.4347V18.2609H31.6521ZM34.0869 30.4347C34.0869 31.0692 34.0869 32.9997 31.0821 34.9101C29.944 35.6337 28.766 36.1029 27.9999 36.3638C27.2339 36.1029 26.0559 35.6337 24.9178 34.9101C21.913 32.9997 21.913 31.0692 21.913 30.4347V21.913H34.0869V30.4347Z"
        fill="#FF9811"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_14980">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAndorra;

const fonts = {
  default: '"Inter", sans-serif',
  black: '"Inter Black", sans-serif',
  bold: '"Inter Bold", sans-serif',
  extraBold: '"Inter ExtraBold", sans-serif',
  extraLight: '"Inter ExtraLight", sans-serif',
  light: '"Inter Light", sans-serif',
  medium: '"Inter Medium", sans-serif',
  semiBold: '"Inter SemiBold", sans-serif',
  thin: '"Inter SemiBold", sans-serif',
  weight: {
    regular: '400',
    bold: '700',
    semiBold: '600',
    medium: '500',
  },
  spacing: {
    large: '0.15px',
    default: '0px',
    xlarge: '0.25px',
  },
};

export default fonts;

import { IIconProps } from '../interface';

const SvgCrypto = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none">
            <path d="M0,0h24v24h-24Z"></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M18.364,5.63604c3.51472,3.51472 3.51472,9.2132 0,12.7279c-3.51472,3.51472 -9.2132,3.51472 -12.7279,0c-3.51472,-3.51472 -3.51472,-9.2132 -1.77636e-15,-12.7279c3.51472,-3.51472 9.2132,-3.51472 12.7279,-1.77636e-15"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M13.705,15.333h-2.807c-0.618,0 -1.12,-0.501 -1.12,-1.12v-2.162h3.927c0.899,0 1.629,0.729 1.629,1.629v0.025c-0.001,0.899 -0.73,1.628 -1.629,1.628Z"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M13.31,12.051h-3.532v-2.247c0,-0.628 0.509,-1.137 1.137,-1.137h2.395c0.913,0 1.654,0.74 1.654,1.654v0.077c0,0.913 -0.74,1.653 -1.654,1.653Z"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.89,8.67h-2.22"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.9,15.33h-2.23"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M13.23,7.56v1.11"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.5,7.56v1.11"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.5,16.44v-1.11"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M13.23,16.44v-1.11"
            ></path>
          </g>
        </svg>
      );
  }
};

export default SvgCrypto;

import { IIconProps } from '../interface';

const SvgTibet = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16784)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.2185 4.86959C16.7122 1.79769 22.1457 0 27.9999 0C33.8541 0 39.2876 1.79769 43.7813 4.86959L27.9999 6.08694L12.2185 4.86959Z"
        fill="#FFDA44"
      />
      <path
        d="M8.20247 47.7975L21.1133 34.8869L28 23.1304L34.8859 34.8861L47.7975 47.7975C52.8649 42.7308 56 35.732 56 28C56 18.3902 51.1581 9.91211 43.7814 4.8696H12.2186C4.84192 9.91211 0 18.3902 0 28C0 35.732 3.13513 42.7308 8.20247 47.7975Z"
        fill="#D80027"
      />
      <path
        d="M53.8728 38.7211L33.4011 30.2382L34.8849 34.8848L47.7975 47.7974C50.3757 45.2196 52.4536 42.1416 53.8728 38.7211Z"
        fill="#0052B4"
      />
      <path
        d="M56 28C56 24.202 55.243 20.5811 53.8729 17.279L28 28H56Z"
        fill="#0052B4"
      />
      <path
        d="M47.7975 8.20247C46.5668 6.972 45.2227 5.85496 43.7814 4.8696H37.5847L28 28L47.7975 8.20247Z"
        fill="#0052B4"
      />
      <path d="M27.9999 4.8696H18.4153L27.9999 28V4.8696Z" fill="#0052B4" />
      <path
        d="M8.20243 8.20248C5.62424 10.7805 3.54645 13.8584 2.1272 17.2789L28 28L8.20243 8.20248Z"
        fill="#0052B4"
      />
      <path
        d="M0 28C0 31.798 0.756984 35.4189 2.12713 38.721L28 28H0Z"
        fill="#0052B4"
      />
      <path
        d="M12.2185 51.1304C16.7121 54.2023 22.1457 56 27.9999 56C33.8541 56 39.2876 54.2023 43.7813 51.1304H12.2185Z"
        fill="#FFDA44"
      />
      <path
        d="M27.9998 28L34.8864 34.8866C36.649 33.1241 37.739 30.6894 37.739 28C37.739 22.6212 33.3787 18.2609 27.9999 18.2609C22.6212 18.2609 18.2607 22.6212 18.2607 28C18.2607 30.6893 19.3508 33.1241 21.1134 34.8866L27.9998 28Z"
        fill="#FFDA44"
      />
      <path
        d="M32.2609 42C32.2609 44.3532 30.353 45.0435 27.9999 45.0435C25.6468 45.0435 23.739 44.3532 23.739 42C23.739 39.6468 25.6468 37.7391 27.9999 37.7391C30.353 37.7391 32.2609 39.6468 32.2609 42Z"
        fill="#FFDA44"
      />
      <path
        d="M32.2609 42C32.2609 44.3532 30.353 46.2609 27.9999 46.2609C25.6468 46.2609 23.739 44.3532 23.739 42"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16784">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTibet;

import { ReactElement } from 'react';
import * as theme from '../theme';

const {
  column,
  gutter: { rem },
} = theme.grid;

enum ESizeColumn {
  'column-1' = column,
  'column-2' = column * 2,
  'column-3' = column * 3,
  'column-4' = column * 4,
  'column-5' = column * 5,
  'column-6' = column * 6,
  'column-7' = column * 7,
  'column-8' = column * 8,
  'column-9' = column * 9,
  'column-10' = column * 10,
  'column-11' = column * 11,
  'column-12' = column * 12,
}

type TColumns = keyof typeof ESizeColumn;

const columns = (sizeColumn: TColumns) => ESizeColumn[sizeColumn];

interface IColumn {
  children?: ReactElement | ReactElement[] | any;
  className?: string;
  sizeColumn: TColumns;
  noPadding?: boolean;
}

const Column = ({ children, className, sizeColumn, noPadding }: IColumn) => {
  const padding = noPadding ? null : { paddingLeft: rem, paddingRight: rem };

  return (
    <div
      className={`${className || ''}`}
      style={{
        width: `${columns(sizeColumn)}%`,
        boxSizing: 'border-box',
        ...padding,
      }}
    >
      {children}
    </div>
  );
};

export default Column;

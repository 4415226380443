import { IIconProps } from '../interface';

const SvgHeart = ({ width = 32, height = 32, color, type }: IIconProps) => {
  if (type === 'sm') {
    return (
      <svg
        width={width || '24'}
        height={height || '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.89333 4C5.07366 4 3 6.73487 3 9.47438C3 12.3285 4.76936 14.9619 6.93495 16.9474C8.00307 17.9267 9.1271 18.7094 10.0887 19.2421C10.5696 19.5085 10.9975 19.7055 11.3467 19.8333C11.7163 19.9685 11.9276 20 12 20C12.0724 20 12.2837 19.9685 12.6533 19.8333C13.0025 19.7055 13.4304 19.5085 13.9113 19.2421C14.8729 18.7094 15.9969 17.9267 17.065 16.9474C19.2306 14.9619 21 12.3285 21 9.47438C21 6.73487 18.9263 4 16.1067 4C14.4692 4 13.4021 4.81433 12.7641 5.56998C12.5741 5.79503 12.2945 5.92488 12 5.92488C11.7055 5.92488 11.4259 5.79503 11.2359 5.56998C10.5979 4.81433 9.53075 4 7.89333 4ZM1 9.47438C1 5.97013 3.65745 2 7.89333 2C9.71402 2 11.0645 2.70562 12 3.50865C12.9355 2.70562 14.286 2 16.1067 2C20.3426 2 23 5.97013 23 9.47438C23 13.1464 20.7571 16.2758 18.4166 18.4216C17.2317 19.5079 15.9792 20.3829 14.8805 20.9916C14.3313 21.2958 13.8082 21.5404 13.3405 21.7115C12.8931 21.8752 12.4215 22 12 22C11.5785 22 11.1069 21.8752 10.6595 21.7115C10.1918 21.5404 9.66866 21.2958 9.11946 20.9916C8.02081 20.3829 6.76831 19.5079 5.58338 18.4216C3.24286 16.2758 1 13.1464 1 9.47438Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2444 5C6.01551 5 3 9.04565 3 13C3 14.9351 3.75708 16.8411 4.9809 18.651C6.20385 20.4597 7.85608 22.1169 9.54811 23.5317C11.2363 24.9432 12.9366 26.0906 14.2306 26.8848C14.8769 27.2815 15.4175 27.5874 15.7991 27.7918C15.8728 27.8313 15.9399 27.8666 16 27.8977C16.0601 27.8666 16.1272 27.8313 16.2009 27.7918C16.5825 27.5874 17.1231 27.2815 17.7694 26.8848C19.0634 26.0906 20.7637 24.9432 22.4519 23.5317C24.1439 22.1169 25.7962 20.4597 27.0191 18.651C28.2429 16.8411 29 14.9351 29 13C29 9.04565 25.9845 5 21.7556 5C19.3022 5 17.7064 6.21959 16.7587 7.32333C16.5687 7.54458 16.2916 7.67188 16 7.67188C15.7084 7.67188 15.4313 7.54458 15.2413 7.32333C14.2936 6.21959 12.6978 5 10.2444 5ZM1 13C1 8.26685 4.61115 3 10.2444 3C12.8605 3 14.7438 4.07946 16 5.23486C17.2562 4.07946 19.1395 3 21.7556 3C27.3888 3 31 8.26685 31 13C31 15.4634 30.0382 17.7566 28.6759 19.7713C27.3127 21.7874 25.5116 23.5803 23.7348 25.066C21.9541 26.5549 20.17 27.758 18.8156 28.5893C18.1377 29.0054 17.5632 29.3309 17.1452 29.5548C16.9369 29.6664 16.7627 29.7553 16.631 29.8185C16.5664 29.8495 16.5032 29.8786 16.447 29.902C16.4202 29.9132 16.3825 29.9283 16.3407 29.9424C16.3201 29.9494 16.2868 29.96 16.2462 29.97L16.2456 29.9701C16.2196 29.9765 16.1236 30 16 30C15.8764 30 15.7804 29.9765 15.7544 29.9701L15.7538 29.97C15.7132 29.96 15.6799 29.9494 15.6593 29.9424C15.6175 29.9283 15.5798 29.9132 15.553 29.902C15.4968 29.8786 15.4336 29.8495 15.369 29.8185C15.2373 29.7553 15.0631 29.6664 14.8548 29.5548C14.4368 29.3309 13.8623 29.0054 13.1844 28.5893C11.83 27.758 10.0459 26.5549 8.26522 25.066C6.48837 23.5803 4.68726 21.7874 3.3241 19.7713C1.96181 17.7566 1 15.4634 1 13Z"
        fill={color}
      />
    </svg>
  );
};

export default SvgHeart;

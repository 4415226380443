import { IIconProps } from '../interface';

const SvgSaoTomeAndPrince = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16503)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FFDA44"
      />
      <path
        d="M17.0434 18.2609H54.2583C50.3022 7.5985 40.0389 0 28 0C20.2678 0 13.2686 3.13458 8.2019 8.20192L17.0434 18.2609Z"
        fill="#6DA544"
      />
      <path
        d="M17.0434 37.7391H54.2583C50.3022 48.4015 40.0389 56 28 56C20.2678 56 13.2686 52.8654 8.2019 47.7981L17.0434 37.7391Z"
        fill="#6DA544"
      />
      <path
        d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.7991C12.7196 43.2804 17.0654 38.9347 28 28L8.20099 8.20094Z"
        fill="#D80027"
      />
      <path
        d="M35.555 23.1304L36.7637 26.8505H40.6752L37.5107 29.1495L38.7194 32.8696L35.555 30.5704L32.3906 32.8696L33.5991 29.1495L30.4348 26.8505H34.3463L35.555 23.1304Z"
        fill="black"
      />
      <path
        d="M47.7289 23.1304L48.9375 26.8505H52.849L49.6847 29.1495L50.8933 32.8696L47.7289 30.5704L44.5644 32.8696L45.7731 29.1495L42.6086 26.8505H46.5201L47.7289 23.1304Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16503">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSaoTomeAndPrince;

export enum StepsEnum {
  Start = 'Start',
  PartOfYear = 'PartOfYear',
  CompanyOrHoldings = 'CompanyOrHoldings',
  TaxTransparent = 'TaxTransparent',
  TypesOfIncome = 'TypesOfIncome',
  TypesOfCapitalGains = 'TypesOfCapitalGains',
}

export enum TatStorageEnum {
  tat_step = 'tat_step',
  tat_year = 'tat_year',
  tat_menu_roadmap = 'tat_menu_roadmap',
  tat_current_category = 'tat_current_category',
  tat_current_state = 'tat_current_state',
  tat_last_answerd = 'tat_last_answerd',
  tat_category = 'tat_category',
}

export enum Actions {
  confirm = 'confirm',
  submit = 'submit',
  clean = 'clean',
}

import { IIconProps } from '../interface';

const SvgDocumentUpload = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.75 16.8107V19.5H16.25V16.8107L15.0303 18.0303L13.9697 16.9697L16.4697 14.4697C16.6103 14.329 16.8011 14.25 17 14.25C17.1989 14.25 17.3897 14.329 17.5303 14.4697L20.0303 16.9697L18.9697 18.0303L17.75 16.8107Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 5C2.25 3.48079 3.48079 2.25 5 2.25H14.172C14.9011 2.25 15.6008 2.54018 16.1163 3.05567L18.9443 5.88367C19.4598 6.39916 19.75 7.09886 19.75 7.828V11.9489C21.5376 12.9242 22.75 14.8207 22.75 17C22.75 20.1751 20.1763 22.75 17 22.75C15.7979 22.75 14.6819 22.381 13.759 21.75H5C3.48079 21.75 2.25 20.5192 2.25 19V5ZM18.108 7.25C18.0497 7.13832 17.9744 7.03505 17.8837 6.94433L15.0557 4.11633C14.9649 4.02561 14.8617 3.95025 14.75 3.89195V7C14.75 7.13779 14.8622 7.25 15 7.25H18.108ZM13.25 3.75V7C13.25 7.96621 14.0338 8.75 15 8.75H18.25V11.3862C17.8476 11.297 17.4293 11.25 17 11.25C13.8248 11.25 11.25 13.8248 11.25 17C11.25 18.206 11.6215 19.3254 12.2562 20.25H5C4.30921 20.25 3.75 19.6908 3.75 19V5C3.75 4.30921 4.30921 3.75 5 3.75H13.25ZM18.7314 13.1175C18.7102 13.1093 18.6894 13.1002 18.6691 13.0902C18.1567 12.8712 17.5925 12.75 17 12.75C14.6532 12.75 12.75 14.6532 12.75 17C12.75 18.3864 13.4142 19.618 14.4418 20.3938L14.4583 20.4062C15.1672 20.9362 16.047 21.25 17 21.25C19.3477 21.25 21.25 19.3469 21.25 17C21.25 15.2696 20.2158 13.7804 18.7314 13.1175Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgDocumentUpload;

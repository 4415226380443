import { IIconProps } from '../interface';

const SvgSuriname = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16715)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M54.2583 18.2609H1.74147C0.616109 21.2943 0 24.575 0 28C0 31.425 0.616109 34.7059 1.74147 37.7391H54.2584C55.384 34.7059 56 31.425 56 28C56 24.575 55.384 21.2943 54.2583 18.2609Z"
        fill="#A2001D"
      />
      <path
        d="M28.0811 56C37.6714 56 46.1344 51.1774 51.1807 43.8261H4.98169C10.028 51.1774 18.4908 56 28.0811 56Z"
        fill="#6DA544"
      />
      <path
        d="M28.0811 0.000213623C37.6714 0.000213623 46.1344 4.82278 51.1807 12.1741H4.98169C10.028 4.82278 18.4908 0.000213623 28.0811 0.000213623Z"
        fill="#6DA544"
      />
      <path
        d="M28.0001 18.2609L30.4174 25.7006H38.2405L31.9116 30.2992L34.3291 37.7391L28.0001 33.1411L21.6711 37.7391L24.0888 30.2992L17.7598 25.7006H25.5828L28.0001 18.2609Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16715">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSuriname;

import { IIconProps } from '../interface';

const SvgRepublicOfMacedonia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#FFDA44"
    />
    <path
      d="M32.2582 55.6781C36.8121 54.9834 41.004 53.1901 44.5581 50.5793L28 28L32.2582 55.6781Z"
      fill="#D80027"
    />
    <path
      d="M23.7418 0.321892C19.1879 1.01664 14.996 2.80996 11.4418 5.42063L28.0001 28L23.7418 0.321892Z"
      fill="#D80027"
    />
    <path
      d="M11.4418 50.5793C14.9958 53.1899 19.1877 54.9833 23.7415 55.6782L28.0001 28L11.4418 50.5793Z"
      fill="#D80027"
    />
    <path
      d="M5.42064 11.4417C2.80985 14.996 1.01676 19.1878 0.321899 23.7417L28 28L5.42064 11.4417Z"
      fill="#D80027"
    />
    <path
      d="M0.321899 32.2582C1.01665 36.8121 2.80996 41.004 5.42074 44.5581L28 28L0.321899 32.2582Z"
      fill="#D80027"
    />
    <path
      d="M55.6781 23.7417C54.9832 19.1879 53.19 14.996 50.5793 11.4418L28 28L55.6781 23.7417Z"
      fill="#D80027"
    />
    <path
      d="M44.5581 5.42052C41.0039 2.80985 36.8121 1.01664 32.2582 0.321892L28 28L44.5581 5.42052Z"
      fill="#D80027"
    />
    <path
      d="M50.5795 44.5582C53.1902 41.004 54.9834 36.812 55.6783 32.2583L28 28L50.5795 44.5582Z"
      fill="#D80027"
    />
    <path
      d="M28.0001 37.7391C33.3788 37.7391 37.7391 33.3788 37.7391 28C37.7391 22.6213 33.3788 18.2609 28.0001 18.2609C22.6213 18.2609 18.261 22.6213 18.261 28C18.261 33.3788 22.6213 37.7391 28.0001 37.7391Z"
      fill="#D80027"
    />
    <path
      d="M27.9999 35.3044C32.0341 35.3044 35.3043 32.0341 35.3043 28C35.3043 23.9659 32.0341 20.6956 27.9999 20.6956C23.9658 20.6956 20.6956 23.9659 20.6956 28C20.6956 32.0341 23.9658 35.3044 27.9999 35.3044Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgRepublicOfMacedonia;

import { IIconProps } from '../interface';

const SvgTransnistria = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16821)">
      <path
        d="M55.0354 20.6956C51.8224 8.77417 40.9368 0 28 0C15.0633 0 4.1776 8.77417 0.9646 20.6956L28 21.9131L55.0354 20.6956Z"
        fill="#A2001D"
      />
      <path
        d="M0.9646 35.3044C4.1776 47.2257 15.0633 56 28 56C40.9368 56 51.8224 47.2258 55.0354 35.3044L28 34.0869L0.9646 35.3044Z"
        fill="#A2001D"
      />
      <path
        d="M55.0354 20.6956H0.964578C0.336875 23.0245 0 25.4727 0 28C0 30.5273 0.336875 32.9755 0.964578 35.3044H55.0355C55.6631 32.9755 56 30.5273 56 28C56 25.4727 55.6631 23.0245 55.0354 20.6956Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16821">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTransnistria;

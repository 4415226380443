import { IIconProps } from '../interface';

const SvgDominica = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M33.4783 55.4626C44.5473 53.2668 53.2668 44.5472 55.4626 33.4783H33.4783V55.4626Z"
      fill="#496E2D"
    />
    <path
      d="M0.537354 33.4783C2.73306 44.5472 11.4527 53.2668 22.5217 55.4626V33.4783H0.537354Z"
      fill="#496E2D"
    />
    <path
      d="M55.4626 22.5217C53.2668 11.4528 44.5473 2.73317 33.4783 0.537361V22.5217H55.4626Z"
      fill="#496E2D"
    />
    <path
      d="M22.5217 0.537361C11.4527 2.73317 2.73306 11.4528 0.537354 22.5217H22.5217V0.537361Z"
      fill="#496E2D"
    />
    <path
      d="M26.1739 33.4783H22.5217V55.4626C23.7136 55.699 24.9328 55.8591 26.1739 55.9391V33.4783Z"
      fill="#FFDA44"
    />
    <path
      d="M26.1739 22.5217V0.0609207C24.9328 0.140764 23.7135 0.300999 22.5217 0.537358V22.5217H26.1739Z"
      fill="#FFDA44"
    />
    <path
      d="M29.8261 0.0609219C29.2221 0.021875 28.6137 0 28 0C27.3862 0 26.7778 0.021875 26.1738 0.0609219V22.5218H29.826V0.0609219H29.8261Z"
      fill="black"
    />
    <path
      d="M26.1738 55.9391C26.7778 55.9781 27.3862 56 28 56C28.6137 56 29.2221 55.9781 29.8261 55.9391V33.4783H26.1739V55.9391H26.1738Z"
      fill="black"
    />
    <path
      d="M22.5217 26.1739V22.5217H0.53735C0.300882 23.7137 0.140757 24.9328 0.0609131 26.1739H22.5217Z"
      fill="#FFDA44"
    />
    <path
      d="M33.4783 26.1739H55.9392C55.8593 24.9328 55.6991 23.7137 55.4628 22.5217H33.4784V26.1739H33.4783Z"
      fill="#FFDA44"
    />
    <path
      d="M33.4783 29.8261H55.9392C55.9781 29.2223 56 28.6137 56 28C56 27.3863 55.9781 26.7777 55.9391 26.1739H33.4783V29.8261Z"
      fill="black"
    />
    <path
      d="M0.0609219 26.1739C0.0219844 26.7777 0 27.3863 0 28C0 28.6137 0.0219844 29.2223 0.0609219 29.8261H22.5218V26.174H0.0609219V26.1739Z"
      fill="black"
    />
    <path
      d="M28 41.3913C35.3958 41.3913 41.3913 35.3958 41.3913 28C41.3913 20.6042 35.3958 14.6087 28 14.6087C20.6041 14.6087 14.6086 20.6042 14.6086 28C14.6086 35.3958 20.6041 41.3913 28 41.3913Z"
      fill="#D80027"
    />
    <path
      d="M31.0508 29.4941C30.0548 27.502 28.7601 25.483 28.7601 25.483C28.7601 25.483 28.7922 24.5637 28.7922 24.0159C28.7922 23.1908 28.1233 22.5219 27.2982 22.5219C26.5165 22.5219 25.876 23.1224 25.8107 23.8871C25.196 23.9272 24.7096 24.4371 24.7096 25.0619C24.7096 25.5161 24.967 25.9097 25.3436 26.1065C25.5309 25.6686 25.8975 25.3267 26.351 25.1708C26.4249 25.2313 26.5043 25.2853 26.5891 25.3311L26.7805 25.4228C26.7805 25.4228 26.0708 27.4234 26.0708 28.4981C26.0708 31.5195 28.0629 32.4512 28.0629 32.4512V32.4824L27.0668 33.4784H29.0589V31.4862L30.055 32.4823C30.0637 32.4636 31.9417 31.2759 31.0508 29.4941Z"
      fill="#496E2D"
    />
    <path
      d="M27.9999 17.0435L28.4532 18.4384H29.9199L28.7333 19.3006L29.1866 20.6956L27.9999 19.8335L26.8132 20.6956L27.2666 19.3006L26.08 18.4384H27.5467L27.9999 17.0435Z"
      fill="#496E2D"
    />
    <path
      d="M21.56 19.136L22.7465 19.9982L23.9332 19.1359L23.48 20.5311L24.6667 21.3931L23.1999 21.3932L22.7466 22.7882L22.2933 21.3932L20.8265 21.3931L22.0131 20.5309L21.56 19.136Z"
      fill="#496E2D"
    />
    <path
      d="M17.5797 24.6143H19.0463L19.4997 23.2193L19.9529 24.6143L21.4198 24.6142L20.2331 25.4765L20.6865 26.8715L19.4997 26.0093L18.3131 26.8714L18.7662 25.4764L17.5797 24.6143Z"
      fill="#496E2D"
    />
    <path
      d="M17.5797 31.3857L18.7662 30.5237L18.313 29.1286L19.4997 29.9908L20.6864 29.1286L20.2331 30.5236L21.4199 31.3858L19.9529 31.3859L19.4997 32.7808L19.0463 31.3857H17.5797Z"
      fill="#496E2D"
    />
    <path
      d="M21.56 36.864L22.0131 35.4692L20.8264 34.607H22.2932L22.7466 33.2119L23.1999 34.6069H24.6667L23.48 35.4692L23.9332 36.8641L22.7465 36.0019L21.56 36.864Z"
      fill="#496E2D"
    />
    <path
      d="M27.9999 38.9565L27.5467 37.5618H26.08L27.2666 36.6995L26.8132 35.3045L27.9999 36.1665L29.1865 35.3044L28.7333 36.6995L29.9199 37.5617L28.4532 37.5618L27.9999 38.9565Z"
      fill="#496E2D"
    />
    <path
      d="M34.4399 36.864L33.2534 36.0019L32.0668 36.8642L32.52 35.4692L31.3333 34.607L32.8001 34.6069L33.2532 33.2119L33.7067 34.607H35.1734L33.9868 35.4692L34.4399 36.864Z"
      fill="#496E2D"
    />
    <path
      d="M38.4202 31.3857H36.9536L36.5003 32.7808L36.047 31.3859H34.5801L35.7668 30.5236L35.3134 29.1288L36.5003 29.9908L37.6868 29.1288L37.2337 30.5237L38.4202 31.3857Z"
      fill="#496E2D"
    />
    <path
      d="M38.4202 24.6143L37.2337 25.4764L37.6869 26.8714L36.5003 26.0093L35.3135 26.8716L35.7668 25.4765L34.5801 24.6144L36.047 24.6142L36.5002 23.2194L36.9536 24.6143H38.4202Z"
      fill="#496E2D"
    />
    <path
      d="M34.4399 19.136L33.9868 20.5309L35.1734 21.3931H33.7067L33.2534 22.7882L32.8001 21.3932L31.3333 21.3933L32.52 20.5309L32.0667 19.1361L33.2534 19.9981L34.4399 19.136Z"
      fill="#496E2D"
    />
  </svg>
);

export default SvgDominica;

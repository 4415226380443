import { IIconProps } from '../interface';

const SvgSintEustatius = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16578)">
      <path
        d="M12.2185 51.1304C16.7123 54.2022 22.1457 56 27.9999 56C33.8541 56 39.2876 54.2022 43.7813 51.1304L27.9999 48.6956L12.2185 51.1304Z"
        fill="#D80027"
      />
      <path
        d="M43.7813 4.86959C39.2876 1.7978 33.8541 0 27.9999 0C22.1457 0 16.7121 1.7978 12.2185 4.86959L27.9999 7.30439L43.7813 4.86959Z"
        fill="#D80027"
      />
      <path
        d="M12.2185 51.1304C16.7123 54.2022 22.1457 56 27.9999 56C33.8541 56 39.2876 54.2022 43.7813 51.1304L27.9999 48.6956L12.2185 51.1304Z"
        fill="#D80027"
      />
      <path
        d="M43.7813 4.86959C39.2876 1.7978 33.8541 0 27.9999 0C22.1457 0 16.7121 1.7978 12.2185 4.86959L27.9999 7.30439L43.7813 4.86959Z"
        fill="#D80027"
      />
      <path
        d="M55.9287 26.1739C55.2653 17.5322 50.0146 9.13041 43.7814 4.86949H12.2186C5.3131 9.59023 0.6289 17.3217 0.0588379 26.1739L28 28L55.9287 26.1739Z"
        fill="#0052B4"
      />
      <path
        d="M0.0588379 29.8261C0.6289 38.6784 5.31299 46.4098 12.2185 51.1305H43.7813C50.6871 46.4099 55.371 38.6784 55.941 29.8261L28 28L0.0588379 29.8261Z"
        fill="#0052B4"
      />
      <path
        d="M55.9287 26.1738H29.8261V3.65215H26.174V26.1738H0.0589531C0.020125 26.7775 0 27.3864 0 28C0 28.6135 0.0200156 29.2223 0.0588437 29.826H26.174V52.3479H29.8261V29.826H55.9413C55.98 29.2223 56 28.6135 56 28C56 27.3909 55.9754 26.7818 55.9287 26.1738Z"
        fill="#D80027"
      />
      <path
        d="M10.9565 28L27.9999 17.2876L45.0435 28L27.9999 38.7125L10.9565 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M18.261 32.8696H40.1739L35.3045 25.5652L32.8697 28L30.4349 26.7827L28.0001 30.4348H23.1305L20.6957 28L18.261 32.8696Z"
        fill="#6DA544"
      />
      <path
        d="M28.0001 20.6956L28.6045 22.5555H30.56L28.9779 23.7052L29.5823 25.5652L28.0001 24.4157L26.4179 25.5652L27.0223 23.7052L25.4402 22.5555H27.3957L28.0001 20.6956Z"
        fill="#FFDA44"
      />
      <path
        d="M27.9999 14.2268L6.08691 28L27.9999 41.7731L49.913 28L27.9999 14.2268ZM12.95 28L27.9999 18.5405L43.05 28L27.9999 37.4595L12.95 28Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16578">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSintEustatius;

import { ReactElement } from 'react';
import * as theme from '../theme';
interface IGrid {
  children: ReactElement | ReactElement[];
  className?: string;
  fluid?: boolean;
  noPadding?: boolean;
  relative?: boolean;
  onClick?(): void;
}

const {
  minSize,
  gutter: { rem },
} = theme.grid;

const Grid = ({
  children,
  className,
  fluid,
  noPadding,
  relative = true,
  onClick,
}: IGrid) => {
  const minWidth = fluid ? 'auto' : minSize;
  const padding = noPadding ? null : { paddingLeft: rem, paddingRight: rem };

  return (
    <div
      className={`flex ${relative ? 'relative' : 'static'} ${className}`}
      style={{
        boxSizing: 'border-box',
        minWidth,
        ...padding,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Grid;

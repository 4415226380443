import { IIconProps } from '../interface';

const SvgMoldova = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1739 2.77834C36.4928 0.998375 32.363 0 28 0C23.6371 0 19.5073 0.998375 15.8262 2.77834L13.3914 28L15.8262 53.2217C19.5073 55.0016 23.6371 56 28 56C32.363 56 36.4928 55.0016 40.1739 53.2217L42.6087 28L40.1739 2.77834Z"
      fill="#FFDA44"
    />
    <path
      d="M15.8261 2.77933C6.46034 7.30822 0 16.8992 0 28C0 39.1008 6.46034 48.6918 15.8261 53.2207V2.77933Z"
      fill="#0052B4"
    />
    <path
      d="M40.1738 2.77933V53.2207C49.5396 48.6918 56 39.1008 56 28C56 16.8992 49.5396 7.30822 40.1738 2.77933Z"
      fill="#D80027"
    />
    <path
      d="M37.739 22.0302H31.0434C31.0434 20.3493 29.6808 18.9867 27.9999 18.9867C26.3191 18.9867 24.9565 20.3493 24.9565 22.0302H18.2609C18.2609 23.6851 19.7025 25.0266 21.3573 25.0266H21.2575C21.2575 26.6817 22.5991 28.0234 24.2542 28.0234C24.2542 29.4887 25.3066 30.7063 26.6965 30.9666L24.3351 36.2977C25.4666 36.7576 26.7032 37.0133 27.9999 37.0133C29.2966 37.0133 30.5333 36.7576 31.6647 36.2977L29.3034 30.9666C30.6933 30.7063 31.7457 29.4887 31.7457 28.0234C33.4008 28.0234 34.7424 26.6817 34.7424 25.0266H34.6425C36.2975 25.0266 37.739 23.685 37.739 22.0302Z"
      fill="#FF9811"
    />
    <path
      d="M28 26.1739L24.0435 28V31.6522L28 34.087L31.9565 31.6522V28L28 26.1739Z"
      fill="#0052B4"
    />
    <path d="M24.0435 24.3479H31.9565V28H24.0435V24.3479Z" fill="#D80027" />
  </svg>
);

export default SvgMoldova;

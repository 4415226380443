import { IIconProps } from '../interface';

const SvgWeather8 = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_40647)">
        <path
          d="M18.0001 16.99C17.9971 16.99 17.9951 16.992 17.9951 16.995C17.9951 16.998 17.9971 17 18.0001 17C18.0031 17 18.0051 16.998 18.0051 16.995C18.0051 16.992 18.0031 16.99 18.0001 16.99Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0781 6.689C16.5161 6.689 15.9861 6.805 15.5231 7.036C15.1761 4.753 13.1911 3 10.8091 3C8.21209 3 6.09509 5.084 6.02909 7.665C4.47409 7.863 3.26709 9.187 3.26709 10.824C3.26609 12.577 4.68909 14 6.44209 14H17.0781C19.0961 14 20.7341 12.362 20.7341 10.344C20.7341 8.326 19.0961 6.689 17.0781 6.689Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0001 16.9951C11.9971 16.9951 11.9951 16.9971 11.9951 17.0001C11.9951 17.0031 11.9971 17.0051 12.0001 17.0051C12.0031 17.0051 12.0051 17.0031 12.0051 17.0001C12.0051 16.9971 12.0031 16.9951 12.0001 16.9951Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00012 16.99C5.99712 16.99 5.99512 16.992 5.99512 16.995C5.99512 16.998 5.99712 17 6.00012 17C6.00312 17 6.00512 16.998 6.00512 16.995C6.00512 16.992 6.00312 16.99 6.00012 16.99Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00012 20C8.99712 20 8.99512 20.002 8.99512 20.005C8.99512 20.008 8.99712 20.01 9.00012 20.01C9.00312 20.01 9.00512 20.008 9.00512 20.005C9.00512 20.002 9.00312 20 9.00012 20Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.995 19.9951C14.992 19.9951 14.99 19.9971 14.99 20.0001C14.99 20.0031 14.992 20.0051 14.995 20.0051C14.998 20.0051 15 20.0031 15 20.0001C15 19.9971 14.998 19.9951 14.995 19.9951Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_40647">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgWeather8;

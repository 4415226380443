import * as Sentry from '@sentry/react';
import type { ScopeContext, SeverityLevel } from '@sentry/types';

type CaptureContextType = Partial<ScopeContext> &
  Pick<ScopeContext, 'tags' | 'level'>;

type CaptureFuncType<ArgType> = (
  _message: ArgType,
  _captureContext: CaptureContextType
) => string;

export enum AppsCategoryEnum {
  APP = 'APP',
  TOOL = 'TOOL',
  LANDING = 'LANDING',
}

type AppsCategory = keyof typeof AppsCategoryEnum;

type TagType = {
  [_key in AppsCategory]: {
    app: AppsCategory;
  };
};

export type AddBreadcrumbType = typeof Sentry.addBreadcrumb & {
  category?: AppsCategory;
};

export const logException = (error: any) => Sentry.captureException(error)

export const logMessage: CaptureFuncType<string> = (message, captureContext) =>
  Sentry.captureMessage(message, captureContext);

export const addBreadcrumb: AddBreadcrumbType = ({
  category,
  message,
  data,
  level,
}) =>
  Sentry.addBreadcrumb({
    category,
    message,
    level,
    data,
  });

export const Level: Record<Capitalize<SeverityLevel>, SeverityLevel> = {
  Error: 'error',
  Debug: 'debug',
  Info: 'info',
  Warning: 'warning',
  Fatal: 'fatal',
  Log: 'log',
};

export const Tags: TagType = {
  APP: { app: 'APP' },
  TOOL: { app: 'TOOL' },
  LANDING: { app: 'LANDING' },
};

import { IIconProps } from '../interface';

const SvgSomaliland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.2584 37.7391C55.3839 34.7058 56 31.425 56 28C56 24.575 55.3839 21.2942 54.2584 18.2609L28 15.8261L1.74158 18.2609C0.616109 21.2942 0 24.575 0 28C0 31.425 0.616109 34.7058 1.74158 37.7391L28 40.1739L54.2584 37.7391Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 56C40.0389 56 50.3022 48.4015 54.2583 37.7391H1.74146C5.69777 48.4015 15.9609 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M28 0C15.9609 0 5.69777 7.5985 1.74146 18.2609H54.2584C50.3022 7.5985 40.0389 0 28 0Z"
      fill="#496E2D"
    />
    <path
      d="M28 21.9131L29.5108 26.5629H34.4002L30.4447 29.437L31.9557 34.0869L28 31.2133L24.0444 34.0869L25.5554 29.437L21.5999 26.5629H26.4892L28 21.9131Z"
      fill="black"
    />
    <path
      d="M36.3189 5.47827V11.1593C36.3189 12.0546 35.5908 12.7825 34.6958 12.7825V15.2173C36.9334 15.2173 38.7537 13.397 38.7537 11.1593V5.47827H36.3189Z"
      fill="#F0F0F0"
    />
    <path
      d="M18.8696 11.1593C18.8696 12.0546 18.1415 12.7825 17.2463 12.7825V15.2173C19.484 15.2173 21.3044 13.397 21.3044 11.1593V5.47827H18.8696V11.1593Z"
      fill="#F0F0F0"
    />
    <path
      d="M32.6663 5.47827H35.1011V11.1593H32.6663V5.47827Z"
      fill="#F0F0F0"
    />
    <path
      d="M29.0143 8.72452C29.0143 8.9483 28.8322 9.13041 28.6085 9.13041C28.3847 9.13041 28.2026 8.9483 28.2026 8.72452V5.47827H25.7678V8.72452C25.7678 8.9483 25.5858 9.13041 25.3621 9.13041C25.1383 9.13041 24.9563 8.9483 24.9563 8.72452V5.47827H22.5215V8.72452C22.5215 10.291 23.7957 11.5652 25.3621 11.5652C25.9653 11.5652 26.5248 11.3757 26.9852 11.0538C27.4458 11.3757 28.0052 11.5652 28.6084 11.5652C28.7299 11.5652 28.8494 11.5567 28.9668 11.5417C28.7942 12.2525 28.1541 12.7825 27.3911 12.7825V15.2173C29.6287 15.2173 31.449 13.397 31.449 11.1593V8.72452V5.47827H29.0142L29.0143 8.72452Z"
      fill="#F0F0F0"
    />
    <path
      d="M22.5217 12.7827H26.1739V15.2174H22.5217V12.7827Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgSomaliland;

import { IIconProps } from '../interface';

const SvgAlgeria = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 0C43.4639 0 56 12.5361 56 28C56 43.4639 43.4639 56 28 56C28 54.7827 24.3479 28 24.3479 28L28 0Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 56C12.5361 56 0 43.4639 0 28C0 12.5361 12.5361 0 28 0"
      fill="#496E2D"
    />
    <path
      d="M34.016 22.6292L31.7187 25.7951L27.998 24.5885L30.2991 27.7516L28.002 30.9175L31.7212 29.7066L34.0222 32.8696L34.0198 28.9582L37.7391 27.7472L34.0186 26.5408L34.016 22.6292Z"
      fill="#D80027"
    />
    <path
      d="M30.3228 35.9131C25.9526 35.9131 22.4098 32.3702 22.4098 28C22.4098 23.6298 25.9526 20.0869 30.3228 20.0869C31.6854 20.0869 32.9676 20.4315 34.087 21.0381C32.3308 19.3208 29.9294 18.2608 27.2794 18.2608C21.9005 18.2608 17.5403 22.6211 17.5403 27.9999C17.5403 33.3786 21.9006 37.739 27.2794 37.739C29.9295 37.739 32.331 36.679 34.087 34.9617C32.9676 35.5685 31.6854 35.9131 30.3228 35.9131Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgAlgeria;

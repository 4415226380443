import { IIconProps } from '../interface';

const SvgKazakhstan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M43.8261 28.3044H12.1739C12.1739 30.4896 14.0771 32.2609 16.2621 32.2609H16.1304C16.1304 34.4461 17.9017 36.2174 20.0869 36.2174C20.0869 38.4027 21.8583 40.174 24.0435 40.174H31.9565C34.1417 40.174 35.9131 38.4027 35.9131 36.2174C38.0983 36.2174 39.8696 34.4461 39.8696 32.2609H39.7379C41.9227 32.2609 43.8261 30.4896 43.8261 28.3044Z"
      fill="#FFDA44"
    />
    <path
      d="M38.9565 23.1304C38.9565 29.1815 34.0511 34.087 28 34.087C21.9488 34.087 17.0435 29.1815 17.0435 23.1304"
      fill="#338AF3"
    />
    <path
      d="M36.3688 23.1304L32.9492 24.739L34.7702 28.0508L31.0569 27.3405L30.5864 31.0916L28 28.3326L25.4135 31.0916L24.9431 27.3405L21.2298 28.0506L23.0507 24.7389L19.6312 23.1304L23.0508 21.5219L21.2298 18.2102L24.943 18.9204L25.4136 15.1693L28 17.9283L30.5865 15.1693L31.0569 18.9204L34.7703 18.2102L32.9493 21.522L36.3688 23.1304Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgKazakhstan;

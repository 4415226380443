import { IIconProps } from '../interface';

const SvgChair = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.75 7C5.75 8.06867 6.35958 8.99497 7.25 9.45014V12.25H6.5C5.5335 12.25 4.75 13.0335 4.75 14V15C4.75 15.8816 5.40193 16.611 6.25 16.7323V21C6.25 21.4142 6.58579 21.75 7 21.75C7.41421 21.75 7.75 21.4142 7.75 21V16.75H16.25V21C16.25 21.4142 16.5858 21.75 17 21.75C17.4142 21.75 17.75 21.4142 17.75 21V16.7323C18.5981 16.611 19.25 15.8816 19.25 15V14C19.25 13.0335 18.4665 12.25 17.5 12.25H16.75V9.45014C17.6404 8.99497 18.25 8.06867 18.25 7V5C18.25 3.48122 17.0188 2.25 15.5 2.25H8.5C6.98122 2.25 5.75 3.48122 5.75 5V7ZM8.5 3.75C7.80964 3.75 7.25 4.30964 7.25 5V7C7.25 7.56788 7.62868 8.04731 8.1473 8.19955C8.1746 8.20498 8.20136 8.2119 8.22749 8.22021C8.31521 8.23971 8.40641 8.25 8.5 8.25H15.5C15.5936 8.25 15.6848 8.23971 15.7725 8.22021C15.7986 8.2119 15.8254 8.20498 15.8527 8.19955C16.3713 8.04731 16.75 7.56788 16.75 7V5C16.75 4.30964 16.1904 3.75 15.5 3.75H8.5ZM15.9964 13.75L16 13.75L16.0036 13.75H17.5C17.6381 13.75 17.75 13.8619 17.75 14V15C17.75 15.1381 17.6381 15.25 17.5 15.25H6.5C6.36193 15.25 6.25 15.1381 6.25 15V14C6.25 13.8619 6.36193 13.75 6.5 13.75H7.99637L8 13.75L8.00363 13.75H15.9964ZM15.25 12.25V9.75H8.75V12.25H15.25Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgChair;

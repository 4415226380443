import { IIconProps } from '../interface';

const SvgDjibouti = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15543)">
      <path
        d="M25.5652 30.4348C25.5652 30.4348 8.21733 8.19711 8.20093 8.20105C13.2678 3.13403 20.2679 0 28 0C43.4639 0 56 12.5361 56 28L25.5652 30.4348Z"
        fill="#338AF3"
      />
      <path
        d="M25.5652 28C25.5652 28 8.21733 47.8029 8.20093 47.799C13.2678 52.866 20.2679 56 28 56C43.4639 56 56 43.4639 56 28H25.5652Z"
        fill="#6DA544"
      />
      <path
        d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20094Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.3323 20.6956L13.1452 26.2755H19.0127L14.2661 29.7243L16.0789 35.3044L11.3323 31.8557L6.58554 35.3044L8.39876 29.7243L3.6521 26.2755H9.5192L11.3323 20.6956Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15543">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDjibouti;

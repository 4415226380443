import { IIconProps } from '../interface';

const SvgBabyChild = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.84844 11.9618C8.38835 8.47787 11.8562 6.04231 15.8944 6.00055C16.8519 6.02327 17.5 6.75235 17.5 7.5C17.5 8.25958 16.8311 9 15.8484 9C15.1518 9 14.5906 8.6137 14.3421 8.1159L11.6579 9.45553C12.4211 10.9848 14.0304 12 15.8484 12C18.3468 12 20.5 10.0541 20.5 7.5C20.5 7.34985 20.4926 7.2018 20.478 7.05613C22.5494 8.09509 24.2105 9.83269 25.1516 11.9618C25.5633 12.8934 26.404 13.3455 27.025 13.5385C27.3145 13.6284 27.6884 13.8972 28.0058 14.3862C28.3171 14.8658 28.5 15.449 28.5 16C28.5 16.551 28.3171 17.1342 28.0058 17.6138C27.6884 18.1028 27.3145 18.3716 27.025 18.4615C26.404 18.6545 25.5633 19.1066 25.1516 20.0382C23.5982 23.5525 20.0833 26 16 26V29C21.2749 29 25.8129 25.8585 27.851 21.3504C27.8684 21.3426 27.8899 21.3342 27.9152 21.3264C29.0361 20.9781 29.9271 20.1638 30.5221 19.2473C31.1231 18.3215 31.5 17.1768 31.5 16C31.5 14.8232 31.1231 13.6785 30.5221 12.7527C29.9271 11.8362 29.0361 11.0219 27.9152 10.6736C27.8899 10.6658 27.8684 10.6574 27.851 10.6496C25.8129 6.14153 21.2749 3 16 3C15.9684 3 15.9369 3.00011 15.9053 3.00034C15.8864 3.00011 15.8674 3 15.8484 3C10.6382 3.05955 6.16756 6.18479 4.14897 10.6496C4.13159 10.6574 4.11011 10.6658 4.08481 10.6736C2.96392 11.0219 2.07288 11.8362 1.47792 12.7527C0.876894 13.6785 0.5 14.8232 0.5 16C0.5 17.1768 0.876894 18.3215 1.47792 19.2473C2.07288 20.1638 2.96392 20.9781 4.08481 21.3264C4.11011 21.3342 4.1316 21.3426 4.14897 21.3504C6.18712 25.8585 10.7251 29 16 29V26C11.9167 26 8.40175 23.5525 6.84844 20.0382C6.4367 19.1066 5.59602 18.6545 4.97501 18.4615C4.68554 18.3716 4.31163 18.1028 3.99422 17.6138C3.68288 17.1342 3.5 16.551 3.5 16C3.5 15.449 3.68288 14.8658 3.99422 14.3862C4.31163 13.8972 4.68554 13.6284 4.97501 13.5385C5.59601 13.3455 6.4367 12.8934 6.84844 11.9618Z"
            fill={color}
          />
          <path
            d="M14.7192 15.0788C15.055 14.6404 14.9719 14.0127 14.5334 13.6769C14.095 13.341 13.4673 13.4242 13.1315 13.8626C13.0392 13.983 12.921 14.0812 12.7856 14.1498C12.6573 14.2149 12.5166 14.2518 12.3731 14.2582C12.2279 14.251 12.0857 14.2134 11.9558 14.1476C11.8189 14.0783 11.6991 13.9794 11.605 13.8581C11.2667 13.4216 10.6385 13.342 10.202 13.6803C9.76552 14.0187 9.68593 14.6468 10.0243 15.0833C10.2998 15.4388 10.6509 15.7287 11.0522 15.9319C11.4535 16.1351 11.8949 16.2466 12.3445 16.2584C12.3603 16.2588 12.376 16.2588 12.3918 16.2585C12.8434 16.2489 13.287 16.138 13.6898 15.9338C14.0927 15.7296 14.4445 15.4374 14.7192 15.0788Z"
            fill={color}
          />
          <path
            d="M21.9792 15.0788C22.315 14.6404 22.2319 14.0127 21.7935 13.6769C21.355 13.341 20.7274 13.4241 20.3915 13.8626C20.2991 13.9831 20.1808 14.0813 20.0454 14.15C19.9168 14.2151 19.7759 14.2519 19.6321 14.2582C19.4871 14.2511 19.3451 14.2135 19.2154 14.1478C19.0787 14.0785 18.9592 13.9797 18.8654 13.8586C18.5274 13.4219 17.8993 13.3419 17.4625 13.6799C17.0258 14.018 16.9458 14.6461 17.2839 15.0828C17.5592 15.4384 17.9101 15.7284 18.3112 15.9317C18.7123 16.135 19.1536 16.2466 19.6032 16.2584C19.6187 16.2588 19.6343 16.2588 19.6498 16.2585C20.1017 16.2492 20.5457 16.1384 20.9489 15.9342C21.3522 15.73 21.7043 15.4376 21.9792 15.0788Z"
            fill={color}
          />
          <path
            d="M12.9412 18.5984L13.6947 19.2558C14.3335 19.813 15.1524 20.12 16 20.12C16.8476 20.12 17.6666 19.813 18.3053 19.2558L19.0588 18.5984L20.3736 20.1055L19.6201 20.7629C18.6171 21.6379 17.3311 22.12 16 22.12C14.669 22.12 13.3829 21.6379 12.3799 20.7629L11.6264 20.1055L12.9412 18.5984Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgBabyChild;

import { IIconProps } from '../interface';

const SvgVietnam = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_17028)">
      <path
        d="M28 56.8359C43.464 56.8359 56 44.2999 56 28.8359C56 13.372 43.464 0.835938 28 0.835938C12.536 0.835938 0 13.372 0 28.8359C0 44.2999 12.536 56.8359 28 56.8359Z"
        fill="#D80027"
      />
      <path
        d="M28.0001 15.4446L31.0219 24.7446H40.8006L32.8894 30.4923L35.9112 39.7924L28.0001 34.0447L20.089 39.7924L23.1108 30.4923L15.1997 24.7446H24.9783L28.0001 15.4446Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_17028">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgVietnam;

import { IIconProps } from '../interface';

const SvgMali = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.7391 1.74158C34.7058 0.616109 31.425 0 28 0C24.5751 0 21.2943 0.616109 18.261 1.74158L15.8262 28L18.261 54.2584C21.2943 55.384 24.5751 56 28 56C31.425 56 34.7058 55.384 37.7391 54.2584L40.1739 28L37.7391 1.74158Z"
      fill="#FFDA44"
    />
    <path
      d="M56.0001 28C56.0001 15.9611 48.4016 5.69779 37.7391 1.7417V54.2586C48.4016 50.3022 56.0001 40.0391 56.0001 28Z"
      fill="#D80027"
    />
    <path
      d="M0 28C0 40.0391 7.5985 50.3022 18.2609 54.2585V1.7417C7.5985 5.69779 0 15.9611 0 28Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgMali;

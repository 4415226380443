import { IIconProps } from '../interface';

const SvgTokelau = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16808)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FFDA44"
      />
      <path
        d="M45.0435 13.3913C44.7923 14.5983 44.6783 15.8838 44.6783 17.1652C44.6783 24.5517 49.0816 30.9448 55.392 33.8228C55.7893 31.9442 56 29.9967 56 28C56 12.5361 43.4639 0 28 0C12.5361 0 0 12.5361 0 28C0 43.4639 12.5361 56 28 56C37.5902 56 46.0532 51.1774 51.0996 43.8261H15.8261V40.174H53.2217C53.6044 39.3825 53.9501 38.57 54.2585 37.7392H15.8261L45.0435 13.3913Z"
        fill="#0052B4"
      />
      <path
        d="M20.5704 14.6087L21.1748 16.4687H23.1304L21.5483 17.6183L22.1527 19.4783L20.5704 18.3288L18.9883 19.4783L19.5926 17.6183L18.0103 16.4687H19.966L20.5704 14.6087Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.626 28L13.3816 30.3252H15.8261L13.8483 31.7618L14.6038 34.0869L12.626 32.65L10.6483 34.0869L11.4037 31.7618L9.42603 30.3252H11.8706L12.626 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.626 8.52173L13.3816 10.8469H15.8261L13.8483 12.2836L14.6038 14.6087L12.626 13.1717L10.6483 14.6087L11.4037 12.2836L9.42603 10.8469H11.8706L12.626 8.52173Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.5391 19.4783L7.29466 21.8035H9.73908L7.76136 23.2401L8.51692 25.5652L6.5391 24.1282L4.56138 25.5652L5.31683 23.2401L3.33911 21.8035H5.78353L6.5391 19.4783Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16808">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTokelau;

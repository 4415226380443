import { IIconProps } from '../interface';

const SvgLocation = ({ width = 32, height = 32, color, type }: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1702_11987)">
        <path
          d="M15.9988 15.7871C15.8028 15.7871 15.6455 15.9471 15.6455 16.1418C15.6455 16.3378 15.8042 16.4951 16.0002 16.4951C16.1962 16.4951 16.3535 16.3364 16.3535 16.1404C16.3548 15.9458 16.1962 15.7871 15.9988 15.7871Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.5188 6.58002C26.3488 7.41001 26.3488 8.7557 25.5188 9.5857C24.6888 10.4157 23.3431 10.4157 22.5131 9.5857C21.6831 8.7557 21.6831 7.41002 22.5131 6.58002C23.3431 5.75002 24.6888 5.75002 25.5188 6.58002Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.1965 11.9453C22.5142 14.2629 22.5142 18.0205 20.1965 20.3381C17.8789 22.6558 14.1213 22.6558 11.8036 20.3381C9.48602 18.0205 9.48602 14.2629 11.8036 11.9453C14.1213 9.62762 17.8789 9.62762 20.1965 11.9453"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.3601 6.76434C17.9494 3.76568 11.8947 4.21634 7.98406 8.12701C3.5574 12.5537 3.5574 19.731 7.98406 24.1577C12.4107 28.5843 19.5894 28.5843 24.0161 24.1577C26.7414 21.4337 27.7814 17.6683 27.1521 14.1417"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_11987">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.141602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgLocation;

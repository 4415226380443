import { IIconProps } from '../interface';

const SvgVenezuela = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_17015)">
      <path
        d="M53.2217 41.0098C55.0017 37.3287 56 33.199 56 28.836C56 24.4729 55.0017 20.3432 53.2217 16.6621L28 14.2273L2.77834 16.6621C0.998266 20.3432 0 24.4729 0 28.836C0 33.199 0.998266 37.3287 2.77834 41.0098L28 43.4446L53.2217 41.0098Z"
        fill="#0052B4"
      />
      <path
        d="M28 56.836C39.1008 56.836 48.6926 50.3757 53.2216 41.0098H2.77832C7.30732 50.3757 16.8991 56.836 28 56.836Z"
        fill="#D80027"
      />
      <path
        d="M48.4934 34.3323L47.0145 35.4879L47.6563 37.2511L46.1002 36.2019L44.6213 37.3573L45.1384 35.5532L43.5825 34.5036L45.4582 34.4382L45.9758 32.6341L46.6178 34.3977L48.4934 34.3323Z"
        fill="#F0F0F0"
      />
      <path
        d="M44.7059 27.773L43.7116 29.3648L44.9181 30.8022L43.0969 30.3487L42.1023 31.94L41.9711 30.0678L40.1501 29.6136L41.8905 28.9104L41.7597 27.0381L42.966 28.4761L44.7059 27.773Z"
        fill="#F0F0F0"
      />
      <path
        d="M38.904 22.9044L38.514 24.7403L40.1393 25.6787L38.2728 25.8755L37.8826 27.7108L37.1191 25.9965L35.2524 26.1923L36.6474 24.9365L35.8841 23.2222L37.5094 24.1604L38.904 22.9044Z"
        fill="#F0F0F0"
      />
      <path
        d="M31.7867 20.3142L32.0482 22.1728L33.8963 22.4985L32.2096 23.3217L32.4709 25.1802L31.1671 23.8301L29.4799 24.6528L30.3612 22.9954L29.0574 21.6455L30.9058 21.9713L31.7867 20.3142Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.213 20.3142L25.0943 21.9713L26.9423 21.6453L25.6388 22.9958L26.5196 24.653L24.8329 23.8301L23.5289 25.1799L23.7904 23.3217L22.1035 22.499L23.9518 22.1728L24.213 20.3142Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.0958 22.9044L18.4908 24.1604L20.1157 23.2222L19.3526 24.9372L20.7473 26.1928L18.8808 25.9965L18.1171 27.7108L17.7274 25.8752L15.8608 25.6792L17.4862 24.7405L17.0958 22.9044Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.2939 27.773L13.0344 28.4761L14.2404 27.0385L14.1099 28.9111L15.8498 29.6142L14.0288 30.068L13.8977 31.94L12.9035 30.3487L11.0823 30.8025L12.2887 29.3647L11.2939 27.773Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.50684 34.3323L9.38284 34.3977L10.0244 32.6343L10.5423 34.4386L12.4178 34.5041L10.8618 35.5537L11.3789 37.3577L9.90018 36.2023L8.3441 37.2515L8.98602 35.4879L7.50684 34.3323Z"
        fill="#F0F0F0"
      />
      <path
        d="M53.2216 16.6621C48.6926 7.29617 39.1008 0.835938 28 0.835938C16.8991 0.835938 7.30732 7.29617 2.77832 16.6621H53.2216Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_17015">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgVenezuela;

import { IIconProps } from '../interface';

const SvgHaiti = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 12.5361 28 0 28 0C28 0 56 12.5361 56 28Z"
      fill="#A2001D"
    />
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28"
      fill="#0052B4"
    />
    <path
      d="M37.7391 35.3044L28 34.0869L18.2609 35.3044V20.6956H37.7391V35.3044Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 34.0869C30.6894 34.0869 32.8696 31.9067 32.8696 29.2173C32.8696 26.5279 30.6894 24.3477 28 24.3477C25.3106 24.3477 23.1304 26.5279 23.1304 29.2173C23.1304 31.9067 25.3106 34.0869 28 34.0869Z"
      fill="#0052B4"
    />
    <path
      d="M28 31.6521C29.3447 31.6521 30.4348 30.562 30.4348 29.2173C30.4348 27.8726 29.3447 26.7825 28 26.7825C26.6553 26.7825 25.5652 27.8726 25.5652 29.2173C25.5652 30.562 26.6553 31.6521 28 31.6521Z"
      fill="#A2001D"
    />
    <path
      d="M24.3478 23.1304H31.6521L28 26.7827L24.3478 23.1304Z"
      fill="#6DA544"
    />
    <path
      d="M26.7827 25.5652H29.2175V32.8696H26.7827V25.5652Z"
      fill="#FFDA44"
    />
    <path
      d="M31.8956 32.1391H24.1042L18.2609 35.3044H37.7391L31.8956 32.1391Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgHaiti;

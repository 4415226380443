import { Suspense, lazy, useEffect } from 'react';
import { Icon, Typography } from '../../../design-system';
import { IAdvice } from './components/AdviceItem';
import Skeleton from 'react-loading-skeleton';
import SkeletonBox from './components/SkeletonBox';
import 'react-loading-skeleton/dist/skeleton.css';
import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { RETRIES_NUMBER, RETRY_DELAY } from '../../AppScreens/const';
import { supabase } from '../../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import AdvisorService from '../../../services/advisors';
import Novu from '../../../components/notification/Notification';

const AdviceItem = lazy(() => import('./components/AdviceItem'));

const AdviceScreen = () => {
  const navigate = useNavigate();
  const handleGetAdvices = async (): Promise<IAdvice[]> => {
    const { data: sessionData } = await supabase.auth.getSession();
    const adviceService = new AdvisorService(sessionData.session);
    const { data } = await adviceService.getAdvices();

    return data;
  };

  const handleAdvisor = async () => {
    const { data: sessionData } = await supabase.auth.getSession();
    const adviceService = new AdvisorService(sessionData.session);
    const { data } = await adviceService.getAdvisorProfile();

    if (data.status !== 'active') {
      navigate(`/partner/info`, { state: { inactiveAdvisor: true } });
    }

    return data;
  };

  const {
    data: advicesSource,
    isRefetching,
    isLoading,
  } = useQuery({
    queryKey: ['advices'],
    queryFn: handleGetAdvices,
    retryDelay: RETRY_DELAY,
    retry: RETRIES_NUMBER,
  });

  const { isLoading: loadingAdvisor } = useQuery({
    queryKey: ['advisor'],
    queryFn: handleAdvisor,
    retryDelay: RETRY_DELAY,
    retry: RETRIES_NUMBER,
  });

  useEffect(() => {
    handleAdvisor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeAdvices =
    advicesSource?.filter((item: IAdvice) => item.status !== 'declined') || [];

  const declinedAdvices =
    advicesSource?.filter((item: IAdvice) => item.status === 'declined') || [];

  const advices = [...activeAdvices, ...declinedAdvices];

  if (isRefetching || isLoading || loadingAdvisor) {
    return (
      <div className="flex justify-center w-full">
        <div className="flex flex-col w-spacing-610 mt-spacing-32 md:mt-spacing-72">
          <Typography variant="HeadingDefault600">
            {t('advice.title')}
          </Typography>
          <div className="mt-spacing-64">
            <Skeleton
              count={5}
              height={64}
              wrapper={SkeletonBox}
              borderRadius={12}
            />
          </div>
        </div>
      </div>
    );
  }

  if (!advices?.length) {
    return (
      <div className="flex justify-center w-full">
        <div className="flex flex-col w-spacing-610 mt-spacing-32 md:mt-spacing-72">
          <Typography variant="HeadingDefault600">
            {t('advice.title')}
          </Typography>
          <Typography
            variant="LabelSmall400"
            className="mt-spacing-64 text-gray-700"
          >
            {t('advice.sub_title_advisor')}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col w-spacing-610 mt-spacing-32 md:mt-spacing-72">
        <div className="flex justify-between items-center">
          <Typography variant="HeadingDefault600">
            {t('advice.title')}
          </Typography>
          <div className="flex gap-2">
            <button
              title={t('see_proposal_templates')}
              onClick={() => navigate('/partner/proposal/templates')}
            >
              <Icon
                width={30}
                height={30}
                name={'InvoiceHandSelect'}
                color="#828299"
              />
            </button>
            <button
              title={t('see_payments')}
              onClick={() => navigate('/partner/payments')}
            >
              <Icon
                width={30}
                height={30}
                name={'HandArmCoins'}
                color="#828299"
              />
            </button>
            <Novu />
          </div>
        </div>
        <div className="w-full flex px-spacing-32 mb-spacing-12 mt-spacing-64 items-center">
          <div className="flex flex-1">
            <div className="flex-1 w-2/4">
              <div className="flex">
                <Typography variant="LabelSmall400">
                  {t('advice.author_name')}
                </Typography>
              </div>
            </div>
            <div className="flex-1 w-2/4">
              <div className="flex">
                <Typography variant="LabelSmall400">
                  {t('advice.countries')}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex justify-end w-[91px]">
            <Typography variant="LabelSmall400">
              {t('advice.status')}
            </Typography>
          </div>
        </div>
        <Suspense
          fallback={
            <Skeleton
              count={5}
              height={72}
              wrapper={SkeletonBox}
              borderRadius={12}
            />
          }
        >
          {advices?.map((advice) => (
            <div className="mb-spacing-24" key={advice.adviceId}>
              <AdviceItem {...advice} />
            </div>
          ))}
        </Suspense>
      </div>
    </div>
  );
};

export default AdviceScreen;

import { IIconProps } from '../interface';

const SvgZambia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#496E2D"
    />
    <path
      d="M38.9565 28H28V56C31.2729 56 34.4139 55.4366 37.3333 54.4047L38.9565 28Z"
      fill="#D80027"
    />
    <path
      d="M46.6666 48.868C52.3943 43.7411 56 36.292 56 28H45.0435L46.6666 48.868Z"
      fill="#FF9811"
    />
    <path
      d="M37.3333 28V54.4047C40.811 53.1752 43.9735 51.2787 46.6667 48.8681V28H37.3333Z"
      fill="black"
    />
    <path
      d="M51.7391 18.2609H44.4347C44.4347 16.9163 43.3446 15.8261 41.9999 15.8261C40.6553 15.8261 39.5651 16.9163 39.5651 18.2609H32.2607C32.2607 19.6057 33.432 20.6957 34.7766 20.6957H34.6955C34.6955 22.0405 35.7855 23.1305 37.1303 23.1305C37.1303 24.4753 38.2203 25.5653 39.5651 25.5653H44.4347C45.7794 25.5653 46.8695 24.4753 46.8695 23.1305C48.2142 23.1305 49.3043 22.0405 49.3043 20.6957H49.2233C50.5679 20.6956 51.7391 19.6056 51.7391 18.2609Z"
      fill="#FF9811"
    />
  </svg>
);

export default SvgZambia;

import { IIconProps } from '../interface';

const SvgHomeCustomer = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_40999)">
        <path
          d="M13.8901 20.526V20.136V20.126C13.8901 19.026 14.7701 18.136 15.8701 18.136H19.0001C20.0901 18.136 20.9801 19.016 20.9801 20.116V20.496C20.9801 20.756 20.7601 20.966 20.5001 20.966H14.3401C14.0701 20.956 13.8601 20.746 13.8601 20.486L13.8901 20.526Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.44 15.789C16.39 15.779 15.54 14.929 15.54 13.889C15.54 12.839 16.39 11.989 17.43 11.989C18.47 11.989 19.32 12.839 19.32 13.879C19.31 14.919 18.47 15.759 17.42 15.769L17.44 15.789Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 10.6899L10.93 3.87991V3.86991C11.54 3.33991 12.44 3.33991 13.05 3.86991L20.98 10.6699"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.7101 9.60999V3.80999C19.7101 3.34999 19.3401 2.98999 18.8901 2.98999H16.4301H16.4201C15.9601 2.98999 15.6001 3.34999 15.6001 3.79999V6.07999"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.54006 20V14.81H9.53906C9.53906 14.35 9.89906 13.99 10.3491 13.99H11.9791"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.28003 9.61011V19.9901"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 20H3"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_40999">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgHomeCustomer;

import { IIconProps } from '../interface';

const SvgSaudiArabia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#6DA544"
    />
    <path
      d="M15.8262 33.4783C15.8262 35.4953 17.4613 37.1304 19.4783 37.1304H30.4348C30.4348 38.8113 31.7974 40.1739 33.4783 40.1739H37.1305C38.8113 40.1739 40.1739 38.8113 40.1739 37.1304V33.4783H15.8262Z"
      fill="#F0F0F0"
    />
    <path
      d="M40.4783 15.8261V24.3479C40.4783 25.6904 39.386 26.7827 38.0435 26.7827V30.4348C41.3998 30.4348 44.1304 27.7041 44.1304 24.3479V15.8261H40.4783Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.3044 24.3479C14.3044 25.6904 13.2122 26.7827 11.8696 26.7827V30.4348C15.226 30.4348 17.9566 27.7041 17.9566 24.3479V15.8261H14.3044V24.3479Z"
      fill="#F0F0F0"
    />
    <path d="M35 15.8261H38.6521V24.3479H35V15.8261Z" fill="#F0F0F0" />
    <path
      d="M29.5218 20.6956C29.5218 21.0313 29.2487 21.3043 28.9131 21.3043C28.5776 21.3043 28.3044 21.0312 28.3044 20.6956V15.826H24.6523V20.6956C24.6523 21.0313 24.3792 21.3043 24.0436 21.3043C23.7081 21.3043 23.435 21.0312 23.435 20.6956V15.826H19.7827V20.6956C19.7827 23.0451 21.6942 24.9565 24.0436 24.9565C24.9485 24.9565 25.7876 24.6722 26.4784 24.1892C27.1691 24.6721 28.0084 24.9565 28.9132 24.9565C29.0955 24.9565 29.2746 24.9437 29.4508 24.9213C29.192 25.9876 28.2317 26.7825 27.0871 26.7825V30.4347C30.4435 30.4347 33.174 27.704 33.174 24.3478V20.6956V15.826H29.5219V20.6956H29.5218Z"
      fill="#F0F0F0"
    />
    <path d="M19.7827 26.7827H25.261V30.4348H19.7827V26.7827Z" fill="#F0F0F0" />
  </svg>
);

export default SvgSaudiArabia;

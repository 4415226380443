import { IIconProps } from '../interface';

const SvgTurkey = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M26.8535 22.8797L29.1509 26.0456L32.8715 24.8392L30.5705 28.0023L32.8676 31.168L29.1483 29.957L26.8474 33.1202L26.8498 29.2088L23.1304 27.9978L26.8511 26.7914L26.8535 22.8797Z"
      fill="#F0F0F0"
    />
    <path
      d="M20.5837 35.9131C16.2135 35.9131 12.6706 32.3702 12.6706 28C12.6706 23.6298 16.2135 20.0869 20.5837 20.0869C21.9463 20.0869 23.2284 20.4316 24.3477 21.0381C22.5917 19.3206 20.1902 18.2608 17.5402 18.2608C12.1614 18.2608 7.80115 22.6211 7.80115 27.9999C7.80115 33.3786 12.1615 37.739 17.5402 37.739C20.1904 37.739 22.5918 36.679 24.3477 34.9617C23.2284 35.5684 21.9463 35.9131 20.5837 35.9131Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgTurkey;

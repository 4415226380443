import { IIconProps } from '../interface';

const SvgNorthernCyprus = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.77453 9.73907C5.79201 10.8802 4.90006 12.1011 4.1095 13.3912H51.8902C51.0996 12.1011 50.2078 10.8801 49.2252 9.73907H6.77453Z"
      fill="#D80027"
    />
    <path
      d="M6.77454 46.2609H49.2253C50.2078 45.1198 51.0997 43.8989 51.8903 42.6088H4.10962C4.90007 43.8987 5.79203 45.1198 6.77454 46.2609Z"
      fill="#D80027"
    />
    <path
      d="M24.4169 22.8797L26.7142 26.0456L30.4348 24.8392L28.1339 28.0023L30.4309 31.168L26.7116 29.957L24.4107 33.1202L24.413 29.2088L20.6937 27.9978L24.4144 26.7914L24.4169 22.8797Z"
      fill="#D80027"
    />
    <path
      d="M18.1469 35.9131C13.7767 35.9131 10.2339 32.3702 10.2339 28C10.2339 23.6298 13.7767 20.0869 18.1469 20.0869C19.5095 20.0869 20.7917 20.4315 21.9111 21.0381C20.155 19.3208 17.7535 18.2608 15.1035 18.2608C9.72461 18.2608 5.36438 22.6211 5.36438 27.9999C5.36438 33.3786 9.72472 37.739 15.1035 37.739C17.7535 37.739 20.155 36.679 21.9111 34.9617C20.7917 35.5685 19.5096 35.9131 18.1469 35.9131Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgNorthernCyprus;

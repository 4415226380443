import { createSlice } from '@reduxjs/toolkit';

export interface IStateHeader {
  helpButton: boolean;
}

const initialState: IStateHeader = {
  helpButton: false,
};

export const headerSlice = createSlice({
  name: 'tatool',
  initialState,
  reducers: {
    setShowHelpButton: (state, action) => {
      state.helpButton = action.payload;
    },
  },
});

export const { setShowHelpButton } = headerSlice.actions;

export default headerSlice.reducer;

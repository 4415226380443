import { IIconProps } from '../interface';

const SvgNepal = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16232)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.8356 31.0435L55.8349 31.0426L55.8348 31.0435H55.8356Z"
        fill="#0052B4"
      />
      <path
        d="M55.8348 31.0435C55.8348 31.0433 55.835 31.043 55.835 31.0426L25.1285 0.145584C23.7432 0.286787 22.3877 0.528944 21.0697 0.864615C8.95759 3.94844 0 28 0 28C0 28 39.1998 54.232 43.6718 51.2055C44.5666 50.6 45.4245 49.944 46.2413 49.2418L28.0432 31.0435H55.8348Z"
        fill="#0052B4"
      />
      <path
        d="M48.6956 28L21.4643 0.768799C9.15337 3.71285 0 14.7866 0 28C0 43.4639 12.5361 56 28 56C33.8657 56 39.309 54.1951 43.8079 51.1122L20.6956 28H48.6956Z"
        fill="#D80027"
      />
      <path
        d="M26.6297 41.343L23.2101 39.7345L25.0311 36.4227L21.3178 37.1331L20.8473 33.3818L18.2609 36.1408L15.6745 33.3818L15.2039 37.1331L11.4907 36.4226L13.3117 39.7345L9.89197 41.343L13.3117 42.9513L11.4907 46.2632L15.2041 45.5529L15.6744 49.3041L18.2609 46.5451L20.8473 49.3041L21.3178 45.5529L25.031 46.2633L23.21 42.9516L26.6297 41.343Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.3478 15.5294L21.8606 14.3596L23.185 11.9509L20.4843 12.4675L20.142 9.73907L18.2609 11.7458L16.3797 9.73907L16.0374 12.4675L13.3366 11.9509L14.6611 14.3596L12.1738 15.5294L18.2609 16.747L24.3478 15.5294Z"
        fill="#F0F0F0"
      />
      <path
        d="M25.5652 15.5294C25.5652 19.5635 22.2949 22.8338 18.2608 22.8338C14.2267 22.8338 10.9564 19.5635 10.9564 15.5294"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16232">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgNepal;

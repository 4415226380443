import { IIconProps } from '../interface';

const SvgEgypt = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C15.961 0 5.69778 7.5985 1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0Z"
      fill="#D80027"
    />
    <path
      d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
      fill="black"
    />
    <path
      d="M37.7391 24.9565H30.4347C30.4347 23.6119 29.3445 22.5217 27.9999 22.5217C26.6552 22.5217 25.5651 23.6119 25.5651 24.9565H18.2607C18.2607 26.3013 19.432 27.3913 20.7766 27.3913H20.6955C20.6955 28.7361 21.7855 29.8261 23.1303 29.8261C23.1303 31.1709 24.2203 32.2609 25.5651 32.2609H30.4347C31.7794 32.2609 32.8695 31.1709 32.8695 29.8261C34.2142 29.8261 35.3043 28.7361 35.3043 27.3913H35.2232C36.5679 27.3913 37.7391 26.3013 37.7391 24.9565Z"
      fill="#FF9811"
    />
  </svg>
);

export default SvgEgypt;

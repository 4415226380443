import { IIconProps } from '../interface';

const SvgTrash = ({
  width = 16,
  height = 16,
  color = '#0E1FC1',
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2036_40394)">
        <path
          d="M20.7219 28H11.2781C10.5223 28 9.79431 27.7147 9.23975 27.2012C8.68519 26.6876 8.3449 25.9837 8.28693 25.2301L7 8.5H25L23.7131 25.2301C23.6551 25.9837 23.3148 26.6876 22.7603 27.2012C22.2057 27.7147 21.4777 28 20.7219 28V28Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6667 8.5H5.33337"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 4H19.75C20.1478 4 20.5294 4.15804 20.8107 4.43934C21.092 4.72064 21.25 5.10218 21.25 5.5V8.5H10.75V5.5C10.75 5.10218 10.908 4.72064 11.1893 4.43934C11.4706 4.15804 11.8522 4 12.25 4V4Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.625 14.5V22"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.375 14.5V22"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2036_40394">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgTrash;

import { IIconProps } from '../interface';

const SvgDemocraticRepublicOfCongo = ({
  width = 56,
  height = 56,
}: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15529)">
      <path
        d="M52.8908 15.1611C51.597 12.6544 49.9012 10.3015 47.7999 8.20017C45.6985 6.09875 43.3455 4.40289 40.8388 3.1092L21.1131 21.113L3.10925 40.8386C4.40305 43.3454 6.09891 45.6984 8.20022 47.7997C10.3015 49.901 12.6545 51.5969 15.1612 52.8907L34.8868 34.8868L52.8908 15.1611Z"
        fill="#FFDA44"
      />
      <path
        d="M8.20022 47.7998C9.3748 48.9744 10.6281 50.022 11.9427 50.9443L50.9441 11.9428C50.0219 10.6282 48.9743 9.37486 47.7998 8.20028C46.6251 7.0257 45.3719 5.97822 44.0573 5.05597L5.05591 44.0573C5.97805 45.3717 7.02553 46.6252 8.20022 47.7998Z"
        fill="#D80027"
      />
      <path
        d="M8.20029 8.20013C-0.633597 17.034 -2.32946 30.301 3.10933 40.8387L40.8389 3.10927C30.301 -2.3294 17.0341 -0.633322 8.20029 8.20013Z"
        fill="#338AF3"
      />
      <path
        d="M47.7999 47.7998C56.6337 38.9661 58.3294 25.6991 52.891 15.1613L15.1614 52.8908C25.6991 58.3295 38.9662 56.6337 47.7999 47.7998Z"
        fill="#338AF3"
      />
      <path
        d="M14.9216 8.52086L16.4325 13.1712H21.322L17.3664 16.0451L18.8773 20.6953L14.9216 17.8212L10.9657 20.6953L12.4769 16.0451L8.521 13.1712H13.4105L14.9216 8.52086Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15529">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDemocraticRepublicOfCongo;

import { IIconProps } from '../interface';

const SvgBurundi = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M45.0489 5.78616C35.0245 -1.92762 20.9755 -1.92762 10.951 5.78605L28 22.835L45.0489 5.78616Z"
      fill="#D80027"
    />
    <path
      d="M27.9998 33.1651L10.951 50.2138C20.9755 57.9276 35.0245 57.9276 45.0489 50.2138L28 33.1649L27.9998 33.1651Z"
      fill="#D80027"
    />
    <path
      d="M22.835 28L5.78613 10.9511C-1.92765 20.9755 -1.92765 35.0245 5.78613 45.049L22.835 28Z"
      fill="#6DA544"
    />
    <path
      d="M33.1651 28L50.2138 45.049C57.9276 35.0245 57.9276 20.9755 50.2138 10.9511L33.1651 28Z"
      fill="#6DA544"
    />
    <path
      d="M28 40.1739C34.7234 40.1739 40.1739 34.7234 40.1739 28C40.1739 21.2766 34.7234 15.8261 28 15.8261C21.2765 15.8261 15.8261 21.2766 15.8261 28C15.8261 34.7234 21.2765 40.1739 28 40.1739Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 19.4783L29.0543 21.3044H31.1629L30.1086 23.1304L31.1629 24.9565H29.0543L28 26.7827L26.9458 24.9565H24.8372L25.8915 23.1304L24.8372 21.3044H26.9458L28 19.4783Z"
      fill="#D80027"
    />
    <path
      d="M22.6412 28L23.6954 29.8261H25.804L24.7497 31.6521L25.804 33.4783H23.6954L22.6412 35.3044L21.5868 33.4783H19.4783L20.5325 31.6521L19.4783 29.8261H21.5868L22.6412 28Z"
      fill="#D80027"
    />
    <path
      d="M33.359 28L34.4132 29.8261H36.5217L35.4675 31.6521L36.5217 33.4783H34.4132L33.359 35.3044L32.3046 33.4783H30.196L31.2503 31.6521L30.196 29.8261H32.3046L33.359 28Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgBurundi;

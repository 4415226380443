import { IIconProps } from '../interface';

const SvgSriLanka = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.000109375 4.8696H0V51.1304H0.000109375V28.0002V4.8696Z"
      fill="#FF9811"
    />
    <path
      d="M27.9999 55.9999C43.4639 55.9999 55.9999 43.4639 55.9999 27.9999C55.9999 12.536 43.4639 0 27.9999 0C12.536 0 0 12.536 0 27.9999C0 43.4639 12.536 55.9999 27.9999 55.9999Z"
      fill="#FFDA44"
    />
    <path
      d="M21.9132 4.8696H12.2187C11.7891 5.16327 11.3687 5.46897 10.9567 5.7854L8.52185 27.9999L10.9567 50.2145C11.3687 50.531 11.7891 50.8367 12.2187 51.1303H21.9132V4.8696Z"
      fill="#FF9811"
    />
    <path
      d="M0.00012207 28.0002C0.00012207 37.0504 4.29507 45.0963 10.9567 50.2147V5.78552C4.29507 10.9041 0.00012207 18.9499 0.00012207 28.0002Z"
      fill="#6DA544"
    />
    <path
      d="M45.0436 17.0695V17.7701L45.0696 17.774L45.0436 17.0695Z"
      fill="#FFDA44"
    />
    <path
      d="M53.7682 35.3043H48.4927L46.2609 37.7391V42.6087H41.3913V40.1739H43.8261V35.3043H32.8696V38.3478H30.4348V32.5475C29.6878 31.8788 29.2175 30.9075 29.2175 29.8261V14C29.2175 11.983 30.8526 10.3479 32.8696 10.3479V32.8697H37.7392L39.41 31.4772C39.1182 30.7821 38.9565 30.0188 38.9565 29.2175V25.5654H35.3044V18.2611H42.6087C42.6087 17.0437 44.4348 15.8263 44.4348 15.8263C44.4348 15.8263 46.2609 17.0436 46.2609 18.2611V20.0872V25.5655C47.6153 25.5815 49.7672 25.5464 52.092 25.5655C51.4812 24.498 51.1304 23.2313 51.1304 21.9133C51.1304 19.7651 52.0579 17.8337 53.5343 16.4972C51.4063 11.7815 48.0106 7.7606 43.7814 4.8696H25.5653V51.1305H43.7815C48.9242 47.6151 52.8343 42.4295 54.7313 36.355L53.7682 35.3043Z"
      fill="#A2001D"
    />
  </svg>
);

export default SvgSriLanka;

import { IIconProps } from '../interface';

const SvgHandkey = ({
  width = 21,
  height = 21,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 20H2C1.448 20 1 19.552 1 19V13C1 12.448 1.448 12 2 12H4C4.552 12 5 12.448 5 13V19C5 19.552 4.552 20 4 20Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16.0001H13.333C13.766 16.0001 14.187 15.8601 14.533 15.6001L16.949 13.7881C17.552 13.3361 18.396 13.3961 18.929 13.9291C19.521 14.5211 19.521 15.4801 18.929 16.0711L16.856 18.1441C16.298 18.7021 15.586 19.0831 14.812 19.2381L11.882 19.8241C11.301 19.9401 10.702 19.9261 10.127 19.7821L7.477 19.1201C7.16 19.0401 6.835 19.0001 6.508 19.0001H5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16H12.485C13.322 16 14 15.322 14 14.485V14.182C14 13.487 13.527 12.881 12.853 12.713L10.561 12.14C10.188 12.047 9.806 12 9.422 12C8.495 12 7.588 12.274 6.817 12.789L5 14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.449 3.174L6.82401 3.177C6.55901 3.177 6.30501 3.283 6.11801 3.47L5.29301 4.295C4.90201 4.686 4.90201 5.319 5.29301 5.709L6.12101 6.537C6.30801 6.724 6.56301 6.83 6.82801 6.829L11.452 6.826L11.463 6.827C12.126 8.114 13.453 9.002 15.002 9C17.21 8.994 19 7.199 19 4.992C19.001 2.784 17.213 0.998002 15.005 1C13.462 1.004 12.138 1.893 11.472 3.175"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.002 5.26394C15.146 5.26394 15.262 5.14694 15.262 5.00294C15.262 4.85894 15.144 4.74194 15.001 4.74194C14.857 4.74194 14.74 4.85894 14.74 5.00294C14.74 5.14694 14.857 5.26394 15.002 5.26394Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgHandkey;

import { IIconProps } from '../interface';

const SvgMapsNavigation = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.34833 3.34835C10.8128 1.88388 13.1872 1.88388 14.6516 3.34835C16.1161 4.81281 16.1161 7.18718 14.6516 8.65165C14.1112 9.19209 13.4468 9.53309 12.75 9.67464V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V9.67465C10.5532 9.5331 9.88878 9.1921 9.34833 8.65165C7.88387 7.18718 7.88387 4.81281 9.34833 3.34835ZM13.591 4.40901C12.7123 3.53033 11.2877 3.53033 10.409 4.40901C9.53031 5.28768 9.53031 6.71231 10.409 7.59099C11.2877 8.46966 12.7123 8.46966 13.591 7.59099C14.4696 6.71231 14.4696 5.28768 13.591 4.40901Z"
            fill={color}
          />
          <path
            d="M9.10231 14.948C9.51265 14.8915 9.79949 14.513 9.74299 14.1027C9.68648 13.6923 9.30803 13.4055 8.89769 13.462C7.09629 13.7101 5.50186 14.1721 4.33423 14.8016C3.21777 15.4034 2.25 16.2992 2.25 17.5C2.25 18.264 2.65106 18.9101 3.19956 19.4144C3.74741 19.9182 4.49877 20.3374 5.36403 20.6739C7.09932 21.3487 9.44569 21.75 12 21.75C14.5543 21.75 16.9007 21.3487 18.636 20.6739C19.5012 20.3374 20.2526 19.9182 20.8004 19.4144C21.3489 18.9101 21.75 18.264 21.75 17.5C21.75 16.2992 20.7822 15.4034 19.6658 14.8016C18.4981 14.1721 16.9037 13.7101 15.1023 13.462C14.692 13.4055 14.3135 13.6923 14.257 14.1027C14.2005 14.513 14.4873 14.8915 14.8977 14.948C16.5893 15.1809 17.9949 15.6049 18.954 16.1219C19.9643 16.6665 20.25 17.1778 20.25 17.5C20.25 17.7025 20.1474 17.9771 19.7852 18.3103C19.4222 18.644 18.8556 18.979 18.0923 19.2759C16.5703 19.8678 14.4167 20.25 12 20.25C9.58331 20.25 7.42968 19.8678 5.90772 19.2759C5.14435 18.979 4.57778 18.644 4.21485 18.3103C3.85256 17.9771 3.75 17.7025 3.75 17.5C3.75 17.1778 4.03573 16.6665 5.04602 16.1219C6.00514 15.6049 7.41071 15.1809 9.10231 14.948Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgMapsNavigation;

import { IIconProps } from '../interface';

const SvgGlobeMoneyCurrency = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 18.25C17.4665 18.25 18.25 17.4665 18.25 16.5C18.25 15.5335 17.4665 14.75 16.5 14.75C15.5335 14.75 14.75 15.5335 14.75 16.5C14.75 17.4665 15.5335 18.25 16.5 18.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.50049 13.9611H5.00003C5.53046 13.9611 6.03917 13.7504 6.41424 13.3753C6.78931 13.0003 7.00003 12.4916 7.00003 11.9611V9.96112C7.00003 9.43069 7.21074 8.92198 7.58581 8.54691C7.96089 8.17183 8.4696 7.96112 9.00003 7.96112H11C11.5305 7.96112 12.0392 7.75041 12.4142 7.37533C12.7893 7.00026 13 6.49155 13 5.96112V2.22412"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 12H13C11.8954 12 11 12.8954 11 14V19C11 20.1046 11.8954 21 13 21H20C21.1046 21 22 20.1046 22 19V14C22 12.8954 21.1046 12 20 12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7693 9.00001C19.4226 7.47474 18.6842 6.06622 17.627 4.91343C16.5698 3.76063 15.2303 2.90339 13.7407 2.42628C12.251 1.94918 10.6627 1.8687 9.13252 2.19278C7.60229 2.51687 6.183 3.23432 5.01467 4.27435C3.84635 5.31438 2.96938 6.64105 2.47028 8.12346C1.97118 9.60586 1.8672 11.1928 2.1686 12.7276C2.47 14.2625 3.16637 15.6923 4.18899 16.8758C5.21161 18.0594 6.52515 18.956 8.00001 19.4769"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgGlobeMoneyCurrency;

import { IIconProps } from '../interface';

const SvgPhone = ({
  width = 32,
  height = 32,
  color = '#323232',
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2066_18868)">
        <path
          d="M10.8561 13.1444C9.68615 11.9744 8.80415 10.6644 8.21815 9.33536C8.09415 9.05436 8.16715 8.72536 8.38415 8.50836L9.20315 7.69036C9.87415 7.01936 9.87415 6.07036 9.28815 5.48436L8.11415 4.31036C7.33315 3.52936 6.06715 3.52936 5.28615 4.31036L4.63415 4.96236C3.89315 5.70336 3.58415 6.77236 3.78415 7.83236C4.27815 10.4454 5.79615 13.3064 8.24515 15.7554C10.6941 18.2044 13.5551 19.7224 16.1681 20.2164C17.2281 20.4164 18.2971 20.1074 19.0381 19.3664L19.6891 18.7154C20.4701 17.9344 20.4701 16.6684 19.6891 15.8874L18.5161 14.7144C17.9301 14.1284 16.9801 14.1284 16.3951 14.7144L15.4921 15.6184C15.2751 15.8354 14.9461 15.9084 14.6651 15.7844C13.3361 15.1974 12.0261 14.3144 10.8561 13.1444V13.1444Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2066_18868">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgPhone;

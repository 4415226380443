import { IIconProps } from '../interface';

const SvgAnguilla = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5334 0.002625 0 12.5375 0 28H14.6087V19.7738L22.8349 28H27.9252C27.9501 27.9751 27.9751 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65213C9.7864 6.14577 6.14563 9.78643 3.6521 14.1657V28H10.9565V10.9567V10.9565H28C28 8.65276 28 6.45804 28 3.65213H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M28 24.5567L18.0518 14.6088H14.6086V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5567V24.5567Z"
      fill="#D80027"
    />
    <path
      d="M47.3527 32.0987C48.1578 30.991 48.6956 29.6382 48.6956 28V15.8261C47.6782 16.5904 46.4138 17.0435 45.0434 17.0435C43.0521 17.0435 41.2843 16.0872 40.1738 14.6089C39.0632 16.0872 37.2954 17.0435 35.3042 17.0435C33.9338 17.0435 32.6694 16.5903 31.6521 15.8263V28C31.6521 29.6382 32.1899 30.991 32.995 32.0987H47.3527Z"
      fill="#F3F3F3"
    />
    <path
      d="M44.8202 25.7415C45.2078 24.7959 45.5093 23.4137 45.5093 22.7258C45.5093 21.6206 44.0684 20.7247 44.0684 20.7247C44.0684 20.7247 42.6275 21.6206 42.6275 22.7258C42.6275 23.4138 42.929 24.796 43.3164 25.7415L42.4846 27.6196C42.9735 27.8184 43.5079 27.9289 44.0685 27.9289C44.629 27.9289 45.1633 27.8183 45.6523 27.6196L44.8202 25.7415Z"
      fill="#FF9811"
    />
    <path
      d="M39.1884 19.6604C38.176 19.7975 36.8282 20.2279 36.2326 20.5718C35.2754 21.1245 35.2198 22.8201 35.2198 22.8201C35.2198 22.8201 36.7163 23.62 37.6733 23.0674C38.2692 22.7234 39.3153 21.7714 39.9401 20.9629L41.9828 20.7442C41.9105 20.2216 41.7386 19.7035 41.4587 19.2182C41.1783 18.7329 40.8157 18.3251 40.399 18.001L39.1884 19.6604Z"
      fill="#FF9811"
    />
    <path
      d="M36.7381 27.5785C37.363 28.3865 38.4094 29.3384 39.0053 29.6825C39.9624 30.2351 41.4588 29.4354 41.4588 29.4354C41.4588 29.4354 41.403 27.7396 40.4461 27.1869C39.8501 26.8429 38.5025 26.413 37.4899 26.276L36.2794 24.6165C35.8628 24.9405 35.5001 25.3484 35.2198 25.8333C34.9397 26.3188 34.7682 26.8367 34.6956 27.3596L36.7381 27.5785Z"
      fill="#FF9811"
    />
    <path
      d="M32.694 31.6525C34.9677 35.3007 40.174 36.5218 40.174 36.5218C40.174 36.5218 45.3803 35.3007 47.6541 31.6525H32.694Z"
      fill="#338AF3"
    />
  </svg>
);

export default SvgAnguilla;

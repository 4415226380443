import { IIconProps } from '../interface';

const SvgPalau = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M21.9131 40.1739C28.6366 40.1739 34.087 34.7234 34.087 28C34.087 21.2766 28.6366 15.8261 21.9131 15.8261C15.1897 15.8261 9.73926 21.2766 9.73926 28C9.73926 34.7234 15.1897 40.1739 21.9131 40.1739Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgPalau;

import { IIconProps } from '../interface';

const SvgSingleUserHeart = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2212_40959)">
        <path
          d="M17.2261 15.1575L17 15.3871L16.7738 15.1574C16.5702 14.9493 16.327 14.7839 16.0586 14.6711C15.7901 14.5582 15.5019 14.5 15.2107 14.5C14.9195 14.5 14.6312 14.5582 14.3628 14.6711C14.0943 14.7839 13.8512 14.9493 13.6475 15.1574C13.2324 15.5815 13 16.1513 13 16.7447C13 17.3381 13.2324 17.9079 13.6475 18.3319L15.7626 20.4796C15.9238 20.6443 16.1163 20.7752 16.3288 20.8646C16.5413 20.954 16.7695 21 17 21C17.2305 21 17.4587 20.954 17.6712 20.8646C17.8837 20.7752 18.0762 20.6443 18.2374 20.4796L20.3525 18.332C20.7675 17.9079 21 17.3381 21 16.7447C21 16.1513 20.7675 15.5815 20.3525 15.1575C20.1488 14.9493 19.9056 14.784 19.6372 14.6711C19.3688 14.5582 19.0805 14.5 18.7893 14.5C18.4981 14.5 18.2098 14.5582 17.9414 14.6711C17.6729 14.784 17.4298 14.9493 17.2261 15.1575Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15H7C5.93913 15 4.92172 15.4214 4.17157 16.1716C3.42143 16.9217 3 17.9391 3 19V20"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 11C13.2091 11 15 9.20914 15 7C15 4.79086 13.2091 3 11 3C8.79086 3 7 4.79086 7 7C7 9.20914 8.79086 11 11 11Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2212_40959">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgSingleUserHeart;

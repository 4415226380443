import { IIconProps } from '../interface';

const SvgCryptoStablecoins = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_40516)">
        <path
          d="M12 5.683C12.91 5.25 13.925 5 15 5C18.866 5 22 8.134 22 12C22 15.866 18.866 19 15 19C13.925 19 12.91 18.75 12 18.317"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9497 7.05025C16.6834 9.78392 16.6834 14.2161 13.9497 16.9497C11.2161 19.6834 6.78392 19.6834 4.05025 16.9497C1.31658 14.2161 1.31658 9.78392 4.05025 7.05025C6.78392 4.31658 11.2161 4.31658 13.9497 7.05025Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9.00049L12.0002 12.0007L9 15.0009L5.99977 12.0007L9 9.00049Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_40516">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCryptoStablecoins;

import { IIconProps } from '../interface';

const SvgHouse = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            strokeLinecap="round"
            strokeWidth="1.5"
            stroke={color}
            fill="none"
            strokeLinejoin="round"
          >
            <path d="M3 11.69l7.93-6.81 0-.01c.61-.53 1.51-.53 2.12 0l7.93 6.8" />
            <path d="M20 21V4.5v0c0-.28-.23-.5-.5-.5h-3l-.01 0c-.28 0-.5.22-.5.5 0 0 0 0 0 0v2.6" />
            <path d="M9.54 21v-5.5l0 0c-.01-.56.44-1.01.99-1.01h2.9l-.01 0c.55-.01 1 .44 1 .99v5.5" />
            <path d="M4.28 10.59v10.4" />
            <path d="M21 21L3 21" />
          </g>
          <path fill="none" d="M0 0h24v24H0Z" />
        </svg>
      );
  }
};

export default SvgHouse;

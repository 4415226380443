import { IIconProps } from '../interface';

const SvgFalklandIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5335 0.002625 0 12.5375 0 28H14.6087V19.7738L22.835 28H27.9252L28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65215C9.7864 6.14579 6.14574 9.78644 3.6521 14.1657V28H10.9565V10.9568V10.9565H28C28 8.65277 28 6.45805 28 3.65215H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M28 24.5568L18.052 14.6088H14.6088V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5568Z"
      fill="#D80027"
    />
    <path
      d="M45.0434 32.8696V34.0869H35.3043V32.8696H31.6521V37.7391H34.0869V38.9565H46.2609V37.7391H48.6956V32.8696H45.0434Z"
      fill="#FFDA44"
    />
    <path
      d="M31.6521 14.6087V28C31.6521 34.523 40.1738 36.5217 40.1738 36.5217C40.1738 36.5217 48.6956 34.523 48.6956 28V14.6087H31.6521Z"
      fill="#338AF3"
    />
    <path
      d="M40.1734 24.5972C38.0431 24.5972 38.0431 26.5451 35.9128 26.5451C33.7825 26.5451 33.7825 24.5972 31.6522 24.5972V28.0059C33.7825 28.0059 33.7825 29.9538 35.9128 29.9538C38.0431 29.9538 38.0431 28.0059 40.1734 28.0059C42.3039 28.0059 42.3039 29.9538 44.4345 29.9538C46.5652 29.9538 46.5651 28.0059 48.6957 28.0059V24.5972C46.5652 24.5972 46.5652 26.5451 44.4345 26.5451C42.3039 26.5451 42.3039 24.5972 40.1734 24.5972Z"
      fill="#F3F3F3"
    />
    <path
      d="M40.1734 17.7739C38.0431 17.7739 38.0431 19.7217 35.9128 19.7217C33.7825 19.7217 33.7825 17.7739 31.6522 17.7739V21.1826C33.7825 21.1826 33.7825 23.1304 35.9128 23.1304C38.0431 23.1304 38.0431 21.1826 40.1734 21.1826C42.3039 21.1826 42.3039 23.1304 44.4345 23.1304C46.5652 23.1304 46.5651 21.1826 48.6957 21.1826V17.7739C46.5652 17.7739 46.5652 19.7217 44.4345 19.7217C42.3039 19.7217 42.3039 17.7739 40.1734 17.7739Z"
      fill="#F3F3F3"
    />
  </svg>
);

export default SvgFalklandIslands;

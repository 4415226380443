import { IIconProps } from '../interface';

const SvgCambodia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 32.8508 1.23408 37.4132 3.40452 41.3913L28 43.8261L52.5955 41.3913C54.7659 37.4132 56 32.8508 56 28C56 23.1492 54.7659 18.5867 52.5955 14.6087L28 12.1739L3.40452 14.6087C1.23408 18.5867 0 23.1492 0 28H0Z"
      fill="#D80027"
    />
    <path
      d="M3.40454 14.6087H52.5954C47.8465 5.90505 38.6132 0 28 0C17.3868 0 8.15349 5.90505 3.40454 14.6087Z"
      fill="#0052B4"
    />
    <path
      d="M28 56C38.6132 56 47.8465 50.095 52.5955 41.3913H3.40454C8.15349 50.095 17.3868 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M37.7391 33.4783V29.8261H35.3044V24.9565L32.8696 22.5217L30.4348 24.9565V20.0869L28 17.6521L25.5652 20.0869V24.9565L23.1304 22.5217L20.6956 24.9565V29.8261H18.2609V33.4783H15.8261V37.1304H40.1739V33.4783H37.7391Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgCambodia;

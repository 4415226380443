import { IIconProps } from '../interface';

const SvgPlay = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5547 16.8321C22.8329 16.6466 23 16.3344 23 16C23 15.6656 22.8329 15.3534 22.5547 15.1679L13.5547 9.16795C13.2478 8.96338 12.8533 8.9443 12.5281 9.11832C12.203 9.29234 12 9.6312 12 10L12 22C12 22.3688 12.203 22.7077 12.5281 22.8817C12.8533 23.0557 13.2478 23.0366 13.5547 22.8321L22.5547 16.8321ZM14 20.1315V11.8685L20.1972 16L14 20.1315Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4.84269C6 3.25582 7.75947 2.30097 9.08998 3.1658L26.2551 14.3231C27.4684 15.1118 27.4684 16.8882 26.2551 17.6769L9.08998 28.8342C7.75947 29.699 6 28.7442 6 27.1573V4.84269ZM25.1651 16L8 4.84269V27.1573L25.1651 16Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5462 7.10889C10.8814 6.93821 11.284 6.97004 11.5882 7.19127L22.5882 15.1913C22.8469 15.3794 23 15.6801 23 16C23 16.3199 22.8469 16.6206 22.5882 16.8087L11.5882 24.8087C11.284 25.03 10.8814 25.0618 10.5462 24.8911C10.2111 24.7204 10 24.3761 10 24L10 8.00001C10 7.62388 10.2111 7.27957 10.5462 7.10889ZM12 9.96378L12 22.0362L20.2998 16L12 9.96378Z"
            fill={color}
          />
        </svg>
      );
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5462 7.10889C10.8814 6.93821 11.284 6.97004 11.5882 7.19127L22.5882 15.1913C22.8469 15.3794 23 15.6801 23 16C23 16.3199 22.8469 16.6206 22.5882 16.8087L11.5882 24.8087C11.284 25.03 10.8814 25.0618 10.5462 24.8911C10.2111 24.7204 10 24.3761 10 24L10 8.00001C10 7.62388 10.2111 7.27957 10.5462 7.10889Z"
            fill={color}
          />
        </svg>
      );
    default:
      console.warn('Need select an option for this icon');
      return null;
  }
};

export default SvgPlay;

import { IIconProps } from '../interface';

const SvgEthiopia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15632)">
      <path
        d="M0 28C0 31.4252 0.616109 34.706 1.74169 37.7394L28 40.1739L54.2583 37.7393C55.384 34.706 56 31.4251 56 28C56 24.622 55.4016 21.3837 54.3053 18.3854L28 15.8261L1.69466 18.3855C0.598391 21.3837 0 24.622 0 28H0Z"
        fill="#FFDA44"
      />
      <path
        d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
        fill="#D80027"
      />
      <path
        d="M28 0C15.961 0 5.69778 7.5985 1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0Z"
        fill="#6DA544"
      />
      <path
        d="M28 41.3913C35.3958 41.3913 41.3913 35.3958 41.3913 28C41.3913 20.6042 35.3958 14.6087 28 14.6087C20.6041 14.6087 14.6086 20.6042 14.6086 28C14.6086 35.3958 20.6041 41.3913 28 41.3913Z"
        fill="#0052B4"
      />
      <path
        d="M27.9971 17.6303L30.4145 25.0702H38.2498L31.9085 29.6684L34.3449 37.1304L27.9971 32.5103L21.6591 37.1219L24.0857 29.6684L17.7501 25.0702H25.5797L27.9971 17.6303Z"
        fill="#FFDA44"
      />
      <path
        d="M37.6386 29.8517L29.9696 27.3597L34.7092 20.8366L32.7396 19.4053L27.9998 25.9289L23.2605 19.4054L21.2906 20.8365L26.0301 27.3601L18.3615 29.8516L19.1137 32.1674L26.7827 29.6757V37.7391H29.2174V29.6756L36.886 32.1674L37.6386 29.8517Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15632">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEthiopia;

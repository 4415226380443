import { IIconProps } from '../interface';

const SvgError = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17 9.00005L17 18H15L15 9.00005H17Z" fill={color} />
          <path
            d="M14.5 21.5C14.5 20.6716 15.1716 20 16 20C16.8284 20 17.5 20.6716 17.5 21.5C17.5 22.3284 16.8284 23 16 23C15.1716 23 14.5 22.3284 14.5 21.5Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5116 1.72409C16.5776 1.17921 15.4225 1.17921 14.4884 1.72409L4.48839 7.55743C3.56673 8.09506 3 9.08176 3 10.1488V21.8513C3 22.9183 3.56674 23.905 4.48839 24.4426L14.4884 30.276C15.4225 30.8208 16.5776 30.8208 17.5116 30.276L27.5116 24.4426C28.4333 23.905 29 22.9183 29 21.8513V10.1488C29 9.08176 28.4333 8.09506 27.5116 7.55743L17.5116 1.72409ZM15.4962 3.45165C15.8075 3.27002 16.1925 3.27002 16.5039 3.45165L26.5039 9.28498C26.8111 9.46419 27 9.79309 27 10.1488V21.8513C27 22.207 26.8111 22.5359 26.5039 22.7151L16.5039 28.5484C16.1925 28.73 15.8075 28.73 15.4962 28.5484L5.49613 22.7151C5.18891 22.5359 5 22.2069 5 21.8513V10.1488C5 9.7931 5.18891 9.4642 5.49613 9.28498L15.4962 3.45165Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17 22L17 2H15L15 22H17Z" fill={color} />
          <path
            d="M16 26C14.8954 26 14 26.8954 14 28C14 29.1046 14.8954 30 16 30C17.1046 30 18 29.1046 18 28C18 26.8954 17.1046 26 16 26Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17 19L17 8H15L15 19L17 19Z" fill={color} />
          <path
            d="M16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21Z"
            fill={color}
          />
        </svg>
      );
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 1.57735C16.3812 1.22009 15.6188 1.22009 15 1.57735L4.00964 7.92265C3.39084 8.27992 3.00964 8.94017 3.00964 9.6547V22.3453C3.00964 23.0598 3.39084 23.7201 4.00964 24.0774L15 30.4227C15.6188 30.7799 16.3812 30.7799 17 30.4227L27.9904 24.0774C28.6092 23.7201 28.9904 23.0598 28.9904 22.3453V9.6547C28.9904 8.94017 28.6092 8.27992 27.9904 7.92265L17 1.57735ZM15 18V8H17V18H15ZM17.5 21.5C17.5 22.3284 16.8285 23 16 23C15.1716 23 14.5 22.3284 14.5 21.5C14.5 20.6716 15.1716 20 16 20C16.8285 20 17.5 20.6716 17.5 21.5Z"
            fill={color}
          />
        </svg>
      );
    default:
      console.warn('Need select an option for this icon');
      return null;
  }
};

export default SvgError;

import { IIconProps } from '../interface';

const SvgPassport = ({ width = 32, height = 33, color, type }: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1702_11931)">
        <path
          d="M16.0002 6.80811C12.5429 10.4988 12.5429 16.4508 16.0002 20.1414C19.4576 16.4508 19.4576 10.4988 16.0002 6.80811Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 6.80811C19.6907 6.80811 22.6667 9.78411 22.6667 13.4748C22.6667 17.1654 19.6907 20.1414 16 20.1414"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9997 20.1414C12.309 20.1414 9.33301 17.1654 9.33301 13.4748C9.33301 9.78411 12.309 6.80811 15.9997 6.80811"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33301 13.4751H22.6663"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7037 29.4748H8.29567C6.65967 29.4748 5.33301 28.1481 5.33301 26.5121V5.77077C5.33301 4.13477 6.65967 2.80811 8.29567 2.80811H23.7037C25.3397 2.80811 26.6663 4.13477 26.6663 5.77077V26.5121C26.6663 28.1481 25.3397 29.4748 23.7037 29.4748Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 24.8081H12"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_11931">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.141602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgPassport;

import { IIconProps } from '../interface';

const SvgGhana = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 31.425 0.616109 34.7058 1.74158 37.7391L28 38.9565L54.2584 37.7392C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2942 54.2584 18.2609L28 17.0435L1.74158 18.2608C0.616109 21.2942 0 24.575 0 28H0Z"
      fill="#FFDA44"
    />
    <path
      d="M28 0C15.961 0 5.69778 7.5985 1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0Z"
      fill="#D80027"
    />
    <path
      d="M54.2584 37.7391H1.74158C5.69778 48.4014 15.961 56 28 56C40.039 56 50.3022 48.4014 54.2584 37.7391Z"
      fill="#496E2D"
    />
    <path
      d="M27.9998 18.2609L30.417 25.7006H38.2403L31.9116 30.2991L34.3288 37.7391L27.9998 33.141L21.6708 37.7391L24.0884 30.2991L17.7596 25.7006H25.5826L27.9998 18.2609Z"
      fill="black"
    />
  </svg>
);

export default SvgGhana;

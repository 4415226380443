import { IIconProps } from '../interface';

const SvgHouseKey = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3129 2.68934C15.5942 2.40803 15.9758 2.25 16.3736 2.25H18.9645C19.3623 2.25 19.7439 2.40804 20.0252 2.68934C20.3065 2.97064 20.4645 3.35217 20.4645 3.75V9.13344L21.4881 10.0108C21.8026 10.2804 21.839 10.7539 21.5694 11.0684C21.2999 11.3829 20.8264 11.4193 20.5119 11.1497L12.8135 4.55094C12.5869 4.35674 12.2984 4.25 12 4.25C11.7016 4.25 11.4131 4.35674 11.1865 4.55094L5.0355 9.82334V19.25H8.25V15C8.25 14.5359 8.43437 14.0908 8.76256 13.7626C9.09075 13.4344 9.53587 13.25 10 13.25H12C12.4142 13.25 12.75 13.5858 12.75 14C12.75 14.4142 12.4142 14.75 12 14.75H10C9.9337 14.75 9.87011 14.7763 9.82322 14.8232C9.77634 14.8701 9.75 14.9337 9.75 15V19.25H10C10.4142 19.25 10.75 19.5858 10.75 20C10.75 20.4142 10.4142 20.75 10 20.75H4.28792L4.2855 20.75L4.28308 20.75H3C2.58579 20.75 2.25 20.4142 2.25 20C2.25 19.5858 2.58579 19.25 3 19.25H3.5355V11.1091L3.4881 11.1497C3.17361 11.4193 2.70013 11.3829 2.43056 11.0684C2.16099 10.7539 2.19741 10.2804 2.5119 10.0108L10.2103 3.41206C10.7087 2.98484 11.3435 2.75 12 2.75C12.6565 2.75 13.2913 2.98484 13.7897 3.41206L14.8736 4.34113V3.75C14.8736 3.35218 15.0316 2.97065 15.3129 2.68934ZM18.9645 3.75V7.8477L16.3736 5.62687V3.75H18.9645Z"
            fill={color}
          />
          <path
            d="M18.5 17.5C19.0523 17.5 19.5 17.0523 19.5 16.5C19.5 15.9477 19.0523 15.5 18.5 15.5C17.9477 15.5 17.5 15.9477 17.5 16.5C17.5 17.0523 17.9477 17.5 18.5 17.5Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7613 18.9945L14.3554 17.3971C14.289 17.1061 14.2536 16.8083 14.2501 16.5088L14.2499 16.5C14.2499 15.6594 14.4993 14.8377 14.9663 14.1388C15.4333 13.4399 16.097 12.8952 16.8736 12.5735C17.6502 12.2518 18.5047 12.1677 19.3291 12.3317C20.1536 12.4957 20.9108 12.9004 21.5052 13.4948C22.0996 14.0892 22.5044 14.8464 22.6683 15.6709C22.8323 16.4953 22.7482 17.3498 22.4265 18.1264C22.1048 18.903 21.5601 19.5668 20.8612 20.0337C20.1623 20.5007 19.3406 20.75 18.5 20.75H18.4912C18.1904 20.7465 17.8913 20.7107 17.599 20.6437L16.0099 22.2362C15.8473 22.399 15.6542 22.5283 15.4417 22.6164C15.2291 22.7046 15.0013 22.75 14.7712 22.75H14C13.5359 22.75 13.0908 22.5656 12.7626 22.2374C12.4344 21.9092 12.25 21.4641 12.25 21V20.2306C12.25 19.7672 12.4339 19.3226 12.7613 18.9945ZM18.5044 19.25C18.2164 19.2462 17.9309 19.1965 17.6586 19.103C17.3885 18.9957 17.0688 19.0516 16.8505 19.2703L14.9481 21.1766C14.9249 21.1999 14.8973 21.2183 14.8669 21.2309C14.8366 21.2435 14.804 21.25 14.7711 21.25H14C13.9337 21.25 13.8701 21.2237 13.8232 21.1768C13.7763 21.1299 13.75 21.0663 13.75 21V20.2307C13.75 20.1645 13.7763 20.1009 13.823 20.0541L15.6958 18.1775C15.9177 17.9809 16.0105 17.6622 15.9055 17.3655C15.8065 17.0859 15.754 16.7921 15.75 16.4956C15.7509 15.9532 15.9121 15.4232 16.2135 14.9722C16.5156 14.5199 16.9451 14.1675 17.4476 13.9593C17.9501 13.7512 18.503 13.6967 19.0365 13.8028C19.5699 13.909 20.0599 14.1709 20.4445 14.5555C20.8291 14.9401 21.0911 15.4301 21.1972 15.9635C21.3033 16.497 21.2488 17.0499 21.0407 17.5524C20.8325 18.0549 20.4801 18.4844 20.0278 18.7865C19.5768 19.0879 19.0468 19.2491 18.5044 19.25Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgHouseKey;

import { IIconProps } from '../interface';

const SvgPlus = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0001 8L17.0001 15.0001H24V17.0001H17.0001L17.0001 24H15.0001L15.0001 17.0001H8L8 15.0001H15.0001L15.0001 8H17.0001Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0001 15L17.0001 2H15.0001L15.0001 15L2 15L2 17L15.0001 17L15.0001 30H17.0001L17.0001 17L30 17L30 15L17.0001 15Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0001 15L17.0001 6H15.0001L15.0001 15.0001L6 15.0001L6.00001 17.0001L15.0001 17.0001L15.0001 26H17.0001L17.0001 17L26 17L26 15L17.0001 15Z"
            fill={color}
          />
        </svg>
      );
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM17.0001 15.0001H24V17.0001H17.0001L17.0001 24H15.0001L15.0001 17.0001H8V15.0001H15.0001L15.0001 8H17.0001L17.0001 15.0001Z"
            fill={color}
          />
        </svg>
      );
    default:
      console.warn('Need select an option for this icon');
      return null;
  }
};

export default SvgPlus;

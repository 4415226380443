import { IIconProps } from '../interface';

const SvgMadagascar = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M18.261 28V54.2584C21.2942 55.384 24.5751 56 28.0001 56C43.4639 56 56.0001 43.4639 56.0001 28C56.0001 12.5361 18.261 28 18.261 28Z"
      fill="#6DA544"
    />
    <path
      d="M28.0001 0C24.5751 0 21.2942 0.616109 18.261 1.74158V28H56.0001C56.0001 12.5361 43.4639 0 28.0001 0Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgMadagascar;

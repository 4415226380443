import { IIconProps } from '../interface';

const SvgCanaryIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 0C24.575 0 21.2941 0.616109 18.2609 1.74158L17.0435 28L18.2608 54.2584C21.2941 55.384 24.575 56 28 56C31.425 56 34.7059 55.384 37.7391 54.2584L38.9565 28L37.7392 1.74158C34.7059 0.616109 31.425 0 28 0V0Z"
      fill="#338AF3"
    />
    <path
      d="M56.0001 28C56.0001 15.961 48.4016 5.69778 37.7391 1.74158V54.2585C48.4016 50.3022 56.0001 40.039 56.0001 28Z"
      fill="#FFDA44"
    />
    <path
      d="M18.2609 54.2584V1.74158C7.5985 5.69778 0 15.961 0 28C0 40.039 7.5985 50.3022 18.2609 54.2584Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgCanaryIslands;

import { IIconProps } from '../interface';

const SvgHawaii = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15822)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 56C35.1003 56 41.5821 53.3558 46.5179 49H9.48218C14.4179 53.3558 20.8997 56 28 56Z"
        fill="#D80027"
      />
      <path
        d="M0.88208 35C1.52138 37.4835 2.49328 39.8333 3.74672 42H52.2531C53.5065 39.8333 54.4784 37.4835 55.1177 35L28 32.8696L0.88208 35Z"
        fill="#0052B4"
      />
      <path
        d="M55.1179 21C54.4786 18.5165 53.5066 16.1667 52.2532 14L28 13.3913C28 8.50084 28 3.87898 28 0C25.92 0 23.8928 0.226953 21.9421 0.657125C21.9047 0.665437 3.6521 15.0629 3.6521 28C4.1188 28.0812 28 30.4348 28 30.4348C28 30.4348 28 25.1016 28 21H55.1179Z"
        fill="#0052B4"
      />
      <path
        d="M28 17.4696V3.46961H19.1324L21.943 0.658997C10.405 3.20372 1.53956 12.8642 0.182656 24.8044C0.0634375 25.8533 0 26.9192 0 28H14.1827V22.4194L19.8185 28H29.8356L19.1323 17.4696H28Z"
        fill="#F0F0F0"
      />
      <path
        d="M28.0128 28L17.4824 17.4696H14.1827V17.4697L24.713 28H10.6521V14H52.2533C50.7288 11.3648 48.7865 9.0019 46.518 7.00001H10.6521V6.02383C7.8272 8.25694 5.4437 11.0228 3.65214 14.17V28H0C0 30.4171 0.30625 32.7627 0.882109 35H55.1179C55.6937 32.7626 56 30.4171 56 28H28.0128Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15822">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgHawaii;

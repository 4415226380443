import { IIconProps } from '../interface';

const SvgInsurance = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          id="Layer_3"
          data-name="Layer 3"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M0,0H24V24H0Z" fill="none" />
          <path
            d="M15.75,11.5a.75.75,0,0,0-.75-.75H13.5V9.25a.75.75,0,0,0-.75-.75h-1.5a.75.75,0,0,0-.75.75v1.5H9a.75.75,0,0,0-.75.75V13a.75.75,0,0,0,.75.75h1.5v1.5a.75.75,0,0,0,.75.75h1.5a.75.75,0,0,0,.75-.75v-1.5H15a.75.75,0,0,0,.75-.75Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M20,13.02089c0,3.7747-4.64485,6.57877-6.85988,7.70461a2.50473,2.50473,0,0,1-2.27647.002C8.65012,19.60272,4,16.79179,4,13.02089L4,7.56937a1.01225,1.01225,0,0,1,.9021-.99168,11.39249,11.39249,0,0,0,5.8725-2.17176,2.05246,2.05246,0,0,1,2.45076,0,11.39249,11.39249,0,0,0,5.8725,2.17176A1.01225,1.01225,0,0,1,20,7.56937Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </svg>
      );
  }
};

export default SvgInsurance;

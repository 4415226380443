import { IIconProps } from '../interface';

const SvgTravelCompass = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2227_40511)">
        <path
          d="M5.63601 5.63601C9.15101 2.12101 14.849 2.12101 18.364 5.63601C21.879 9.15101 21.879 14.849 18.364 18.364C14.849 21.879 9.15101 21.879 5.63601 18.364C2.12101 14.849 2.12101 9.15101 5.63601 5.63601Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.90402 15.507L9.04002 9.82503C9.12002 9.42903 9.42902 9.12003 9.82502 9.04103L15.507 7.90503C15.857 7.83503 16.165 8.14303 16.095 8.49303L14.959 14.175C14.88 14.571 14.57 14.88 14.175 14.959L8.49302 16.095C8.14302 16.166 7.83402 15.857 7.90402 15.507Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.999 11.75C11.861 11.75 11.749 11.862 11.75 12C11.75 12.138 11.862 12.25 12 12.25C12.138 12.25 12.25 12.138 12.25 12C12.25 11.862 12.138 11.75 11.999 11.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2227_40511">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgTravelCompass;

import { IIconProps } from '../interface';

const SvgMoney = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.3743 2.30067C12.9734 0.899777 10.7016 0.899777 9.30067 2.30067C7.89978 3.70156 7.89978 5.97344 9.30067 7.37433C9.99309 8.06675 10.8973 8.41536 11.8023 8.4238C11.9766 8.88887 12.2508 9.32501 12.6249 9.69911C14.0261 11.1003 16.2979 11.1003 17.6991 9.69911C19.1003 8.2979 19.1003 6.02611 17.6991 4.62491C17.0059 3.9317 16.0996 3.58144 15.1911 3.57413C15.0193 3.11165 14.749 2.67535 14.3743 2.30067ZM14.7371 5.11746C14.7423 5.11671 14.7476 5.1159 14.7528 5.11504C14.7704 5.11215 14.7877 5.10866 14.8048 5.10461C15.451 4.993 16.1396 5.18665 16.6385 5.68557C17.4539 6.50098 17.4539 7.82303 16.6385 8.63845C15.8231 9.45386 14.501 9.45386 13.6856 8.63845C13.386 8.3389 13.1965 7.97099 13.1171 7.58494C13.1153 7.55158 13.1113 7.51796 13.1049 7.48424C13.1008 7.46277 13.0958 7.4417 13.0901 7.42104C13.0134 6.80401 13.2119 6.15929 13.6856 5.68557C13.9846 5.38654 14.3518 5.19717 14.7371 5.11746ZM13.6938 3.88709C13.5981 3.6968 13.4716 3.51929 13.3137 3.36133C12.4986 2.54622 11.1764 2.54622 10.3613 3.36133C9.54622 4.17644 9.54622 5.49856 10.3613 6.31367C10.7039 6.65626 11.1357 6.85481 11.5829 6.9089C11.6414 6.07675 11.9887 5.2611 12.6249 4.62491C12.9431 4.30671 13.3062 4.06077 13.6938 3.88709Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 12.25C3.0335 12.25 2.25 13.0335 2.25 14V20C2.25 20.9665 3.0335 21.75 4 21.75H6C6.6981 21.75 7.30073 21.3412 7.58159 20.75H8.75379C8.85599 20.75 8.95781 20.7625 9.05696 20.7873L11.9455 21.5095C12.6278 21.68 13.3394 21.697 14.0291 21.559L16.9595 20.973C17.8791 20.789 18.7236 20.3371 19.3867 19.674L21.4593 17.6014C22.3437 16.717 22.3437 15.283 21.4593 14.3986C20.6623 13.6017 19.4007 13.512 18.499 14.1882L16.75 15.5L16.75 15.489C16.75 14.5669 16.1328 13.7588 15.2432 13.5162L12.2958 12.7124C11.0804 12.3809 9.78314 12.5458 8.68934 13.1708L7.72777 13.7203C7.59388 12.8868 6.8713 12.25 6 12.25H4ZM7.75 15.4352V19.25H8.75379C8.97863 19.25 9.20263 19.2776 9.42076 19.3321L12.3093 20.0542C12.7761 20.1709 13.2631 20.1825 13.7349 20.0882L16.6653 19.5021C17.2945 19.3762 17.8723 19.067 18.326 18.6133L20.3986 16.5407C20.6972 16.2421 20.6972 15.7579 20.3986 15.4593C20.1295 15.1902 19.7035 15.1599 19.399 15.3882L16.9833 17.2C16.5073 17.557 15.9284 17.75 15.3333 17.75H12V16.25H13.9077L14.8373 16.0176C15.0798 15.957 15.25 15.739 15.25 15.489C15.25 15.2433 15.0856 15.028 14.8485 14.9633L11.9011 14.1595C11.0696 13.9327 10.1819 14.0456 9.43355 14.4732L7.75 15.4352ZM3.75 14C3.75 13.8619 3.86193 13.75 4 13.75H6C6.13807 13.75 6.25 13.8619 6.25 14V20C6.25 20.1381 6.13807 20.25 6 20.25H4C3.86193 20.25 3.75 20.1381 3.75 20V14Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgMoney;

import { IIconProps } from '../interface';

const SvgStVincentAndTheGrenadines = ({
  width = 56,
  height = 56,
}: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3913 3.40452C37.4132 1.23408 32.8507 0 27.9999 0C23.1492 0 18.5867 1.23408 14.6086 3.40452L12.1738 28L14.6086 52.5955C18.5867 54.7659 23.1492 56 27.9999 56C32.8507 56 37.4132 54.7659 41.3913 52.5955L43.8261 28L41.3913 3.40452Z"
      fill="#FFDA44"
    />
    <path
      d="M14.6087 52.5953V3.40442C5.90505 8.15348 0 17.3868 0 28C0 38.6132 5.90505 47.8465 14.6087 52.5953Z"
      fill="#338AF3"
    />
    <path
      d="M56 28C56 17.3868 50.095 8.15348 41.3914 3.40442V52.5953C50.095 47.8465 56 38.6132 56 28Z"
      fill="#6DA544"
    />
    <path
      d="M21.9132 35.3044L17.0435 28L21.9131 20.6956L26.7829 28L21.9132 35.3044Z"
      fill="#6DA544"
    />
    <path
      d="M34.0871 35.3044L29.2175 28L34.0871 20.6956L38.9567 28L34.0871 35.3044Z"
      fill="#6DA544"
    />
    <path
      d="M28.0002 45.0435L23.1306 37.7391L28.0002 30.4348L32.8698 37.7391L28.0002 45.0435Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgStVincentAndTheGrenadines;

import { IIconProps } from '../interface';

const SvgCentralAfricanRepublic = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15368)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M52.5955 14.6087C48.9773 7.97737 42.7558 2.97139 35.3044 0.963043L34.087 14.6087H52.5955Z"
        fill="#0052B4"
      />
      <path
        d="M3.40454 14.6087H21.913L20.6956 0.963043C13.2442 2.97139 7.02278 7.97748 3.40454 14.6087Z"
        fill="#0052B4"
      />
      <path
        d="M34.0869 28V41.3913H52.5954C54.7659 37.4132 56 32.8508 56 28H34.0869Z"
        fill="#6DA544"
      />
      <path
        d="M21.9131 28H0C0 32.8508 1.23408 37.4132 3.40452 41.3913H21.913V28H21.9131Z"
        fill="#6DA544"
      />
      <path
        d="M3.40454 41.3913C7.02278 48.0226 13.2442 53.0286 20.6956 55.037L21.913 43.1032L3.40454 41.3913Z"
        fill="#FFDA44"
      />
      <path
        d="M35.3044 55.037C42.7558 53.0286 48.9773 48.0226 52.5955 41.3913L34.087 43.1029L35.3044 55.037Z"
        fill="#FFDA44"
      />
      <path
        d="M34.0869 43.1029L52.5955 41.3913H34.0869V43.1029Z"
        fill="#FFDA44"
      />
      <path
        d="M21.9131 41.3913H3.40454L21.9131 43.1029V41.3913Z"
        fill="#FFDA44"
      />
      <path
        d="M27.9999 0C25.4726 0 23.0246 0.336875 20.6956 0.964578V55.0355C23.0246 55.6631 25.4726 56 27.9999 56C30.5273 56 32.9753 55.6631 35.3043 55.0354V0.964578C32.9753 0.336875 30.5273 0 27.9999 0V0Z"
        fill="#D80027"
      />
      <path
        d="M15.0607 6.08694L15.8161 8.41192H18.2609L16.2832 9.84889L17.0387 12.1739L15.0607 10.737L13.0829 12.1739L13.8386 9.84889L11.8608 8.41192H14.3055L15.0607 6.08694Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15368">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCentralAfricanRepublic;

const invoices = {
  title: 'Invoices',
  tabs: {
    amount: 'amount',
    due_date: 'due date',
    status: 'status',
    actions: 'actions',
    client_name: 'client',
  },
  pay_button: 'Pay now',
  empty_state: 'No payments have been issued yet.',
  deposit_payment: 'Request deposit payment',
  final_payment: 'Request final payment',
  deposit_payment_lbl: 'deposit payment',
  final_payment_lbl: 'final payment',
  upload_modal: {
    title: 'Adding a new invoice',
    description: `
              Please include the following details on your invoice:\n
              amount excl VAT: {{basePrice}}
              VAT: {{vat}}
              amount incl VAT: {{priceVat}}
            `,
  },
  order_by: 'order by:',
  direction: 'direction:',
  ascending: 'ascending',
  descending: 'descending'
};

export default invoices;

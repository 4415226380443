import { IIconProps } from '../interface';

const SvgKosovo = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M12.2992 17.0436L12.9036 18.9038H14.8592L13.2771 20.0533L13.8814 21.9132L12.2992 20.7638L10.7168 21.9132L11.3214 20.0533L9.73914 18.9038H11.6947L12.2992 17.0436Z"
      fill="#F0F0F0"
    />
    <path
      d="M18.3861 15.2175L18.9905 17.0777H20.9461L19.364 18.2271L19.9683 20.087L18.3861 18.9376L16.8039 20.087L17.4083 18.2271L15.8262 17.0777H17.7818L18.3861 15.2175Z"
      fill="#F0F0F0"
    />
    <path
      d="M24.473 13.3914L25.0774 15.2516H27.033L25.4509 16.4011L26.0552 18.261L24.473 17.1115L22.8908 18.261L23.4952 16.4011L21.9131 15.2516H23.8687L24.473 13.3914Z"
      fill="#F0F0F0"
    />
    <path
      d="M43.7009 17.0436L43.0965 18.9038H41.1409L42.7231 20.0533L42.1187 21.9132L43.7009 20.7638L45.2832 21.9132L44.6787 20.0533L46.2609 18.9038H44.3053L43.7009 17.0436Z"
      fill="#F0F0F0"
    />
    <path
      d="M37.614 15.2175L37.0096 17.0777H35.054L36.6362 18.2271L36.0318 20.087L37.614 18.9376L39.1962 20.087L38.5918 18.2271L40.1739 17.0777H38.2183L37.614 15.2175Z"
      fill="#F0F0F0"
    />
    <path
      d="M31.5271 13.3914L30.9227 15.2516H28.967L30.5491 16.4011L29.9449 18.261L31.5271 17.1115L33.1093 18.261L32.5049 16.4011L34.087 15.2516H32.1314L31.5271 13.3914Z"
      fill="#F0F0F0"
    />
    <path
      d="M32.8696 29.2174L28 23.1304L25.5652 24.3478V26.7826L21.9131 29.2174H19.4783V32.3629C23.047 33.8079 25.5652 37.3046 25.5652 41.3914H28V38.9566L30.4348 36.5218L32.8696 38.9566L35.3044 36.5218V34.087L37.7392 30.4348L32.8696 29.2174Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgKosovo;

import { IIconProps } from '../interface';

const SvgGuam = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15773)">
      <path
        d="M12.2186 51.1304C16.7122 54.2022 22.1459 56 28 56C33.8541 56 39.2877 54.2022 43.7814 51.1304L28 48.6956L12.2186 51.1304Z"
        fill="#D80027"
      />
      <path
        d="M43.7814 4.86959C39.2877 1.7978 33.8541 0 28 0C22.1459 0 16.7122 1.7978 12.2186 4.86959L28 7.30439L43.7814 4.86959Z"
        fill="#D80027"
      />
      <path
        d="M56 28C56 18.7348 50.4529 9.43032 43.7814 4.8696H12.2186C4.84203 9.91222 0 18.3903 0 28C0 37.61 4.84203 46.0878 12.2186 51.1304H43.7814C51.1581 46.0878 56 37.61 56 28Z"
        fill="#0052B4"
      />
      <path
        d="M26.4155 45.6747C26.0329 45.3465 17.0435 37.5319 17.0435 28C17.0435 18.4681 26.0328 10.6533 26.4155 10.3253L28 8.9671L29.5845 10.3252C29.9671 10.6533 38.9565 18.468 38.9565 28C38.9565 37.532 29.9672 45.3468 29.5845 45.6747L28 47.0329L26.4155 45.6747Z"
        fill="#D80027"
      />
      <path
        d="M36.5217 28C36.5217 19.4783 28 12.1739 28 12.1739C28 12.1739 19.4783 19.4785 19.4783 28C19.4783 30.607 20.2764 33.0995 21.3835 35.3044H34.6165C35.7236 33.0995 36.5217 30.607 36.5217 28Z"
        fill="#338AF3"
      />
      <path
        d="M36.5217 28C36.5217 30.607 35.7236 33.0995 34.6165 35.3044L28 36.5217L21.3835 35.3044C20.2764 33.0996 19.4783 30.607 19.4783 28H36.5217Z"
        fill="#0052B4"
      />
      <path
        d="M28 43.8261C28 43.8261 32.1046 40.3068 34.6165 35.3044H21.3834C23.8953 40.3068 28 43.8261 28 43.8261Z"
        fill="#FFDA44"
      />
      <path
        d="M22.5217 23.1304H33.4783L28 28.6087L22.5217 23.1304Z"
        fill="#6DA544"
      />
      <path
        d="M26.1738 26.7827H29.826V37.7392H26.1738V26.7827Z"
        fill="#A2001D"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15773">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGuam;

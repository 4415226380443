import { IIconProps } from '../interface';

const SvgDominicanRepublic = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M0.9646 35.3044C3.55001 44.8969 11.1031 52.4501 20.6957 55.0355V35.3044H0.9646Z"
      fill="#D80027"
    />
    <path
      d="M20.6956 0.964577C11.1031 3.54987 3.5499 11.1031 0.9646 20.6956H20.6957V0.964577H20.6956Z"
      fill="#0052B4"
    />
    <path
      d="M55.0355 20.6956C52.4502 11.1031 44.897 3.54987 35.3044 0.964577V20.6957H55.0355V20.6956Z"
      fill="#D80027"
    />
    <path
      d="M35.3044 55.0354C44.897 52.45 52.4502 44.8969 55.0356 35.3043H35.3044V55.0354Z"
      fill="#0052B4"
    />
    <path
      d="M35.3043 28C35.3043 32.0342 32.0341 35.3044 27.9999 35.3044C23.9659 35.3044 20.6956 32.0342 20.6956 28C20.6956 23.9658 27.9999 20.6956 27.9999 20.6956C27.9999 20.6956 35.3043 23.9658 35.3043 28Z"
      fill="#496E2D"
    />
    <path
      d="M20.6956 28C20.6956 23.9658 23.9659 20.6956 27.9999 20.6956C32.0341 20.6956 35.3043 23.9658 35.3043 28"
      fill="#0052B4"
    />
    <path
      d="M23.8914 24.3479V28.9131C23.8914 31.1823 25.7308 33.0217 28 33.0217C30.2692 33.0217 32.1087 31.1823 32.1087 28.9131V24.3479H23.8914Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgDominicanRepublic;

import { IIconProps } from '../interface';

const SvgHongKong = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M30.8908 21.1824C30.2545 23.8292 29.1239 23.3244 28.569 25.6327C25.3006 24.8468 23.2876 21.5601 24.0735 18.2914C24.859 15.023 28.1461 13.0101 31.4146 13.7959C30.3048 18.4123 31.4601 18.8152 30.8908 21.1824Z"
      fill="#F0F0F0"
    />
    <path
      d="M22.4096 23.1438C24.7302 24.5667 23.9007 25.4862 25.9243 26.7273C24.1669 29.5931 20.4191 30.4916 17.5533 28.7342C14.6876 26.9769 13.7888 23.229 15.5463 20.3632C19.5938 22.8452 20.3337 21.871 22.4096 23.1438Z"
      fill="#F0F0F0"
    />
    <path
      d="M21.6538 31.8163C23.7244 30.0491 24.3423 31.122 26.1479 29.5809C28.3305 32.1378 28.0269 35.9799 25.4701 38.1625C22.9132 40.3451 19.071 40.0411 16.8888 37.4845C20.4997 34.402 19.8018 33.3969 21.6538 31.8163Z"
      fill="#F0F0F0"
    />
    <path
      d="M29.6684 35.2149C28.6272 32.6996 29.8386 32.4433 28.9309 30.25C32.037 28.9645 35.5974 30.4404 36.883 33.5465C38.1685 36.6527 36.6924 40.2129 33.5862 41.4985C31.7709 37.1116 30.5996 37.4648 29.6684 35.2149Z"
      fill="#F0F0F0"
    />
    <path
      d="M35.3774 28.6427C32.6634 28.8557 32.7937 27.6241 30.4274 27.8098C30.1646 24.4587 32.6686 21.5284 36.0199 21.2654C39.3712 21.003 42.3014 23.5068 42.5641 26.8583C37.8307 27.2294 37.8045 28.4525 35.3774 28.6427Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgHongKong;

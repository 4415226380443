import { IIconProps } from '../interface';

const SvgSouthAfrica = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16640)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M8.20099 47.799C-2.73366 36.8643 -2.73366 19.1357 8.20099 8.20093C8.20022 8.20213 24.3479 28 24.3479 28L8.20099 47.799Z"
        fill="black"
      />
      <path
        d="M24.3478 28L3.71354 14.0561C3.07381 15.1681 2.50965 16.3288 2.0249 17.5302L12.4712 28.0001L2.02523 38.4706C2.50954 39.6711 3.07315 40.831 3.71245 41.9423L24.3478 28Z"
        fill="#FFDA44"
      />
      <path
        d="M55.7629 24.3479H24.347L8.20061 8.20139C6.46067 9.94132 4.949 11.9091 3.7135 14.0562L17.6303 28L3.7124 41.942C4.9479 44.0897 6.46023 46.0581 8.20061 47.7985L24.347 31.6521H55.7629C55.9185 30.4567 55.9999 29.2378 55.9999 28C55.9999 26.7622 55.9185 25.5433 55.7629 24.3479Z"
        fill="#6DA544"
      />
      <path
        d="M10.9526 50.2115C15.6742 53.8408 21.5845 56 28 56C40.9368 56 51.8225 47.2258 55.0355 35.3044H25.8599L10.9526 50.2115Z"
        fill="#0052B4"
      />
      <path
        d="M55.0355 20.6956C51.8225 8.77417 40.9368 0 28 0C21.5845 0 15.6742 2.15917 10.9526 5.78845L25.8598 20.6956H55.0355Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16640">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSouthAfrica;

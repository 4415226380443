import { IIconProps } from '../interface';

const SvgSouthSudan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16669)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M53.8156 17.1449C49.5753 7.07241 39.6143 0 28 0C20.2678 0 13.2686 3.13458 8.2019 8.20192L15.2139 17.1449H53.8156Z"
        fill="black"
      />
      <path
        d="M15.2934 38.7538L8.2019 47.7981C13.2686 52.8654 20.2678 56 28 56C39.6534 56 49.6429 48.8803 53.8588 38.7538H15.2934Z"
        fill="#496E2D"
      />
      <path
        d="M6.08691 20.5942V35.3043H55.0355C55.6635 32.9756 56 30.5273 56 28C56 25.436 55.6546 22.9532 55.0089 20.5942H6.08691Z"
        fill="#A2001D"
      />
      <path
        d="M8.20099 8.20093C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20093Z"
        fill="#0052B4"
      />
      <path
        d="M9.12034 21.0385L12.5378 25.8074L18.1299 24.0306L14.6506 28.7548L18.068 33.5239L12.4999 31.6747L9.02048 36.3988L9.05876 30.5316L3.49048 28.6822L9.08217 26.9055L9.12034 21.0385Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16669">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSouthSudan;

import { IIconProps } from '../interface';

const SvgMicronesia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M27.9998 12.1739L29.2084 15.894H33.1201L29.9557 18.1931L31.1642 21.9131L27.9998 19.614L24.8353 21.9131L26.0442 18.1931L22.8796 15.894H26.7912L27.9998 12.1739Z"
      fill="#F0F0F0"
    />
    <path
      d="M12.1738 28.0002L15.8939 26.7914V22.8797L18.1931 26.0444L21.913 24.8357L19.614 28.0002L21.913 31.1644L18.1931 29.9556L15.8939 33.1202V29.2087L12.1738 28.0002Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 43.8261L26.7913 40.1061H22.8796L26.0443 37.8069L24.8356 34.0869L28 36.386L31.1644 34.0869L29.9555 37.8069L33.1201 40.1061H29.2086L28 43.8261Z"
      fill="#F0F0F0"
    />
    <path
      d="M43.8261 27.9999L40.106 29.2086V33.1202L37.8069 29.9556L34.0869 31.1643L36.386 27.9999L34.0869 24.8356L37.8069 26.0444L40.106 22.8797V26.7913L43.8261 27.9999Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgMicronesia;

import { IIconProps } from '../interface';

const SvgBahrain = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C22.3808 0 17.1491 1.65648 12.7641 4.50603L19.9652 9.33264L10.9565 13.9992L19.9652 18.6658L10.9565 23.3324L19.9652 27.9985L10.9565 32.665L19.9652 37.3314L10.9565 41.9985L19.9652 46.6651L12.7632 51.4932C17.1485 54.3433 22.3804 56 28 56C43.4639 56 56 43.4639 56 28C56 12.5361 43.4639 0 28 0V0Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgBahrain;

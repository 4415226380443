import { IIconProps } from '../interface';

const SvgMartinique = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M55.76 24.3478C54.9876 18.443 52.3352 12.7374 47.7989 8.20106C43.2626 3.66473 37.5568 1.01227 31.6521 0.239868L31.6522 24.3478H55.76Z"
      fill="#0052B4"
    />
    <path
      d="M24.3476 0.239868C18.4429 1.01227 12.7372 3.66462 8.20083 8.20095C3.66461 12.7374 1.01215 18.4432 0.239746 24.348L24.3478 24.3479L24.3476 0.239868Z"
      fill="#0052B4"
    />
    <path
      d="M0.239746 31.6523C1.01215 37.557 3.66461 43.2629 8.20083 47.7991C12.7372 52.3353 18.4428 54.9877 24.3478 55.7604L24.3476 31.6525L0.239746 31.6523Z"
      fill="#0052B4"
    />
    <path
      d="M31.6521 55.7601C37.5568 54.9877 43.2625 52.3353 47.7988 47.799C52.3351 43.2627 54.9875 37.557 55.7599 31.6523H31.6521V55.7601Z"
      fill="#0052B4"
    />
    <path
      d="M37.5581 17.7666C37.5581 17.3943 37.8608 17.0917 38.2332 17.0917H39.6796C41.966 17.0917 43.8261 15.2317 43.8261 12.9454C43.8261 10.6591 41.9661 8.79899 39.6796 8.79899H38.3061C37.8577 8.46222 37.2563 8.25452 36.5928 8.25452C35.2089 8.25452 34.087 9.15194 34.087 10.259C34.087 11.3554 35.1874 12.2448 36.5529 12.2621L36.5522 12.2701H39.6796C40.052 12.2701 40.3546 12.5729 40.3546 12.9452C40.3546 13.3175 40.052 13.6201 39.6796 13.6201H38.2332C35.9468 13.6201 34.0869 15.4802 34.0869 17.7664C34.0869 20.0527 35.9467 21.9128 38.2332 21.9128H43.826V18.4415H38.2332C37.8608 18.4416 37.5581 18.139 37.5581 17.7666Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.6452 17.7666C15.6452 17.3943 15.9478 17.0917 16.3203 17.0917H17.7666C20.053 17.0917 21.9132 15.2317 21.9132 12.9454C21.9132 10.6591 20.0531 8.79899 17.7666 8.79899H16.3931C15.9448 8.46222 15.3433 8.25452 14.6799 8.25452C13.2959 8.25452 12.1741 9.15194 12.1741 10.259C12.1741 11.3554 13.2745 12.2448 14.6399 12.2621L14.6393 12.2701H17.7667C18.1392 12.2701 18.4418 12.5729 18.4418 12.9452C18.4418 13.3175 18.1392 13.6201 17.7667 13.6201H16.3204C14.034 13.6201 12.1741 15.4802 12.1741 17.7664C12.1741 20.0527 14.0339 21.9128 16.3204 21.9128H21.913V18.4415H16.3203C15.9478 18.4416 15.6452 18.139 15.6452 17.7666Z"
      fill="#F0F0F0"
    />
    <path
      d="M37.5583 43.599C37.5583 43.2267 37.8609 42.9241 38.2333 42.9241H39.6797C41.9661 42.9241 43.8262 41.0641 43.8262 38.7778C43.8262 36.4915 41.9662 34.6314 39.6797 34.6314H38.3062C37.8578 34.2946 37.2564 34.0869 36.5929 34.0869C35.2089 34.0869 34.087 34.9844 34.087 36.0914C34.087 37.1878 35.1874 38.0772 36.5529 38.0945L36.5522 38.1025H39.6796C40.052 38.1025 40.3546 38.4053 40.3546 38.7776C40.3546 39.1499 40.052 39.4525 39.6796 39.4525H38.2332C35.9468 39.4525 34.0869 41.3126 34.0869 43.5988C34.0869 45.8851 35.9467 47.7452 38.2332 47.7452H43.8261V44.274H38.2333C37.8609 44.2741 37.5583 43.9712 37.5583 43.599Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.6453 43.599C15.6453 43.2267 15.9479 42.9241 16.3204 42.9241H17.7667C20.0531 42.9241 21.9133 41.0641 21.9133 38.7778C21.9133 36.4915 20.0532 34.6314 17.7667 34.6314H16.3932C15.9449 34.2946 15.3434 34.0869 14.68 34.0869C13.2959 34.0869 12.1741 34.9844 12.1741 36.0914C12.1741 37.1878 13.2745 38.0772 14.6399 38.0945L14.6393 38.1025H17.7667C18.1392 38.1025 18.4418 38.4053 18.4418 38.7776C18.4418 39.1499 18.1392 39.4525 17.7667 39.4525H16.3204C14.034 39.4525 12.1741 41.3126 12.1741 43.5988C12.1741 45.8851 14.0339 47.7452 16.3204 47.7452H21.9132V44.274H16.3204C15.9479 44.2741 15.6453 43.9712 15.6453 43.599Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgMartinique;

import { IIconProps } from '../interface';

const SvgRepublicOfTheCongo = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16452)">
      <path
        d="M15.1781 52.8986L35.7477 35.7477L52.8985 15.1782C50.2319 10.0104 46.0037 5.77991 40.8381 3.10996L20.2522 20.2524L3.10999 40.838C5.77972 46.0039 10.0102 50.232 15.1781 52.8986Z"
        fill="#FFDA44"
      />
      <path
        d="M47.7998 47.7999C56.6336 38.966 58.3294 25.6991 52.8908 15.1613L15.1613 52.8908C25.6991 58.3294 38.966 56.6335 47.7998 47.7999Z"
        fill="#D80027"
      />
      <path
        d="M8.20018 8.20016C-0.633586 17.0339 -2.32933 30.3009 3.10911 40.8387L40.8387 3.1092C30.3009 -2.32947 17.0339 -0.633499 8.20018 8.20016Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16452">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgRepublicOfTheCongo;

import { IIconProps } from '../interface';

const SvgWeather1 = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_40569)">
        <path
          d="M15.988 15H17.523C19.718 15 21.5 13.213 21.5 11.012C21.5 8.811 19.718 7.025 17.523 7.025C16.912 7.025 16.335 7.152 15.832 7.404C15.455 4.912 13.295 3 10.704 3C7.879 3 5.576 5.273 5.504 8.089C3.813 8.305 2.5 9.749 2.5 11.535C2.499 13.448 4.047 15 5.954 15H6.017H7.014"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3 21L13.8 18H10.2L11.7 15"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_40569">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgWeather1;

import { IIconProps } from '../interface';

const SvgTurkmenistan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16843)">
      <path
        d="M27.9999 0.417969C25.9093 0.417969 23.8728 0.649078 21.913 1.08352L20.6957 28.4179L21.913 55.7523C23.8729 56.1867 25.9094 56.418 27.9999 56.418C43.4638 56.418 55.9999 43.8818 55.9999 28.4179C55.9998 12.954 43.4638 0.417969 27.9999 0.417969V0.417969Z"
        fill="#496E2D"
      />
      <path
        d="M7.3044 9.55853C2.76709 14.5347 0.00012207 21.1529 0.00012207 28.4179C0.00012207 35.6828 2.7672 42.301 7.30451 47.2774L8.52186 28.4179L7.3044 9.55853Z"
        fill="#496E2D"
      />
      <path
        d="M40.9523 21.5382C42.3299 17.6984 40.7823 13.5224 37.4609 11.4425C38.0583 11.5203 38.6563 11.6609 39.2465 11.8726C43.6766 13.462 45.9793 18.3414 44.3899 22.7714C42.8005 27.2016 37.921 29.5042 33.491 27.9147C32.9007 27.703 32.3496 27.4312 31.8391 27.1115C35.7252 27.6175 39.5748 25.3779 40.9523 21.5382Z"
        fill="#F0F0F0"
      />
      <path
        d="M33.3117 10.39L32.1146 11.9368L30.2739 11.2763L31.3749 12.8926L30.178 14.4392L32.0554 13.8917L33.1566 15.5078L33.2157 13.553L35.0932 13.0054L33.2525 12.3451L33.3117 10.39Z"
        fill="#F0F0F0"
      />
      <path
        d="M36.544 15.4299L35.3469 16.9766L33.5061 16.3161L34.6072 17.9324L33.4102 19.479L35.2876 18.9312L36.3888 20.5477L36.448 18.5928L38.3255 18.0451L36.4847 17.3847L36.544 15.4299Z"
        fill="#F0F0F0"
      />
      <path
        d="M27.6128 12.2255L26.4157 13.7721L24.575 13.1118L25.6761 14.728L24.4791 16.2747L26.3564 15.7271L27.4576 17.3433L27.5169 15.3885L29.3943 14.8409L27.5537 14.1804L27.6128 12.2255Z"
        fill="#F0F0F0"
      />
      <path
        d="M27.4816 17.9986L26.2846 19.5453L24.4438 18.8849L25.545 20.5012L24.3478 22.0478L26.2254 21.5002L27.3265 23.1164L27.3857 21.1616L29.2632 20.614L27.4223 19.9535L27.4816 17.9986Z"
        fill="#F0F0F0"
      />
      <path
        d="M32.9751 19.9696L31.778 21.5162L29.9373 20.8558L31.0385 22.4721L29.8413 24.0186L31.7189 23.471L32.82 25.0874L32.8792 23.1324L34.7567 22.5849L32.9158 21.9245L32.9751 19.9696Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.30444 9.55853V47.2773C11.1194 51.4613 16.1868 54.4828 21.9131 55.7522V1.0835C16.1867 2.3529 11.1196 5.37439 7.30444 9.55853Z"
        fill="#D80027"
      />
      <path
        d="M12.8792 15.1601L10.4335 13.3381V10.7616L12.8792 8.9397H16.3382L18.7839 10.7616V13.3381L16.3382 15.1601H12.8792Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.6088 8.9397H12.8792L10.4335 10.7616V12.0212H14.6088V8.9397Z"
        fill="#FF9811"
      />
      <path
        d="M14.6088 15.1601H16.3382L18.7839 13.3381V12.0237H14.6088V15.1601Z"
        fill="#FF9811"
      />
      <path
        d="M12.8792 47.896L10.4335 46.0741V43.4976L12.8792 41.6757H16.3382L18.7839 43.4976V46.0741L16.3382 47.896H12.8792Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.6088 41.6757H12.8792L10.4335 43.4976V44.7573H14.6088V41.6757Z"
        fill="#FF9811"
      />
      <path
        d="M14.6088 47.896H16.3382L18.7839 46.0741V44.7598H14.6088V47.896Z"
        fill="#FF9811"
      />
      <path
        d="M12.8793 31.5281L10.4336 29.7061V27.1296L12.8793 25.3077H16.3383L18.784 27.1296V29.7061L16.3383 31.5281H12.8793Z"
        fill="#496E2D"
      />
      <path
        d="M18.8696 35.5366H17.8043V34.4714H15.9324L14.6087 33.1478L13.2852 34.4714H11.4132V35.5366H10.348V37.667H11.4132V38.7322H13.2851L14.6088 40.056L15.9324 38.7322H17.8043V37.667H18.8696V35.5366Z"
        fill="#496E2D"
      />
      <path
        d="M18.8696 19.1686H17.8043V18.1033H15.9324L14.6087 16.7796L13.2852 18.1033H11.4132V19.1686H10.348V21.299H11.4132V22.3642H13.2851L14.6088 23.6881L15.9324 22.3642H17.8043V21.299H18.8696V19.1686Z"
        fill="#496E2D"
      />
      <path
        d="M13.3912 27.2004H15.826V29.6352H13.3912V27.2004Z"
        fill="#D80027"
      />
      <path
        d="M14.6086 21.4507C15.2809 21.4507 15.8259 20.9057 15.8259 20.2333C15.8259 19.561 15.2809 19.016 14.6086 19.016C13.9363 19.016 13.3912 19.561 13.3912 20.2333C13.3912 20.9057 13.9363 21.4507 14.6086 21.4507Z"
        fill="#D80027"
      />
      <path
        d="M14.6086 37.8196C15.2809 37.8196 15.8259 37.2746 15.8259 36.6023C15.8259 35.93 15.2809 35.3849 14.6086 35.3849C13.9363 35.3849 13.3912 35.93 13.3912 36.6023C13.3912 37.2746 13.9363 37.8196 14.6086 37.8196Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16843">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTurkmenistan;

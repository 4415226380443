import { IIconProps } from '../interface';

const SvgFaroeIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15665)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M54.7826 28L55.7625 24.3479C55.5998 23.1031 55.3557 21.8838 55.0355 20.6957H25.5652V0.106537C24.3227 0.213506 23.1037 0.402615 21.913 0.666646L17.0434 3.65215L14.6086 3.40638C13.3273 4.10551 12.1066 4.90176 10.9565 5.7854V20.6956H0.964552C0.644411 21.8836 0.400286 23.103 0.237536 24.3478L1.21732 28L0.237427 31.6521C0.400177 32.8969 0.644302 34.1163 0.964442 35.3043H10.9564V50.2145C12.1065 51.0983 13.3273 51.8944 14.6085 52.5935L18.2607 52.3478L21.9128 55.3333C23.1036 55.5972 24.3226 55.7863 25.565 55.8934V35.3044H55.0352C55.3553 34.1164 55.5994 32.8971 55.7622 31.6523L54.7826 28Z"
        fill="#0052B4"
      />
      <path
        d="M55.763 24.3479H21.9132H21.9131V0.665558C19.3255 1.23934 16.8723 2.16935 14.6087 3.40453V24.3478H0.237016C0.081375 25.5433 0 26.7622 0 28C0 29.2378 0.081375 30.4567 0.237016 31.6522H14.6086H14.6087V52.5954C16.8723 53.8305 19.3255 54.7607 21.9131 55.3344V31.6525V31.6523H55.763C55.9186 30.4567 56 29.2378 56 28C56 26.7622 55.9186 25.5433 55.763 24.3479Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15665">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgFaroeIslands;

import { IIconProps } from '../interface';

const SvgTaiwan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C9.61734 18.3827 17.7739 10.2261 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#D80027"
    />
    <path
      d="M28 28C28 12.5361 28 10.7131 28 0C12.5361 0 0 12.5361 0 28H28Z"
      fill="#0052B4"
    />
    <path
      d="M24.3479 16.3867L20.9283 17.9953L22.7491 21.3071L19.036 20.5966L18.5653 24.3478L15.9791 21.589L13.3926 24.3478L12.9221 20.5966L9.20885 21.3069L11.0297 17.9951L7.61023 16.3867L11.0298 14.7783L9.20885 11.4664L12.922 12.1768L13.3927 8.42569L15.9791 11.1846L18.5654 8.42569L19.036 12.1768L22.7493 11.4664L20.9283 14.7784L24.3479 16.3867Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.9795 21.5982C18.858 21.5982 21.1916 19.2647 21.1916 16.3861C21.1916 13.5076 18.858 11.1741 15.9795 11.1741C13.101 11.1741 10.7675 13.5076 10.7675 16.3861C10.7675 19.2647 13.101 21.5982 15.9795 21.5982Z"
      fill="#0052B4"
    />
    <path
      d="M15.979 19.1639C14.4477 19.1639 13.2019 17.918 13.2019 16.3867C13.2019 14.8553 14.4478 13.6094 15.979 13.6094C17.5104 13.6094 18.7563 14.8553 18.7563 16.3867C18.7562 17.918 17.5102 19.1639 15.979 19.1639Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgTaiwan;

import { IIconProps } from '../interface';

const SvgBenin = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15143)">
      <path
        d="M18.2609 25.5652L20.6957 55.0354C23.0246 55.6631 25.4726 56 27.9999 56C43.4638 56 55.9999 43.4639 55.9999 28L18.2609 25.5652Z"
        fill="#D80027"
      />
      <path
        d="M18.2609 28L20.6956 0.964578C23.0246 0.336875 25.4726 0 27.9999 0C43.4638 0 55.9999 12.5361 55.9999 28H18.2609Z"
        fill="#FFDA44"
      />
      <path
        d="M0 28C0 40.9368 8.77417 51.8224 20.6956 55.0354V0.964569C8.77417 4.17757 0 15.0632 0 28Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15143">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBenin;

import { IIconProps } from "../interface";

const SvgHandArmCoins = ({
    width = 24,
    height = 24,
    color = '#323232',
}: IIconProps) => {
    return (
        <svg width={width}
            height={height}
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="none">
                <path d="M0,0h24v24h-24Z"></path>
                <path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6,21h-2c-0.552,0 -1,-0.448 -1,-1v-6c0,-0.552 0.448,-1 1,-1h2c0.552,0 1,0.448 1,1v6c0,0.552 -0.448,1 -1,1Z"></path><path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13,17h2.333c0.433,0 0.854,-0.14 1.2,-0.4l2.416,-1.812c0.603,-0.452 1.447,-0.392 1.98,0.141v0c0.592,0.592 0.592,1.551 0,2.142l-2.073,2.073c-0.558,0.558 -1.27,0.939 -2.044,1.094l-2.93,0.586c-0.581,0.116 -1.18,0.102 -1.755,-0.042l-2.65,-0.662c-0.317,-0.08 -0.642,-0.12 -0.969,-0.12h-1.508"></path><path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13,17h1.485c0.837,0 1.515,-0.678 1.515,-1.515v-0.303c0,-0.695 -0.473,-1.301 -1.147,-1.469l-2.292,-0.573c-0.373,-0.093 -0.755,-0.14 -1.139,-0.14v0c-0.927,0 -1.834,0.274 -2.605,0.789l-1.817,1.211"></path><path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.1688,5.15523c1.10831,1.10831 1.10831,2.90523 0,4.01354c-1.10831,1.10831 -2.90523,1.10831 -4.01354,0c-1.10831,-1.10831 -1.10831,-2.90523 0,-4.01354c1.10831,-1.10831 2.90523,-1.10831 4.01354,0"></path><path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.631,4.375c-0.093,-0.565 -0.351,-1.108 -0.787,-1.544c-1.108,-1.108 -2.905,-1.108 -4.013,0c-1.108,1.108 -1.108,2.905 0,4.013c0.69,0.69 1.646,0.949 2.537,0.78"></path>
            </g>
        </svg>
    )
}

export default SvgHandArmCoins;
import { IIconProps } from '../interface';

const SvgAruba = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#FFDA44"
    />
    <path
      d="M28 56C36.4865 56 44.0906 52.2239 49.2254 46.2609H6.7746C11.9094 52.2239 19.5135 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M56 28C56 12.5361 43.4639 0 28 0C12.5361 0 0 12.5361 0 28C0 30.5273 0.336875 32.9754 0.964578 35.3044H55.0355C55.6631 32.9754 56 30.5273 56 28Z"
      fill="#338AF3"
    />
    <path
      d="M2.22565 38.9565C2.76541 40.2249 3.3964 41.4446 4.10963 42.6087H51.8903C52.6036 41.4445 53.2345 40.2247 53.7745 38.9565H2.22565Z"
      fill="#338AF3"
    />
    <path
      d="M12.8316 17.66L7.36108 15.2457L12.8316 12.8313L15.2458 7.36093L17.6601 12.8313L23.1304 15.2457L17.6601 17.66L15.2458 23.1304L12.8316 17.66Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.2458 10.3761L16.7367 13.7546L20.1153 15.2457L16.7367 16.7367L15.2458 20.1151L13.7547 16.7367L10.3762 15.2457L13.7547 13.7546L15.2458 10.3761Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgAruba;

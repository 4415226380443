import { IIconProps } from '../interface';

const SvgSudan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16710)">
      <path
        d="M2.77834 15.8261C0.998375 19.5072 0 23.6369 0 28C0 32.3631 0.998375 36.4928 2.77834 40.1739L28 42.6087L53.2217 40.1739C55.0016 36.4928 56 32.3631 56 28C56 23.6369 55.0016 19.5072 53.2217 15.8261L28 13.3913L2.77834 15.8261Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.7793 40.1739C7.30808 49.5398 16.8991 56 28 56C39.1009 56 48.6919 49.5398 53.2206 40.1739H2.7793Z"
        fill="black"
      />
      <path
        d="M2.7793 15.8261H53.2206C48.6919 6.46023 39.1009 0 28 0C16.8991 0 7.30808 6.46023 2.7793 15.8261Z"
        fill="#D80027"
      />
      <path
        d="M8.20099 8.20093C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20093Z"
        fill="#496E2D"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16710">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSudan;

import { IIconProps } from '../interface';

const SvgMalaysia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16063)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M26.7827 28H56.0001C56.0001 25.4727 55.6632 23.0246 55.0355 20.6956H26.7827V28Z"
        fill="#D80027"
      />
      <path
        d="M26.7827 13.3913H51.8904C50.1764 10.5944 47.9851 8.12219 45.4297 6.08694H26.7827V13.3913Z"
        fill="#D80027"
      />
      <path
        d="M28 56C34.5897 56 40.6466 53.7224 45.4296 49.9131H10.5703C15.3534 53.7224 21.4102 56 28 56Z"
        fill="#D80027"
      />
      <path
        d="M4.10968 42.6087H51.8904C53.2664 40.3634 54.3335 37.9089 55.0354 35.3043H0.9646C1.66646 37.9089 2.73363 40.3634 4.10968 42.6087Z"
        fill="#D80027"
      />
      <path
        d="M28 28C28 12.5361 28 10.7131 28 0C12.5361 0 0 12.5361 0 28H28Z"
        fill="#0052B4"
      />
      <path
        d="M18.6194 23.9674C14.7954 23.9674 11.6955 20.8673 11.6955 17.0435C11.6955 13.2196 14.7955 10.1196 18.6194 10.1196C19.8117 10.1196 20.9335 10.4211 21.913 10.9518C20.3765 9.44935 18.2752 8.52185 15.9563 8.52185C11.25 8.52185 7.43457 12.3372 7.43457 17.0436C7.43457 21.75 11.25 25.5653 15.9563 25.5653C18.2752 25.5653 20.3765 24.6378 21.913 23.1353C20.9335 23.6658 19.8118 23.9674 18.6194 23.9674Z"
        fill="#FFDA44"
      />
      <path
        d="M20.5706 12.1739L21.7999 14.7444L24.5759 14.103L23.3328 16.6667L25.5653 18.4371L22.7857 19.0637L22.7934 21.9131L20.5706 20.1305L18.3477 21.9131L18.3553 19.0637L15.5757 18.4371L17.8082 16.6667L16.565 14.103L19.3412 14.7444L20.5706 12.1739Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16063">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMalaysia;

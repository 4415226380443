import { IIconProps } from '../interface';

const SvgSerbia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C12.5361 0 0 12.5361 0 28C0 31.425 0.616109 34.7059 1.74158 37.7391H54.2585C55.384 34.7059 56 31.425 56 28C56 12.5361 43.4639 0 28 0Z"
      fill="#0052B4"
    />
    <path
      d="M28.0001 0C15.9611 0 5.6979 7.5985 1.7417 18.2609H54.2586C50.3023 7.5985 40.0391 0 28.0001 0Z"
      fill="#D80027"
    />
    <path
      d="M7.24048 15.8261V29.794C7.24048 37.7394 17.6203 40.174 17.6203 40.174C17.6203 40.174 28 37.7394 28 29.794V15.8261H7.24048Z"
      fill="#D80027"
    />
    <path
      d="M11.5332 18.2609H23.7072V13.3913L21.2724 14.6087L17.6202 10.9565L13.968 14.6087L11.5332 13.3913V18.2609Z"
      fill="#FFDA44"
    />
    <path
      d="M25.5651 31.7388L17.6521 23.8257L9.73901 31.7388L12.3215 34.3212L17.6521 28.9906L22.9827 34.3212L25.5651 31.7388Z"
      fill="#FFDA44"
    />
    <path
      d="M25.5651 24.3479H21.8731C22.0807 23.9895 22.2014 23.5746 22.2014 23.1307C22.2014 21.7859 21.1113 20.6959 19.7666 20.6959C18.8375 20.6959 18.0306 21.2163 17.6201 21.9814C17.2097 21.2165 16.4028 20.6959 15.4737 20.6959C14.129 20.6959 13.0389 21.7859 13.0389 23.1307C13.0389 23.5746 13.1596 23.9897 13.3672 24.3479H9.73901C9.73901 25.6926 10.9103 26.7827 12.2549 26.7827H12.1738C12.1738 28.1274 13.2638 29.2175 14.6086 29.2175C14.6086 30.4081 15.4637 31.3973 16.593 31.609L14.6746 35.9405C15.594 36.3142 16.5987 36.5218 17.6522 36.5218C18.7057 36.5218 19.7104 36.3142 20.6298 35.9405L18.7114 31.609C19.8407 31.3975 20.6958 30.4082 20.6958 29.2175C22.0405 29.2175 23.1306 28.1274 23.1306 26.7827H23.0495C24.394 26.7827 25.5651 25.6925 25.5651 24.3479Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgSerbia;

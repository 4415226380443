import { IIconProps } from '../interface';

const SvgSeychelles = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M46.9009 7.34311C41.92 2.78305 35.2852 0 27.9999 0C26.8073 0 25.6321 0.0751406 24.4786 0.219844L10.9565 15.8261L0.885986 35.0143C1.53655 37.5366 2.5312 39.9203 3.8145 42.1146L27.9999 28L46.9009 7.34311Z"
      fill="#FFDA44"
    />
    <path
      d="M51.3111 43.5135L12.1365 51.0735C16.6459 54.1799 22.1101 56 27.9999 56C37.7256 56 46.2918 51.0406 51.3111 43.5135Z"
      fill="#6DA544"
    />
    <path
      d="M46.905 7.34683L3.83057 42.1418C4.95724 44.0633 6.30616 45.8385 7.84255 47.432L56 28C56 19.8232 52.4945 12.4658 46.905 7.34683Z"
      fill="#D80027"
    />
    <path
      d="M0 28C0 30.4223 0.307781 32.7727 0.886047 35.0143L24.4787 0.219849C10.677 1.95169 0 13.7288 0 28Z"
      fill="#0052B4"
    />
  </svg>
);

export default SvgSeychelles;

import { IIconProps } from '../interface';

const SvgBritishVirginIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5334 0.002625 0 12.5375 0 28H14.6087V19.7738L22.8349 28H27.9252C27.9501 27.9751 27.9751 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65215C9.7864 6.14579 6.14563 9.78644 3.6521 14.1657V28H10.9565V10.9568V10.9565H28C28 8.65277 28 6.45805 28 3.65215H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M40.1738 28L32.6058 35.568C34.0242 38.3036 36.8798 40.1739 40.1738 40.1739C43.4679 40.1739 46.3234 38.3036 47.7418 35.568L40.1738 28Z"
      fill="#FFDA44"
    />
    <path
      d="M28 24.5568L18.0518 14.6088H14.6086V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5568Z"
      fill="#D80027"
    />
    <path
      d="M31.6521 14.6087V27.9999V28C31.6522 34.523 40.1738 36.5217 40.1738 36.5217C40.1738 36.5217 48.6955 34.523 48.6956 28V27.9999V14.6087H31.6521Z"
      fill="#6DA544"
    />
    <path d="M38.3479 20.6956H42V30.4347H38.3479V20.6956Z" fill="#F0F0F0" />
    <path
      d="M40.1739 22.5217C41.1824 22.5217 42 21.7041 42 20.6956C42 19.6871 41.1824 18.8695 40.1739 18.8695C39.1654 18.8695 38.3478 19.6871 38.3478 20.6956C38.3478 21.7041 39.1654 22.5217 40.1739 22.5217Z"
      fill="#A2001D"
    />
    <path
      d="M34.0869 17.0435H36.5217V19.4783H34.0869V17.0435Z"
      fill="#FFDA44"
    />
    <path
      d="M34.0869 22.5217H36.5217V24.9565H34.0869V22.5217Z"
      fill="#FFDA44"
    />
    <path d="M34.0869 28H36.5217V30.4348H34.0869V28Z" fill="#FFDA44" />
    <path d="M43.826 17.0435H46.2608V19.4783H43.826V17.0435Z" fill="#FFDA44" />
    <path d="M43.826 22.5217H46.2608V24.9565H43.826V22.5217Z" fill="#FFDA44" />
    <path d="M43.826 28H46.2608V30.4348H43.826V28Z" fill="#FFDA44" />
  </svg>
);

export default SvgBritishVirginIslands;

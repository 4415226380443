import { IIconProps } from '../interface';

const SvgStLucia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M17.6521 37.7391H38.3478L28 13.3913L17.6521 37.7391Z"
      fill="#F3F3F3"
    />
    <path
      d="M21.2484 35.3044L28 19.9471L34.7516 35.3044H21.2484Z"
      fill="#333333"
    />
    <path d="M17.6521 37.7391H38.3478L28 28L17.6521 37.7391Z" fill="#FFDA44" />
  </svg>
);

export default SvgStLucia;

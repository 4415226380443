import { IIconProps } from '../interface';

const SvgCoins = ({ width = 32, height = 32, color, type }: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1702_11954)">
        <path
          d="M8 17.4749V12.1416H18.6667V17.4749"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6663 12.1414H19.9997V6.80811H9.33301V12.1414"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7716 19.0372C25.8544 21.12 25.8544 24.4969 23.7716 26.5797C21.6888 28.6625 18.3119 28.6625 16.2291 26.5797C14.1463 24.4969 14.1463 21.12 16.2291 19.0372C18.3119 16.9544 21.6888 16.9544 23.7716 19.0372"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 22.8081V28.1414H20"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0003 17.4751H6.66699V22.8084H14.667"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_11954">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.141602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCoins;

import { IIconProps } from '../interface';

const SvgOman = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16331)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M0 28C0 38.6132 5.90516 47.8466 14.6087 52.5955V3.40452C5.90516 8.15336 0 17.3868 0 28Z"
        fill="#D80027"
      />
      <path
        d="M54.2585 37.7391C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2941 54.2585 18.2609H1.74169C0.616109 21.2941 0 24.575 0 28C0 31.425 0.616109 34.7058 1.74169 37.7391L28 40.1739L54.2585 37.7391Z"
        fill="#D80027"
      />
      <path
        d="M28 56C40.0391 56 50.3022 48.4015 54.2585 37.7391H1.7417C5.69779 48.4015 15.9611 56 28 56Z"
        fill="#6DA544"
      />
      <path
        d="M0 28C0 40.9368 8.77417 51.8224 20.6956 55.0354V0.964577C8.77417 4.17758 0 15.0632 0 28Z"
        fill="#D80027"
      />
      <path
        d="M17.13 12.3215L14.5475 9.73907L12.826 11.4609L11.1044 9.73907L8.52195 12.3215L10.2436 14.0432L8.52173 15.765L11.1042 18.3474L12.8259 16.6257L14.5475 18.3474L17.13 15.765L15.4083 14.0432L17.13 12.3215Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16331">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgOman;

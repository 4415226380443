import { IIconProps } from '../interface';

const SvgNorthKorea = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16302)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28.0001 0C19.5136 0 11.9095 3.77606 6.77466 9.73908H49.2254C44.0907 3.77606 36.4866 0 28.0001 0Z"
        fill="#0052B4"
      />
      <path
        d="M49.2255 46.2609H6.77466C11.9095 52.2239 19.5136 56 28.0001 56C36.4866 56 44.0907 52.2239 49.2255 46.2609Z"
        fill="#0052B4"
      />
      <path
        d="M51.8903 13.3913H4.10966C1.50336 17.6444 0 22.6464 0 28C0 33.3536 1.50336 38.3557 4.10966 42.6087H51.8903C54.4966 38.3557 56 33.3536 56 28C56 22.6464 54.4966 17.6444 51.8903 13.3913Z"
        fill="#D80027"
      />
      <path
        d="M17.226 38.7739C23.1763 38.7739 27.9999 33.9502 27.9999 28C27.9999 22.0497 23.1763 17.2261 17.226 17.2261C11.2758 17.2261 6.45215 22.0497 6.45215 28C6.45215 33.9502 11.2758 38.7739 17.226 38.7739Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.226 17.2261L19.6433 24.6659H27.4787L21.1375 29.2643L23.5737 36.7263L17.226 32.106L10.888 36.7176L13.3146 29.2643L6.979 24.6659H14.8086L17.226 17.2261Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16302">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgNorthKorea;

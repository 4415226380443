import { IIconProps } from '../interface';

const SvgFilterSort = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41140)">
        <path
          d="M14.75 15V19C14.75 19.5304 14.5393 20.0391 14.1642 20.4142C13.7891 20.7893 13.2804 21 12.75 21H11.25C10.7196 21 10.2109 20.7893 9.83579 20.4142C9.46071 20.0391 9.25 19.5304 9.25 19V15L3.50814 8.5691C3.18088 8.20258 3 7.72842 3 7.23706V6C3 4.34314 7.02944 3 12 3C16.9706 3 21 4.34314 21 6V7.23706C21 7.7284 20.8191 8.20255 20.4919 8.56906L14.75 15Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 6C21 7.65685 16.9706 9 12 9C7.02944 9 3 7.65685 3 6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41140">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgFilterSort;

import { IIconProps } from '../interface';

const SvgSahrawiArabDemocraticRepublic = ({
  width = 56,
  height = 56,
}: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16478)">
      <path
        d="M2.77834 15.8261C0.998375 19.5072 0 23.6369 0 28C0 32.3631 0.998375 36.4928 2.77834 40.1739L28 42.6087L53.2217 40.1739C55.0016 36.4928 56 32.3631 56 28C56 23.6369 55.0016 19.5072 53.2217 15.8261L28 13.3913L2.77834 15.8261Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.7793 40.1739C7.30808 49.5398 16.8992 56 28 56C39.1008 56 48.6919 49.5398 53.2206 40.1739H2.7793Z"
        fill="#496E2D"
      />
      <path
        d="M2.7793 15.8261H53.2206C48.6919 6.46023 39.1009 0 28 0C16.8991 0 7.30808 6.46023 2.7793 15.8261Z"
        fill="black"
      />
      <path
        d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20094Z"
        fill="#D80027"
      />
      <path
        d="M35.3043 28C35.3043 23.9205 38.1714 20.5125 42 19.6765C41.4115 19.5479 40.801 19.4783 40.1738 19.4783C35.4673 19.4783 31.6521 23.2935 31.6521 28C31.6521 32.7065 35.4673 36.5217 40.1738 36.5217C40.8009 36.5217 41.4114 36.4521 42 36.3235C38.1714 35.4875 35.3043 32.0795 35.3043 28Z"
        fill="#D80027"
      />
      <path
        d="M42.3581 22.5217L43.567 26.242H47.4784L44.3139 28.5408L45.5228 32.2609L42.3581 29.9618L39.1938 32.2609L40.4027 28.5408L37.238 26.242H41.1494L42.3581 22.5217Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16478">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSahrawiArabDemocraticRepublic;

import { IIconProps } from '../interface';

const SvgEritrea = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15623)">
      <path
        d="M25.5652 25.5652C25.5652 25.5652 8.21733 47.803 8.20093 47.7989C13.2678 52.866 20.2679 56 28 56C43.4639 56 56 43.4639 56 28L25.5652 25.5652Z"
        fill="#338AF3"
      />
      <path
        d="M25.5652 28C25.5652 28 8.21733 8.19711 8.20093 8.20105C13.2678 3.13403 20.2679 0 28 0C43.4639 0 56 12.5361 56 28H25.5652Z"
        fill="#6DA544"
      />
      <path
        d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.7991C8.20023 47.8035 56 28 56 28L8.20099 8.20094Z"
        fill="#D80027"
      />
      <path
        d="M14.6087 16.4348C9.2385 16.4348 4.86963 20.8038 4.86963 26.1739V29.826C4.86963 35.1962 9.23861 39.5651 14.6087 39.5651C19.9788 39.5651 24.3478 35.1961 24.3478 29.826V26.1739C24.3479 20.8038 19.9789 16.4348 14.6087 16.4348ZM20.6956 29.8261C20.6956 32.5465 18.9017 34.8553 16.4347 35.6328V31.6523L19.0186 29.0684L16.4361 26.4859L16.4347 25.5668V24.348H12.7827V28L10.2006 30.582L12.7827 33.164V35.633C10.3157 34.8554 8.52177 32.5466 8.52177 29.8262V26.1741C8.52177 22.8177 11.2524 20.0872 14.6087 20.0872C17.965 20.0872 20.6956 22.8178 20.6956 26.1741V29.8261Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15623">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEritrea;

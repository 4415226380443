import { IIconProps } from '../interface';

const SvgSanMarino = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C1.21734 28 28 24.3479 28 24.3479L56 28Z"
      fill="#338AF3"
    />
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28"
      fill="#F0F0F0"
    />
    <path
      d="M39.1144 19.3204L28.0001 30.4348L16.8859 19.3204C15.4486 21.454 14.6089 24.022 14.6089 26.7825V30.4347C14.6089 36.2989 18.3982 41.2944 23.6566 43.1025C22.9547 44.478 23.0731 46.1947 24.1015 47.4782C25.4674 46.3834 26.8815 45.2502 28.0812 44.289C29.2808 45.2503 30.695 46.3835 32.0608 47.4782C33.1012 46.1797 33.2111 44.4373 32.4815 43.0537C37.6681 41.2064 41.3913 36.2475 41.3913 30.4347V26.7827C41.3914 24.022 40.5517 21.4541 39.1144 19.3204Z"
      fill="#6DA544"
    />
    <path
      d="M28.0001 40.1739C22.6299 40.1739 18.261 35.8049 18.261 30.4348V26.7827C18.261 21.4125 22.63 17.0436 28.0001 17.0436C33.3702 17.0436 37.7391 21.4126 37.7391 26.7827V30.4348C37.7391 35.8049 33.3703 40.1739 28.0001 40.1739Z"
      fill="#FFDA44"
    />
    <path
      d="M34.087 30.4348V26.7827C34.087 23.4263 31.3563 20.6957 28 20.6957C24.6437 20.6957 21.9131 23.4264 21.9131 26.7827V30.4348L28 31.6521L34.087 30.4348Z"
      fill="#338AF3"
    />
    <path
      d="M21.9131 30.4348C21.9131 33.7912 24.6437 36.5217 28 36.5217C31.3563 36.5217 34.087 33.7911 34.087 30.4348H21.9131Z"
      fill="#6DA544"
    />
    <path
      d="M35.3045 17.0435C35.3045 15.0265 33.6693 13.3913 31.6523 13.3913C30.7166 13.3913 29.8638 13.7435 29.2175 14.322V12.1739H30.4349V9.73908H29.2175V8.52174H26.7827V9.73908H25.5654V12.1739H26.7827V14.322C26.1364 13.7435 25.2835 13.3913 24.3479 13.3913C22.331 13.3913 20.6958 15.0265 20.6958 17.0435C20.6958 18.1249 21.1662 19.0961 21.9131 19.7649V21.9131H34.087V19.7649C34.8341 19.0961 35.3045 18.1249 35.3045 17.0435Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgSanMarino;

import { IIconProps } from '../interface';

const SvgSendMessage = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41157)">
        <path
          d="M10.481 18.7099L21.468 12.8779C22.177 12.5019 22.177 11.4899 21.468 11.1129L10.497 5.28994C9.669 4.84994 8.741 5.67994 9.092 6.54594L11.281 11.9459L9.074 17.4559C8.728 18.3229 9.655 19.1479 10.481 18.7099Z"
          stroke={color}
          strokeWidth="1.5036"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.28 11.95L22 12"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 15H5.6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 12H5.6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 9H5.6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41157">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgSendMessage;

import { IIconProps } from '../interface';

const SvgEngagementRings = ({
  width = 32,
  height = 32,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1667 2C12.7339 2 12.3129 2.14036 11.9667 2.4L10.5396 3.47028C9.59644 4.17767 9.46668 5.54326 10.2597 6.41563L14.5201 11.102C15.3136 11.9749 16.6864 11.9749 17.4799 11.102L21.7403 6.41563C22.5333 5.54326 22.4036 4.17767 21.4604 3.47028L20.0333 2.4C19.6871 2.14036 19.2661 2 18.8333 2H13.1667ZM13.2189 5.21085L13.5 5H18.5L18.7811 5.21085L16 8.27009L13.2189 5.21085Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.63604 27.364C7.72549 30.4534 12.5022 30.8272 16 28.4854C19.4978 30.8272 24.2745 30.4534 27.364 27.364C30.8787 23.8492 30.8787 18.1508 27.364 14.636C24.2745 11.5466 19.4978 11.1728 16 13.5146C12.5022 11.1728 7.72549 11.5466 4.63604 14.636C1.12132 18.1508 1.12132 23.8492 4.63604 27.364ZM16 17.6813C14.6667 19.6854 14.6667 22.3146 16 24.3187C17.3333 22.3146 17.3333 19.6854 16 17.6813ZM13.7499 15.6654C11.4167 18.8281 11.4167 23.1719 13.7499 26.3346C11.492 27.4991 8.64985 27.1351 6.75736 25.2426C4.41421 22.8995 4.41421 19.1005 6.75736 16.7574C8.64985 14.8649 11.492 14.5009 13.7499 15.6654ZM25.2426 16.7574C23.3501 14.8649 20.508 14.5009 18.2502 15.6654C20.5833 18.8281 20.5833 23.1719 18.2502 26.3346C20.508 27.4991 23.3501 27.1351 25.2426 25.2426C27.5858 22.8995 27.5858 19.1005 25.2426 16.7574Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgEngagementRings;

import { IIconProps } from '../interface';

const SvgGlobe = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2ZM15.9583 4.00007C15.9722 4.00002 15.9861 4 16 4C16.0139 4 16.0278 4.00002 16.0417 4.00007C17.9681 5.93306 19.4315 8.32606 20.253 11H11.747C12.5685 8.32606 14.0319 5.93306 15.9583 4.00007ZM19.04 4.38842C20.5399 6.32848 21.6731 8.56716 22.3351 11H26.9119C25.4271 7.76486 22.5459 5.30376 19.04 4.38842ZM20.7362 13H11.2638C11.0904 13.9738 11 14.9763 11 16C11 17.0237 11.0904 18.0262 11.2638 19H20.7362C20.9096 18.0262 21 17.0237 21 16C21 14.9763 20.9096 13.9738 20.7362 13ZM22.7644 19C22.9195 18.0228 23 17.0208 23 16C23 14.9792 22.9195 13.9772 22.7644 13H27.6219C27.8687 13.9589 28 14.9641 28 16C28 17.0359 27.8687 18.0411 27.6219 19H22.7644ZM20.253 21H11.747C12.5685 23.6739 14.0319 26.0669 15.9583 27.9999C15.9722 28 15.9861 28 16 28C16.0139 28 16.0278 28 16.0417 27.9999C17.9681 26.0669 19.4315 23.6739 20.253 21ZM19.04 27.6116C20.5399 25.6715 21.6731 23.4328 22.3351 21H26.9119C25.4271 24.2351 22.5459 26.6962 19.04 27.6116ZM12.96 27.6116C11.4601 25.6715 10.3269 23.4328 9.66494 21H5.08808C6.5729 24.2351 9.4541 26.6962 12.96 27.6116ZM9.23559 19C9.08054 18.0228 9 17.0208 9 16C9 14.9792 9.08054 13.9772 9.23559 13H4.37805C4.13126 13.9589 4 14.9641 4 16C4 17.0359 4.13126 18.0411 4.37805 19H9.23559ZM9.66495 11C10.3269 8.56716 11.4601 6.32848 12.96 4.38842C9.45412 5.30376 6.5729 7.76486 5.08808 11H9.66495Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgGlobe;

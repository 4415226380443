import { IIconProps } from './interface';
import { IconsList } from './icons/enum';
import { FlagsList } from './flags/enum';

import * as Icons from './icons/index';
import * as Flags from './flags/index';

interface IIcon extends IIconProps {
  name: IconsList | FlagsList;
  disabled?: boolean;
}

const getIcon = (icon: IconsList) => Icons[icon];
const getFlag = (icon: FlagsList) => Flags[icon];

const Icon = ({
  name,
  width,
  height,
  color = 'black',
  type,
  disabled,
}: IIcon) => {
  const RendeIcon = getIcon(name as IconsList) || getFlag(name as FlagsList);

  if (!RendeIcon) {
    console.warn('Icon not found - see icons list name');
    return null;
  }

  return (
    <div className={disabled ? 'grayscale' : ''}>
      <RendeIcon width={width} height={height} color={color} type={type} />
    </div>
  );
};

export default Icon;

import { IIconProps } from '../interface';

const SvgMacao = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#496E2D"
    />
    <path
      d="M32.8696 26.8324C32.1823 26.8324 31.5298 26.9695 30.9238 27.1876C31.2558 26.4477 31.4784 25.6246 31.4784 24.7455C31.4784 21.2673 28.0001 18.6586 28.0001 18.6586C28.0001 18.6586 24.5219 21.2673 24.5219 24.7455C24.5219 25.6245 24.7445 26.4477 25.0764 27.1876C24.4704 26.9697 23.8178 26.8324 23.1306 26.8324C19.6524 26.8324 17.0437 30.3106 17.0437 30.3106C17.0437 30.3106 19.6524 33.789 23.1306 33.789C25.1699 33.789 26.9092 32.5937 28.0002 31.6043C29.0912 32.5936 30.8305 33.789 32.8698 33.789C36.3481 33.789 38.9568 30.3106 38.9568 30.3106C38.9568 30.3106 36.3478 26.8324 32.8696 26.8324Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 10.9565L28.7185 13.1677H31.0435L29.1628 14.5344L29.8807 16.7455L28 15.3788L26.1193 16.7455L26.8372 14.5344L24.9565 13.1677H27.2815L28 10.9565Z"
      fill="#FFDA44"
    />
    <path
      d="M19.1588 14.8239L20.5411 16.0684L22.1519 15.1385L21.3954 16.8376L22.7776 18.0823L20.9279 17.8879L20.1714 19.587L19.7845 17.7678L17.9348 17.5733L19.5455 16.6432L19.1588 14.8239Z"
      fill="#FFDA44"
    />
    <path
      d="M12.6868 21.2961L14.5061 21.6827L15.4361 20.072L15.6306 21.9216L17.4497 22.3086L15.7507 23.0651L15.9452 24.9146L14.7004 23.5327L13.0013 24.2891L13.9313 22.6783L12.6868 21.2961Z"
      fill="#FFDA44"
    />
    <path
      d="M36.8411 14.8239L35.4589 16.0684L33.8481 15.1385L34.6045 16.8376L33.2224 18.0823L35.0722 17.8879L35.8286 19.587L36.2156 17.7678L38.0652 17.5733L36.4546 16.6432L36.8411 14.8239Z"
      fill="#FFDA44"
    />
    <path
      d="M43.3132 21.2961L41.4939 21.6827L40.564 20.072L40.3694 21.9216L38.5503 22.3086L40.2493 23.0651L40.0549 24.9146L41.2997 23.5327L42.9987 24.2891L42.0687 22.6783L43.3132 21.2961Z"
      fill="#FFDA44"
    />
    <path
      d="M27.9999 43.5282C30.7628 43.5282 33.3095 42.6064 35.3524 41.0553H20.6475C22.6904 42.6063 25.2369 43.5282 27.9999 43.5282Z"
      fill="#F0F0F0"
    />
    <path
      d="M16.8401 36.2238C17.2265 37.1082 17.7165 37.9365 18.292 38.6966H37.7081C38.2836 37.9366 38.7736 37.1082 39.16 36.2238H16.8401Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgMacao;

import { IIconProps } from '../interface';

const SvgKenya = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15929)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 0C16.8991 0 7.30732 6.46023 2.77832 15.8261H53.2215C48.6926 6.46023 39.1008 0 28 0Z"
        fill="black"
      />
      <path
        d="M28 56C16.8991 56 7.30732 49.5398 2.77832 40.1739H53.2215C48.6926 49.5398 39.1008 56 28 56Z"
        fill="#496E2D"
      />
      <path
        d="M54.6778 19.4783H1.32223C0.464516 22.1655 0 25.0284 0 28C0 30.9716 0.464516 33.8345 1.32223 36.5217H54.6777C55.5355 33.8345 56 30.9716 56 28C56 25.0284 55.5355 22.1655 54.6778 19.4783Z"
        fill="#A2001D"
      />
      <path
        d="M36.7188 12.9392L33.4029 11.4087L28 23.6424L22.5971 11.4087L19.2812 12.9392L25.9889 28L19.2812 43.0608L22.5971 44.5913L28 32.3576L33.4029 44.5913L36.7188 43.0608L30.0111 28L36.7188 12.9392Z"
        fill="#F0F0F0"
      />
      <path
        d="M30.2521 14.3816C28.9685 12.9798 28 12.1739 28 12.1739C28 12.1739 27.0315 12.9797 25.7479 14.3816V41.6182C27.0315 43.0203 28 43.8261 28 43.8261C28 43.8261 28.9685 43.0204 30.2521 41.6183V14.3816Z"
        fill="#F0F0F0"
      />
      <path
        d="M21.9131 19.6047V36.395C23.2696 38.7986 24.9298 40.7686 26.174 42.0748V13.925C24.9298 15.2311 23.2694 17.2013 21.9131 19.6047Z"
        fill="#A2001D"
      />
      <path
        d="M34.087 19.6047C32.7304 17.2012 31.0703 15.2311 29.826 13.925V42.0748C31.0702 40.7686 32.7304 38.7986 34.087 36.395V19.6047Z"
        fill="#A2001D"
      />
      <path
        d="M34.0869 19.6047V36.3951C35.4601 33.9616 36.5217 31.084 36.5217 28C36.5217 24.9159 35.4601 22.0383 34.0869 19.6047Z"
        fill="black"
      />
      <path
        d="M21.9131 19.6047V36.3951C20.5399 33.9616 19.4783 31.084 19.4783 28C19.4783 24.9159 20.5399 22.0383 21.9131 19.6047Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15929">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgKenya;

import { IIconProps } from '../interface';

const SvgMadeira = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1739 2.77834C36.4928 0.998375 32.3631 0 28 0C23.637 0 19.5073 0.998375 15.8262 2.77834L13.3914 28L15.8262 53.2217C19.5073 55.0016 23.637 56 28 56C32.3631 56 36.4928 55.0016 40.1739 53.2217L42.6087 28L40.1739 2.77834Z"
      fill="#FFDA44"
    />
    <path
      d="M15.8261 2.77933C6.46023 7.30822 0 16.8992 0 28C0 39.1008 6.46023 48.6918 15.8261 53.2207V2.77933Z"
      fill="#0052B4"
    />
    <path
      d="M40.1738 2.77933V53.2207C49.5396 48.6918 56 39.1008 56 28C56 16.8992 49.5396 7.30822 40.1738 2.77933Z"
      fill="#0052B4"
    />
    <path
      d="M35.9131 30.4348L37.7391 32.8696V23.1304L35.9131 25.5652H30.4349V20.0869L32.8697 18.2609H23.1305L25.5653 20.0869V25.5652H20.087L18.261 23.1304V32.8696L20.087 30.4348H25.5653V35.9131L23.1305 37.7391H32.8697L30.4349 35.9131V30.4348H35.9131Z"
      fill="#D80027"
    />
    <path
      d="M35.3043 26.7827H29.2173V20.6956H26.7826V26.7827H20.6956V29.2174H26.7826V35.3044H29.2173V29.2174H35.3043V26.7827Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgMadeira;

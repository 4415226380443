import React from 'react';
import { IconsList } from '../../Icon/icons/enum';
import AnchorCategory from './Category';
import AnchorQuestion from './Question';

export enum ANCHOR_VARIANTS {
  CATEGORY = 'CATEGORY',
  QUESTION = 'QUESTION',
}

export interface IToolAnchor {
  id: string;
  label: string;
  labelYear?: string;
  action: () => void;
  icon: IconsList;
  done: boolean;
  variant: ANCHOR_VARIANTS;
  disabled: boolean;
  className?: string;
  isActive?: boolean;
}

const Anchor = (props: IToolAnchor) => {
  if (props.disabled) {
    return null;
  }

  if (props.variant === ANCHOR_VARIANTS.CATEGORY) {
    return <AnchorCategory {...props} />;
  }

  return <AnchorQuestion {...props} />;
};

export default Anchor;

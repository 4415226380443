import { IIconProps } from '../interface';

const SvgAntiguaAndBarbuda = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15010)">
      <path
        d="M0 28C0 29.9179 0.193375 31.7906 0.560766 33.6L28 35.3044L55.4393 33.6C55.8068 31.7906 56 29.9179 56 28C56 26.0821 55.8066 24.2094 55.4393 22.4L28 20.6956L0.560766 22.4C0.193375 24.2094 0 26.0821 0 28H0Z"
        fill="#0052B4"
      />
      <path
        d="M55.4394 33.6H0.560791C3.15506 46.3805 14.454 56 28 56C41.546 56 52.845 46.3805 55.4394 33.6Z"
        fill="#F0F0F0"
      />
      <path
        d="M0.560791 22.4H55.4395C52.845 9.61953 41.5462 0 28 0C14.4538 0 3.15506 9.61953 0.560791 22.4V22.4Z"
        fill="black"
      />
      <path
        d="M40.1739 22.4H15.8262L20.8005 20.06L18.1516 15.2426L23.5531 16.2757L24.2378 10.8191L28 14.8325L31.7625 10.8191L32.4468 16.2757L37.8485 15.2426L35.1998 20.0603L40.1739 22.4Z"
        fill="#FFDA44"
      />
      <path
        d="M0 28C0 43.4639 12.5361 56 28 56L5.59956 11.199C2.08381 15.8791 0 21.6959 0 28Z"
        fill="#A2001D"
      />
      <path
        d="M28 56C43.4639 56 56 43.4639 56 28C56 21.6959 53.9162 15.8791 50.4004 11.199L28 56Z"
        fill="#A2001D"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15010">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAntiguaAndBarbuda;

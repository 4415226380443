import { IIconProps } from '../interface';

const SvgTanzania = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#FFDA44"
    />
    <path
      d="M8.20098 47.7991C9.37556 48.9736 10.6288 50.0211 11.9434 50.9434L50.9432 11.9434C50.0212 10.6289 48.9736 9.37564 47.799 8.20106C46.6243 7.02648 45.3712 5.9791 44.0566 5.05685L5.05688 44.0568C5.97892 45.3711 7.02651 46.6244 8.20098 47.7991Z"
      fill="black"
    />
    <path
      d="M8.20096 8.20094C-0.63239 17.0345 -2.32825 30.3007 3.1102 40.8382L40.8382 3.1103C30.3007 -2.32815 17.0344 -0.632183 8.20096 8.20094Z"
      fill="#6DA544"
    />
    <path
      d="M47.7989 47.799C56.6323 38.9657 58.328 25.6993 52.8898 15.1618L15.1617 52.8898C25.699 58.3282 38.9655 56.6324 47.7989 47.799Z"
      fill="#338AF3"
    />
  </svg>
);

export default SvgTanzania;

import { IIconProps } from '../interface';

const SvgBrunei = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15277)">
      <path
        d="M28.0003 55.9961C43.462 55.9961 55.9962 43.4619 55.9962 28.0002C55.9962 12.5385 43.462 0.00427246 28.0003 0.00427246C12.5386 0.00427246 0.00439453 12.5385 0.00439453 28.0002C0.00439453 43.4619 12.5386 55.9961 28.0003 55.9961Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.75494 14.002C2.49131 16.1905 1.55919 18.4784 0.938477 20.8089L47.7583 47.8406C49.4663 46.1377 50.9818 44.1863 52.2451 41.998L3.75494 14.002Z"
        fill="black"
      />
      <path
        d="M41.9981 3.75485C30.7961 -2.71252 16.9839 -0.557018 8.2417 8.15948L55.0615 35.1909C58.2393 23.262 53.1998 10.2223 41.9981 3.75485Z"
        fill="#FFDA44"
      />
      <path
        d="M14.0022 52.2452C25.2039 58.7126 39.0165 56.5571 47.7584 47.8407L0.938469 20.8089C-2.23905 32.7382 2.80018 45.7778 14.0022 52.2452Z"
        fill="#FFDA44"
      />
      <path
        d="M35.912 24.957C35.912 29.3265 32.3697 32.8689 28.0001 32.8689C23.6305 32.8689 20.0882 29.3266 20.0882 24.957C20.0882 23.5946 20.4326 22.3125 21.0392 21.1933C19.3223 22.9491 18.2623 25.3502 18.2623 27.9999C18.2623 33.3779 22.622 37.7377 28.0001 37.7377C33.3782 37.7377 37.7378 33.378 37.7378 27.9999C37.7378 25.3502 36.678 22.9491 34.961 21.1933C35.5676 22.3128 35.912 23.5946 35.912 24.957Z"
        fill="#D80027"
      />
      <path
        d="M26.1744 15.828H29.8261V34.0863H26.1744V15.828Z"
        fill="#D80027"
      />
      <path
        d="M34.0862 19.4796H21.9142C21.9142 20.3199 22.6462 21.0011 23.4865 21.0011H23.4357C23.4357 21.8415 24.117 22.5227 24.9573 22.5227C24.9573 23.363 25.6386 24.0442 26.4788 24.0442H29.5218C30.3622 24.0442 31.0434 23.363 31.0434 22.5227C31.8837 22.5227 32.5649 21.8415 32.5649 21.0011H32.5143C33.3543 21.001 34.0862 20.32 34.0862 19.4796Z"
        fill="#D80027"
      />
      <path
        d="M19.4799 35.3034H36.5208V38.9551H19.4799V35.3034Z"
        fill="#D80027"
      />
      <path
        d="M31.6519 36.5207H38.9551V40.1723H31.6519V36.5207Z"
        fill="#D80027"
      />
      <path
        d="M17.0454 36.5207H24.3486V40.1723H17.0454V36.5207Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15277">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBrunei;

import { IIconProps } from '../interface';

const SvgWeather7 = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_40639)">
        <path
          d="M17.233 6.689C16.653 6.689 16.108 6.805 15.631 7.036C15.273 4.753 13.227 3 10.773 3C8.097 3 5.915 5.084 5.847 7.665C4.244 7.863 3 9.186 3 10.824C3 12.577 4.466 14 6.273 14H17.233C19.313 14 21 12.362 21 10.344C21 8.326 19.313 6.689 17.233 6.689Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 17L5.75 19"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.75 17L13.75 19"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.75 18L9.75 20"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.25 18L17.75 19"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_40639">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgWeather7;

import { IIconProps } from '../interface';

const SvgBrowser = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            strokeLinecap="round"
            strokeWidth="1.5"
            stroke={color}
            fill="none"
            strokeLinejoin="round"
          >
            <path d="M22,5.004v13.996c0,1.105 -0.895,2 -2,2h-16c-1.105,0 -2,-0.895 -2,-2v-13.962c0,-1.103 0.893,-1.998 1.996,-2l16,-0.034c1.106,-0.002 2.004,0.894 2.004,2Z"></path>
            <path d="M8,5.99c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.002,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c0,-0.003 -0.002,-0.005 -0.005,-0.005"></path>
            <path d="M11.005,5.995c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.002,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c-1.77636e-15,-0.003 -0.002,-0.005 -0.005,-0.005"></path>
            <path d="M5,6c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.002,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c0,-0.003 -0.002,-0.005 -0.005,-0.005"></path>
            <path d="M12,17.95c2.333,-2.463 2.333,-6.437 0,-8.9c-2.334,2.463 -2.334,6.437 0,8.9Z"></path>
            <path d="M12,18c2.491,0 4.5,-2.009 4.5,-4.5c0,-2.491 -2.009,-4.5 -4.5,-4.5c-2.491,0 -4.5,2.009 -4.5,4.5c0,2.491 2.009,4.5 4.5,4.5Z"></path>
            <path d="M16.5,13.5h-9"></path>
          </g>
          <path fill="none" d="M0,0h24v24h-24Z"></path>
        </svg>
      );
  }
};

export default SvgBrowser;

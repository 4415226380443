import { IIconProps } from '../interface';

const SvgChile = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 12.5361 28 28 28 28C28 28 49.1926 28 56 28Z"
      fill="#D80027"
    />
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0V28C28 28 9.73908 28 0 28Z"
      fill="#0052B4"
    />
    <path
      d="M16.6676 9.73908L18.4807 15.3192H24.3479L19.6012 18.7678L21.4142 24.3479L16.6676 20.8992L11.9209 24.3479L13.7339 18.7678L8.98724 15.3192H14.8544L16.6676 9.73908Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgChile;

import { IIconProps } from '../interface';

const SvgPuertoRico = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16425)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28.0001 0C18.8397 0 10.7071 4.39939 5.59888 11.2H50.4013C45.293 4.39939 37.1605 0 28.0001 0Z"
        fill="#D80027"
      />
      <path
        d="M28.0001 56C37.1605 56 45.293 51.6006 50.4013 44.8H5.59888C10.7071 51.6006 18.8397 56 28.0001 56Z"
        fill="#D80027"
      />
      <path
        d="M0 28C0 29.9179 0.193375 31.7906 0.560766 33.6H55.4395C55.8068 31.7906 56 29.9179 56 28C56 26.0821 55.8066 24.2094 55.4393 22.4H0.560766C0.193375 24.2094 0 26.0821 0 28H0Z"
        fill="#D80027"
      />
      <path
        d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.7991C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20094Z"
        fill="#0052B4"
      />
      <path
        d="M11.3323 20.6956L13.1452 26.2755H19.0127L14.2661 29.7243L16.0789 35.3044L11.3323 31.8557L6.58554 35.3044L8.39876 29.7243L3.6521 26.2755H9.5192L11.3323 20.6956Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16425">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPuertoRico;

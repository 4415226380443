import { IIconProps } from '../interface';

const SvgPin = ({
  width = 32,
  height = 32,
  color = '#272B35',
  type,
}: IIconProps) => {
  if (type === 'sm') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 8C11.6569 8 13 6.65685 13 5C13 3.34315 11.6569 2 10 2C8.34315 2 7 3.34315 7 5C7 6.65685 8.34315 8 10 8ZM15 5C15 7.41896 13.2822 9.43671 11 9.89998V16.5V17.5H9V16.5V9.89998C6.71776 9.43671 5 7.41896 5 5C5 2.23858 7.23858 0 10 0C12.7614 0 15 2.23858 15 5ZM2.3841 16.8738C2.0528 17.1784 2 17.3911 2 17.5C2 17.6089 2.0528 17.8216 2.3841 18.1262C2.71624 18.4316 3.25207 18.7526 3.99849 19.0429C5.48496 19.6209 7.60648 20 10 20C12.3935 20 14.515 19.6209 16.0015 19.0429C16.7479 18.7526 17.2838 18.4316 17.6159 18.1262C17.9472 17.8216 18 17.6089 18 17.5C18 17.3911 17.9472 17.1784 17.6159 16.8738C17.2838 16.5684 16.7479 16.2474 16.0015 15.9571C15.0589 15.5905 13.8608 15.304 12.5 15.1434V13.1308C14.0978 13.3018 15.5469 13.6344 16.7264 14.0931C17.6087 14.4362 18.3909 14.8694 18.9696 15.4015C19.5491 15.9344 20 16.6424 20 17.5C20 18.3576 19.5491 19.0656 18.9696 19.5985C18.3909 20.1306 17.6087 20.5638 16.7264 20.9069C14.9555 21.5956 12.577 22 10 22C7.42296 22 5.04448 21.5956 3.27359 20.9069C2.39133 20.5638 1.60913 20.1306 1.03043 19.5985C0.45088 19.0656 0 18.3576 0 17.5C0 16.6424 0.45088 15.9344 1.03043 15.4015C1.60913 14.8694 2.39133 14.4362 3.27359 14.0931C4.45312 13.6344 5.90221 13.3018 7.49999 13.1308V15.1434C6.13916 15.304 4.94113 15.5905 3.99849 15.9571C3.25207 16.2474 2.71624 16.5684 2.3841 16.8738Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1702_11994)">
        <path
          d="M21.333 17.4751H23.9997C25.473 17.4751 26.6663 18.6684 26.6663 20.1418C26.6663 21.6151 25.473 22.8084 23.9997 22.8084H7.99967C6.52634 22.8084 5.33301 24.0018 5.33301 25.4751C5.33301 26.9484 6.52634 28.1418 7.99967 28.1418H13.333"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6663 17.4749C14.6663 17.4749 19.9997 13.2163 19.9997 9.32693C19.9997 6.46293 17.6117 4.1416 14.6663 4.1416C11.721 4.1416 9.33301 6.46293 9.33301 9.32693C9.33301 13.2163 14.6663 17.4749 14.6663 17.4749Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6685 9.10449C14.8791 9.10449 15.0485 9.27116 15.0485 9.47516C15.0485 9.67916 14.8778 9.84449 14.6671 9.84449C14.4565 9.84449 14.2871 9.67916 14.2871 9.47383C14.2858 9.27116 14.4565 9.10449 14.6685 9.10449Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_11994">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.141602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgPin;

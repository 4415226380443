import { IIconProps } from '../interface';

const SvgRealState = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41074)">
        <path
          d="M11.75 16.5H15.25C15.5152 16.5 15.7696 16.3946 15.9571 16.2071C16.1447 16.0196 16.25 15.7652 16.25 15.5V8.5C16.25 8.23478 16.1447 7.98043 15.9571 7.79289C15.7696 7.60536 15.5152 7.5 15.25 7.5H10.95C10.6848 7.5 10.4304 7.60536 10.2429 7.79289C10.0554 7.98043 9.95001 8.23478 9.95001 8.5V11"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 15.5V13.3298C7.25 13.1799 7.28373 13.0318 7.3487 12.8967C7.41366 12.7615 7.50819 12.6427 7.6253 12.549L9.21912 11.2739C9.44076 11.0966 9.71615 11 10 11C10.2838 11 10.5592 11.0966 10.7809 11.2739L12.3747 12.549C12.4918 12.6427 12.5863 12.7615 12.6513 12.8967C12.7163 13.0318 12.75 13.1799 12.75 13.3299V15.5C12.75 15.7652 12.6446 16.0196 12.4571 16.2071C12.2696 16.3946 12.0152 16.5 11.75 16.5H8.25C7.98478 16.5 7.73043 16.3946 7.54289 16.2071C7.35536 16.0196 7.25 15.7652 7.25 15.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41074">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgRealState;

import { IIconProps } from '../interface';

const SvgSomalia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M27.9999 14.6087L31.0218 23.9087H40.8003L32.8892 29.6564L35.911 38.9565L27.9999 33.2088L20.0889 38.9565L23.1107 29.6564L15.1995 23.9087H24.9781L27.9999 14.6087Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgSomalia;

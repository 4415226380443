import { IIconProps } from '../interface';

const SvgVanuatu = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16984)">
      <path
        d="M55.9999 28.836C55.9999 27.5982 55.9187 26.3794 55.7629 25.1839H24.3475C18.6271 19.4638 8.20054 9.03786 8.20098 9.03699C-2.73366 19.9716 -2.73366 37.7002 8.20098 48.6351L24.3479 32.4883H55.7629C55.9186 31.2927 55.9999 30.0738 55.9999 28.836Z"
        fill="black"
      />
      <path
        d="M55.992 29.4728C55.9908 29.5268 55.9896 29.5811 55.988 29.6351C55.9894 29.5808 55.9907 29.5268 55.992 29.4728Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.9649 30.238C55.9624 30.2915 55.9587 30.3447 55.9558 30.3981C55.9587 30.3448 55.9622 30.2915 55.9649 30.238Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.9273 30.8483C55.9217 30.9269 55.9163 31.0055 55.9099 31.084C55.9163 31.0055 55.9216 30.9269 55.9273 30.8483Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.8337 25.7842C55.844 25.8779 55.8543 25.9714 55.8636 26.0652C55.8543 25.9713 55.8439 25.8779 55.8337 25.7842Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.9558 27.2738C55.9588 27.3272 55.9624 27.3804 55.9649 27.4339C55.9623 27.3804 55.9588 27.3271 55.9558 27.2738Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.9878 28.037C55.9893 28.0911 55.9904 28.1453 55.9917 28.1994C55.9905 28.1451 55.9892 28.0911 55.9878 28.037Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.9099 26.588C55.9163 26.6664 55.9217 26.745 55.9273 26.8237C55.9216 26.745 55.9163 26.6664 55.9099 26.588Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.7629 25.1838C55.7754 25.2797 55.7866 25.3762 55.7981 25.4723C54.1362 11.5946 42.3253 0.83606 27.9999 0.83606C20.2678 0.83606 13.2677 3.9702 8.20068 9.03742L24.347 25.1839H55.7629V25.1838Z"
        fill="#D80027"
      />
      <path
        d="M55.7629 32.4881H24.347L8.20068 48.6346C13.2677 53.7017 20.2678 56.8359 27.9999 56.8359C42.3253 56.8359 54.1363 46.0774 55.7981 32.1996C55.7866 32.2957 55.7752 32.3922 55.7629 32.4881Z"
        fill="#6DA544"
      />
      <path
        d="M55.8636 31.6067C55.8543 31.7005 55.844 31.794 55.8337 31.8877C55.844 31.794 55.8544 31.7006 55.8636 31.6067Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.9272 26.8236C55.9379 26.9733 55.9475 27.1234 55.9557 27.2738C55.9474 27.1234 55.9379 26.9733 55.9272 26.8236Z"
        fill="black"
      />
      <path
        d="M55.7981 25.4723C55.8107 25.5761 55.8226 25.6801 55.8338 25.7842C55.8224 25.6801 55.8105 25.5762 55.7981 25.4723Z"
        fill="black"
      />
      <path
        d="M55.8635 26.0652C55.8806 26.239 55.896 26.4132 55.9099 26.5881C55.8961 26.4132 55.8807 26.2389 55.8635 26.0652Z"
        fill="black"
      />
      <path
        d="M55.9999 28.836C55.9999 29.049 55.9966 29.261 55.9919 29.4727C55.9966 29.2609 55.9999 29.0488 55.9999 28.836Z"
        fill="black"
      />
      <path
        d="M55.9559 30.3981C55.9476 30.5485 55.938 30.6985 55.9275 30.8483C55.938 30.6985 55.9475 30.5485 55.9559 30.3981Z"
        fill="black"
      />
      <path
        d="M55.9099 31.084C55.896 31.2587 55.8806 31.433 55.8635 31.6067C55.8807 31.433 55.8961 31.2587 55.9099 31.084Z"
        fill="black"
      />
      <path
        d="M55.8338 31.8876C55.8226 31.9918 55.8107 32.0957 55.7981 32.1996C55.8105 32.0958 55.8224 31.9918 55.8338 31.8876Z"
        fill="black"
      />
      <path
        d="M55.9919 28.1992C55.9966 28.411 55.9999 28.6232 55.9999 28.8359C55.9999 28.6232 55.9966 28.411 55.9919 28.1992Z"
        fill="black"
      />
      <path
        d="M55.9651 27.4339C55.9749 27.6343 55.9824 27.8354 55.9881 28.0369C55.9824 27.8353 55.975 27.6343 55.9651 27.4339Z"
        fill="black"
      />
      <path
        d="M55.9878 29.6351C55.9821 29.8365 55.9747 30.0377 55.9648 30.2381C55.9749 30.0376 55.9822 29.8365 55.9878 29.6351Z"
        fill="black"
      />
      <path
        d="M55.9731 27.6085H23.3187L6.54532 10.8376C6.00315 11.4094 5.41548 12.2078 5.05203 12.7765L21.0928 28.8257L5.03857 44.8803C5.35642 45.4457 6.04887 46.2827 6.53504 46.8273L23.319 30.0433H55.977C55.9944 29.6394 55.9999 29.2339 55.9999 28.8259C55.9998 28.2793 55.9847 27.8762 55.9731 27.6085Z"
        fill="#FFDA44"
      />
      <path
        d="M6.80168 34.8048V32.4594C9.14208 32.4594 11.046 30.5553 11.046 28.2151C11.046 26.5593 9.6988 25.2123 8.04308 25.2123C6.9151 25.2123 5.99734 26.1299 5.99734 27.2581C5.99734 27.9794 6.58413 28.5663 7.30557 28.5663C7.71332 28.5663 8.04505 28.2344 8.04505 27.8266H10.3903C10.3903 29.5277 9.00646 30.9116 7.30568 30.9116C5.29111 30.9117 3.65234 29.2726 3.65234 27.2583C3.65234 24.8371 5.62218 22.8674 8.04319 22.8674C10.9922 22.8674 13.3914 25.2666 13.3914 28.2155C13.3913 31.8486 10.4352 34.8048 6.80168 34.8048Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16984">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgVanuatu;

import { IIconProps } from '../interface';

const SvgBosniaAndHerzegovina = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15178)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 0C18.072 0 9.35157 5.1672 4.37982 12.9586L42.3503 52.046C50.5253 47.1567 56 38.2181 56 28C56 12.5361 43.4639 0 28 0Z"
        fill="#FFDA44"
      />
      <path
        d="M56 28C56 20.7351 53.233 14.117 48.6956 9.14069V46.8594C53.233 41.883 56 35.2649 56 28Z"
        fill="#0052B4"
      />
      <path
        d="M34.9769 52.7048L37.9098 52.703L38.8146 49.9129L39.7226 52.7019L41.1937 52.701C42.0957 52.2181 42.9682 51.6874 43.8078 51.1119L4.88786 12.1921C4.24648 13.128 3.66155 14.1051 3.13556 15.1179L3.48141 16.1802L6.4143 16.1783L4.04261 17.9037L4.95064 20.6927L2.57677 18.9702L1.16091 20.0001C0.406109 22.535 0 25.22 0 28C0 43.4639 12.5361 56 28 56C31.2694 56 34.4075 55.4387 37.3242 54.4084L34.9769 52.7048ZM6.2452 26.7827L7.14973 23.9927L4.77608 22.2701L7.70897 22.2682L8.61361 19.4782L9.52164 22.2671L12.4545 22.2652L10.0827 23.9906L10.9908 26.7796L8.61689 25.0572L6.2452 26.7827ZM12.2854 32.8696L13.19 30.0797L10.8163 28.357L13.7492 28.3551L14.6538 25.5651L15.5619 28.354L18.4948 28.3522L16.1231 30.0776L17.0311 32.8665L14.6572 31.1441L12.2854 32.8696ZM18.3256 38.9565L19.2301 36.1666L16.8564 34.4439L19.7893 34.4421L20.694 31.652L21.602 34.441L24.5349 34.4391L22.1632 36.1645L23.0712 38.9535L20.6974 37.2308L18.3256 38.9565ZM26.7375 43.3181L24.3657 45.0435L25.2702 42.2535L22.8966 40.5309L25.8295 40.529L26.7342 37.739L27.6422 40.5279L30.5751 40.5261L28.2034 42.2514L29.1115 45.0404L26.7375 43.3181ZM30.4059 51.1304L31.3105 48.3405L28.9368 46.6178L31.8697 46.6159L32.7743 43.8259L33.6824 46.6149L36.6152 46.613L34.2436 48.3384L35.1516 51.1273L32.7777 49.4047L30.4059 51.1304Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15178">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBosniaAndHerzegovina;

import { IIconProps } from '../interface';

const SvgPhilippines = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0V28L8.20093 47.7991C13.2679 52.866 20.2679 56 28 56C43.4639 56 56 43.4639 56 28C56 12.5361 28 0 28 0Z"
      fill="#D80027"
    />
    <path
      d="M28 0C20.2679 0 13.2679 3.13403 8.20093 8.20094C15.0708 15.0708 17.5095 17.5096 28 28H56C56 12.5361 43.4639 0 28 0Z"
      fill="#0052B4"
    />
    <path
      d="M19.1725 28L15.753 26.3915L17.574 23.0798L13.8607 23.7902L13.3901 20.0389L10.8036 22.7978L8.21725 20.0389L7.74661 23.7902L4.03344 23.0797L5.85442 26.3915L2.43481 28L5.85442 29.6085L4.03344 32.9202L7.74694 32.21L8.21714 35.9611L10.8036 33.2022L13.3901 35.9611L13.8607 32.21L17.5737 32.9203L15.7529 29.6086L19.1725 28Z"
      fill="#FFDA44"
    />
    <path
      d="M7.46744 10.9565L8.60538 12.5472L10.4697 11.9567L9.30833 13.5303L10.4463 15.121L8.59061 14.5029L7.42938 16.0765L7.44393 14.1209L5.58838 13.5027L7.453 12.9122L7.46744 10.9565Z"
      fill="#FFDA44"
    />
    <path
      d="M7.46744 39.9235L8.60538 41.5141L10.4697 40.9235L9.30833 42.4973L10.4463 44.088L8.59061 43.4699L7.42938 45.0435L7.44393 43.0878L5.58838 42.4696L7.453 41.8791L7.46744 39.9235Z"
      fill="#FFDA44"
    />
    <path
      d="M23.6978 25.4401L22.56 27.0307L20.6956 26.44L21.8569 28.0138L20.719 29.6045L22.5746 28.9863L23.736 30.5599L23.7213 28.6044L25.5769 27.9862L23.7123 27.3957L23.6978 25.4401Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgPhilippines;

import { IIconProps } from '../interface';

const SvgSwaziland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16722)">
      <path
        d="M53.2217 40.1739C55.0016 36.4929 56 32.363 56 28C56 23.637 55.0016 19.5071 53.2217 15.8261L28 14.6087L2.77834 15.826C0.998375 19.5071 0 23.637 0 28C0 32.363 0.998375 36.4929 2.77834 40.1739L28 41.3913L53.2217 40.1739Z"
        fill="#FFDA44"
      />
      <path
        d="M28 0C16.8991 0 7.30732 6.46023 2.77832 15.8261H53.2215C48.6926 6.46023 39.1008 0 28 0Z"
        fill="black"
      />
      <path
        d="M28 56C16.8991 56 7.30732 49.5398 2.77832 40.1739H53.2215C48.6926 49.5398 39.1008 56 28 56Z"
        fill="#0052B4"
      />
      <path
        d="M54.6778 19.4783H1.32223C0.464516 22.1655 0 25.0284 0 28C0 30.9716 0.464516 33.8345 1.32223 36.5217H54.6777C55.5355 33.8345 56 30.9716 56 28C56 25.0284 55.5355 22.1655 54.6778 19.4783Z"
        fill="#A2001D"
      />
      <path
        d="M9.73901 26.7827H46.2607V29.2175H9.73901V26.7827Z"
        fill="#FFDA44"
      />
      <path
        d="M14.6086 21.9131H41.3913V24.3479H14.6086V21.9131Z"
        fill="#FFDA44"
      />
      <path
        d="M41.5652 28C41.5652 28 35.3042 35.3044 27.9999 35.3044C20.6957 35.3044 20.6956 28 20.6956 28C20.6956 28 20.6956 20.6956 27.9999 20.6956C35.3043 20.6956 41.5652 28 41.5652 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 35.3044C20.6957 35.3044 14.4348 28 14.4348 28C14.4348 28 20.6957 20.6956 28 20.6956"
        fill="black"
      />
      <path
        d="M23.1304 25.5652H25.5652V30.4348H23.1304V25.5652Z"
        fill="#F0F0F0"
      />
      <path
        d="M30.4348 25.5652H32.8696V30.4348H30.4348V25.5652Z"
        fill="black"
      />
      <path
        d="M9.739 30.4348C11.0837 30.4348 12.1738 29.3447 12.1738 28C12.1738 26.6553 11.0837 25.5652 9.739 25.5652C8.39429 25.5652 7.3042 26.6553 7.3042 28C7.3042 29.3447 8.39429 30.4348 9.739 30.4348Z"
        fill="#0052B4"
      />
      <path
        d="M46.261 30.4348C47.6057 30.4348 48.6958 29.3447 48.6958 28C48.6958 26.6553 47.6057 25.5652 46.261 25.5652C44.9163 25.5652 43.8262 26.6553 43.8262 28C43.8262 29.3447 44.9163 30.4348 46.261 30.4348Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16722">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSwaziland;

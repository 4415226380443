import { IIconProps } from '../interface';

const SvgBankNoteHand = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_40751)">
        <path
          d="M13 11H16.2918C16.7575 11 17.2169 11.1084 17.6334 11.3167L18.2186 11.6093C18.7289 11.8645 19.1559 12.2599 19.4495 12.7491L22 17"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 22L14.2486 20.942C13.4382 20.6304 12.7513 20.0636 12.2914 19.3272L9.24266 14.4452C9.05125 14.1387 8.96934 13.7763 9.0103 13.4173C9.05126 13.0583 9.21267 12.7237 9.4682 12.4682C9.62171 12.3147 9.8049 12.194 10.0066 12.1136C10.2082 12.0332 10.4242 11.9948 10.6412 12.0006C10.8582 12.0064 11.0718 12.0563 11.2689 12.1474C11.466 12.2384 11.6424 12.3687 11.7875 12.5302L14.0067 15H16"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 13.8795V4.83333C13 4.3471 12.8068 3.88079 12.463 3.53697C12.1192 3.19315 11.6529 3 11.1667 3H3.83333C3.3471 3 2.88079 3.19315 2.53697 3.53697C2.19315 3.88079 2 4.3471 2 4.83333V17.0556C2 17.5418 2.19315 18.0081 2.53697 18.3519C2.88079 18.6957 3.3471 18.8889 3.83333 18.8889H11.7778"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.08577 12.3588C5.71145 11.9835 5.50139 11.4751 5.50171 10.9451C5.50203 10.4151 5.71272 9.90685 6.08749 9.53208C6.46226 9.15731 6.97047 8.94662 7.50048 8.94629C8.03049 8.94596 8.53895 9.15603 8.91419 9.53034"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_40751">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgBankNoteHand;

import { IIconProps } from '../interface';

const SvgMauritius = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.40454 14.6087L28 17.0435L52.5955 14.6087C47.8465 5.90505 38.6132 0 28 0C17.3868 0 8.15349 5.90505 3.40454 14.6087Z"
      fill="#D80027"
    />
    <path
      d="M3.40452 41.3913L28 43.8261L52.5955 41.3913C54.7659 37.4133 56 32.8508 56 28L28 25.5652L0 28C0 32.8508 1.23408 37.4133 3.40452 41.3913Z"
      fill="#FFDA44"
    />
    <path
      d="M3.40452 14.6087C1.23408 18.5868 0 23.1492 0 28H56C56 23.1492 54.7659 18.5868 52.5955 14.6087H28H3.40452Z"
      fill="#0052B4"
    />
    <path
      d="M28 56C38.6132 56 47.8465 50.095 52.5955 41.3913H3.40454C8.15349 50.095 17.3868 56 28 56Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgMauritius;

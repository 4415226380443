import { IIconProps } from '../interface';

const SvgGambia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15709)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 0C16.4243 0 6.49001 7.02472 2.22559 17.0435H53.7746C49.5102 7.02472 39.5757 0 28 0Z"
        fill="#A2001D"
      />
      <path
        d="M28 56C39.5757 56 49.5102 48.9753 53.7745 38.9565H2.22559C6.49001 48.9753 16.4243 56 28 56Z"
        fill="#496E2D"
      />
      <path
        d="M55.0354 20.6956H0.964578C0.336875 23.0245 0 25.4727 0 28C0 30.5273 0.336875 32.9755 0.964578 35.3044H55.0355C55.6631 32.9755 56 30.5273 56 28C56 25.4727 55.6631 23.0245 55.0354 20.6956Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15709">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGambia;

import { IIconProps } from '../interface';

const SvgCookIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5334 0.002625 0 12.5375 0 28H14.6087V19.7738L22.8349 28H27.9252C27.9501 27.9751 27.9751 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65214C9.7864 6.14578 6.14563 9.78644 3.6521 14.1657V28H10.9565V10.9568V10.9565H28C28 8.65277 28 6.45805 28 3.65214H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M28 24.5568L18.0518 14.6088H14.6086V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5568Z"
      fill="#D80027"
    />
    <path
      d="M37.7391 28L38.2573 29.5942H39.9335L38.5775 30.5795L39.0955 32.1739L37.7391 31.1885L36.383 32.1739L36.901 30.5795L35.5449 29.5942H37.2211L37.7391 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M30.8526 30.8526L32.3461 31.6135L33.5315 30.4282L33.2692 32.084L34.7629 32.845L33.1071 33.107L32.845 34.763L32.0838 33.2692L30.4282 33.5315L31.6137 32.3462L30.8526 30.8526Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 37.7391L29.5942 37.2211L29.5945 35.545L30.5795 36.9009L32.1739 36.3828L31.1885 37.7391L32.1739 39.0954L30.5795 38.5774L29.5945 39.9335V38.2573L28 37.7391Z"
      fill="#F0F0F0"
    />
    <path
      d="M30.8526 44.6257L31.6135 43.132L30.4282 41.9468L32.0839 42.209L32.845 40.7154L33.1071 42.3711L34.7629 42.6332L33.2692 43.3943L33.5315 45.0501L32.3461 43.8645L30.8526 44.6257Z"
      fill="#F0F0F0"
    />
    <path
      d="M37.7391 47.4783L37.2211 45.8839H35.5448L36.9009 44.8987L36.3829 43.3044L37.7391 44.2897L39.0954 43.3044L38.5774 44.8987L39.9335 45.8839H38.2573L37.7391 47.4783Z"
      fill="#F0F0F0"
    />
    <path
      d="M44.6256 44.6257L43.132 43.8648L41.9468 45.0501L42.209 43.3943L40.7153 42.6334L42.3711 42.3711L42.6333 40.7154L43.3943 42.209L45.05 41.9468L43.8645 43.1321L44.6256 44.6257Z"
      fill="#F0F0F0"
    />
    <path
      d="M47.4783 37.7391L45.8839 38.2573V39.9335L44.8987 38.5773L43.3044 39.0954L44.2898 37.7391L43.3044 36.3828L44.8987 36.9009L45.8839 35.545V37.2211L47.4783 37.7391Z"
      fill="#F0F0F0"
    />
    <path
      d="M44.6256 30.8527L43.8648 32.3462L45.05 33.5315L43.3943 33.2692L42.6333 34.763L42.3711 33.107L40.7153 32.8449L42.209 32.084L41.9468 30.4283L43.1321 31.6135L44.6256 30.8527Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgCookIslands;

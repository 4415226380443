import { IIconProps } from '../interface';

const SvgCashProtection = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2212_40792)">
        <path
          d="M12 8V8.80786"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 10.4235C10.0562 9.94628 10.2969 9.51 10.6707 9.20803C11.0445 8.90607 11.5216 8.7624 12 8.80779"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 16V15.1921"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 13.5764C13.9438 14.0536 13.7031 14.4899 13.3293 14.7919C12.9555 15.0939 12.4784 15.2375 12 15.1921"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9491 10.071C13.8025 9.67934 13.5339 9.34515 13.183 9.11771C12.8321 8.89026 12.4174 8.78162 12 8.80778"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.051 13.929C10.1976 14.3206 10.4661 14.6548 10.8171 14.8822C11.168 15.1097 11.5827 15.2183 12.0001 15.1922"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 10.4236C10 10.7553 10.1149 11.0768 10.3253 11.3334C10.5356 11.5899 10.8283 11.7657 11.1536 11.8307L12.8464 12.1693C13.1717 12.2344 13.4644 12.4101 13.6747 12.6667C13.8851 12.9232 14 13.2447 14 13.5764"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 13.0208V6.56938C19.9965 6.3225 19.9028 6.08544 19.7366 5.9028C19.5705 5.72016 19.3433 5.60454 19.0979 5.5777C16.976 5.41645 14.9416 4.66412 13.2254 3.40593C12.8712 3.14235 12.4415 3 12 3C11.5585 3 11.1288 3.14235 10.7746 3.40593C9.05839 4.66412 7.02404 5.41646 4.90212 5.5777C4.65668 5.60453 4.4295 5.72015 4.26336 5.90279C4.09721 6.08543 4.00355 6.3225 4 6.56938V13.0208C4 16.7917 8.65012 19.6027 10.8637 20.7274C11.2163 20.9069 11.6064 21.0003 12.0021 21C12.3978 20.9997 12.7878 20.9056 13.1401 20.7255C15.3551 19.5996 20 16.7955 20 13.0208Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2212_40792">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCashProtection;

import { IIconProps } from '../interface';

const SvgHomePriceIncrease = ({
  width = 21,
  height = 21,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2212_40910)">
        <path
          d="M15.5 14V12H13.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 17V10.6675C21 10.1276 20.8786 9.59459 20.6447 9.10799C20.4108 8.62138 20.0705 8.19361 19.6489 7.85634L14.8111 3.9861C14.0132 3.34777 13.0218 3 12 3C10.9782 3 9.98677 3.34777 9.18886 3.9861L4.35109 7.85634C3.92951 8.19361 3.58918 8.62138 3.35531 9.10799C3.12143 9.59459 3 10.1276 3 10.6675V17C3 18.0609 3.42143 19.0783 4.17157 19.8284C4.92172 20.5786 5.93913 21 7 21H17C18.0609 21 19.0783 20.5786 19.8284 19.8284C20.5786 19.0783 21 18.0609 21 17Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 12L12.5 15L10 13L8 15"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2212_40910">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgHomePriceIncrease;

import { IIconProps } from '../interface';

const SvgColombia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28L28 30.4348L0 28Z"
      fill="#FFDA44"
    />
    <path
      d="M3.7467 42C8.58819 50.3689 17.6361 56 28 56C38.3638 56 47.4117 50.3689 52.2532 42L28 40.1739L3.7467 42Z"
      fill="#D80027"
    />
    <path
      d="M52.2533 42C54.6357 37.8816 56 33.1003 56 28H0C0 33.1003 1.36434 37.8816 3.74675 42H52.2533Z"
      fill="#0052B4"
    />
  </svg>
);

export default SvgColombia;

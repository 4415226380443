import { IIconProps } from '../interface';

const SvgZimbabwe = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M53.32 16.0347C51.8975 13.0297 49.956 10.3191 47.611 8.0173L28.0001 6.08694L8.38911 8.01741C8.32655 8.07877 8.26552 8.14155 8.20361 8.20356L16.0349 16.0348L28.0001 16.4002L53.32 16.0347Z"
      fill="#FFDA44"
    />
    <path
      d="M8.20325 47.7968C8.30628 47.8998 8.40909 48.0029 8.51365 48.1044L28 49.9131L47.4861 48.1044C49.8553 45.8076 51.8206 43.0967 53.2631 40.087L17.0434 38.9565C13.9269 42.0731 10.3324 45.6673 8.20325 47.7968Z"
      fill="#FFDA44"
    />
    <path
      d="M55.7215 24.0521L23.1304 23.1304L28 28C26.5576 29.4423 24.4045 31.5955 23.1304 32.8696L55.7044 32.0696C55.898 30.7408 56 29.3824 56 28C56 26.6597 55.9037 25.3422 55.7215 24.0521Z"
      fill="black"
    />
    <path
      d="M28 0C20.3625 0 13.4406 3.05922 8.38904 8.01741H47.6109C42.5593 3.05922 35.6374 0 28 0Z"
      fill="#6DA544"
    />
    <path
      d="M24.0521 24.0521H55.7215C55.3219 21.2216 54.4999 18.5272 53.32 16.0347H16.0347L24.0521 24.0521Z"
      fill="#D80027"
    />
    <path
      d="M15.913 40.087H53.2632C54.4569 37.5963 55.2921 34.9017 55.7046 32.0696H23.9304C20.8255 35.1745 18.1942 37.8058 15.913 40.087Z"
      fill="#D80027"
    />
    <path
      d="M28.0001 56C35.5748 56 42.4458 52.9909 47.4862 48.1043H8.51392C13.5544 52.9909 20.4253 56 28.0001 56Z"
      fill="#6DA544"
    />
    <path
      d="M9.9985 6.55528C9.37605 7.07831 8.77547 7.62661 8.20093 8.20094L28 28L8.20093 47.7991C8.77536 48.3735 9.37605 48.9217 9.9985 49.4447L31.4432 28L9.9985 6.55528Z"
      fill="black"
    />
    <path
      d="M11.2574 20.6956L13.0703 26.2755H18.9377L14.1911 29.7243L16.0041 35.3044L11.2574 31.8557L6.51066 35.3044L8.32388 29.7243L3.57733 26.2755H9.44443L11.2574 20.6956Z"
      fill="#D80027"
    />
    <path
      d="M16.2443 28.4565L11.5195 26.7827C11.5195 26.7827 11.1704 23.5076 11.1493 23.3966C10.9889 22.5519 10.2466 21.9131 9.35519 21.9131C8.34675 21.9131 7.52906 22.7306 7.52906 23.7392C7.52906 23.9064 7.55345 24.0675 7.59556 24.2215L6.27081 25.5551H8.62631C8.62631 28 6.80194 28 6.80194 30.4348L7.81398 32.8696H13.9009L14.9155 30.4348H14.915C15.0167 30.2012 15.0783 29.9602 15.1038 29.7195C15.9784 29.3658 16.2443 28.4565 16.2443 28.4565Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgZimbabwe;

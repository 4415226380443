import { IIconProps } from '../interface';

const SvgGoldBars = ({
  width = 21,
  height = 21,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2212_40899)">
        <path
          d="M19.5 11H20.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 11H4.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.50086 21H10.4991C10.6599 21 10.8182 20.9613 10.9608 20.887C11.1034 20.8128 11.226 20.7054 11.3182 20.5737C11.4104 20.442 11.4695 20.2901 11.4905 20.1307C11.5116 19.9714 11.4938 19.8093 11.4389 19.6583L9.98437 15.6583C9.91422 15.4653 9.78641 15.2987 9.61828 15.1809C9.45015 15.0632 9.24985 15 9.04458 15H4.9554C4.75013 15 4.54983 15.0632 4.3817 15.1809C4.21358 15.2987 4.08576 15.4653 4.01561 15.6583L2.56106 19.6583C2.50613 19.8093 2.48842 19.9714 2.50944 20.1307C2.53046 20.2901 2.58958 20.442 2.68179 20.5737C2.774 20.7054 2.8966 20.8128 3.03918 20.887C3.18176 20.9613 3.34012 21 3.50086 21Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5009 21H20.4991C20.6599 21 20.8182 20.9613 20.9608 20.887C21.1034 20.8128 21.226 20.7054 21.3182 20.5737C21.4104 20.442 21.4695 20.2901 21.4905 20.1307C21.5116 19.9714 21.4939 19.8093 21.4389 19.6583L19.9844 15.6583C19.9142 15.4653 19.7864 15.2987 19.6183 15.1809C19.4501 15.0632 19.2498 15 19.0446 15H14.9554C14.7501 15 14.5498 15.0632 14.3817 15.1809C14.2136 15.2987 14.0858 15.4653 14.0156 15.6583L12.5611 19.6583C12.5061 19.8093 12.4884 19.9714 12.5094 20.1307C12.5305 20.2901 12.5896 20.442 12.6818 20.5737C12.774 20.7054 12.8966 20.8128 13.0392 20.887C13.1818 20.9613 13.3401 21 13.5009 21Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.50086 15H15.4991C15.6599 15 15.8182 14.9613 15.9608 14.887C16.1034 14.8128 16.226 14.7054 16.3182 14.5737C16.4104 14.442 16.4695 14.2901 16.4905 14.1307C16.5116 13.9714 16.4939 13.8093 16.4389 13.6583L14.9844 9.65826C14.9142 9.46535 14.7864 9.2987 14.6183 9.18093C14.4501 9.06317 14.2498 9 14.0446 9H9.9554C9.75013 9 9.54983 9.06317 9.3817 9.18093C9.21357 9.2987 9.08576 9.46535 9.01561 9.65826L7.56106 13.6583C7.50613 13.8093 7.48842 13.9714 7.50944 14.1307C7.53046 14.2901 7.58958 14.442 7.68179 14.5737C7.774 14.7054 7.8966 14.8128 8.03918 14.887C8.18176 14.9613 8.34012 15 8.50086 15Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 21H10"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3033 5.69661L18.0105 4.9895"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.69673 5.69661L5.98962 4.9895"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 3.5V2.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2212_40899">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgGoldBars;

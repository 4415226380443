import { IIconProps } from '../interface';

const SvgMoneyBag = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41064)">
        <path
          d="M7.5 14.4C7.44926 13.1541 7.89435 11.9388 8.7378 11.0204C9.58124 10.102 10.7543 9.55529 12 9.5C13.2457 9.55529 14.4188 10.102 15.2622 11.0204C16.1056 11.9388 16.5507 13.1541 16.5 14.4C16.5 14.9569 16.2787 15.4911 15.8849 15.8849C15.4911 16.2787 14.9569 16.5 14.4 16.5H9.6C9.04306 16.5 8.50894 16.2787 8.11512 15.8849C7.72131 15.4911 7.50004 14.9569 7.5 14.4Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 6.5H13.5C13.6989 6.5 13.8897 6.57902 14.0303 6.71967C14.171 6.86032 14.25 7.05109 14.25 7.25C14.25 7.54547 14.1918 7.83805 14.0787 8.11104C13.9657 8.38402 13.7999 8.63206 13.591 8.84099C13.3821 9.04992 13.134 9.21566 12.861 9.32873C12.5881 9.4418 12.2955 9.5 12 9.5C11.4033 9.5 10.831 9.26295 10.409 8.84099C9.98705 8.41903 9.75 7.84674 9.75 7.25C9.75 7.05109 9.82902 6.86032 9.96967 6.71967C10.1103 6.57902 10.3011 6.5 10.5 6.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41064">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgMoneyBag;

import { IIconProps } from '../interface';

const SvgIraq = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C15.961 0 5.69778 7.5985 1.74158 18.2609H54.2585C50.3022 7.5985 40.039 0 28 0Z"
      fill="#A2001D"
    />
    <path
      d="M28 56C40.039 56 50.3022 48.4015 54.2584 37.7391H1.74158C5.69778 48.4015 15.961 56 28 56Z"
      fill="black"
    />
    <path
      d="M21.3044 26.1739C21.2891 26.1739 21.2741 26.1749 21.2588 26.1751V26.1739H15.9063C16.1777 25.1254 17.129 24.3478 18.2609 24.3478V20.6956C14.9045 20.6956 12.174 23.4263 12.174 26.7826V29.7652V29.8261H21.2588H21.3044C21.6401 29.8261 21.9131 30.0992 21.9131 30.4348V31.6521H9.73907V35.3043H25.5652V30.4347C25.5652 28.0853 23.6538 26.1739 21.3044 26.1739Z"
      fill="#496E2D"
    />
    <path
      d="M30.4348 31.6521V20.6956H26.7827V35.3044H32.8696V31.6521H30.4348Z"
      fill="#496E2D"
    />
    <path
      d="M42.6086 31.6523V20.6956H38.9565V31.6523H37.7391V28.0001H34.0869V35.3045H45.0434V31.6523H42.6086Z"
      fill="#496E2D"
    />
  </svg>
);

export default SvgIraq;

import { IIconProps } from '../interface';

const SvgArrowLeft = ({
  width = 14,
  height = 12,
  color = '#777A83',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3846_53192)">
        <path
          d="M7.35701 16.0002L5.40214 7.25631C5.28893 6.74994 5.32591 6.22153 5.50854 5.73585C5.69118 5.25018 6.01154 4.82834 6.43037 4.52203C6.84919 4.21572 7.34828 4.03826 7.86646 4.01139C8.38465 3.98452 8.89941 4.10941 9.34767 4.37075L25.344 13.6965C25.7467 13.9313 26.0809 14.2676 26.3131 14.6718C26.5454 15.076 26.6676 15.5341 26.6676 16.0002C26.6676 16.4664 26.5454 16.9245 26.3131 17.3287C26.0809 17.7329 25.7467 18.0692 25.344 18.304L9.34767 27.6298C8.89942 27.8911 8.38466 28.016 7.86648 27.9891C7.3483 27.9622 6.84922 27.7848 6.4304 27.4785C6.01158 27.1722 5.69122 26.7504 5.50857 26.2647C5.32593 25.779 5.28894 25.2506 5.40214 24.7442L7.35701 16.0002Z"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3846_53192">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgArrowLeft;

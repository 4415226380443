import { IIconProps } from '../interface';

const SvgSintMaarten = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16593)">
      <path
        d="M25.5652 30.4348C25.5652 30.4348 8.21733 8.19711 8.20093 8.20105C13.2678 3.13403 20.2679 0 28 0C43.4639 0 56 12.5361 56 28L25.5652 30.4348Z"
        fill="#D80027"
      />
      <path
        d="M25.5652 28C25.5652 28 8.21733 47.8029 8.20093 47.799C13.2678 52.866 20.2679 56 28 56C43.4639 56 56 43.4639 56 28H25.5652Z"
        fill="#0052B4"
      />
      <path
        d="M8.20099 8.20093C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20093Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.06916 28.0001C3.05385 28.2012 3.04346 28.4037 3.04346 28.6087C3.04346 32.9789 6.58633 36.5217 10.9565 36.5217C15.3267 36.5217 18.8696 32.9789 18.8696 28.6087C18.8696 28.4037 18.8592 28.201 18.8439 28.0001H3.06916Z"
        fill="#FFDA44"
      />
      <path
        d="M10.9565 24.3479C12.3012 24.3479 13.3913 23.2578 13.3913 21.9131C13.3913 20.5684 12.3012 19.4783 10.9565 19.4783C9.61182 19.4783 8.52173 20.5684 8.52173 21.9131C8.52173 23.2578 9.61182 24.3479 10.9565 24.3479Z"
        fill="#FFDA44"
      />
      <path
        d="M5.47827 21.3044V29.2174C5.47827 33.4109 10.9565 34.6957 10.9565 34.6957C10.9565 34.6957 16.4348 33.4109 16.4348 29.2174V21.3044H5.47827Z"
        fill="#D80027"
      />
      <path
        d="M10.9592 32.1475C9.94285 31.7817 7.91296 30.847 7.91296 29.2173V23.7391H14V29.2173C14 30.8524 11.9703 31.7848 10.9592 32.1475Z"
        fill="#338AF3"
      />
      <path
        d="M12.1738 26.7827V25.5652L10.9565 24.9565L9.73904 25.5652V26.7827L9.13037 27.3913V29.8261H12.7826V27.3913L12.1738 26.7827Z"
        fill="#F3F3F3"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16593">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSintMaarten;

import { IIconProps } from '../interface';

const SvgUnitedArabEmirates = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16918)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.8262 38.157L18.261 54.6766C21.2942 55.8022 24.5751 56.418 28.0001 56.418C40.039 56.418 50.3023 48.8195 54.2584 38.157H15.8262Z"
        fill="black"
      />
      <path
        d="M15.8262 18.6789L18.261 2.15933C21.2942 1.03375 24.5751 0.417969 28.0001 0.417969C40.039 0.417969 50.3023 8.01647 54.2584 18.6789H15.8262Z"
        fill="#6DA544"
      />
      <path
        d="M0 28.418C0 40.457 7.59861 50.7202 18.2609 54.6764V2.15955C7.59861 6.11575 0 16.379 0 28.418Z"
        fill="#A2001D"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16918">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgUnitedArabEmirates;

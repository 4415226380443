import { IIconProps } from '../interface';

const SvgMontenegro = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16169)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FFDA44"
      />
      <path
        d="M56 28C56 18.3902 51.1581 9.91211 43.7814 4.8696H12.2186C4.84203 9.91211 0 18.3902 0 28C0 37.61 4.84203 46.0879 12.2186 51.1304H43.7814C51.1581 46.0879 56 37.61 56 28Z"
        fill="#A2001D"
      />
      <path
        d="M21.9131 20.6956H34.087V15.8261L31.6522 17.0435L28 13.3913L24.3479 17.0435L21.9131 15.8261V20.6956Z"
        fill="#FFDA44"
      />
      <path
        d="M38.9565 28H32.8719C33.1105 27.5872 33.2492 27.1096 33.2492 26.5986C33.2492 25.0469 31.9913 23.7892 30.4398 23.7892C29.3678 23.7892 28.4368 24.3899 27.9631 25.2725C27.4894 24.3898 26.5584 23.7892 25.4864 23.7892C23.9349 23.7892 22.677 25.0469 22.677 26.5986C22.677 27.1097 22.8159 27.5873 23.0544 28H17.0432C17.0432 29.8619 18.6649 31.3712 20.5267 31.3712H20.4145C20.4145 32.8984 21.4302 34.1879 22.8229 34.6021L20.0867 37.3386L22.6691 39.921L26.3324 36.2577C26.4759 36.3103 26.6244 36.3523 26.7778 36.3812L24.5643 41.3791C25.625 41.8105 26.7843 42.0499 28 42.0499C29.2156 42.0499 30.3748 41.8105 31.4356 41.3791L29.2221 36.3812C29.3755 36.3524 29.5239 36.3103 29.6673 36.2577L33.3306 39.921L35.9129 37.3386L33.1766 34.6022C34.5691 34.1878 35.585 32.8985 35.585 31.3713H35.4727C37.3347 31.3712 38.9565 29.8619 38.9565 28Z"
        fill="#FFDA44"
      />
      <path
        d="M28 29.8261L24.0435 31.6521V35.3044L28 37.7391L31.9565 35.3044V31.6521L28 29.8261Z"
        fill="#6DA544"
      />
      <path d="M24.0435 28H31.9565V32.8696H24.0435V28Z" fill="#0052B4" />
    </g>
    <defs>
      <clipPath id="clip0_172_16169">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMontenegro;

import { IIconProps } from '../interface';

const SvgNorfolkIsland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1739 2.77834C36.4928 0.998375 32.3631 0 28 0C23.637 0 19.5073 0.998375 15.8262 2.77834L13.3914 28L15.8262 53.2217C19.5073 55.0016 23.637 56 28 56C32.3631 56 36.4928 55.0016 40.1739 53.2217L42.6087 28L40.1739 2.77834Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.8261 2.77933C6.46023 7.30811 0 16.8991 0 28C0 39.1009 6.46023 48.6919 15.8261 53.2207V2.77933Z"
      fill="#6DA544"
    />
    <path
      d="M40.1738 2.77933V53.2207C49.5397 48.6919 56 39.1008 56 28C56 16.8992 49.5397 7.30811 40.1738 2.77933Z"
      fill="#6DA544"
    />
    <path
      d="M35.3043 36.5217L27.9999 13.3913L20.6956 36.5217H26.1738V42.6087H29.8261V36.5217H35.3043Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgNorfolkIsland;

import { IIconProps } from '../interface';

const SvgMaldives = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M9.73914 14.6087H46.2609V41.3913H9.73914V14.6087Z"
      fill="#6DA544"
    />
    <path
      d="M32.5092 35.9131C28.139 35.9131 24.5962 32.3702 24.5962 28C24.5962 23.6298 28.139 20.0869 32.5092 20.0869C33.8718 20.0869 35.154 20.4315 36.2734 21.0381C34.5174 19.3208 32.116 18.2608 29.4658 18.2608C24.0871 18.2608 19.7267 22.6211 19.7267 27.9999C19.7267 33.3786 24.0871 37.739 29.4658 37.739C32.116 37.739 34.5175 36.679 36.2734 34.9617C35.154 35.5685 33.8718 35.9131 32.5092 35.9131Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgMaldives;

import { IIconProps } from '../interface';

const SvgBitcoin = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2212_40929)">
        <path
          d="M10.5 20.8651C7.78896 20.4091 5.49096 18.7471 4.17896 16.4431"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.15 6.16992C3.812 7.74092 3 9.77392 3 11.9999C3 12.5549 3.058 13.0959 3.154 13.6239"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 20.8651C16.211 20.4091 18.509 18.7471 19.821 16.4431"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.44995 4.244C8.78595 3.458 10.338 3 12 3C13.662 3 15.214 3.458 16.55 4.244"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.85 6.16992C20.188 7.74092 21 9.77392 21 11.9999C21 12.5549 20.942 13.0959 20.846 13.6239"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 9.00024L15.0002 12.0005L12 15.0007L8.99977 12.0005L12 9.00024Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2212_40929">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgBitcoin;

import { IIconProps } from '../interface';

const SvgCeuta = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C20.2679 0 13.2679 3.13403 8.20093 8.20094L28 28V0Z"
      fill="black"
    />
    <path
      d="M0 28C0 35.732 3.13403 42.732 8.20094 47.7991L28 28H0Z"
      fill="black"
    />
    <path
      d="M28 56C35.732 56 42.732 52.866 47.7991 47.7991L28 28V56Z"
      fill="black"
    />
    <path
      d="M56 28C56 20.2679 52.866 13.2679 47.7991 8.20094L28 28H56Z"
      fill="black"
    />
    <path
      d="M18.2609 19.4783L27.9999 20.6959L37.739 19.4783V13.6348L33.8434 15.5827L27.9999 9.73907L22.1565 15.5827L18.2609 13.6348V19.4783Z"
      fill="#FFDA44"
    />
    <path
      d="M27.9999 42.6087C22.6297 42.6087 18.2609 38.2397 18.2609 32.8696V23.131L21.913 19.4785H34.0869L37.739 23.131V32.8696C37.739 38.2397 33.3701 42.6087 27.9999 42.6087Z"
      fill="#D80027"
    />
    <path
      d="M28 38.9565C24.6436 38.9565 21.9131 36.2259 21.9131 32.8696V23.1306H34.087V32.8695C34.087 36.2259 31.3564 38.9565 28 38.9565Z"
      fill="#F0F0F0"
    />
    <path d="M18.2609 19.4783H21.913V23.1304H18.2609V19.4783Z" fill="#FFDA44" />
    <path
      d="M34.0869 19.4783H37.7391V23.1304H34.0869V19.4783Z"
      fill="#FFDA44"
    />
    <path
      d="M34.0869 27.3913H37.7391V31.0435H34.0869V27.3913Z"
      fill="#FFDA44"
    />
    <path d="M18.2609 27.3913H21.913V31.0435H18.2609V27.3913Z" fill="#FFDA44" />
    <path
      d="M28 38.9565C27.3638 38.9565 26.7506 38.8581 26.1738 38.6763V42.4345C26.7658 42.5474 27.3756 42.6088 28 42.6088C28.6243 42.6088 29.2341 42.5474 29.8261 42.4345V38.6763C29.2493 38.858 28.6361 38.9565 28 38.9565Z"
      fill="#FFDA44"
    />
    <path
      d="M21.9943 33.8577L19.0797 36.7723C19.585 37.9228 20.306 38.9574 21.1922 39.8247L23.7734 37.2435C22.8523 36.3535 22.2106 35.1766 21.9943 33.8577Z"
      fill="#FFDA44"
    />
    <path
      d="M36.9202 36.7725L34.0055 33.8579C33.7892 35.1767 33.1476 36.3535 32.2266 37.2438L34.8078 39.8251C35.6938 38.9576 36.4149 37.923 36.9202 36.7725Z"
      fill="#FFDA44"
    />
    <path
      d="M26.7826 25.1595V27.1884C26.7826 27.8607 27.3276 28.4058 27.9999 28.4058C28.6723 28.4058 29.2173 27.8607 29.2173 27.1884V25.1595H26.7826Z"
      fill="#0052B4"
    />
    <path
      d="M26.7826 33.2755V35.3044C26.7826 35.9766 27.3276 36.5217 27.9999 36.5217C28.6723 36.5217 29.2173 35.9766 29.2173 35.3044V33.2755H26.7826Z"
      fill="#0052B4"
    />
    <path
      d="M30.4348 29.2173V31.2465C30.4348 31.9187 30.9798 32.4638 31.6522 32.4638C32.3245 32.4638 32.8695 31.9187 32.8695 31.2465V29.2173H30.4348Z"
      fill="#0052B4"
    />
    <path
      d="M26.7826 29.2173V31.2465C26.7826 31.9187 27.3276 32.4638 27.9999 32.4638C28.6723 32.4638 29.2173 31.9187 29.2173 31.2465V29.2173H26.7826Z"
      fill="#0052B4"
    />
    <path
      d="M23.1304 29.2173V31.2465C23.1304 31.9187 23.6754 32.4638 24.3477 32.4638C25.02 32.4638 25.5651 31.9187 25.5651 31.2465V29.2173H23.1304Z"
      fill="#0052B4"
    />
  </svg>
);

export default SvgCeuta;

import { IIconProps } from '../interface';

const SvgBusinessChart = ({
  width = 21,
  height = 21,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 15.889L10.071 10.818C10.462 10.427 11.095 10.427 11.485 10.818L13.626 12.959C14.017 13.35 14.65 13.35 15.04 12.959L21 7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.333 7H21V9.667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 20.9289H1.92505V3.22095"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgBusinessChart;

import { ReactElement, useEffect } from 'react';

import Button from '../Button';
import Icon from '../Icon';
import { BottomSheet } from 'react-spring-bottom-sheet';
import useDeviceType from '../../customHooks/useDeviceType';
import { SnapPointProps } from 'react-spring-bottom-sheet/dist/types';
import 'react-spring-bottom-sheet/dist/style.css';
import './index.css';

export interface ILightbox {
  children: ReactElement;
  buttonLabel?: string;
  buttonAction?: () => void;
  buttonActionLoading?: boolean;
  closeActtion: () => void;
  visible: boolean;
  closeButton?: boolean;
  noPadding?: boolean;
  noFlex?: boolean;
  className?: string;
  minHeight?: boolean;
  calcSnapPoints?: (snapProps: SnapPointProps) => number[];
}

const Lightbox = ({
  children,
  buttonLabel,
  buttonAction,
  buttonActionLoading,
  closeActtion,
  visible,
  closeButton = true,
  noPadding = false,
  noFlex = false,
  className,
  minHeight = true,
  calcSnapPoints,
}: ILightbox) => {
  const isMobile = useDeviceType();
  useEffect(() => {
    if (visible) {
      document.querySelector('html')?.classList.add('overflow-hidden');
      document.querySelector('body')?.classList.add('overflow-hidden');
      return;
    }

    document.querySelector('html')?.classList.remove('overflow-hidden');
    document.querySelector('body')?.classList.remove('overflow-hidden');
  }, [visible]);

  if (isMobile) {
    return (
      <div className="z-40">
        <BottomSheet
          open={visible}
          onDismiss={closeActtion}
          blocking={true}
          snapPoints={calcSnapPoints}
        >
          <div className="p-spacing-16">
            {children}
            {!!buttonAction && !!buttonLabel ? (
              <Button
                variant="primary"
                size="large"
                onClick={buttonAction}
                loading={buttonActionLoading}
                className="w-full"
              >
                {buttonLabel || ''}
              </Button>
            ) : (
              <></>
            )}
          </div>
        </BottomSheet>
      </div>
    );
  }

  return (
    <>
      <div
        className={`fixed top-0 left-0 px-4 md:px-0 w-full h-full flex items-center justify-center transition-all ${
          visible
            ? 'opacity-100 pointer-events-auto z-50'
            : 'opacity-0 pointer-events-none -z-50'
        }`}
        style={{ backgroundColor: 'rgba(0, 0, 0, .56)' }}
      >
        <div
          className="fixed top-0 left-0 w-full h-full"
          onClick={closeActtion}
        ></div>
        <div
          className={`${className} ${
            noPadding ? '' : 'pt-10'
          } bg-white w-full max-w-xl rounded-2xl flex relative flex-col`}
          style={{ maxHeight: 'calc(100vh - 100px)' }}
        >
          <div
            className={`absolute top-3 right-3 ${
              closeButton ? 'visible' : 'invisible'
            }`}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                closeActtion();
              }}
            >
              <Icon name="Close" type="sm" />
            </button>
          </div>
          <div
            className={`${noPadding ? 'p-0' : 'px-8'} ${
              noFlex ? '' : 'flex flex-1'
            } max-h-full overflow-auto`}
          >
            {children}
          </div>
          {buttonAction && buttonLabel && (
            <div className="border-t-1 border-gray-200 mt-8 pt-6 pb-8 flex justify-end px-8">
              <Button
                variant="primary"
                size="medium"
                onClick={buttonAction}
                loading={buttonActionLoading}
              >
                {buttonLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Lightbox;

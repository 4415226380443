import { IIconProps } from '../interface';

const SvgIran = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M37.0942 20.6956H33.4308C33.4611 21.0982 33.4783 21.5044 33.4783 21.9129C33.4783 24.6215 32.8012 27.2468 31.621 29.1156C31.2569 29.692 30.645 30.506 29.826 31.0551V20.6955H26.1739V31.0551C25.355 30.506 24.743 29.6921 24.3789 29.1156C23.1986 27.2468 22.5216 24.6215 22.5216 21.9129C22.5216 21.5044 22.5389 21.0981 22.5691 20.6956H18.9057C18.8822 21.0962 18.8694 21.502 18.8694 21.9129C18.8694 29.4222 22.8799 35.3043 27.9998 35.3043C33.1196 35.3043 37.1302 29.4222 37.1302 21.9129C37.1304 21.502 37.1176 21.0962 37.0942 20.6956Z"
      fill="#D80027"
    />
    <path
      d="M11.5652 13.3913H15.2173V15.8261H18.8695V13.3913H22.5216V15.8261H26.1737V13.3913H29.8259V15.8261H33.478V13.3913H37.1302V15.8261H40.7823V13.3913H44.4344V15.8261H53.2213C48.6926 6.46023 39.1008 0 28 0C16.8992 0 7.30732 6.46023 2.77832 15.8261H11.5652V13.3913Z"
      fill="#6DA544"
    />
    <path
      d="M44.4348 40.1739V42.6087H40.7826V40.1739H37.1305V42.6087H33.4783V40.1739H29.8262V42.6087H26.1741V40.1739H22.5219V42.6087H18.8698V40.1739H15.2173V42.6087H11.5652V40.1739H2.77832C7.30732 49.5398 16.8991 56 28 56C39.1009 56 48.6926 49.5398 53.2216 40.1739H44.4348Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgIran;

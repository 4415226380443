import { IIconProps } from '../interface';

const SvgUserGroup = ({ width = 32, height = 32, color, type }: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1702_11972)">
        <path
          d="M27.7228 12.8393C28.7762 13.8927 28.7762 15.6006 27.7228 16.6539C26.6694 17.7073 24.9616 17.7073 23.9082 16.6539C22.8548 15.6006 22.8548 13.8927 23.9082 12.8393C24.9616 11.786 26.6694 11.786 27.7228 12.8393Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9369 8.02459C20.5588 9.64656 20.5588 12.2763 18.9369 13.8983C17.3149 15.5203 14.6851 15.5203 13.0632 13.8983C11.4412 12.2763 11.4412 9.64657 13.0632 8.02459C14.6851 6.40261 17.3149 6.40261 18.9369 8.02459"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.09096 12.8393C9.14433 13.8927 9.14433 15.6006 8.09096 16.6539C7.03758 17.7073 5.32973 17.7073 4.27636 16.6539C3.22298 15.6006 3.22298 13.8927 4.27636 12.8393C5.32973 11.786 7.03759 11.786 8.09096 12.8393Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.666 25.4748V24.0135C30.666 22.1722 29.174 20.6802 27.3326 20.6802H26.2646"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.33301 25.4748V24.0135C1.33301 22.1722 2.82501 20.6802 4.66634 20.6802H5.73434"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.1185 25.4747V23.34C23.1185 20.7627 21.0292 18.6733 18.4519 18.6733H13.5465C10.9692 18.6733 8.87988 20.7627 8.87988 23.34V25.4747"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_11972">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.141602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgUserGroup;

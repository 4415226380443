import { IIconProps } from '../interface';

const SvgArgentina = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C16.8991 0 7.30732 6.46023 2.77832 15.8261H53.2215C48.6926 6.46023 39.1008 0 28 0Z"
      fill="#338AF3"
    />
    <path
      d="M28 56C39.1008 56 48.6926 49.5398 53.2216 40.1739H2.77832C7.30732 49.5398 16.8991 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M36.3688 28L32.9492 29.6086L34.7702 32.9203L31.0569 32.2099L30.5864 35.9611L28 33.2022L25.4135 35.9611L24.9431 32.2099L21.2298 32.9202L23.0507 29.6085L19.6312 28L23.0508 26.3914L21.2298 23.0798L24.943 23.79L25.4136 20.0389L28 22.7978L30.5865 20.0389L31.0569 23.79L34.7703 23.0798L32.9493 26.3915L36.3688 28Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgArgentina;

import { IIconProps } from '../interface';

const SvgPiggy = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41165)">
        <path
          d="M17.2535 10.4966V10.5037H17.2465V10.4966H17.2535Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.53801 8.5C2.23752 8.67294 2.00262 8.94035 1.86985 9.26063C1.73708 9.5809 1.7139 9.93608 1.80391 10.2709C1.89391 10.6057 2.09206 10.9014 2.36752 11.1119C2.64298 11.3225 2.98031 11.436 3.32701 11.435H4.25001"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.75 4.5V7.054C19.478 7.70327 20.0362 8.52066 20.376 9.435H21.25C21.5152 9.4351 21.7695 9.54048 21.957 9.728C22.1445 9.91551 22.2499 10.1698 22.25 10.435V13.5C22.2499 13.7652 22.1445 14.0195 21.957 14.207C21.7695 14.3945 21.5152 14.4999 21.25 14.5H19.939C19.4123 15.4083 18.6578 16.1635 17.75 16.691V18.5C17.7499 18.7652 17.6445 19.0195 17.457 19.207C17.2695 19.3945 17.0152 19.4999 16.75 19.5H14.75C14.4848 19.4999 14.2305 19.3945 14.043 19.207C13.8555 19.0195 13.7501 18.7652 13.75 18.5V17.5H10.25V18.368C10.2499 18.6332 10.1445 18.8875 9.957 19.075C9.76949 19.2625 9.51519 19.3679 9.25 19.368H7.25C6.98481 19.3679 6.73052 19.2625 6.543 19.075C6.35548 18.8875 6.2501 18.6332 6.25 18.368V15.9631C5.34427 15.1538 4.70582 14.0883 4.41923 12.908C4.13263 11.7276 4.21143 10.488 4.64517 9.35346C5.07891 8.21888 5.84713 7.24286 6.84805 6.55471C7.84897 5.86655 9.03534 5.49874 10.25 5.5H14.7765C15.2052 5.49967 15.6221 5.35954 15.9639 5.10086C16.4777 4.71085 17.105 4.49981 17.75 4.5H18.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41165">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgPiggy;

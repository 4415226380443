import { IIconProps } from '../interface';

const SvgIsrael = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M38.543 21.913H31.5142L28 15.8261L24.4859 21.913H17.457L20.9717 28L17.457 34.0869H24.4859L28 40.1739L31.5142 34.0869H38.543L35.0283 28L38.543 21.913ZM32.3176 28L30.159 31.7393H25.8414L23.6824 28L25.8413 24.2607H30.1589L32.3176 28ZM28 20.5213L28.8035 21.9129H27.1968L28 20.5213ZM21.5235 24.2607H23.1304L22.327 25.6524L21.5235 24.2607ZM21.5235 31.7393L22.3271 30.3476L23.1305 31.7393H21.5235ZM28 35.4786L27.1967 34.0869H28.8035L28 35.4786ZM34.4765 31.7393H32.8696L33.6732 30.3476L34.4765 31.7393ZM32.8696 24.2607H34.4765L33.6731 25.6524L32.8696 24.2607Z"
      fill="#0052B4"
    />
    <path
      d="M45.4296 6.08691H10.5703C8.01496 8.12216 5.82353 10.5943 4.10962 13.3913H51.8903C50.1763 10.5944 47.9848 8.12216 45.4296 6.08691Z"
      fill="#0052B4"
    />
    <path
      d="M10.5703 49.913H45.4295C47.9848 47.8778 50.1763 45.4057 51.8902 42.6086H4.10962C5.82363 45.4056 8.01507 47.8778 10.5703 49.913Z"
      fill="#0052B4"
    />
  </svg>
);

export default SvgIsrael;

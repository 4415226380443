import { IIconProps } from '../interface';

const SvgMontserrat = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5334 0.002625 0 12.5375 0 28H14.6087V19.7738L22.8349 28H27.9252C27.9501 27.9751 27.9751 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65213C9.7864 6.14577 6.14563 9.78643 3.6521 14.1657V28H10.9565V10.9567V10.9565H28C28 8.65276 28 6.45804 28 3.65213H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M28 24.5567L18.0518 14.6088H14.6086V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5567Z"
      fill="#D80027"
    />
    <path
      d="M31.6521 14.6087V27.9999C31.6521 34.523 40.1738 36.5217 40.1738 36.5217C40.1738 36.5217 48.6956 34.523 48.6956 27.9999V14.6087H31.6521Z"
      fill="#338AF3"
    />
    <path
      d="M31.6521 27.9999C31.6521 34.523 40.1738 36.5217 40.1738 36.5217C40.1738 36.5217 48.6956 34.523 48.6956 27.9999H31.6521Z"
      fill="#A2001D"
    />
    <path
      d="M43.8261 20.6958H41.3912V18.2609H38.9564V20.6958H36.5217V23.1305H38.9564V30.4348H41.3912V23.1305H43.8261V20.6958Z"
      fill="black"
    />
  </svg>
);

export default SvgMontserrat;

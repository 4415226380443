import { IIconProps } from '../interface';

const SvgScotland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M45.0467 5.78813C40.3253 2.15895 34.4153 0 28 0C21.5847 0 15.6745 2.15906 10.9531 5.78813L28 22.8349L45.0467 5.78813Z"
      fill="#0052B4"
    />
    <path
      d="M5.78802 10.9532C2.15906 15.6746 0 21.5847 0 28C0 34.4153 2.15906 40.3255 5.78813 45.047L22.835 28L5.78802 10.9532Z"
      fill="#0052B4"
    />
    <path
      d="M10.9531 50.2119C15.6746 53.8411 21.5847 56 28 56C34.4153 56 40.3253 53.8409 45.0468 50.2119L28 33.1651L10.9531 50.2119Z"
      fill="#0052B4"
    />
    <path
      d="M50.2118 45.0468C53.841 40.3254 55.9999 34.4153 55.9999 28C55.9999 21.5847 53.841 15.6746 50.2118 10.9532L33.165 28L50.2118 45.0468Z"
      fill="#0052B4"
    />
  </svg>
);

export default SvgScotland;

import { IIconProps } from '../interface';

const SvgMozambique = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16192)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.1343 38.9565L8.2019 47.7981C13.2686 52.8654 20.2678 56 28 56C39.5751 56 49.5079 48.975 53.7721 38.9565H15.1343Z"
        fill="#FFDA44"
      />
      <path
        d="M53.8156 17.1449C49.5753 7.07241 39.6143 0 28 0C20.2678 0 13.2686 3.13458 8.2019 8.20192L15.2139 17.1449H53.8156Z"
        fill="#496E2D"
      />
      <path
        d="M6.08691 20.5942V35.3043H55.0355C55.6635 32.9756 56 30.5273 56 28C56 25.436 55.6546 22.9532 55.0089 20.5942H6.08691Z"
        fill="black"
      />
      <path
        d="M8.20099 8.20093C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20093Z"
        fill="#A2001D"
      />
      <path
        d="M11.3323 20.6956L13.1452 26.2755H19.0127L14.2661 29.7243L16.0789 35.3044L11.3323 31.8557L6.58554 35.3044L8.39876 29.7243L3.6521 26.2755H9.5192L11.3323 20.6956Z"
        fill="#FFDA44"
      />
      <path d="M6.02734 28H16.6393V32.8696H6.02734V28Z" fill="#F0F0F0" />
      <path
        d="M18.6483 22.4174L16.9267 20.6956L11.3323 26.2899L5.73803 20.6956L4.01636 22.4174L9.61559 28.0067L4.01636 33.606L5.73803 35.3044L11.3323 29.7201L16.9267 35.3044L18.6483 33.606L13.0491 28.0067L18.6483 22.4174Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16192">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMozambique;

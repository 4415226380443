const SvgCleaningWindowShine = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2066_18735)">
        <path
          d="M19.3432 3H4.65684C3.74179 3 3 3.74179 3 4.65684V19.3432C3 20.2582 3.74179 21 4.65684 21H19.3432C20.2582 21 21 20.2582 21 19.3432V4.65684C21 3.74179 20.2582 3 19.3432 3Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 3V21"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 6.5C7.5 7.05229 7.05229 7.5 6.5 7.5V7.5C7.05229 7.5 7.5 7.94771 7.5 8.5V8.5C7.5 7.94771 7.94771 7.5 8.5 7.5V7.5C7.94771 7.5 7.5 7.05229 7.5 6.5V6.5Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 15C16.5 15.5523 16.0523 16 15.5 16V16C16.0523 16 16.5 16.4477 16.5 17V17C16.5 16.4477 16.9477 16 17.5 16V16C16.9477 16 16.5 15.5523 16.5 15V15Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2066_18735">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCleaningWindowShine;

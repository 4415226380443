import { IIconProps } from '../interface';

const SvgComoros = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15439)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.999 27.919H25.6463C19.6201 33.9453 15.9759 38.5978 12.255 42.3187L28.0811 43.745L52.6395 41.3102C54.7827 37.3513 56 32.8179 56 28C56 27.9729 55.999 27.9461 55.999 27.919Z"
        fill="#D80027"
      />
      <path
        d="M12.2551 14.5276H52.5502C47.7882 5.86863 38.5799 0 28 0C20.2885 0 13.3064 3.11839 8.24292 8.162L12.2551 14.5276Z"
        fill="#FFDA44"
      />
      <path
        d="M13.4725 41.3102C10.9554 43.8273 9.98258 45.9363 8.16138 47.7574C13.2315 52.8485 20.2473 56 28 56C38.6462 56 47.9041 50.0582 52.6397 41.3102H13.4725Z"
        fill="#0052B4"
      />
      <path
        d="M8.20099 8.11989C-2.73366 19.0545 -2.73366 36.7832 8.20099 47.718C12.7196 43.1994 17.0654 38.8536 28 27.9189L8.20099 8.11989Z"
        fill="#6DA544"
      />
      <path
        d="M7.38553 27.919C7.38553 23.8395 10.2526 20.4315 14.0811 19.5954C13.4927 19.4669 12.8822 19.3972 12.255 19.3972C7.5485 19.3972 3.73328 23.2124 3.73328 27.919C3.73328 32.6255 7.5485 36.4407 12.255 36.4407C12.8821 36.4407 13.4926 36.371 14.0811 36.2425C10.2526 35.4063 7.38553 31.9984 7.38553 27.919Z"
        fill="#F0F0F0"
      />
      <path
        d="M13.9872 20.6146L14.4406 22.0095H15.9073L14.7206 22.8717L15.1739 24.2667L13.9872 23.4046L12.8005 24.2667L13.2539 22.8717L12.0671 22.0095H13.5339L13.9872 20.6146Z"
        fill="#F0F0F0"
      />
      <path
        d="M13.9872 24.2667L14.4406 25.6618H15.9073L14.7206 26.5239L15.1739 27.919L13.9872 27.0568L12.8005 27.919L13.2539 26.5239L12.0671 25.6618H13.5339L13.9872 24.2667Z"
        fill="#F0F0F0"
      />
      <path
        d="M13.9872 27.919L14.4406 29.3139H15.9073L14.7206 30.1761L15.1739 31.5711L13.9872 30.7089L12.8005 31.5711L13.2539 30.1761L12.0671 29.3139H13.5339L13.9872 27.919Z"
        fill="#F0F0F0"
      />
      <path
        d="M13.9872 31.5711L14.4406 32.9661H15.9073L14.7206 33.8283L15.1739 35.2232L13.9872 34.3611L12.8005 35.2232L13.2539 33.8283L12.0671 32.9661H13.5339L13.9872 31.5711Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15439">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComoros;

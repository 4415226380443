import { ErrorBoundary } from 'react-error-boundary';
import AdviceScreen from './AdviceScreen';
import GenericError from '../../../components/genericError';
import { logException } from '../../../utils/sentry';

const Advice = () => {
  return (
    <ErrorBoundary
      fallback={<GenericError action={() => window.location.reload()} />}
      onError={(error) => logException(error)}
    >
      <AdviceScreen />
    </ErrorBoundary>
  );
};

export default Advice;

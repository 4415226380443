import { IIconProps } from '../interface';

const SvgPassportPages = ({
  width = 32,
  height = 32,
  color,
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1702_11948)">
        <path
          d="M13.333 16.4368V14.9257C13.333 14.7427 13.3894 14.5642 13.4945 14.4145C13.5997 14.2648 13.7485 14.1512 13.9206 14.0892L15.2761 13.6012C15.7432 13.4331 16.2543 13.4331 16.7214 13.6012L18.0788 14.0901C18.2507 14.1521 18.3994 14.2656 18.5046 14.4151C18.6097 14.5646 18.6662 14.7429 18.6663 14.9257V16.4368C18.6663 17.5683 17.0832 18.4057 16.3552 18.7319C16.2435 18.7823 16.1223 18.8084 15.9997 18.8084C15.8771 18.8084 15.7559 18.7823 15.6441 18.7319C14.9161 18.4057 13.333 17.5665 13.333 16.4368Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6663 22.8081H13.333"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.6663 8.1416H9.33301C7.12387 8.1416 5.33301 9.93246 5.33301 12.1416V24.1416C5.33301 26.3507 7.12387 28.1416 9.33301 28.1416H22.6663C24.8755 28.1416 26.6663 26.3507 26.6663 24.1416V12.1416C26.6663 9.93246 24.8755 8.1416 22.6663 8.1416Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33301 23.7312V10.3295C5.33301 9.57934 5.56152 8.84694 5.98813 8.22986C6.41473 7.61277 7.01918 7.14027 7.72101 6.87526L18.0348 2.98089C18.5212 2.78101 19.0618 2.75572 19.5647 2.9093C20.0741 3.06851 20.5198 3.38532 20.8376 3.81408C21.1554 4.24284 21.3289 4.76142 21.333 5.29509V7.73117"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_11948">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.141602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgPassportPages;

import { IIconProps } from '../interface';

const SvgQuestion = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5637 11.0812C13.1427 11.6706 13.0686 12.4011 13.3944 13.0528L11.6056 13.9472C10.9314 12.5989 11.1073 11.0794 11.9363 9.91876C12.7687 8.75339 14.2125 8 16 8C17.7394 8 19.0237 8.58943 19.8649 9.51483C20.6847 10.4165 21 11.5452 21 12.5C21 13.6833 20.699 14.5906 20.1997 15.3187C19.7189 16.0199 19.0926 16.493 18.587 16.8596L18.4261 16.976C17.9637 17.3098 17.6389 17.5443 17.391 17.8397C17.1616 18.1131 17 18.4436 17 19H15C15 17.9564 15.3384 17.1744 15.859 16.5541C16.279 16.0535 16.8205 15.6655 17.2518 15.3564C17.3075 15.3165 17.3615 15.2777 17.413 15.2404C17.9074 14.882 18.2811 14.5801 18.5503 14.1876C18.801 13.8219 19 13.3167 19 12.5C19 11.9548 18.8153 11.3335 18.3851 10.8602C17.9763 10.4106 17.2606 10 16 10C14.7875 10 13.9813 10.4966 13.5637 11.0812Z"
            fill={color}
          />
          <path
            d="M17.5 22.5C17.5 23.3284 16.8284 24 16 24C15.1716 24 14.5 23.3284 14.5 22.5C14.5 21.6716 15.1716 21 16 21C16.8284 21 17.5 21.6716 17.5 22.5Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2ZM4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4487 11.1838C9.79452 9.22129 10.1371 7.44599 11.1086 6.16758C12.0838 4.88447 13.772 4 16 4C18.2949 4 19.7559 4.68249 20.649 5.62525C21.5533 6.57968 22 7.93225 22 9.5C22 12.0181 20.5914 13.372 18.8195 15.0172L18.753 15.0789C17.0382 16.6707 15 18.5626 15 22H17C17 19.4819 18.4086 18.128 20.1804 16.4828L20.2469 16.4211C21.9618 14.8293 24 12.9374 24 9.5C24 7.56775 23.4468 5.67032 22.1009 4.24975C20.7441 2.81751 18.705 2 16 2C13.228 2 10.9161 3.11553 9.51632 4.95742C8.11292 6.80401 7.70548 9.27871 8.55131 11.8162L10.4487 11.1838Z"
            fill={color}
          />
          <path
            d="M16 30C17.1046 30 18 29.1046 18 28C18 26.8954 17.1046 26 16 26C14.8954 26 14 26.8954 14 28C14 29.1046 14.8954 30 16 30Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3944 13.0528C13.0686 12.4011 13.1427 11.6706 13.5637 11.0812C13.9813 10.4966 14.7875 10 16 10C17.2606 10 17.9763 10.4106 18.3851 10.8602C18.8153 11.3335 19 11.9548 19 12.5C19 13.3167 18.801 13.8219 18.5503 14.1876C18.2811 14.5801 17.9074 14.882 17.413 15.2404C17.3615 15.2777 17.3077 15.3163 17.252 15.3562C16.8207 15.6653 16.279 16.0535 15.859 16.5541C15.3384 17.1744 15 17.9564 15 19H17C17 18.4436 17.1616 18.1131 17.391 17.8397C17.6389 17.5443 17.9637 17.3098 18.4261 16.976L18.587 16.8596C19.0926 16.493 19.7189 16.0199 20.1997 15.3187C20.699 14.5906 21 13.6833 21 12.5C21 11.5452 20.6847 10.4165 19.8649 9.51483C19.0237 8.58943 17.7394 8 16 8C14.2125 8 12.7687 8.75339 11.9363 9.91876C11.1073 11.0794 10.9314 12.5989 11.6056 13.9472L13.3944 13.0528Z"
            fill={color}
          />
          <path
            d="M16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24Z"
            fill={color}
          />
        </svg>
      );
    case 'filled':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM13.5637 11.0812C13.1427 11.6706 13.0686 12.4011 13.3944 13.0528L11.6056 13.9472C10.9314 12.5989 11.1073 11.0794 11.9363 9.91876C12.7687 8.75339 14.2125 8 16 8C17.7394 8 19.0237 8.58943 19.8649 9.51483C20.6847 10.4165 21 11.5452 21 12.5C21 13.6833 20.699 14.5906 20.1997 15.3187C19.7189 16.0199 19.0926 16.493 18.587 16.8596L18.4261 16.976C17.9637 17.3098 17.6389 17.5443 17.391 17.8397C17.1616 18.1131 17 18.4436 17 19H15C15 17.9564 15.3384 17.1744 15.859 16.5541C16.279 16.0535 16.8205 15.6655 17.2518 15.3564C17.3074 15.3166 17.3617 15.2776 17.413 15.2404C17.9074 14.882 18.2811 14.5801 18.5503 14.1876C18.801 13.8219 19 13.3167 19 12.5C19 11.9548 18.8153 11.3335 18.3851 10.8602C17.9763 10.4106 17.2606 10 16 10C14.7875 10 13.9813 10.4966 13.5637 11.0812ZM17.5 22.5C17.5 23.3284 16.8284 24 16 24C15.1716 24 14.5 23.3284 14.5 22.5C14.5 21.6716 15.1716 21 16 21C16.8284 21 17.5 21.6716 17.5 22.5Z"
            fill={color}
          />
        </svg>
      );
    default:
      console.warn('Need select an option for this icon');
      return null;
  }
};

export default SvgQuestion;

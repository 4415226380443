import { IIconProps } from '../interface';

const SvgPapuaNewGuinea = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16373)">
      <path
        d="M47.7991 47.799C36.8644 58.7336 19.1359 58.7336 8.20098 47.799C-2.73368 36.8643 -2.73368 19.1356 8.20109 8.20083C19.1357 -2.73383 35.1427 -1.01216 46.0773 9.92261C57.0119 20.8573 58.7336 36.8643 47.7991 47.799Z"
        fill="black"
      />
      <path
        d="M8.20105 8.20094C19.1357 -2.73372 36.8642 -2.7335 47.7991 8.20105C58.7337 19.1357 58.7337 36.8643 47.7991 47.7991"
        fill="#A2001D"
      />
      <path
        d="M21.3983 37.7392L21.8516 39.1343H23.3184L22.1317 39.9964L22.5851 41.3914L21.3983 40.5292L20.2116 41.3914L20.6649 39.9964L19.4783 39.1343H20.945L21.3983 37.7392Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.6521 24.3479L18.4078 26.673H20.8523L18.8745 28.1098L19.6299 30.4349L17.6521 28.9978L15.6744 30.4349L16.43 28.1098L14.452 26.673H16.8968L17.6521 24.3479Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.6521 41.3914L18.4076 43.7165H20.8523L18.8743 45.1533L19.6299 47.4784L17.6521 46.0413L15.6744 47.4784L16.4297 45.1533L14.452 43.7165H16.8964L17.6521 41.3914Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.7998 30.4349L25.5555 32.76H28L26.0222 34.1968L26.7777 36.5218L24.7998 35.0848L22.8221 36.5218L23.5777 34.1968L21.5999 32.76H24.0445L24.7998 30.4349Z"
        fill="#F0F0F0"
      />
      <path
        d="M10.5044 30.4349L11.2601 32.76H13.7045L11.7268 34.1968L12.4821 36.5218L10.5044 35.0848L8.52659 36.5218L9.28226 34.1968L7.30432 32.76H9.74896L10.5044 30.4349Z"
        fill="#F0F0F0"
      />
      <path
        d="M46.8726 20.7332C45.6212 19.4819 43.9953 18.7426 42.248 18.6156L47.4783 14.5476C45.3953 12.4646 42.6033 11.5239 39.879 11.7228C39.5529 10.5507 38.9311 9.44366 38.0094 8.52195L35.9434 12.6539C35.8079 12.407 35.6363 12.1745 35.4269 11.9653C34.2382 10.7767 32.3113 10.7767 31.1228 11.9653C29.9343 13.1539 29.9341 15.0808 31.1228 16.2694C31.332 16.4787 31.5646 16.6503 31.8115 16.7859L27.6796 18.8519C28.6015 19.7739 29.7093 20.3961 30.8821 20.7221C30.6825 23.4464 31.6222 26.2379 33.7054 28.321L38.275 22.4456C38.6188 22.1071 39.0728 21.9202 39.5558 21.9202C40.0436 21.9202 40.5021 22.11 40.847 22.4551C41.192 22.8 41.382 23.2586 41.382 23.7463C41.382 24.2341 41.192 24.6926 40.847 25.0376L42.5688 26.7594C43.3735 25.9546 43.8167 24.8846 43.8167 23.7464C43.8167 22.8152 43.5197 21.9299 42.9713 21.198C43.7704 21.4114 44.5256 21.8294 45.1512 22.4551C47.05 24.3538 47.05 27.4432 45.1512 29.3417L46.873 31.0635C48.2526 29.6838 49.0124 27.8496 49.0124 25.8985C49.0121 23.9471 48.2521 22.1129 46.8726 20.7332Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16373">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPapuaNewGuinea;

import { IIconProps } from '../interface';

const SvgPlaneGlobe = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.75C9.95445 3.75 8.267 5.45594 8.267 7.517C8.267 9.57068 9.9471 11.25 12 11.25C14.0456 11.25 15.733 9.54406 15.733 7.483C15.733 5.42932 14.0529 3.75 12 3.75ZM6.767 7.517C6.767 4.64206 9.11155 2.25 12 2.25C14.8811 2.25 17.233 4.60068 17.233 7.483C17.233 10.3579 14.8884 12.75 12 12.75C9.1189 12.75 6.767 10.3993 6.767 7.517ZM8 15.75C6.21421 15.75 4.75 17.2142 4.75 19V20C4.75 20.1358 4.86421 20.25 5 20.25H19C19.1358 20.25 19.25 20.1358 19.25 20V19C19.25 17.2142 17.7858 15.75 16 15.75H8ZM3.25 19C3.25 16.3858 5.38579 14.25 8 14.25H16C18.6142 14.25 20.75 16.3858 20.75 19V20C20.75 20.9642 19.9642 21.75 19 21.75H5C4.03579 21.75 3.25 20.9642 3.25 20V19Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgPlaneGlobe;

import { IIconProps } from '../interface';

const SvgPakistan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M0 28C0 38.6132 5.90505 47.8465 14.6087 52.5955V3.40452C5.90505 8.15347 0 17.3868 0 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C23.1492 0 18.5867 1.23408 14.6086 3.40452V52.5954C18.5867 54.7659 23.1492 56 28 56C43.4638 56 56 43.4639 56 28C56 12.5361 43.4638 0 28 0Z"
      fill="#496E2D"
    />
    <path
      d="M39.9715 32.6306C36.4291 35.1901 31.4825 34.3934 28.9232 30.8512C26.3636 27.3087 27.1604 22.3623 30.7027 19.8029C31.8072 19.0049 33.0481 18.5332 34.3106 18.3692C31.8818 18.006 29.3142 18.5533 27.1664 20.1052C22.8067 23.2554 21.8259 29.3433 24.976 33.7032C28.1261 38.0628 34.2142 39.0437 38.5741 35.8933C40.7221 34.3413 42.0478 32.0759 42.4654 29.6555C41.9135 30.8024 41.076 31.8325 39.9715 32.6306Z"
      fill="#F0F0F0"
    />
    <path
      d="M39.8197 18.2611L41.8151 20.4116L44.4769 19.1787L43.0481 21.7407L45.0434 23.8913L42.1651 23.3242L40.7365 25.8864L40.3864 22.9738L37.5081 22.4067L40.1699 21.1738L39.8197 18.2611Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgPakistan;

import { IIconProps } from '../interface';

const SvgEmployee = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none">
            <path d="M0,0h24v24h-24Z"></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M14,5h4c1.105,0 2,0.895 2,2v13c0,1.105 -0.895,2 -2,2h-12c-1.105,0 -2,-0.895 -2,-2v-13c0,-1.105 0.895,-2 2,-2h4"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M13.591,10.659c0.87868,0.87868 0.87868,2.3033 0,3.18198c-0.87868,0.87868 -2.3033,0.87868 -3.18198,0c-0.87868,-0.87868 -0.87868,-2.3033 0,-3.18198c0.87868,-0.87868 2.3033,-0.87868 3.18198,0"
            ></path>
            <path
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M15.595,19c-0.175,-0.439 -0.445,-0.833 -0.792,-1.154v0c-0.587,-0.544 -1.357,-0.846 -2.157,-0.846h-1.292c-0.8,0 -1.57,0.302 -2.157,0.846v0c-0.347,0.321 -0.617,0.715 -0.792,1.154"
            ></path>
            <path
              fillRule="evenodd"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M13,7h-2c-0.552,0 -1,-0.448 -1,-1v-3c0,-0.552 0.448,-1 1,-1h2c0.552,0 1,0.448 1,1v3c0,0.552 -0.448,1 -1,1Z"
            ></path>
          </g>
        </svg>
      );
  }
};

export default SvgEmployee;

import { IIconProps } from '../interface';

const SvgCleaningRushStarts = ({
  width = 24,
  height = 24,
  color = '#323232',
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1933_39507)">
        <path
          d="M16.1119 7.32458L7.32457 16.1119C6.8918 16.5447 6.8918 17.2464 7.32457 17.6791L8.32086 18.6754C8.75363 19.1082 9.45529 19.1082 9.88806 18.6754L18.6754 9.88807C19.1082 9.4553 19.1082 8.75364 18.6754 8.32087L17.6791 7.32458C17.2464 6.89181 16.5447 6.89181 16.1119 7.32458Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 4H7"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 3V5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9H9"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8V10"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.6394 15.5L20.5569 18.4175C20.8406 18.7012 21 19.086 21 19.4872C21 19.8884 20.8406 20.2732 20.5569 20.5569C20.2732 20.8406 19.8884 21 19.4872 21C19.086 21 18.7012 20.8406 18.4175 20.5569L15.5 17.6394"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.6394 15.5L16.4291 12.1343"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1344 16.4291L15.5 17.6394"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 17.6394L17.6394 15.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 3L13 5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 12.5L3 10"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1933_39507">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCleaningRushStarts;

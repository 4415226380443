import { IIconProps } from '../interface';

const SvgCertificateMedal = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_40725)">
        <path
          d="M14.25 17.5V22L12 20.875L9.75 22V17.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.21994 14.837L8.75194 15.537L8.86694 16.402C8.93087 16.8817 9.30825 17.2591 9.78794 17.323L10.6549 17.439L11.3489 17.97C11.7331 18.262 12.2648 18.262 12.6489 17.97L13.3489 17.439L14.2149 17.323C14.6949 17.2592 15.0726 16.8819 15.1369 16.402L15.2529 15.535C15.2529 15.535 15.5209 15.184 15.7839 14.841C16.076 14.4569 16.076 13.9251 15.7839 13.541L15.2529 12.841L15.1369 11.976C15.0735 11.4962 14.6966 11.1185 14.2169 11.054L13.3489 10.939L12.6489 10.409C12.2652 10.1159 11.7327 10.1159 11.3489 10.409L10.6489 10.939L9.78294 11.054C9.30309 11.1176 8.92554 11.4952 8.86194 11.975L8.74594 12.842L8.21494 13.536C7.92455 13.9217 7.9266 14.4536 8.21994 14.837Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 19H19C20.1046 19 21 18.1046 21 17V5.59302C21 4.48845 20.1046 3.59302 19 3.59302H5C3.89543 3.59302 3 4.48845 3 5.59302V17C3 18.1046 3.89543 19 5 19H7"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 7H16.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_40725">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCertificateMedal;

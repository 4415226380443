import { IIconProps } from '../interface';

const SvgBasqueCountry = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15105)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M0.237122 31.6522C0.888559 36.652 2.85972 41.2365 5.78845 45.0467L20.6956 31.6522H0.237122Z"
        fill="#D80027"
      />
      <path
        d="M24.3479 55.763V35.3044L10.9534 50.2117C14.7637 53.1404 19.348 55.1117 24.3479 55.763Z"
        fill="#D80027"
      />
      <path
        d="M24.3479 0.23703C19.348 0.888467 14.7637 2.85962 10.9534 5.78836L24.3479 20.6957V0.23703Z"
        fill="#D80027"
      />
      <path
        d="M0.237122 24.3479H20.6956L5.78834 10.9534C2.85961 14.7636 0.88845 19.348 0.237122 24.3479Z"
        fill="#D80027"
      />
      <path
        d="M31.6521 0.23703V20.6956L45.0468 5.78836C41.2365 2.85984 36.6521 0.888358 31.6521 0.23703Z"
        fill="#D80027"
      />
      <path
        d="M55.763 24.3479C55.1116 19.348 53.1404 14.7636 50.2117 10.9533L35.3044 24.3479H55.763Z"
        fill="#D80027"
      />
      <path
        d="M35.3044 31.6522L50.2118 45.0468C53.1404 41.2365 55.1117 36.652 55.7631 31.6522H35.3044Z"
        fill="#D80027"
      />
      <path
        d="M31.6521 55.763C36.652 55.1116 41.2365 53.1404 45.0467 50.2116L31.6521 35.3044V55.763Z"
        fill="#D80027"
      />
      <path
        d="M50.2138 45.0489L33.1651 28.0001L33.165 28L50.2138 10.9511C49.4786 9.99557 48.6743 9.07638 47.799 8.20094C46.9238 7.32573 46.0045 6.52127 45.0489 5.78616L28.0001 22.835H28L10.9511 5.78616C9.99565 6.52138 9.07635 7.32562 8.20091 8.20094C7.3257 9.07638 6.52124 9.99557 5.78613 10.9512L22.835 27.9999L22.8351 28L5.78624 45.0489C6.52146 46.0043 7.3258 46.9236 8.20102 47.7991C9.07635 48.6743 9.99565 49.4787 10.9513 50.2138L28 33.165L45.0489 50.2138C46.0043 49.4786 46.9236 48.6743 47.799 47.7991C48.6744 46.9236 49.4786 46.0043 50.2138 45.0489Z"
        fill="#496E2D"
      />
      <path
        d="M31.6521 55.763V31.6525V31.6523H55.763C55.9188 30.4567 56 29.2378 56 28C56 26.7623 55.9187 25.5434 55.763 24.348H31.6524L31.6523 24.3479V0.237125C30.4568 0.0814844 29.238 0 28 0C26.7622 0.000109375 25.5433 0.0814844 24.3479 0.237125L24.348 24.3476V24.3479H0.237125C0.081375 25.5433 0 26.7622 0 28C0.000109375 29.238 0.081375 30.4567 0.237125 31.6521H24.3477L24.3479 55.763C25.5434 55.9186 26.762 56 28 56C29.238 56 30.4568 55.9186 31.6521 55.763Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15105">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBasqueCountry;

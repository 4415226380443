import { IIconProps } from '../interface';

const SvgMorocco = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M44.5471 22.9786H31.9062L28 10.9565L24.0938 22.9786H11.4529L21.6795 30.4087L17.7732 42.4311L28 35.0009L38.2268 42.4312L34.3204 30.4088L44.5471 22.9786ZM24.5418 29.4786L25.8627 25.4133H30.1372L31.4582 29.4786V29.4788L28 31.9912L24.5419 29.4788L24.5418 29.4786ZM29.3462 22.9786H26.6539L28 18.8355L29.3462 22.9786ZM33.5292 27.974L32.6972 25.4134H37.0535L33.5292 27.974ZM23.3027 25.4134L22.4707 27.974L18.9463 25.4134H23.3027ZM22.4045 36.0567L23.7507 31.9137L25.9289 33.4961L22.4045 36.0567ZM30.0711 33.4962L32.2493 31.9138L33.5955 36.0568L30.0711 33.4962Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgMorocco;

import { IIconProps } from '../interface';

const SvgSouthKorea = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M37.7391 28C37.7391 30.4348 33.3788 36.5217 28.0001 36.5217C22.6213 36.5217 18.261 30.4348 18.261 28C18.261 22.6212 22.6213 18.2609 28.0001 18.2609C33.3788 18.2609 37.7391 22.6212 37.7391 28Z"
      fill="#D80027"
    />
    <path
      d="M37.7391 28C37.7391 33.3788 33.3788 37.7391 28.0001 37.7391C22.6213 37.7391 18.261 33.3788 18.261 28"
      fill="#0052B4"
    />
    <path
      d="M38.3223 36.6087L40.9043 34.0266L42.6257 35.748L40.0437 38.3301L38.3223 36.6087Z"
      fill="black"
    />
    <path
      d="M34.0176 40.9123L36.5996 38.3302L38.321 40.0516L35.739 42.6337L34.0176 40.9123Z"
      fill="black"
    />
    <path
      d="M43.4868 41.7726L46.0689 39.1905L47.7903 40.9119L45.2082 43.494L43.4868 41.7726Z"
      fill="black"
    />
    <path
      d="M39.1821 46.0768L41.7642 43.4948L43.4856 45.2162L40.9035 47.7982L39.1821 46.0768Z"
      fill="black"
    />
    <path
      d="M40.9043 39.1909L43.4864 36.6088L45.2078 38.3302L42.6257 40.9123L40.9043 39.1909Z"
      fill="black"
    />
    <path
      d="M36.6008 43.4944L39.1829 40.9123L40.9043 42.6337L38.3222 45.2158L36.6008 43.4944Z"
      fill="black"
    />
    <path
      d="M43.498 19.3961L36.6125 12.5105L38.3339 10.7891L45.2194 17.6747L43.498 19.3961Z"
      fill="black"
    />
    <path
      d="M36.6113 17.6754L34.0293 15.0933L35.7506 13.372L38.3327 15.954L36.6113 17.6754Z"
      fill="black"
    />
    <path
      d="M40.9148 21.9799L38.3327 19.3978L40.0541 17.6765L42.6361 20.2585L40.9148 21.9799Z"
      fill="black"
    />
    <path
      d="M41.7749 12.5103L39.1928 9.92822L40.9142 8.20682L43.4963 10.7889L41.7749 12.5103Z"
      fill="black"
    />
    <path
      d="M46.0808 16.8153L43.4987 14.2333L45.2201 12.5119L47.8022 15.0939L46.0808 16.8153Z"
      fill="black"
    />
    <path
      d="M9.92627 39.186L16.8118 46.0715L15.0904 47.7929L8.20487 40.9074L9.92627 39.186Z"
      fill="black"
    />
    <path
      d="M16.8115 40.906L19.3936 43.4881L17.6723 45.2094L15.0902 42.6274L16.8115 40.906Z"
      fill="black"
    />
    <path
      d="M12.5076 36.6034L15.0896 39.1855L13.3682 40.9069L10.7862 38.3248L12.5076 36.6034Z"
      fill="black"
    />
    <path
      d="M15.0901 34.0206L21.9756 40.9061L20.2542 42.6275L13.3687 35.742L15.0901 34.0206Z"
      fill="black"
    />
    <path
      d="M16.811 9.92288L9.92551 16.8084L8.20411 15.087L15.0896 8.20148L16.811 9.92288Z"
      fill="black"
    />
    <path
      d="M19.3931 12.5047L12.5075 19.3902L10.7861 17.6688L17.6717 10.7833L19.3931 12.5047Z"
      fill="black"
    />
    <path
      d="M21.9775 15.0883L15.092 21.9738L13.3707 20.2525L20.2562 13.367L21.9775 15.0883Z"
      fill="black"
    />
  </svg>
);

export default SvgSouthKorea;

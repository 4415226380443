import { IIconProps } from '../interface';

const SvgEastTimor = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15587)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FFDA44"
      />
      <path
        d="M28 0C21.1447 0 14.8659 2.46498 9.99854 6.55528L40.1739 28L9.99854 49.4447C14.8659 53.535 21.1447 56 28 56C43.4639 56 56 43.4639 56 28C56 12.5361 43.4639 0 28 0Z"
        fill="#D80027"
      />
      <path
        d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20094Z"
        fill="black"
      />
      <path
        d="M7.76331 21.5857L12.0246 25.6186L17.177 22.8116L14.6586 28.1108L18.92 32.1438L13.1019 31.386L10.5832 36.6851L9.5062 30.9176L3.68811 30.1596L8.84033 27.3529L7.76331 21.5857Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15587">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEastTimor;

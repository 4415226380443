import { IIconProps } from '../interface';

const SvgPause = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 10H14L14 22H12L12 10Z" fill={color} />
          <path d="M18 10H20L20 22H18L18 10Z" fill={color} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 30L10 2H8V30H10Z" fill={color} />
          <path d="M24 30L24 2H22L22 30H24Z" fill={color} />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 24L12 8H10V24H12Z" fill={color} />
          <path d="M22 24V8H20V24H22Z" fill={color} />
        </svg>
      );
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      console.warn('Need select an option for this icon');
      return null;
  }
};

export default SvgPause;

import { IIconProps } from '../interface';

const SvgMauritania = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#496E2D"
    />
    <path
      d="M28.0001 32.3478C23.3378 32.3478 19.443 29.0711 18.4874 24.6956C18.3406 25.3682 18.261 26.0657 18.261 26.7826C18.261 32.1615 22.6212 36.5216 28.0001 36.5216C33.3789 36.5216 37.7391 32.1614 37.7391 26.7826C37.7391 26.0657 37.6595 25.3682 37.5127 24.6955C36.5571 29.0712 32.6623 32.3478 28.0001 32.3478Z"
      fill="#FFDA44"
    />
    <path
      d="M28 19.4783L28.9065 22.2683H31.8402L29.4669 23.9926L30.3734 26.7827L28 25.0583L25.6266 26.7827L26.5332 23.9926L24.1599 22.2683H27.0935L28 19.4783Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgMauritania;

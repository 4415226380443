import { IIconProps } from '../interface';

const SvgSendMoneyPerson = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41016)">
        <path
          d="M3.00001 15V14C2.99274 13.604 3.06538 13.2107 3.21357 12.8434C3.36176 12.4762 3.58247 12.1425 3.86251 11.8625C4.14254 11.5825 4.47616 11.3618 4.84342 11.2136C5.21068 11.0654 5.60404 10.9927 6.00001 11H9.00001C9.39597 10.9927 9.78934 11.0654 10.1566 11.2136C10.5239 11.3618 10.8575 11.5825 11.1375 11.8625C11.4175 12.1425 11.6383 12.4762 11.7864 12.8434C11.9346 13.2107 12.0073 13.604 12 14V15"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 11H18C18.396 10.9927 18.7893 11.0654 19.1566 11.2136C19.5238 11.3618 19.8575 11.5825 20.1375 11.8625C20.4175 12.1425 20.6382 12.4762 20.7864 12.8434C20.9346 13.2107 21.0073 13.604 21 14V15"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 8C8.88071 8 10 6.88071 10 5.5C10 4.11929 8.88071 3 7.5 3C6.11929 3 5 4.11929 5 5.5C5 6.88071 6.11929 8 7.5 8Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 8C18.1046 8 19 7.10457 19 6C19 4.89543 18.1046 4 17 4C15.8954 4 15 4.89543 15 6C15 7.10457 15.8954 8 17 8Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9999 17.5H15.408"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 20.0561V17.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 17.5483C6.56867 18.5927 7.40837 19.4645 8.43072 20.0719C9.45307 20.6793 10.6203 20.9999 11.8094 20.9999C12.9986 20.9999 14.1658 20.6793 15.1881 20.0719C16.2105 19.4645 17.0502 18.5927 17.6189 17.5483"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41016">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgSendMoneyPerson;

import { IIconProps } from '../interface';

const SvgGuineaBissau = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9999 0C25.4727 0 23.0245 0.336875 20.6956 0.964578L18.2609 28L20.6957 55.0354C23.0245 55.6631 25.4727 56 27.9999 56C43.4638 56 55.9999 43.4639 55.9999 28C55.9999 12.5361 43.4638 0 27.9999 0Z"
      fill="#FFDA44"
    />
    <path
      d="M18.2609 28L20.6957 55.0354C23.0245 55.6631 25.4727 56 27.9999 56C43.4638 56 55.9999 43.4639 55.9999 28H18.2609Z"
      fill="#6DA544"
    />
    <path
      d="M0 28C0 38.6132 5.90516 47.8466 14.6087 52.5955V3.40451C5.90516 8.15335 0 17.3868 0 28Z"
      fill="#D80027"
    />
    <path
      d="M0 28C0 40.9368 8.77417 51.8224 20.6956 55.0354V0.964569C8.77417 4.17757 0 15.0632 0 28Z"
      fill="#D80027"
    />
    <path
      d="M10.5805 20.6956L12.3934 26.2755H18.2609L13.5142 29.7243L15.327 35.3044L10.5805 31.8557L5.83371 35.3044L7.64693 29.7243L2.90027 26.2755H8.76747L10.5805 20.6956Z"
      fill="black"
    />
  </svg>
);

export default SvgGuineaBissau;

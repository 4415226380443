import { Outlet } from 'react-router-dom';
import Sidebar from '../sidebar';

const AppLayout = ({ short }: { short?: boolean }) => {
  return (
    <div
      className="flex min-h-screen h-full bg-base-gray25 flex-col justify-between"

    >
      <div className="flex msm:flex-col flex-1">
        <Sidebar variant="APP" short={short} />
        <div
          className={`flex flex-1 flex-col w-full pt-[72px] md:pt-0 pb-spacing-32 px-spacing-16 ${!short && 'md:pl-spacing-270 md:pr-spacing-128'

            }`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;

import { IIconProps } from '../interface';

const SvgUkraine = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16915)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#FFDA44"
      />
      <path
        d="M0 28.418C0 12.9541 12.5361 0.417969 28 0.417969C43.4639 0.417969 56 12.9541 56 28.418"
        fill="#338AF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16915">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgUkraine;

import { IIconProps } from '../interface';

const SvgGuatemala = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M56 28C56 16.8991 49.5397 7.30735 40.1738 2.77835V53.2215C49.5397 48.6927 56 39.1008 56 28Z"
      fill="#338AF3"
    />
    <path
      d="M0 28C0 39.1008 6.46023 48.6927 15.8261 53.2217V2.77835C6.46023 7.30735 0 16.8991 0 28Z"
      fill="#338AF3"
    />
    <path
      d="M35.3169 32.7346L30.5825 28.0002L35.0891 23.4934L34.8849 21.1132L33.5947 19.8228L27.9999 25.4175L22.4052 19.8228L21.115 21.1132L20.9108 23.4934L25.4174 28.0002L20.6829 32.7346L23.2654 35.317L27.9999 30.5827L32.7345 35.317L35.3169 32.7346Z"
      fill="#ACABB1"
    />
    <path
      d="M34.8865 21.1134L32.3041 23.6959C33.4056 24.7974 34.087 26.3191 34.087 28C34.087 31.3618 31.3617 34.087 28.0001 34.087C24.6384 34.087 21.913 31.3618 21.913 28C21.913 26.3191 22.5944 24.7974 23.6959 23.6959L21.1135 21.1134C19.3509 22.8757 18.2609 25.3105 18.2609 28C18.2609 33.3789 22.6212 37.7391 27.9999 37.7391C33.3787 37.7391 37.739 33.3787 37.739 28C37.739 25.3106 36.649 22.8758 34.8865 21.1134Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgGuatemala;

import { IIconProps } from '../interface';

const SvgGroupUser = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0103 3.4504C9.36068 1.91857 6.57981 1.73492 4.95041 3.48965C3.35709 5.20554 3.51013 7.75157 5.05041 9.41033C6.68016 11.1654 9.3611 10.981 11.0103 9.44959C12.7412 7.84234 12.7119 5.15122 11.0303 3.46966C11.0238 3.46312 11.0171 3.4567 11.0103 3.4504ZM6.0496 4.51033C7.01734 3.46815 8.82861 3.48133 9.97947 4.54017C11.0879 5.65877 11.0557 7.36055 9.98967 8.3504C8.83891 9.41896 7.11985 9.43454 6.1496 8.38965C5.08988 7.24842 5.04292 5.59445 6.0496 4.51033Z"
            fill={color}
          />
          <path
            d="M2.75 17C2.75 15.2142 4.21421 13.75 6 13.75H10C11.7858 13.75 13.25 15.2142 13.25 17V21H14.75V17C14.75 14.3858 12.6142 12.25 10 12.25H6C3.38579 12.25 1.25 14.3858 1.25 17V21H2.75V17Z"
            fill={color}
          />
          <path
            d="M19 12.75H16V11.25H19C21.1142 11.25 22.75 12.8858 22.75 15V18H21.25V15C21.25 13.7142 20.2858 12.75 19 12.75Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8017 4.14252C18.5374 3.00466 16.4929 2.96264 15.2837 4.15572C13.9189 5.40012 14.14 7.47673 15.2425 8.70171C16.4854 10.0827 18.5724 9.86385 19.8017 8.75746C21.1492 7.54477 21.1068 5.44616 19.8303 4.16966C19.821 4.16036 19.8115 4.15131 19.8017 4.14252ZM16.3303 5.23032C16.919 4.6416 18.0522 4.60026 18.7836 5.2444C19.4926 5.96804 19.4466 7.059 18.7983 7.64252C18.0276 8.33612 16.9146 8.3173 16.3575 7.69826C15.6639 6.92759 15.6827 5.81459 16.3017 5.25746C16.3115 5.24866 16.321 5.23961 16.3303 5.23032Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgGroupUser;

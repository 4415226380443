const sidebar = {
    general: {
        label: 'General',
        name_and_email: 'Name and Email',
        address: 'Address',
        family: 'Family',
        personal_details: 'Personal Details',
    },
    countries: {
        label: 'Countries',
    },
    wrapping_up: {
        label: 'Wrapping up',
        name_and_email: 'Name and Email',
        address: 'Address',
        family: 'Family',
        personal_details: 'Personal Details',
    },
    profile: 'Profile',
    logout: 'Logout'
}

export default sidebar;
import { IIconProps } from '../interface';

const SvgAlbania = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#D80027"
    />
    <path
      d="M43.8261 20.7823H33.6842C34.3103 20.1267 34.6956 19.2391 34.6956 18.2609C34.6956 16.244 33.0604 14.6088 31.0434 14.6088C29.7727 14.6088 28.6541 15.258 28 16.2425C27.3458 15.258 26.2271 14.6088 24.9565 14.6088C22.9395 14.6088 21.3044 16.244 21.3044 18.2609C21.3044 19.2391 21.6897 20.1268 22.3157 20.7823H12.174C12.174 23.4717 14.5164 25.6517 17.2056 25.6517H17.0433C17.0433 28.3413 19.2235 30.5214 21.913 30.5214C21.913 31.3925 22.1426 32.2091 22.5434 32.9161L18.5041 36.9554L21.6032 40.0542L25.9992 35.6584C26.1713 35.7215 26.3495 35.7721 26.5335 35.8066L23.8771 41.8041L28 46.2609L32.1229 41.8039L29.4665 35.8063C29.6505 35.7719 29.8286 35.7214 30.0008 35.6581L34.3968 40.054L37.4958 36.9551L33.4565 32.9158C33.8573 32.2089 34.0869 31.3922 34.0869 30.5211C36.7763 30.5211 38.9565 28.3411 38.9565 25.6514H38.7943C41.4835 25.6516 43.8261 23.4717 43.8261 20.7823V20.7823Z"
      fill="black"
    />
  </svg>
);

export default SvgAlbania;

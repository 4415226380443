import { IIconProps } from '../interface';

const SvgStBarts = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M49.994 22.5217H40.7825C40.7825 20.8409 39.4199 19.4783 37.739 19.4783L35.3042 24.3478C35.3042 24.3478 37.9014 31.6522 37.8201 31.6522H40.8636C42.5445 31.6522 43.907 30.2897 43.907 28.6087C45.588 28.6087 46.9505 27.2462 46.9505 25.5653H46.8491C48.53 25.5652 49.994 24.2027 49.994 22.5217Z"
      fill="#ACABB1"
    />
    <path
      d="M6.00574 22.5217H15.2173C15.2173 20.8409 16.5799 19.4783 18.2608 19.4783L20.6956 24.3478C20.6956 24.3478 18.0984 31.6522 18.1797 31.6522H15.1362C13.4553 31.6522 12.0928 30.2897 12.0928 28.6087C10.4118 28.6087 9.0493 27.2462 9.0493 25.5653H9.15066C7.46972 25.5652 6.00574 24.2027 6.00574 22.5217Z"
      fill="#ACABB1"
    />
    <path
      d="M37.7391 38.9565V40.1738H18.2608V38.9565H13.3912V43.826H18.2608V45.0434H37.7391V43.826H42.6086V38.9565H37.7391Z"
      fill="#FFDA44"
    />
    <path
      d="M18.2609 19.4782V31.652C18.2609 39.1068 27.9999 41.3912 27.9999 41.3912C27.9999 41.3912 37.739 39.1068 37.739 31.652V19.4782L27.9999 18.2608L18.2609 19.4782Z"
      fill="#0052B4"
    />
    <path d="M18.2609 24.3483H37.739V31.6526H18.2609V24.3483Z" fill="#D80027" />
    <path
      d="M32.8695 13.3913V15.2174L31.6521 15.8261L30.4348 14.6086V10.9565H25.5652V14.6086L24.3479 15.8261L23.1304 15.2174V13.3913H18.2609V19.4782H37.7391V13.3913H32.8695Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgStBarts;

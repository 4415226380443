import { IIconProps } from '../interface';

const SvgNamibia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M8.20109 47.7991C9.37567 48.9736 10.6289 50.0211 11.9435 50.9434L50.9432 11.9435C50.021 10.6289 48.9736 9.37573 47.7989 8.20115C46.6243 7.02657 45.3711 5.9792 44.0565 5.05695L5.05688 44.0568C5.97903 45.3712 7.02651 46.6245 8.20109 47.7991Z"
      fill="#A2001D"
    />
    <path
      d="M8.20097 8.20094C-0.632598 17.0345 -2.32835 30.3008 3.11011 40.8382L40.8381 3.1103C30.3007 -2.32815 17.0343 -0.632183 8.20097 8.20094Z"
      fill="#0052B4"
    />
    <path
      d="M47.7991 47.799C56.6324 38.9657 58.3281 25.6993 52.8899 15.1618L15.1619 52.8898C25.6992 58.3282 38.9656 56.6325 47.7991 47.799Z"
      fill="#496E2D"
    />
    <path
      d="M23.1303 15.8261L19.9928 17.3019L21.6636 20.3406L18.2567 19.6889L17.8249 23.1304L15.4519 20.5991L13.0788 23.1304L12.6472 19.6889L9.24016 20.3405L10.9109 17.3018L7.77344 15.8261L10.911 14.3502L9.24016 11.3118L12.6471 11.9634L13.0789 8.52173L15.4519 11.0531L17.825 8.52173L18.2567 11.9634L21.6636 11.3118L19.993 14.3503L23.1303 15.8261Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgNamibia;

import { IIconProps } from '../interface';

const SvgBritishIndianOceanTerritory = ({
  width = 56,
  height = 56,
}: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M51.3329 31.3509C48.9996 31.3509 48.9996 29.2175 46.6662 29.2175C44.3329 29.2175 44.3329 31.3509 41.9997 31.3509C39.6665 31.3509 39.6664 29.2175 37.3332 29.2175C35 29.2175 34.9999 31.3509 32.6665 31.3509C30.3331 31.3509 30.333 29.2175 27.9995 29.2175C25.6662 29.2175 25.6662 31.3509 23.333 31.3509C21.0001 31.3509 21.0001 29.2175 18.6668 29.2175C16.3333 29.2175 16.3333 31.3509 13.9997 31.3509C11.6662 31.3509 11.6662 29.2175 9.3327 29.2175C6.99974 29.2175 6.99974 31.3509 4.66644 31.3509C2.34572 31.3509 2.33227 29.241 0.036377 29.2184C0.101018 30.4991 0.253924 31.7566 0.485142 32.9863C2.34495 33.2594 2.50781 35.0841 4.66666 35.0841C6.99995 35.0841 6.99995 32.9509 9.33292 32.9509C11.6664 32.9509 11.6664 35.0841 14 35.0841C16.3335 35.0841 16.3335 32.9509 18.667 32.9509C21.0003 32.9509 21.0003 35.0841 23.3332 35.0841C25.6665 35.0841 25.6665 32.9509 27.9997 32.9509C30.3332 32.9509 30.3332 35.0841 32.6668 35.0841C35.0003 35.0841 35.0003 32.9509 37.3335 32.9509C39.6668 32.9509 39.6668 35.0841 42 35.0841C44.3332 35.0841 44.3332 32.9509 46.6664 32.9509C48.9999 32.9509 48.9999 35.0841 51.3331 35.0841C53.4921 35.0841 53.6549 33.2595 55.5149 32.9863C55.7462 31.7565 55.8989 30.4992 55.9639 29.2184C53.6674 29.2411 53.6537 31.3509 51.3329 31.3509Z"
      fill="#0052B4"
    />
    <path
      d="M46.6662 36.5217C44.3329 36.5217 44.3329 38.6552 41.9997 38.6552C39.6665 38.6552 39.6664 36.5217 37.3332 36.5217C34.9999 36.5217 34.9999 38.6552 32.6665 38.6552C30.333 38.6552 30.333 36.5217 27.9995 36.5217C25.6662 36.5217 25.6662 38.6552 23.333 38.6552C21 38.6552 21 36.5217 18.6667 36.5217C16.3332 36.5217 16.3332 38.6552 13.9997 38.6552C11.6662 38.6552 11.6662 36.5217 9.33269 36.5217C6.99972 36.5217 6.99972 38.6552 4.66642 38.6552C2.9476 38.6552 2.4938 37.4982 1.44128 36.8888C2.07872 38.794 2.91599 40.6073 3.92716 42.3053C4.14613 42.3581 4.3896 42.3885 4.66631 42.3885C6.99961 42.3885 6.99961 40.2552 9.33258 40.2552C11.6661 40.2552 11.6661 42.3885 13.9996 42.3885C16.3331 42.3885 16.3331 40.2552 18.6666 40.2552C20.9999 40.2552 20.9999 42.3885 23.3329 42.3885C25.6662 42.3885 25.6662 40.2552 27.9994 40.2552C30.3329 40.2552 30.3329 42.3885 32.6664 42.3885C34.9999 42.3885 34.9999 40.2552 37.3331 40.2552C39.6664 40.2552 39.6664 42.3885 41.9996 42.3885C44.3328 42.3885 44.3329 40.2552 46.6661 40.2552C48.9996 40.2552 48.9996 42.3885 51.3328 42.3885C51.6097 42.3885 51.8534 42.3581 52.0725 42.3052C53.0838 40.6073 53.9209 38.794 54.5583 36.8889C53.5057 37.4983 53.0518 38.6553 51.3328 38.6553C48.9997 38.6552 48.9997 36.5217 46.6662 36.5217Z"
      fill="#0052B4"
    />
    <path
      d="M55.3485 21.9779C53.6429 22.332 53.4254 24.0465 51.3329 24.0465C48.9996 24.0465 48.9996 21.9131 46.6662 21.9131C44.3329 21.9131 44.3329 24.0465 41.9997 24.0465C39.6665 24.0465 39.6664 21.9131 37.3332 21.9131C34.9999 21.9131 34.9999 24.0465 32.6665 24.0465C30.3331 24.0465 30.333 21.9131 27.9995 21.9131C26.7387 21.9131 26.159 22.5358 25.5245 23.1085V26.8418C26.159 26.2692 26.7386 25.6466 27.9995 25.6466C30.333 25.6466 30.333 27.7798 32.6665 27.7798C35 27.7798 35 25.6466 37.3332 25.6466C39.6665 25.6466 39.6665 27.7798 41.9997 27.7798C44.3329 27.7798 44.333 25.6466 46.6662 25.6466C48.9997 25.6466 48.9997 27.7798 51.3329 27.7798C53.4918 27.7798 53.6547 25.9552 55.5146 25.682C55.6338 25.682 55.767 25.6808 55.9034 25.6792C55.8001 24.4199 55.613 23.1844 55.3485 21.9779Z"
      fill="#0052B4"
    />
    <path
      d="M53.3032 15.9987C52.8196 16.4048 52.2607 16.7421 51.333 16.7421C48.9997 16.7421 48.9997 14.6087 46.6663 14.6087C44.333 14.6087 44.333 16.7421 41.9998 16.7421C39.6666 16.7421 39.6665 14.6087 37.3333 14.6087C35 14.6087 35 16.7421 32.6666 16.7421C30.3332 16.7421 30.3331 14.6087 27.9996 14.6087C26.7388 14.6087 26.1591 15.2315 25.5247 15.8041V19.5374C26.1591 18.9649 26.7387 18.3422 27.9996 18.3422C30.3331 18.3422 30.3331 20.4754 32.6666 20.4754C34.9999 20.4754 34.9999 18.3422 37.3332 18.3422C39.6665 18.3422 39.6665 20.4754 41.9997 20.4754C44.3329 20.4754 44.3329 18.3422 46.6662 18.3422C48.9997 18.3422 48.9997 20.4754 51.3329 20.4754C52.9885 20.4754 53.4707 19.403 54.4442 18.7794C54.1124 17.8281 53.7315 16.9001 53.3032 15.9987Z"
      fill="#0052B4"
    />
    <path
      d="M46.8656 7.31009C46.8008 7.30659 46.7349 7.3044 46.6663 7.3044C44.333 7.3044 44.333 9.43787 41.9998 9.43787C39.6665 9.43787 39.6665 7.3044 37.3333 7.3044C35 7.3044 35 9.43787 32.6665 9.43787C30.333 9.43787 30.333 7.3044 27.9995 7.3044C26.7387 7.3044 26.159 7.92718 25.5245 8.49987V12.2332C26.159 11.6606 26.7386 11.0379 27.9995 11.0379C30.333 11.0379 30.333 13.1712 32.6665 13.1712C35 13.1712 35 11.0379 37.3332 11.0379C39.6665 11.0379 39.6665 13.1712 41.9997 13.1712C44.3329 13.1712 44.333 11.0379 46.6662 11.0379C48.9997 11.0379 48.9997 13.1712 51.3329 13.1712C51.4766 13.1712 51.6108 13.1626 51.7379 13.1474C50.3826 10.9857 48.7416 9.02169 46.8656 7.31009Z"
      fill="#0052B4"
    />
    <path
      d="M35.1566 0.924651C34.5161 1.50084 33.9372 2.13368 32.6664 2.13368C30.3329 2.13368 30.3329 0.000432373 27.9995 0.000213623H27.9991C26.7924 0.000322998 26.2099 0.570932 25.6057 1.12207V4.85548C26.2098 4.30434 26.7925 3.73362 27.9995 3.73362C30.333 3.73362 30.333 5.86687 32.6665 5.86687C34.9999 5.86687 34.9999 3.73362 37.3332 3.73362C39.6665 3.73362 39.6665 5.86687 41.9997 5.86687C42.9787 5.86687 43.5466 5.49106 44.0489 5.05509C41.3871 3.1897 38.3861 1.77592 35.1566 0.924651Z"
      fill="#0052B4"
    />
    <path
      d="M32.6664 53.2639C30.3329 53.2639 30.3329 51.1304 27.9994 51.1304C25.6661 51.1304 25.6661 53.2639 23.3329 53.2639C20.9999 53.2639 20.9999 51.1304 18.6666 51.1304C16.8793 51.1304 16.4601 52.381 15.3138 52.967C18.4748 54.5764 21.9821 55.6022 25.6943 55.9048C26.2684 55.3803 26.8516 54.8638 27.9994 54.8638C29.1475 54.8638 29.7307 55.3803 30.3049 55.905C34.0172 55.6024 37.5247 54.5765 40.6858 52.9671C39.5393 52.3812 39.1202 51.1303 37.3331 51.1303C34.9999 51.1304 34.9999 53.2639 32.6664 53.2639Z"
      fill="#0052B4"
    />
    <path
      d="M41.9997 45.9596C39.6664 45.9596 39.6664 43.8261 37.3332 43.8261C34.9999 43.8261 34.9999 45.9596 32.6665 45.9596C30.333 45.9596 30.333 43.8261 27.9995 43.8261C25.6662 43.8261 25.6662 45.9596 23.333 45.9596C21.0001 45.9596 21.0001 43.8261 18.6668 43.8261C16.3332 43.8261 16.3332 45.9596 13.9997 45.9596C11.6662 45.9596 11.6662 43.8261 9.33269 43.8261C7.65477 43.8261 7.18358 44.9297 6.18335 45.5493C6.81029 46.3279 7.47682 47.0731 8.18218 47.7797C8.49816 47.6447 8.86894 47.5595 9.33258 47.5595C11.6661 47.5595 11.6661 49.6928 13.9996 49.6928C16.3331 49.6928 16.3331 47.5595 18.6666 47.5595C20.9999 47.5595 20.9999 49.6928 23.3329 49.6928C25.6662 49.6928 25.6662 47.5595 27.9994 47.5595C30.3329 47.5595 30.3329 49.6928 32.6664 49.6928C34.9999 49.6928 34.9999 47.5595 37.3331 47.5595C39.6663 47.5595 39.6664 49.6928 41.9996 49.6928C44.3328 49.6928 44.3329 47.5595 46.6661 47.5595C47.1303 47.5595 47.5011 47.6447 47.8173 47.7799C48.5227 47.0733 49.1893 46.328 49.8162 45.5494C48.8159 44.9299 48.3447 43.8262 46.6662 43.8262C44.3329 43.8261 44.3329 45.9596 41.9997 45.9596Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28.0001 14.6087V22.8351L18.261 13.3913L28.0001 14.6087Z"
      fill="#0052B4"
    />
    <path
      d="M14.6088 28H22.8349L14.6087 17.0435L13.3914 25.5652L14.6088 28Z"
      fill="#0052B4"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5335 0.002625 0 12.5375 0 28H14.6088V19.7738L22.8349 28H27.9252C27.9501 27.9751 27.9751 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65215C9.7864 6.14579 6.14574 9.78644 3.6521 14.1657V28H10.9565V10.9568V10.9565H28C28 8.65277 28 6.45805 28 3.65215H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M28 24.5568L18.0518 14.6088H14.6086V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5568Z"
      fill="#D80027"
    />
    <path
      d="M36.5217 14.6087H47.4783L42 20.0869L36.5217 14.6087Z"
      fill="#6DA544"
    />
    <path
      d="M40.7827 18.8696H43.2175V25.5652H40.7827V18.8696Z"
      fill="#A2001D"
    />
    <path
      d="M40.7827 36.5217H43.2175V43.2173H40.7827V36.5217Z"
      fill="#A2001D"
    />
    <path
      d="M49.3044 32.8696C49.3044 30.8526 47.6692 29.2175 45.6522 29.2175C44.7166 29.2175 43.8637 29.5696 43.2174 30.1481V28H44.4348V25.5652H43.2174V24.3479H40.7826V25.5652H39.5653V28H40.7826V30.1481C40.1363 29.5696 39.2834 29.2175 38.3478 29.2175C36.3308 29.2175 34.6957 30.8526 34.6957 32.8696C34.6957 33.951 35.1661 34.9222 35.913 35.5911V37.7392H48.0869V35.5911C48.8339 34.9222 49.3044 33.951 49.3044 32.8696Z"
      fill="#FFDA44"
    />
    <path
      d="M45.6521 31.6521C45.2681 31.6521 44.9993 31.8208 44.8412 31.9621L43.2173 33.4158H40.7826L39.1587 31.9621C39.0007 31.8208 38.7319 31.6521 38.3478 31.6521C37.6766 31.6521 37.1305 32.1983 37.1305 32.8695C37.1305 33.3423 37.3854 33.641 37.5374 33.7772L38.3479 34.503V35.3043H45.6523V34.503L46.4629 33.7772C46.6148 33.6411 46.8698 33.3423 46.8698 32.8695C46.8696 32.1983 46.3235 31.6521 45.6521 31.6521Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgBritishIndianOceanTerritory;

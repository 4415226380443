import { IIconProps } from '../interface';

const SvgCalendar = ({ width = 32, height = 32, color, type }: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1702_11962)">
        <path
          d="M18.0938 23.4746H21.6671"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.8803 23.4748V16.8081L17.9336 18.5681"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28 12.1416H4"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.667 3.98145V6.96811"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.333 3.98145V6.96811"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 28.1413H8C5.79067 28.1413 4 26.3506 4 24.1413V9.47461C4 7.26528 5.79067 5.47461 8 5.47461H24C26.2093 5.47461 28 7.26528 28 9.47461V24.1413C28 26.3506 26.2093 28.1413 24 28.1413Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 18.0881C11.212 17.3548 11.86 16.8081 12.6613 16.8081C13.632 16.8081 14.42 17.5948 14.42 18.5668C14.42 19.4361 13.7147 20.1414 12.8453 20.1414C13.7147 20.1414 14.42 20.8468 14.42 21.7161C14.42 22.6868 13.6333 23.4748 12.6613 23.4748C11.86 23.4748 11.212 22.9281 11 22.1948"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_11962">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.141602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCalendar;

import { IIconProps } from '../interface';

const SvgElSalvador = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C16.8991 0 7.30732 6.46023 2.77832 15.8261H53.2215C48.6926 6.46023 39.1008 0 28 0Z"
      fill="#0052B4"
    />
    <path
      d="M28 56C39.1008 56 48.6926 49.5398 53.2216 40.1739H2.77832C7.30732 49.5398 16.8991 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M22.3771 29.2174L28 19.4783L33.623 29.2174H22.3771Z"
      fill="#FFDA44"
    />
    <path
      d="M35.3043 32.4331L27.9999 36.0853L20.6956 32.4331V27.5636H35.3043V32.4331Z"
      fill="#6DA544"
    />
    <path
      d="M34.8865 19.8961L32.3041 22.4785C33.4056 23.58 34.087 25.1018 34.087 26.7826C34.087 30.1444 31.3617 32.8696 28.0001 32.8696C24.6384 32.8696 21.9131 30.1443 21.9131 26.7826C21.9131 25.1018 22.5945 23.58 23.696 22.4785L21.1136 19.8961C19.351 21.6583 18.261 24.0931 18.261 26.7826C18.261 32.1615 22.6213 36.5217 28.0001 36.5217C33.3788 36.5217 37.7391 32.1614 37.7391 26.7826C37.739 24.0931 36.649 21.6583 34.8865 19.8961Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgElSalvador;

import { IIconProps } from '../interface';

const SvgMarshallIsland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16086)">
      <path
        d="M28 56C43.4639 56 56 43.4639 56 28C56 23.3009 54.8406 18.8731 52.7951 14.9844L27.9671 28.0328L41.0156 3.20491C37.1269 1.15938 32.6991 0 28 0C12.5361 0 0 12.5361 0 28C0 35.732 3.13403 42.732 8.20094 47.7991C13.268 52.8661 20.268 56 28 56Z"
        fill="#0052B4"
      />
      <path
        d="M52.7655 15.0149C51.4839 12.5754 49.8176 10.285 47.7663 8.23374C45.715 6.18252 43.4249 4.5162 40.9852 3.23465L8.16821 47.8317L52.7655 15.0149Z"
        fill="#F0F0F0"
      />
      <path
        d="M8.16821 47.8318L52.7655 15.0149C51.4839 12.5754 49.8176 10.285 47.7663 8.23376L8.16821 47.8318Z"
        fill="#FF9811"
      />
      <path
        d="M24.315 16.4196L20.8955 18.0281L22.7165 21.3399L19.0033 20.6295L18.5327 24.3807L15.9462 21.6217L13.3597 24.3807L12.8894 20.6295L9.17613 21.3397L10.9969 18.028L7.57739 16.4196L10.997 14.811L9.17613 11.4994L12.8892 12.2095L13.3598 8.4584L15.9462 11.2174L18.5327 8.4584L19.0033 12.2095L22.7166 11.4994L20.8957 14.8111L24.315 16.4196Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16086">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMarshallIsland;

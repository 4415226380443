import { IIconProps } from '../interface';

const SvgBalearicIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15087)">
      <path
        d="M28.0001 0.00012207C43.4639 0.00012207 56 12.5362 56 28.0001C56 43.4639 43.4639 56 28.0001 56C12.5362 56 0.00012207 43.4639 0.00012207 28.0001"
        fill="#FFDA44"
      />
      <path
        d="M27.3914 12.4445H51.2836C49.7104 10.0944 47.7905 7.9966 45.5976 6.22232H27.3914V12.4445Z"
        fill="#D80027"
      />
      <path
        d="M27.3914 24.889H55.8272C55.5885 22.7315 55.105 20.6482 54.4046 18.6669H27.3914V24.889Z"
        fill="#D80027"
      />
      <path
        d="M1.5955 37.3334H54.4044C55.1047 35.3521 55.5884 33.2686 55.827 31.1111H0.172974C0.411411 33.2688 0.895066 35.3522 1.5955 37.3334Z"
        fill="#D80027"
      />
      <path
        d="M10.4024 49.7778H45.5975C47.7903 48.0036 49.7103 45.9056 51.2835 43.5555H4.71655C6.2898 45.9056 8.20955 48.0035 10.4024 49.7778Z"
        fill="#D80027"
      />
      <path
        d="M28.0001 0.00012207C12.5361 0.00012207 -0.000109374 12.5362 7.15692e-10 28.0003C6.39033 28.0001 12.5361 28.0003 27.9999 28.0003L28.0001 0.00012207Z"
        fill="#4A1F63"
      />
      <path
        d="M23.1305 14.6087V17.0435H21.9131V14.6087H19.4783V17.0435H18.261V12.174H13.3914V17.0435H12.174V14.6087H9.73927V17.0435H8.52182V14.6087H6.08691V21.913H25.5653V14.6087H23.1305Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15087">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBalearicIslands;

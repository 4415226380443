import { IIconProps } from '../interface';

const SvgPalestine = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16362)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.0434 18.2609H54.2583C50.3022 7.5985 40.0389 0 28 0C20.2678 0 13.2686 3.13458 8.2019 8.20192L17.0434 18.2609Z"
        fill="black"
      />
      <path
        d="M17.0434 37.7391H54.2583C50.3022 48.4015 40.0389 56 28 56C20.2678 56 13.2686 52.8654 8.2019 47.7981L17.0434 37.7391Z"
        fill="#6DA544"
      />
      <path
        d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.799C12.7196 43.2804 17.0654 38.9346 28 28L8.20099 8.20094Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16362">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPalestine;

import { IIconProps } from '../interface';

const SvgPitcairnIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5334 0.002625 0 12.5375 0 28H14.6087V19.7738L22.8349 28H27.9252C27.9501 27.9751 27.9751 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.1657 3.65214C9.7864 6.14578 6.14563 9.78644 3.6521 14.1657V28H10.9565V10.9568V10.9565H28C28 8.65277 28 6.45805 28 3.65214H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M28 24.5568L18.0518 14.6088H14.6086V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5568Z"
      fill="#D80027"
    />
    <path
      d="M43.8262 17.0435L40.1739 18.2609L36.5218 17.0435L35.3044 14L36.5218 10.9565H43.8262L42.6087 14L43.8262 17.0435Z"
      fill="#ACABB1"
    />
    <path
      d="M31.6521 17.0435V30.4347C31.6521 36.9578 40.1738 38.9565 40.1738 38.9565C40.1738 38.9565 48.6956 36.9578 48.6956 30.4347V17.0435H31.6521Z"
      fill="#338AF3"
    />
    <path
      d="M32.4204 33.6072C34.5152 37.6288 40.174 38.9565 40.174 38.9565C40.174 38.9565 45.8328 37.6288 47.9276 33.6072L40.174 20.6957L32.4204 33.6072Z"
      fill="#6DA544"
    />
    <path
      d="M48.6956 30.2994L40.1738 17.0435L31.6521 30.2995V30.4347C31.6521 31.642 31.9446 32.6939 32.4202 33.6072L40.1738 21.5459L47.9274 33.6071C48.4031 32.6937 48.6956 31.6419 48.6956 30.4346V30.2994Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgPitcairnIslands;

import { IIconProps } from '../interface';

const SvgTonga = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M18.2609 14.6087V10.9565H14.6087V14.6087H10.9565V18.2609H14.6087V21.9131H18.2609V18.2609H21.9131V14.6087H18.2609Z"
      fill="#D80027"
    />
    <path
      d="M28 0V28C12.5361 28.0001 6.39023 28 0 28.0001C0 43.4639 12.5361 56 28 56C43.4639 56 56 43.4639 56 28C56 12.5361 43.4639 0 28 0Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgTonga;

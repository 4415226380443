import { IIconProps } from '../interface';

const SvgTuvalu = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16885)">
      <path
        d="M56 28.418C56 43.8818 43.4639 56.418 28.0001 56.418C12.5361 56.418 0 43.8818 0 28.418C0 28.4246 28.0001 0.421031 28.0001 0.417969C43.4639 0.417969 56 12.9541 56 28.418Z"
        fill="#338AF3"
      />
      <path
        d="M13.3914 28.418H22.835L14.6087 18.6789L13.3914 22.331V28.418Z"
        fill="#0052B4"
      />
      <path
        d="M28.0002 23.2531L18.261 13.8093H28.0001L28.0002 23.2531Z"
        fill="#0052B4"
      />
      <path
        d="M27.925 28.4179H28C28 28.3928 28 28.3683 28 28.343C27.975 28.3681 27.9501 28.393 27.925 28.4179Z"
        fill="#F0F0F0"
      />
      <path
        d="M28.0001 15.0266C28.0001 10.0998 28.0001 6.87153 28.0001 0.417969H27.9954C12.5335 0.420594 0 12.9555 0 28.418H14.6088V20.1918L22.835 28.418H27.9253C27.9502 28.393 27.9752 28.3681 28.0002 28.343C28.0002 26.4567 28.0002 24.7735 28.0002 23.2531L19.7737 15.0266H28.0001Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.1657 4.07013C9.7864 6.56377 6.14574 10.2044 3.6521 14.5837V28.418H10.9565V11.3747V11.3745H28C28 9.07076 28 6.87604 28 4.07013H14.1657Z"
        fill="#D80027"
      />
      <path
        d="M28 24.9748L18.0518 15.0268H14.6086V15.027L27.9998 28.418H28C28 28.418 28 26.0438 28 24.9748Z"
        fill="#D80027"
      />
      <path
        d="M30.56 46.6789L31.1644 48.5389H33.1202L31.5381 49.6885L32.1425 51.5484L30.56 50.399L28.9779 51.5484L29.5823 49.6885L28 48.5389H29.9556L30.56 46.6789Z"
        fill="#FFDA44"
      />
      <path
        d="M46.1356 34.5049L46.7399 36.3649H48.6956L47.1135 37.5146L47.718 39.3745L46.1356 38.2251L44.5535 39.3745L45.1579 37.5146L43.5754 36.3649H45.5312L46.1356 34.5049Z"
        fill="#FFDA44"
      />
      <path
        d="M50.0383 16.2441L50.6427 18.1041H52.5984L51.0163 19.2536L51.6206 21.1136L50.0383 19.9641L48.4562 21.1136L49.0606 19.2536L47.4783 18.1041H49.434L50.0383 16.2441Z"
        fill="#FFDA44"
      />
      <path
        d="M51.0052 29.6353L51.6095 31.4955H53.5652L51.9831 32.645L52.5875 34.5049L51.0052 33.3555L49.4231 34.5049L50.0275 32.645L48.4451 31.4955H50.4008L51.0052 29.6353Z"
        fill="#FFDA44"
      />
      <path
        d="M33.3644 42.3041L35.1073 43.1921L36.4902 41.8093L36.1843 43.7407L37.9268 44.6286L35.995 44.9347L35.6891 46.8663L34.8014 45.1237L32.8696 45.4298L34.2525 44.047L33.3644 42.3041Z"
        fill="#FFDA44"
      />
      <path
        d="M33.3644 34.9998L35.1073 35.8877L36.4902 34.5049L36.1843 36.4364L37.9268 37.3242L35.995 37.6303L35.6891 39.5619L34.8014 37.8194L32.8696 38.1254L34.2525 36.7426L33.3644 34.9998Z"
        fill="#FFDA44"
      />
      <path
        d="M39.4514 39.6818L41.1942 40.5698L42.5771 39.187L42.2713 41.1185L44.0137 42.0064L42.0819 42.3124L41.776 44.2441L40.8883 42.5013L38.9565 42.8075L40.3394 41.4247L39.4514 39.6818Z"
        fill="#FFDA44"
      />
      <path
        d="M39.4514 24.0433L41.1942 24.9312L42.5771 23.5483L42.2713 25.4799L44.0137 26.3677L42.0819 26.6737L41.776 28.6054L40.8883 26.8628L38.9565 27.1689L40.3394 25.786L39.4514 24.0433Z"
        fill="#FFDA44"
      />
      <path
        d="M45.3508 23.8558L47.0937 24.7437L48.4765 23.3609L48.1706 25.2925L49.9131 26.1803L47.9814 26.4863L47.6754 28.418L46.7877 26.6754L44.856 26.9814L46.2388 25.5986L45.3508 23.8558Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16885">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTuvalu;

import { IIconProps } from '../interface';

const SvgBelize = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 18.3902 51.1581 9.91221 43.7814 4.86958L28 3.65213L12.2186 4.86947C4.84203 9.91221 0 18.3903 0 28C0 37.61 4.84203 46.0878 12.2186 51.1304L28 52.3479L43.7814 51.1305C51.1581 46.0878 56 37.61 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M43.7814 4.86959C39.2877 1.79769 33.8542 0 28 0C22.1459 0 16.7123 1.79769 12.2186 4.86959H43.7814Z"
      fill="#A2001D"
    />
    <path
      d="M12.2186 51.1304C16.7122 54.2023 22.1459 56 28 56C33.8542 56 39.2877 54.2023 43.7814 51.1304H12.2186Z"
      fill="#A2001D"
    />
    <path
      d="M28 41.3913C35.3958 41.3913 41.3913 35.3958 41.3913 28C41.3913 20.6042 35.3958 14.6087 28 14.6087C20.6041 14.6087 14.6086 20.6042 14.6086 28C14.6086 35.3958 20.6041 41.3913 28 41.3913Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 38.9565C34.0511 38.9565 38.9565 34.0511 38.9565 28C38.9565 21.9489 34.0511 17.0435 28 17.0435C21.9489 17.0435 17.0435 21.9489 17.0435 28C17.0435 34.0511 21.9489 38.9565 28 38.9565Z"
      fill="#6DA544"
    />
    <path
      d="M27.9999 35.3044C32.0341 35.3044 35.3043 32.0341 35.3043 28C35.3043 23.9659 32.0341 20.6956 27.9999 20.6956C23.9658 20.6956 20.6956 23.9659 20.6956 28C20.6956 32.0341 23.9658 35.3044 27.9999 35.3044Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 26.1739L24.0435 28V31.0435L28 33.4783L31.9565 31.0435V28L28 26.1739Z"
      fill="#0052B4"
    />
    <path d="M24.0435 24.3479H31.9565V28H24.0435V24.3479Z" fill="#FFDA44" />
  </svg>
);

export default SvgBelize;

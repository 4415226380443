import { IIconProps } from '../interface';

const SvgMalawi = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 31.425 0.616109 34.7058 1.74158 37.7391L28 38.9565L54.2584 37.7392C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2942 54.2584 18.2609L28 17.0435L1.74158 18.2608C0.616109 21.2942 0 24.575 0 28H0Z"
      fill="#D80027"
    />
    <path
      d="M28.0001 0C15.9611 0 5.6979 7.5985 1.7417 18.2609H54.2586C50.3023 7.5985 40.0391 0 28.0001 0Z"
      fill="black"
    />
    <path
      d="M54.2585 37.7391H1.7417C5.6979 48.4014 15.9611 56 28.0001 56C40.0391 56 50.3023 48.4014 54.2585 37.7391Z"
      fill="#496E2D"
    />
    <path
      d="M36.3688 13.3913L32.9493 11.7829L34.7702 8.4711L31.057 9.18149L30.5864 5.43015L27.9999 8.18913L25.4135 5.43015L24.9429 9.18149L21.2297 8.4711L23.0507 11.7829L19.6311 13.3913H36.3688Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgMalawi;

import { IIconProps } from '../interface';

const SvgArrow = ({
  width = 24,
  height = 24,
  type,
  color = '#777A83',
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 6H14M7 1L2 6L7 11" stroke={color} strokeWidth="2" />
        </svg>
      );
  }
};

export default SvgArrow;

import { IIconProps } from '../interface';

const SvgNauru = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#FFDA44"
    />
    <path
      d="M28 0C13.3565 0 1.34077 11.2417 0.106689 25.5652H55.8935C54.6593 11.2417 42.6436 0 28 0Z"
      fill="#0052B4"
    />
    <path
      d="M28 56C42.6436 56 54.6593 44.7583 55.8935 30.4348H0.106689C1.34077 44.7583 13.3565 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M23.1306 37.7391L19.993 39.215L21.6638 42.2535L18.2568 41.6018L17.8251 45.0435L15.452 42.5122L13.079 45.0435L12.6473 41.6018L9.24028 42.2534L10.9111 39.2149L7.77356 37.7391L10.9112 36.2633L9.24028 33.2248L12.6472 33.8765L13.0791 30.4348L15.452 32.9661L17.8252 30.4348L18.2568 33.8765L21.6639 33.2248L19.9932 36.2634L23.1306 37.7391Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgNauru;

import { IIconProps } from '../interface';

const SvgInvoiceHandSelect = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 3.75C5.40326 3.75 4.83097 3.98705 4.40901 4.40901C3.98705 4.83097 3.75 5.40326 3.75 6V14C3.75 14.5967 3.98705 15.169 4.40901 15.591C4.83097 16.0129 5.40326 16.25 6 16.25H8V17.75H6C5.00544 17.75 4.05161 17.3549 3.34835 16.6517C2.64509 15.9484 2.25 14.9946 2.25 14V6C2.25 5.00544 2.64509 4.05161 3.34835 3.34835C4.05161 2.64509 5.00544 2.25 6 2.25H18C18.9946 2.25 19.9484 2.64509 20.6517 3.34835C21.3549 4.05161 21.75 5.00544 21.75 6V12H20.25V6C20.25 5.40326 20.0129 4.83097 19.591 4.40901C19.169 3.98705 18.5967 3.75 18 3.75H6Z"
            fill={color}
          />
          <path d="M7 7.75H17V6.25H7V7.75Z" fill={color} />
          <path d="M10 10.25H7V11.75H10V10.25Z" fill={color} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5 10.25C14.9033 10.25 14.331 10.4871 13.909 10.909C13.4871 11.331 13.25 11.9033 13.25 12.5V15.4178L13.2427 15.4147C12.9801 15.306 12.6987 15.25 12.4144 15.25C12.1302 15.25 11.8487 15.306 11.5862 15.4147C11.3266 15.5223 11.0905 15.6793 10.8909 15.877L10.884 15.8839C10.5302 16.2377 10.3099 16.7031 10.2606 17.201C10.2113 17.6989 10.336 18.1985 10.6135 18.6148L12.5535 21.5252C12.8046 21.902 13.1449 22.2109 13.5441 22.4246C13.9433 22.6382 14.389 22.75 14.8418 22.75H18.6379C19.3082 22.75 19.9554 22.5052 20.4579 22.0615C20.9603 21.6179 21.2835 21.0061 21.3666 20.341L21.7286 17.4441C21.8168 16.7381 21.6279 16.0253 21.2016 15.4556C20.7753 14.8859 20.1447 14.5036 19.4424 14.389L17.75 14.1131V12.5C17.75 11.9033 17.5129 11.331 17.091 10.909C16.669 10.4871 16.0967 10.25 15.5 10.25ZM14.9697 11.9697C15.1103 11.829 15.3011 11.75 15.5 11.75C15.6989 11.75 15.8897 11.829 16.0303 11.9697C16.171 12.1103 16.25 12.3011 16.25 12.5V14.7507C16.25 15.1183 16.5165 15.4317 16.8793 15.4909L19.201 15.8695C19.5202 15.9216 19.8068 16.0953 20.0006 16.3543C20.1944 16.6132 20.2803 16.9372 20.2402 17.2581L19.8782 20.155C19.8404 20.4573 19.6935 20.7354 19.4651 20.9371C19.2367 21.1387 18.9425 21.25 18.6379 21.25H14.8418C14.636 21.25 14.4334 21.1992 14.2519 21.1021C14.0705 21.0049 13.9158 20.8645 13.8016 20.6933L11.8617 17.7828C11.7765 17.655 11.7382 17.5017 11.7533 17.3488C11.7682 17.1978 11.8344 17.0566 11.9408 16.9485L11.9447 16.9446C12.0064 16.8829 12.0796 16.8339 12.1602 16.8005C12.2408 16.7672 12.3272 16.75 12.4144 16.75C12.5017 16.75 12.5881 16.7672 12.6687 16.8005C12.7493 16.8339 12.8225 16.8829 12.8842 16.9446L13.4697 17.5301C13.6842 17.7446 14.0067 17.8088 14.287 17.6927C14.5673 17.5766 14.75 17.3031 14.75 16.9998V12.5C14.75 12.3011 14.829 12.1103 14.9697 11.9697Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgInvoiceHandSelect;

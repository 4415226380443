import { IIconProps } from '../interface';

const SvgAmericanSamoa = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_14972)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M41.5839 3.51225C37.5607 1.27575 32.9295 0 28 0C12.5372 0 0.00196875 12.5342 0 27.9967L19.4783 18.2609L41.5839 3.51225Z"
        fill="#0052B4"
      />
      <path
        d="M0 28.0033C0.00196875 43.4659 12.5372 56 28 56C32.9295 56 37.5607 54.7245 41.5839 52.4878L19.4783 37.7391L0 28.0033Z"
        fill="#0052B4"
      />
      <path
        d="M43.9173 4.96366C43.1653 4.44315 42.386 3.95905 41.583 3.5127L0 27.9967V28C0 28 0 28.0023 0 28.0033L41.5831 52.4872C42.3861 52.0407 43.1653 51.5568 43.9175 51.0362L4.79303 28L43.9173 4.96366Z"
        fill="#D80027"
      />
      <path
        d="M48.6956 26.9558H45.3603C46.2276 25.9146 46.1741 24.3655 45.197 23.3887C46.2319 22.3539 46.2319 20.6759 45.197 19.6409L45.1347 19.7035C46.1695 18.6687 46.2319 16.9282 45.197 15.8934L30.2065 30.8839C31.2414 31.9189 32.8899 31.9066 33.9247 30.8717L34.2133 30.6079L41.3913 29.9554V33.0426H43.8261V29.734L47.4783 29.4021L48.6956 26.9558Z"
        fill="#A2001D"
      />
      <path
        d="M30.4357 34.0869L28 32.8696L30.4357 31.6521H46.2617V34.0869H30.4357Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_14972">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAmericanSamoa;

import { IIconProps } from '../interface';

const SvgBhutan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15163)">
      <path
        d="M47.799 47.799C36.8643 58.7337 19.1358 58.7337 8.20099 47.799C-2.73366 36.8644 -2.73366 19.1357 8.20099 8.20093C8.20493 8.20093 33.3636 0.767045 44.2983 11.7017C55.2331 22.6364 47.8065 47.7914 47.799 47.799Z"
        fill="#FFDA44"
      />
      <path
        d="M47.7989 47.7991C58.7336 36.8644 58.7336 19.1357 47.7989 8.20094C36.8643 -2.73382 19.1357 -2.73372 8.20093 8.20094L47.7989 47.7991Z"
        fill="#D80027"
      />
      <path
        d="M35.319 35.3217C34.1688 35.7734 32.8324 35.7255 31.6729 35.0748C29.6243 33.9251 28.893 31.3226 30.0431 29.2737L29.6939 29.0779C33.2017 28.2321 35.7969 25.0482 35.7514 21.2909C35.7231 18.955 34.6794 16.8652 33.0467 15.4352L30.4903 18.0543C31.4567 18.8245 32.0834 20.006 32.0994 21.3352C32.1279 23.6846 30.2398 25.6191 27.8906 25.6474L27.8955 26.0474C25.4091 23.4327 21.3543 22.7772 18.1233 24.6952C16.1145 25.8879 14.8267 27.8365 14.4044 29.9655L17.9508 30.87C18.1347 29.6478 18.8444 28.5145 19.9875 27.8358C22.0077 26.6365 24.627 27.3046 25.8263 29.3247L26.1707 29.1205C25.1493 32.5809 26.6089 36.4203 29.8857 38.2596C31.9229 39.403 34.2543 39.544 36.3093 38.8452L35.319 35.3217ZM27.92 28.0817L27.9198 28.0818L27.92 28.0817Z"
        fill="#FF9811"
      />
      <path
        d="M38.3672 18.9635L36.3882 26.026L30.4502 24.9295L26.628 17.0507L16.3022 20.7149L15.4879 18.4202L12.7864 18.0873L14.822 23.824L21.9279 22.0066L23.9473 27.6973L19.0351 34.9467L27.3713 42.0568L25.7912 43.9093L26.8538 46.4156L30.8042 41.7842L25.6773 36.5389L29.5959 31.9448L38.3304 32.5742L40.3198 21.7996L42.7141 22.2418L44.3535 20.0686L38.3672 18.9635ZM27.9979 28.1905V28.1908V28.1905Z"
        fill="#FFEACF"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15163">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBhutan;

import { IIconProps } from '../interface';

const SvgEngland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15612)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.763 24.3479H31.6524H31.6523V0.237016C30.4567 0.081375 29.2378 0 28 0C26.7622 0 25.5433 0.081375 24.3479 0.237016V24.3476V24.3477H0.237016C0.081375 25.5433 0 26.762 0 28C0 29.238 0.081375 30.4567 0.237016 31.6521H24.3476H24.3477V55.763C25.5433 55.9186 26.7622 56 28 56C29.2378 56 30.4567 55.9187 31.6521 55.763V31.6524V31.6523H55.763C55.9186 30.4567 56 29.238 56 28C56 26.762 55.9186 25.5433 55.763 24.3479Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15612">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEngland;

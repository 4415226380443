import { IIconProps } from '../interface';

const SvgGovernmentBuilding = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <line
        x1="21.99999"
        y1="21"
        x2="1.99999"
        y2="21"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M18,12.5l-.10558-.58067A5.991,5.991,0,0,0,12,7h0a5.99106,5.99106,0,0,0-5.89442,4.91934L6,12.5"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="11.99999"
        y1="4.00003"
        x2="11.99999"
        y2="7.00002"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <rect
        x="11.99999"
        y="1.50003"
        width="4"
        height="3"
        rx="0.5"
        strokeWidth="1.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <line
        x1="7.99999"
        y1="12.50002"
        x2="7.99999"
        y2="21"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="11.99999"
        y1="12.50002"
        x2="11.99999"
        y2="21"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="15.99999"
        y1="12.50002"
        x2="15.99999"
        y2="21"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M20,21V13.5a1,1,0,0,0-1-1H5a1,1,0,0,0-1,1V21"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default SvgGovernmentBuilding;

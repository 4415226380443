import { IIconProps } from '../interface';

const SvgBritishColumbia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15197)">
      <path
        d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28H0Z"
        fill="#F0F0F0"
      />
      <path d="M20.6956 26.4693L19.1649 28H20.6956V26.4693Z" fill="#0052B4" />
      <path d="M35.3044 26.4694V28H36.835L35.3044 26.4694Z" fill="#0052B4" />
      <path
        d="M0.807844 21.3045C0.281203 23.45 0 25.6921 0 28H8.83498L15.5305 21.3045H0.807844Z"
        fill="#0052B4"
      />
      <path
        d="M40.4692 21.3045L47.1647 28H55.9999C55.9999 25.6921 55.7186 23.45 55.1921 21.3045H40.4692Z"
        fill="#0052B4"
      />
      <path
        d="M38.7477 21.3045H35.3044L41.9999 28H45.4433L38.7477 21.3045Z"
        fill="#D80027"
      />
      <path
        d="M20.6956 24.7475V21.3045H20.6954L13.9999 28H17.4432L20.6956 24.7475Z"
        fill="#D80027"
      />
      <path
        d="M13.1165 4.28116C11.9624 5.00697 10.865 5.81438 9.8324 6.69561H15.5308L13.1165 4.28116Z"
        fill="#0052B4"
      />
      <path
        d="M46.1677 6.69561C45.1351 5.81427 44.0378 5.00686 42.8838 4.28116L40.4695 6.69561H46.1677Z"
        fill="#0052B4"
      />
      <path
        d="M31.6522 17.6521H54.0248C52.9663 14.9923 51.5114 12.5337 49.7337 10.3477H31.6523H31.6522V0.237016C30.4567 0.081375 29.2378 0 28 0C26.762 0 25.5433 0.081375 24.3479 0.237016V10.3476V10.3477H6.26633C4.48866 12.5337 3.03375 14.9923 1.97522 17.6521H24.3477H24.3478V28H31.6522V17.6523V17.6521Z"
        fill="#D80027"
      />
      <path
        d="M20.6956 6.69562V6.69551L16.4745 2.4744C15.6941 2.82735 14.9335 3.21618 14.1934 3.63651L17.2525 6.69562H20.6956Z"
        fill="#D80027"
      />
      <path
        d="M35.3044 6.69561L39.5257 2.47451C38.7227 2.11149 37.8995 1.78599 37.0584 1.49844L35.3044 3.25249V6.69561Z"
        fill="#D80027"
      />
      <path
        d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28H56Z"
        fill="#F0F0F0"
      />
      <path
        d="M51.3329 31.5711C48.9996 31.5711 48.9996 29.4376 46.6662 29.4376C44.3329 29.4376 44.3329 31.5711 41.9997 31.5711C39.6665 31.5711 39.6664 29.4376 37.3332 29.4376C35 29.4376 34.9999 31.5711 32.6665 31.5711C30.3331 31.5711 30.333 29.4376 27.9995 29.4376C25.6662 29.4376 25.6662 31.5711 23.333 31.5711C21.0001 31.5711 21.0001 29.4376 18.6668 29.4376C16.3333 29.4376 16.3333 31.5711 13.9997 31.5711C11.6662 31.5711 11.6662 29.4376 9.3327 29.4376C6.99974 29.4376 6.99974 31.5711 4.66644 31.5711C2.34572 31.5711 2.33227 29.4611 0.036377 29.4386C0.101018 30.7194 0.253924 31.9768 0.485142 33.2064C2.34495 33.4797 2.50781 35.3044 4.66655 35.3044C6.99985 35.3044 6.99985 33.1711 9.33281 33.1711C11.6663 33.1711 11.6663 35.3044 13.9998 35.3044C16.3334 35.3044 16.3334 33.1711 18.6669 33.1711C21.0002 33.1711 21.0002 35.3044 23.3331 35.3044C25.6664 35.3044 25.6664 33.1711 27.9996 33.1711C30.3331 33.1711 30.3331 35.3044 32.6667 35.3044C35.0002 35.3044 35.0002 33.1711 37.3334 33.1711C39.6667 33.1711 39.6667 35.3044 41.9998 35.3044C44.333 35.3044 44.3331 33.1711 46.6663 33.1711C48.9998 33.1711 48.9998 35.3044 51.333 35.3044C53.492 35.3044 53.6548 33.4798 55.5148 33.2065C55.7461 31.9768 55.8988 30.7195 55.9637 29.4387C53.6674 29.4614 53.6537 31.5711 51.3329 31.5711Z"
        fill="#0052B4"
      />
      <path
        d="M46.6662 36.742C44.3329 36.742 44.3329 38.8755 41.9997 38.8755C39.6665 38.8755 39.6664 36.742 37.3332 36.742C34.9999 36.742 34.9999 38.8755 32.6665 38.8755C30.333 38.8755 30.333 36.742 27.9995 36.742C25.6662 36.742 25.6662 38.8755 23.333 38.8755C21 38.8755 21 36.742 18.6667 36.742C16.3332 36.742 16.3332 38.8755 13.9997 38.8755C11.6662 38.8755 11.6662 36.742 9.33269 36.742C6.99972 36.742 6.99972 38.8755 4.66642 38.8755C2.99922 38.8755 2.523 37.7865 1.53589 37.1644C2.19301 39.0631 3.04789 40.8692 4.07744 42.5575C4.25648 42.5905 4.45139 42.6088 4.66642 42.6088C6.99972 42.6088 6.99972 40.4755 9.33269 40.4755C11.6662 40.4755 11.6662 42.6088 13.9997 42.6088C16.3332 42.6088 16.3332 40.4755 18.6667 40.4755C21 40.4755 21 42.6088 23.333 42.6088C25.6663 42.6088 25.6663 40.4755 27.9995 40.4755C30.333 40.4755 30.333 42.6088 32.6665 42.6088C35 42.6088 35 40.4755 37.3332 40.4755C39.6665 40.4755 39.6665 42.6088 41.9997 42.6088C44.3329 42.6088 44.333 40.4755 46.6662 40.4755C48.9997 40.4755 48.9997 42.6088 51.3329 42.6088C51.548 42.6088 51.7431 42.5905 51.9221 42.5574C52.9517 40.8692 53.8065 39.0631 54.4639 37.1645C53.4767 37.7863 53.0004 38.8755 51.3329 38.8755C48.9997 38.8755 48.9997 36.742 46.6662 36.742Z"
        fill="#0052B4"
      />
      <path
        d="M55.9849 27.1203C55.9873 27.1981 55.99 27.2758 55.9918 27.3539C55.99 27.2759 55.9872 27.1981 55.9849 27.1203Z"
        fill="#F0F0F0"
      />
      <path
        d="M0.00817871 27.3538C0.0100381 27.2758 0.0126631 27.1981 0.0150693 27.1202C0.0127725 27.1981 0.00992871 27.2759 0.00817871 27.3538Z"
        fill="#F0F0F0"
      />
      <path
        d="M0 28C0 27.784 0.00328125 27.5688 0.00820313 27.3538C0.00317188 27.5688 0 27.784 0 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M0 28C0 28.51 0.0144375 29.0168 0.0416719 29.52C2.33231 29.5457 2.34752 31.6521 4.66659 31.6521C6.99989 31.6521 6.99989 29.5189 9.33286 29.5189C11.6664 29.5189 11.6664 31.6521 13.9999 31.6521C16.3334 31.6521 16.3334 29.5189 18.6669 29.5189C21.0002 29.5189 21.0002 31.6521 23.3332 31.6521C25.6665 31.6521 25.6665 29.5189 27.9997 29.5189C30.3332 29.5189 30.3332 31.6521 32.6667 31.6521C35.0002 31.6521 35.0002 29.5189 37.3334 29.5189C39.6667 29.5189 39.6667 31.6521 41.9999 31.6521C44.3331 31.6521 44.3332 29.5189 46.6664 29.5189C48.9999 29.5189 48.9999 31.6521 51.3331 31.6521C53.6524 31.6521 53.6676 29.5457 55.9587 29.52C55.9856 29.0166 56 28.51 56 28H0Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.9918 27.3538C55.9967 27.5686 56 27.7839 56 28C56 27.784 55.9969 27.5688 55.9918 27.3538Z"
        fill="#F0F0F0"
      />
      <path
        d="M46.6663 44.0463C44.333 44.0463 44.333 46.1798 41.9998 46.1798C39.6666 46.1798 39.6665 44.0463 37.3333 44.0463C35 44.0463 35 46.1798 32.6666 46.1798C30.3331 46.1798 30.3331 44.0463 27.9995 44.0463C25.6663 44.0463 25.6663 46.1798 23.3331 46.1798C21.0001 46.1798 21.0001 44.0463 18.6668 44.0463C16.3333 44.0463 16.3333 46.1798 13.9998 46.1798C11.6663 46.1798 11.6663 44.0463 9.33274 44.0463C7.7212 44.0463 7.22256 45.0639 6.29944 45.6936C8.74824 48.6933 11.8075 51.1744 15.2861 52.9508L28 52.3103L40.7137 52.9507C44.1923 51.1742 47.2515 48.6934 49.7003 45.6937C48.7768 45.0641 48.2781 44.0463 46.6663 44.0463Z"
        fill="#0052B4"
      />
      <path
        d="M43.5294 44.714L35.0118 46.3432L33.9327 37.7392L28 44.0671L22.0675 37.7391L20.988 46.3431L12.4708 44.7139L16.6477 52.3102L15.2836 52.9518C19.0995 54.9007 23.4212 56 28 56C32.5788 56 36.9005 54.9007 40.7164 52.952L39.3528 52.3106L43.5294 44.714Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15197">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBritishColumbia;

import { IIconProps } from '../interface';

const SvgAbkhazia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M47.5932 8.00012C42.5431 3.052 35.6286 0 28 0C25.5652 0 18.4413 3.052 13.3914 8.00012H47.5932Z"
      fill="#6DA544"
    />
    <path
      d="M28 24H55.7143C55.3103 21.1754 54.4853 18.487 53.3039 16H26.7827L28 24Z"
      fill="#6DA544"
    />
    <path
      d="M2.69622 40.0001H53.3038C54.4853 37.5132 55.3103 34.8245 55.7143 31.9999H0.285706C0.689846 34.8247 1.51464 37.5133 2.69622 40.0001Z"
      fill="#6DA544"
    />
    <path
      d="M8.4068 47.9999C13.457 52.948 20.3714 56 28 56C35.6286 56 42.5431 52.948 47.5932 47.9999H8.4068Z"
      fill="#6DA544"
    />
    <path
      d="M28 0C13.8944 0 2.22689 10.4312 0.285706 24H28V0Z"
      fill="#D80027"
    />
    <path
      d="M14 21.4667L12.7827 19.032V14.1624L15.8261 11.7276L18.8696 14.1624V17.8146L20.0869 16.5972V19.032L17.6521 21.4667H14Z"
      fill="#F0F0F0"
    />
    <path
      d="M7.45224 14L7.77457 14.992H8.81757L7.97374 15.6053L8.29618 16.5972L7.45224 15.9841L6.6083 16.5972L6.93074 15.6053L6.08691 14.992H7.12991L7.45224 14Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.88706 11.5652L10.2094 12.5572H11.2525L10.4086 13.1705L10.7309 14.1624L9.88706 13.5494L9.04323 14.1624L9.36567 13.1705L8.52173 12.5572H9.56462L9.88706 11.5652Z"
      fill="#F0F0F0"
    />
    <path
      d="M12.3218 9.1304L12.6441 10.1224H13.687L12.8433 10.7357L13.1655 11.7276L12.3218 11.1146L11.4779 11.7276L11.8002 10.7357L10.9565 10.1224H11.9994L12.3218 9.1304Z"
      fill="#F0F0F0"
    />
    <path
      d="M24.1999 14L23.8775 14.992H22.8347L23.6784 15.6053L23.356 16.5972L24.1999 15.9841L25.0438 16.5972L24.7215 15.6053L25.5652 14.992H24.5223L24.1999 14Z"
      fill="#F0F0F0"
    />
    <path
      d="M21.7651 11.5652L21.4427 12.5572H20.3998L21.2436 13.1705L20.9212 14.1624L21.7651 13.5494L22.609 14.1624L22.2866 13.1705L23.1304 12.5572H22.0875L21.7651 11.5652Z"
      fill="#F0F0F0"
    />
    <path
      d="M19.3304 9.1304L19.0079 10.1224H17.965L18.8087 10.7357L18.4864 11.7276L19.3304 11.1146L20.1742 11.7276L19.8519 10.7357L20.6956 10.1224H19.6527L19.3304 9.1304Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.8261 7.91306L15.5036 8.90509H14.4608L15.3045 9.51824L14.9821 10.5102L15.8261 9.89712L16.6699 10.5102L16.3476 9.51824L17.1913 8.90509H16.1484L15.8261 7.91306Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgAbkhazia;

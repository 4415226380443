import { IIconProps } from '../interface';

const SvgMoneyCoinsOne = ({
  width = 24,
  height = 24,
  color = '#323232',
  type,
}: IIconProps) => {
  if (type === 'circle') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2213_41059)">
          <path
            d="M13.5 14.5C12.8078 14.5 12.1311 14.2947 11.5555 13.9101C10.9799 13.5256 10.5313 12.9789 10.2664 12.3394C10.0015 11.6999 9.9322 10.9961 10.0673 10.3172C10.2023 9.63825 10.5356 9.01461 11.0251 8.52513C11.5146 8.03564 12.1383 7.7023 12.8172 7.56725C13.4961 7.4322 14.1999 7.50152 14.8394 7.76642C15.4789 8.03133 16.0256 8.47993 16.4101 9.05551C16.7947 9.63108 17 10.3078 17 11C17 11.9283 16.6313 12.8185 15.9749 13.4749C15.3185 14.1313 14.4283 14.5 13.5 14.5Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.33 9.51807C9.71197 9.54701 9.11271 9.73961 8.59353 10.0762C8.07434 10.4127 7.65387 10.8811 7.37511 11.4335C7.09635 11.9858 6.96932 12.6023 7.007 13.2199C7.04469 13.8375 7.24573 14.4339 7.58958 14.9483C7.93343 15.4627 8.40774 15.8765 8.96399 16.1474C9.52024 16.4183 10.1385 16.5367 10.7554 16.4902C11.3724 16.4438 11.966 16.2344 12.4755 15.8833C12.9849 15.5322 13.392 15.0521 13.655 14.4921"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2213_41059">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41170)">
        <path
          d="M8.52698 13.263L9.63198 12.157V16.579"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.53003 16.582H10.734"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.632 21.0001C13.2948 21.0001 16.264 18.0308 16.264 14.3681C16.264 10.7053 13.2948 7.73608 9.632 7.73608C5.96925 7.73608 3 10.7053 3 14.3681C3 18.0308 5.96925 21.0001 9.632 21.0001Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.68005 4.9431C12.2691 2.3531 16.4681 2.3541 19.0581 4.9431C21.6481 7.5321 21.6481 11.7311 19.0581 14.3211"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41170">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgMoneyCoinsOne;

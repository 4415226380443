import { IIconProps } from '../interface';

const SvgBonaire = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M8.20096 8.20094C2.2001 14.2018 -0.5066 22.249 0.0788842 30.0962L30.0961 0.0789719C22.2489 -0.506622 14.2018 2.20008 8.20096 8.20094Z"
      fill="#FFDA44"
    />
    <path
      d="M9.99799 49.4453C20.999 58.7008 37.4445 58.1534 47.7991 47.799C58.1536 37.4445 58.701 20.9989 49.4454 9.99797L9.99799 49.4453Z"
      fill="#0052B4"
    />
    <path
      d="M27.9001 26.8722L30.3131 25.5652L27.9001 24.2582C27.3368 20.5984 24.4451 17.7066 20.7853 17.1433L19.4782 14.7303L18.1711 17.1432C14.5114 17.7065 11.6198 20.5983 11.0563 24.2581L8.64325 25.5652L11.0562 26.8722C11.6196 30.532 14.5112 33.4238 18.171 33.9871L19.4782 36.4001L20.7852 33.9872C24.4451 33.4238 27.3368 30.532 27.9001 26.8722ZM19.4783 30.4348C16.7888 30.4348 14.6087 28.2547 14.6087 25.5652C14.6087 22.8757 16.7887 20.6956 19.4783 20.6956C22.1678 20.6956 24.3479 22.8757 24.3479 25.5652C24.3479 28.2547 22.1677 30.4348 19.4783 30.4348Z"
      fill="black"
    />
    <path
      d="M19.4783 21.9131L20.5326 23.7391H22.6412L21.5868 25.5652L22.6412 27.3913H20.5326L19.4783 29.2173L18.424 27.3913H16.3155L17.3698 25.5652L16.3155 23.7391H18.424L19.4783 21.9131Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgBonaire;

import { IIconProps } from '../interface';

const SvgNigeria = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M0 28C0 40.0391 7.5985 50.3022 18.2609 54.2585V1.74168C7.5985 5.69778 0 15.9611 0 28Z"
      fill="#6DA544"
    />
    <path
      d="M55.9999 28C55.9999 15.9611 48.4014 5.69778 37.739 1.74168V54.2586C48.4014 50.3022 55.9999 40.0391 55.9999 28Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgNigeria;

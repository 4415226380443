import { createSlice } from '@reduxjs/toolkit';
import { payloadSkeletonMenu } from '../screens/AppScreens/TaxAllocation/consts';
import {
  StepsEnum,
  TatStorageEnum,
} from '../screens/AppScreens/TaxAllocation/types';
import { EStatus } from '../screens/AppScreens/types';

export interface IStateTat {
  tatId: string;
  calendarYearId: string;
  step: string;
  fiscalYear: string;
  tatMenuRoadmap: typeof payloadSkeletonMenu;
  currentState: any;
  lastAnswerd: any;
  currentCategory: string;
  currentQuestion: string;
  parsedFy: string;
}

const initialState: IStateTat = {
  tatId: '',
  calendarYearId: '',
  fiscalYear: '',
  step: localStorage.getItem(TatStorageEnum.tat_step) || StepsEnum.Start,
  tatMenuRoadmap: payloadSkeletonMenu,
  currentState: {},
  lastAnswerd: {},
  currentCategory: '',
  currentQuestion: '',
  parsedFy: '',
};

export const tatSlice = createSlice({
  name: 'tatool',
  initialState,
  reducers: {
    setTatId: (state, action) => {
      state.tatId = action.payload;
    },
    setCalendarYearId: (state, action) => {
      state.calendarYearId = action.payload;
    },
    setFiscalYear: (state, action) => {
      localStorage.setItem(TatStorageEnum.tat_year, action.payload);
      state.fiscalYear = action.payload;
    },
    setStep: (state, action) => {
      localStorage.setItem(TatStorageEnum.tat_step, action.payload);
      state.step = action.payload;
    },
    setMenuRoadmap: (state, action) => {
      if (!action.payload) {
        localStorage.removeItem(TatStorageEnum.tat_menu_roadmap);
        state.tatMenuRoadmap = payloadSkeletonMenu;
        return;
      }

      localStorage.setItem(
        TatStorageEnum.tat_menu_roadmap,
        JSON.stringify(action.payload)
      );

      state.tatMenuRoadmap = action.payload;
    },
    setCurrentState: (state, action) => {
      const search = action.payload;
      const searchKeys = Object.keys(search);

      const hasPending = !!searchKeys.find(
        (key) => search[key]?.status === EStatus.to_do
      );

      state.currentState = {
        ...action.payload,
        submit: {
          items: [],
          status: hasPending ? EStatus.to_do : EStatus.in_progress,
        },
      };
    },
    setLastAnswerd: (state, action) => {
      state.lastAnswerd = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    setParsedFy: (state, action) => {
      state.parsedFy = action.payload;
    },
  },
});

export const {
  setTatId,
  setCalendarYearId,
  setFiscalYear,
  setStep,
  setMenuRoadmap,
  setCurrentState,
  setLastAnswerd,
  setCurrentCategory,
  setCurrentQuestion,
  setParsedFy,
} = tatSlice.actions;

export default tatSlice.reducer;

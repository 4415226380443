import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NoMatch = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/', { state: { notFound: true } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default NoMatch;

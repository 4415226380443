import { IIconProps } from '../interface';

const SvgEuropeanUnion = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M28.0002 10.9565L28.9068 13.7466H31.8402L29.4669 15.4709L30.3735 18.2609L28.0002 16.5365L25.6268 18.2609L26.5334 15.4709L24.16 13.7466H27.0936L28.0002 10.9565Z"
      fill="#FFDA44"
    />
    <path
      d="M15.9484 15.9484L18.5624 17.2802L20.6367 15.206L20.1777 18.1033L22.7916 19.4352L19.8942 19.8941L19.4353 22.7917L18.1034 20.1777L15.2061 20.6368L17.2804 18.5625L15.9484 15.9484Z"
      fill="#FFDA44"
    />
    <path
      d="M10.9567 28L13.7467 27.0934V24.1598L15.4709 26.5333L18.2611 25.6267L16.5365 28L18.2611 30.3733L15.4709 29.4668L13.7467 31.8401V28.9066L10.9567 28Z"
      fill="#FFDA44"
    />
    <path
      d="M15.9484 40.0516L17.2802 37.4375L15.2061 35.3632L18.1035 35.8224L19.4351 33.2084L19.8942 36.1059L22.7915 36.5648L20.1779 37.8967L20.6367 40.794L18.5624 38.7197L15.9484 40.0516Z"
      fill="#FFDA44"
    />
    <path
      d="M28.0002 45.0435L27.0935 42.2534H24.16L26.5335 40.5291L25.6268 37.7393L28.0002 39.4635L30.3735 37.7393L29.4669 40.5291L31.8402 42.2534H28.9067L28.0002 45.0435Z"
      fill="#FFDA44"
    />
    <path
      d="M40.0518 40.0516L37.4378 38.7198L35.3634 40.7941L35.8225 37.8966L33.2087 36.5648L36.1061 36.1059L36.565 33.2084L37.8968 35.8224L40.7941 35.3632L38.7198 37.4377L40.0518 40.0516Z"
      fill="#FFDA44"
    />
    <path
      d="M45.0435 28L42.2535 28.9066V31.8401L40.5292 29.4667L37.7394 30.3733L39.4637 28L37.7394 25.6267L40.5293 26.5333L42.2535 24.1598V27.0935L45.0435 28Z"
      fill="#FFDA44"
    />
    <path
      d="M40.0518 15.9484L38.7199 18.5625L40.7942 20.6368L37.8967 20.1776L36.565 22.7916L36.1061 19.8941L33.2087 19.4351L35.8225 18.1033L35.3634 15.2061L37.4379 17.2803L40.0518 15.9484Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgEuropeanUnion;

import { IIconProps } from '../interface';

const SvgArrowLeft = ({
  width = 14,
  height = 12,
  color = '#777A83',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5858 11.0001L12.2929 4.70718L13.7071 3.29297L21.7071 11.293C22.0976 11.6835 22.0976 12.3167 21.7071 12.7072L13.7071 20.7072L12.2929 19.293L18.5858 13.0001H2V11.0001H18.5858Z"
        fill={color}
      />
    </svg>
  );
};

export default SvgArrowLeft;

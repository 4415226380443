import { IIconProps } from '../interface';

const SvgChess = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0554 2.05546C9.19094 2.91996 9.02234 4.21685 9.54966 5.25H9C7.75779 5.25 6.75 6.25779 6.75 7.5C6.75 8.74221 7.75779 9.75 9 9.75H9.06504L7.39837 17.25H6.5C5.25779 17.25 4.25 18.2578 4.25 19.5V20C4.25 20.9662 5.03379 21.75 6 21.75H18C18.9662 21.75 19.75 20.9662 19.75 20V19.5C19.75 18.2578 18.7422 17.25 17.5 17.25H16.6016L14.935 9.75H15C16.2422 9.75 17.25 8.74221 17.25 7.5C17.25 6.25779 16.2422 5.25 15 5.25H14.4503C14.9776 4.21685 14.809 2.91996 13.9445 2.05546L13.9437 2.0546C12.8697 0.981515 11.1291 0.9818 10.0554 2.05546ZM12.8839 3.11612C12.3957 2.62796 11.6043 2.62796 11.1161 3.11612C10.6279 3.60427 10.6279 4.39573 11.1161 4.88389C11.3594 5.12722 11.6781 5.24926 11.9971 5.25H12.0029C12.3218 5.24926 12.6405 5.12722 12.8839 4.88389C13.372 4.39573 13.372 3.60427 12.8839 3.11612ZM15.9839 18.75C15.9941 18.7502 16.0044 18.7502 16.0147 18.75H17.5C17.9138 18.75 18.25 19.0862 18.25 19.5V20C18.25 20.1378 18.1378 20.25 18 20.25H6C5.86221 20.25 5.75 20.1378 5.75 20V19.5C5.75 19.0862 6.08621 18.75 6.5 18.75H7.98527C7.99559 18.7502 8.00588 18.7502 8.01614 18.75H15.9839ZM15.065 17.25L13.3984 9.75H10.6016L8.93496 17.25H15.065ZM9.98382 8.25C9.99411 8.24979 10.0044 8.24978 10.0148 8.25H13.9852C13.9956 8.24978 14.0059 8.24979 14.0162 8.25H15C15.4138 8.25 15.75 7.91379 15.75 7.5C15.75 7.08621 15.4138 6.75 15 6.75H9C8.58621 6.75 8.25 7.08621 8.25 7.5C8.25 7.91379 8.58621 8.25 9 8.25H9.98382Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgChess;

import { IIconProps } from '../interface';

const SvgGraduateHat = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.8899V14.9861C9 15.2647 9.07757 15.5377 9.22403 15.7747C9.37048 16.0116 9.58003 16.2031 9.82919 16.3277L10.4348 16.6305C10.9208 16.8735 11.4566 17 12 17C12.5433 17 13.0792 16.8735 13.5652 16.6305L14.1708 16.3277C14.42 16.2031 14.6295 16.0116 14.776 15.7747C14.9224 15.5377 15 15.2647 15 14.9861V11.8899"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.125 10.9922L10.7045 12.7059C11.1089 12.8995 11.5516 13 12 13C12.4483 13 12.891 12.8995 13.2954 12.7059L16.875 10.9922C17.0622 10.9026 17.2202 10.7619 17.3308 10.5863C17.4414 10.4108 17.5001 10.2075 17.5001 9.99999C17.5001 9.79249 17.4414 9.58922 17.3308 9.41365C17.2202 9.23808 17.0622 9.09738 16.875 9.00779L13.2954 7.29411C12.891 7.1005 12.4484 7 12 7C11.5516 7 11.109 7.1005 10.7046 7.29411L7.125 9.00779C6.93783 9.09738 6.77982 9.23808 6.66921 9.41365C6.5586 9.58922 6.49991 9.79249 6.49991 9.99999C6.49991 10.2075 6.5586 10.4108 6.66921 10.5863C6.77982 10.7619 6.93783 10.9026 7.125 10.9922Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 10V12.5499"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgGraduateHat;

import { IIconProps } from '../interface';

const SvgGlobePin = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.51387 14.9999H6C6.53043 14.9999 7.03914 14.7892 7.41421 14.4141C7.78929 14.0391 8 13.5304 8 12.9999V10.9999C8 10.4695 8.21071 9.96079 8.58579 9.58572C8.96086 9.21064 9.46957 8.99993 10 8.99993H12C12.5304 8.99993 13.0391 8.78922 13.4142 8.41414C13.7893 8.03907 14 7.53036 14 6.99993V3.22461"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5009 16.25C16.5503 16.2502 16.5986 16.265 16.6396 16.2926C16.6805 16.3202 16.7124 16.3593 16.7312 16.405C16.75 16.4507 16.7548 16.501 16.745 16.5494C16.7353 16.5978 16.7114 16.6423 16.6764 16.6771C16.6414 16.712 16.5968 16.7357 16.5483 16.7453C16.4999 16.7548 16.4497 16.7498 16.404 16.7309C16.3584 16.7119 16.3194 16.6799 16.292 16.6388C16.2646 16.5977 16.2499 16.5494 16.2499 16.5C16.2498 16.467 16.2561 16.4344 16.2687 16.4039C16.2813 16.3734 16.2998 16.3457 16.3231 16.3225C16.3465 16.2992 16.3742 16.2808 16.4048 16.2684C16.4353 16.256 16.468 16.2497 16.5009 16.25Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 16.5C13 15.5717 13.3687 14.6815 14.0251 14.0251C14.6815 13.3687 15.5717 13 16.5 13C17.4283 13 18.3185 13.3687 18.9749 14.0251C19.6313 14.6815 20 15.5717 20 16.5C20 18.1615 18.1433 19.9384 17.1338 20.7816C16.9526 20.9232 16.7292 21.0001 16.4993 21C16.2693 20.9999 16.046 20.923 15.8648 20.7813C14.8563 19.9381 13 18.1613 13 16.5Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 16.5C20 15.5717 19.6313 14.6815 18.9749 14.0251C18.3185 13.3687 17.4283 13 16.5 13C15.5717 13 14.6815 13.3687 14.0251 14.0251C13.3687 14.6815 13 15.5717 13 16.5"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};

export default SvgGlobePin;

import { IIconProps } from '../interface';

const SvgMapsNavigationMultiple = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.34833 3.34835C10.8128 1.88388 13.1872 1.88388 14.6516 3.34835C16.1161 4.81281 16.1161 7.18718 14.6516 8.65165C14.1112 9.19209 13.4468 9.53309 12.75 9.67464V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V9.67465C10.5532 9.5331 9.88879 9.1921 9.34833 8.65165C7.88387 7.18718 7.88387 4.81281 9.34833 3.34835ZM13.591 4.40901C12.7123 3.53033 11.2877 3.53033 10.409 4.40901C9.53032 5.28768 9.53032 6.71231 10.409 7.59099C11.2877 8.46966 12.7123 8.46966 13.591 7.59099C14.4696 6.71231 14.4696 5.28768 13.591 4.40901Z"
            fill={color}
          />
          <path
            d="M9.10231 14.948C9.51266 14.8915 9.7995 14.513 9.74299 14.1027C9.68649 13.6923 9.30803 13.4055 8.89769 13.462C7.09629 13.7101 5.50187 14.1721 4.33423 14.8016C3.21777 15.4034 2.25 16.2992 2.25 17.5C2.25 18.264 2.65107 18.9101 3.19956 19.4144C3.74741 19.9182 4.49877 20.3374 5.36403 20.6739C7.09932 21.3487 9.44569 21.75 12 21.75C14.5543 21.75 16.9007 21.3487 18.636 20.6739C19.5012 20.3374 20.2526 19.9182 20.8004 19.4144C21.3489 18.9101 21.75 18.264 21.75 17.5C21.75 16.2992 20.7822 15.4034 19.6658 14.8016C18.4981 14.1721 16.9037 13.7101 15.1023 13.462C14.692 13.4055 14.3135 13.6923 14.257 14.1027C14.2005 14.513 14.4873 14.8915 14.8977 14.948C16.5893 15.1809 17.9949 15.6049 18.954 16.1219C19.9643 16.6665 20.25 17.1778 20.25 17.5C20.25 17.7025 20.1474 17.9771 19.7852 18.3103C19.4222 18.644 18.8556 18.979 18.0923 19.2759C16.5703 19.8678 14.4167 20.25 12 20.25C9.58331 20.25 7.42968 19.8678 5.90772 19.2759C5.14436 18.979 4.57778 18.644 4.21485 18.3103C3.85256 17.9771 3.75 17.7025 3.75 17.5C3.75 17.1778 4.03574 16.6665 5.04602 16.1219C6.00514 15.6049 7.41071 15.1809 9.10231 14.948Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9391 5.15533C22.4396 3.56519 20.7455 2.68112 19.1553 3.1807C17.5652 3.68028 16.6811 5.37435 17.1807 6.96449C17.4985 7.97604 18.2997 8.70187 19.25 8.96754V12.5C19.25 12.9142 19.5858 13.25 20 13.25C20.4142 13.25 20.75 12.9142 20.75 12.5V8.99807C20.8216 8.98116 20.8932 8.96154 20.9645 8.93913C22.5547 8.43954 23.4387 6.74548 22.9391 5.15533ZM19.6049 4.61174C20.4048 4.36046 21.2568 4.80513 21.5081 5.60493C21.7594 6.40473 21.3147 7.25681 20.5149 7.50809C19.7151 7.75937 18.863 7.3147 18.6118 6.5149C18.3605 5.71509 18.8051 4.86301 19.6049 4.61174Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.15535 4.1807C4.74549 3.68112 6.43956 4.56519 6.93914 6.15533C7.43873 7.74548 6.55465 9.43954 4.96451 9.93913C4.89318 9.96154 4.82164 9.98116 4.75 9.99807L4.75 12C4.75 12.4142 4.41422 12.75 4 12.75C3.58579 12.75 3.25 12.4142 3.25 12L3.25 9.96754C2.2997 9.70187 1.49852 8.97604 1.18072 7.96449C0.681131 6.37435 1.56521 4.68028 3.15535 4.1807ZM5.50811 6.60493C5.25683 5.80513 4.40475 5.36046 3.60495 5.61174C2.80514 5.86301 2.36047 6.71509 2.61175 7.5149C2.86303 8.3147 3.71511 8.75937 4.51491 8.50809C5.31471 8.25681 5.75939 7.40473 5.50811 6.60493Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgMapsNavigationMultiple;

import { IIconProps } from '../interface';

const SvgPanama = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M0 28C0 43.4639 12.5361 56 28 56C28 45.3518 28 28 28 28C28 28 9.73908 28 0 28Z"
      fill="#0052B4"
    />
    <path
      d="M28 0C43.4639 0 56 12.5361 56 28C45.3518 28 28 28 28 28C28 28 28 9.73908 28 0Z"
      fill="#D80027"
    />
    <path
      d="M16.6676 9.73907L18.4807 15.3192H24.3479L19.6013 18.7678L21.4143 24.3479L16.6676 20.8992L11.921 24.3479L13.734 18.7678L8.9873 15.3192H14.8545L16.6676 9.73907Z"
      fill="#0052B4"
    />
    <path
      d="M39.3324 31.6521L41.1455 37.2322H47.0127L42.266 40.6808L44.0791 46.2609L39.3324 42.8122L34.5858 46.2609L36.3988 40.6808L31.6521 37.2322H37.5193L39.3324 31.6521Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgPanama;

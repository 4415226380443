import { IIconProps } from '../interface';

const SvgWales = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_17044)">
      <path
        d="M28 56.8359C43.464 56.8359 56 44.2999 56 28.8359C56 13.372 43.464 0.835938 28 0.835938C12.536 0.835938 0 13.372 0 28.8359C0 44.2999 12.536 56.8359 28 56.8359Z"
        fill="#F0F0F0"
      />
      <path
        d="M56 28.8359C56 44.2998 43.4639 56.8359 28 56.8359C12.5361 56.8359 0 44.2998 0 28.8359"
        fill="#496E2D"
      />
      <path
        d="M48.4546 20.3203C47.4533 17.4806 44.7477 15.4447 41.5654 15.4447H35.3043C31.2702 15.4447 27.9999 18.715 27.9999 22.7491C27.9999 24.0671 28.3508 25.3337 28.9614 26.4012C26.6365 26.3822 24.4847 26.4173 23.1303 26.4012C23.1303 23.5842 23.1303 19.9956 23.1303 19.2447L23.418 18.9571C24.7093 17.6658 24.2787 15.5137 24.2787 15.5137C24.2787 15.5137 22.1268 15.0833 20.8355 16.3746L19.3307 17.8795H17.6521C13.9541 17.8795 10.9565 20.8773 10.9565 24.5751H15.8261V30.0534C15.8261 30.8547 15.9876 31.618 16.2795 32.3132L14.6086 33.7055H12.1738V31.2707H9.73901V36.1403H20.6955V41.0099H18.2607V43.4447H23.1303V38.5751L25.3621 35.8968H30.6376L32.8694 38.5751V41.0099H30.4346V43.4447H40.1737V30.0533C40.1737 28.0363 41.8089 26.4011 43.8258 26.4011C43.8777 26.4011 43.9291 26.403 43.9805 26.4051C43.8797 26.0148 43.8258 25.6056 43.8258 25.1838C43.8261 22.5753 45.8772 20.446 48.4546 20.3203ZM35.3043 41.0098V35.8967H35.5072L37.739 38.575V41.0098H35.3043Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_17044">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgWales;

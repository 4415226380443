import { IIconProps } from '../interface';

const SvgArrowLeft = ({
  width = 14,
  height = 12,
  color = '#777A83',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 6H14M7 1L2 6L7 11" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default SvgArrowLeft;

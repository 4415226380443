import { IIconProps } from '../interface';

const SvgHonduras = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 56C39.1008 56 48.6926 49.5398 53.2216 40.1739H2.77832C7.30732 49.5398 16.8991 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M28 0C16.8991 0 7.30732 6.46023 2.77832 15.8261H53.2215C48.6926 6.46023 39.1008 0 28 0Z"
      fill="#338AF3"
    />
    <path
      d="M17.2315 18.2609L18.138 21.051H21.0716L18.6983 22.7752L19.6049 25.5652L17.2315 23.8409L14.8581 25.5652L15.7647 22.7752L13.3914 21.051H16.3249L17.2315 18.2609Z"
      fill="#338AF3"
    />
    <path
      d="M17.2315 30.4348L18.138 33.2249H21.0716L18.6983 34.9491L19.6049 37.7391L17.2315 36.0148L14.8581 37.7391L15.7647 34.9491L13.3914 33.2249H16.3249L17.2315 30.4348Z"
      fill="#338AF3"
    />
    <path
      d="M38.7685 18.2609L39.6751 21.051H42.6087L40.2353 22.7752L41.1419 25.5652L38.7685 23.8409L36.3951 25.5652L37.3017 22.7752L34.9283 21.051H37.862L38.7685 18.2609Z"
      fill="#338AF3"
    />
    <path
      d="M38.7685 30.4348L39.6751 33.2249H42.6087L40.2353 34.9491L41.1419 37.7391L38.7685 36.0148L36.3951 37.7391L37.3017 34.9491L34.9283 33.2249H37.862L38.7685 30.4348Z"
      fill="#338AF3"
    />
    <path
      d="M28 24.3479L28.9066 27.138H31.8401L29.4668 28.8622L30.3734 31.6522L28 29.9279L25.6265 31.6522L26.5331 28.8622L24.1598 27.138H27.0933L28 24.3479Z"
      fill="#338AF3"
    />
  </svg>
);

export default SvgHonduras;

import { IIconProps } from '../interface';

const SvgRwanda = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16464)">
      <path
        d="M0 28C0 32.8508 1.23408 37.4132 3.40452 41.3913L28 43.8261L52.5955 41.3913C54.7659 37.4132 56 32.8508 56 28L28 25.5652L0 28Z"
        fill="#FFDA44"
      />
      <path
        d="M52.5955 14.6087C47.8465 5.90505 38.6132 0 28 0C17.3868 0 8.15347 5.90505 3.40452 14.6087C1.23408 18.5868 0 23.1492 0 28H56C56 23.1492 54.7659 18.5868 52.5955 14.6087Z"
        fill="#338AF3"
      />
      <path
        d="M28 56C38.6132 56 47.8465 50.095 52.5955 41.3913H3.40454C8.15349 50.095 17.3868 56 28 56Z"
        fill="#496E2D"
      />
      <path
        d="M31.6521 16.3867L35.0718 17.9953L33.2508 21.3071L36.9641 20.5968L37.4346 24.3479L40.0209 21.589L42.6074 24.3479L43.0779 20.5968L46.7911 21.3069L44.9703 17.9951L48.3898 16.3867L44.9701 14.7783L46.7911 11.4664L43.078 12.1767L42.6073 8.4257L40.0209 11.1846L37.4345 8.4257L36.9641 12.1767L33.2507 11.4664L35.0717 14.7784L31.6521 16.3867Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16464">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgRwanda;

import spacing from './space';

const number = 16;

const grid = {
  minSize: 1080,
  gutter: {
    number,
    rem: spacing[`spacing-${number}`],
  },
  column: 100 / 12,
};

export default grid;

import { IIconProps } from '../interface';

const SvgCapeVerde = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 56C36.4865 56 44.0906 52.2239 49.2254 46.2609H6.77454C11.9094 52.2239 19.5134 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M56 28C56 12.5361 43.4639 0 28 0C12.5361 0 0 12.5361 0 28C0 30.5273 0.336875 32.9754 0.964578 35.3044H55.0355C55.6631 32.9754 56 30.5273 56 28Z"
      fill="#0052B4"
    />
    <path
      d="M2.22559 38.9565C2.76535 40.2248 3.39634 41.4446 4.10979 42.6087H51.8905C52.6037 41.4445 53.2347 40.2247 53.7747 38.9565H2.22559Z"
      fill="#D80027"
    />
    <path
      d="M20.0146 29.8261L20.4678 31.2211H21.9347L20.748 32.0833L21.2013 33.4783L20.0146 32.6161L18.8279 33.4783L19.2812 32.0833L18.0946 31.2211H19.5614L20.0146 29.8261Z"
      fill="#FFDA44"
    />
    <path
      d="M20.0146 48.0869L20.4678 49.482H21.9347L20.748 50.3441L21.2013 51.7391L20.0146 50.877L18.8279 51.7391L19.2812 50.3441L18.0946 49.482H19.5614L20.0146 48.0869Z"
      fill="#FFDA44"
    />
    <path
      d="M11.5143 41.9113L11.9676 43.3062H13.4344L12.2478 44.1683L12.701 45.5634L11.5143 44.7012L10.3276 45.5634L10.7811 44.1683L9.59436 43.3062H11.0611L11.5143 41.9113Z"
      fill="#FFDA44"
    />
    <path
      d="M14.7611 31.6521L15.2144 33.0472H16.6811L15.4946 33.9093L15.9479 35.3044L14.7611 34.4422L13.5744 35.3044L14.0279 33.9093L12.8411 33.0472H14.3079L14.7611 31.6521Z"
      fill="#FFDA44"
    />
    <path
      d="M9.59436 37.397L11.0609 37.3971L11.5142 36.0018L11.9676 37.397L13.4344 37.3968L12.2477 38.2591L12.701 39.6541L11.5142 38.7919L10.3276 39.6538L10.7809 38.2589L9.59436 37.397Z"
      fill="#FFDA44"
    />
    <path
      d="M13.5745 49.6465L14.0278 48.2518L12.8409 47.3897H14.3078L14.7611 45.9946L15.2145 47.3896H16.6812L15.4945 48.2517L15.9477 49.6468L14.761 48.7845L13.5745 49.6465Z"
      fill="#FFDA44"
    />
    <path
      d="M28.5115 41.9113L28.0584 43.3062H26.5916L27.7781 44.1683L27.3248 45.5634L28.5115 44.7012L29.6982 45.5634L29.2449 44.1683L30.4316 43.3062H28.9648L28.5115 41.9113Z"
      fill="#FFDA44"
    />
    <path
      d="M25.2647 31.6521L24.8116 33.0472H23.3447L24.5312 33.9093L24.078 35.3044L25.2647 34.4422L26.4514 35.3044L25.9981 33.9093L27.1848 33.0472H25.7179L25.2647 31.6521Z"
      fill="#FFDA44"
    />
    <path
      d="M30.4316 37.397L28.965 37.3971L28.5116 36.0018L28.0584 37.397L26.5916 37.3968L27.7782 38.2591L27.3248 39.6541L28.5116 38.7919L29.6982 39.6538L29.245 38.2589L30.4316 37.397Z"
      fill="#FFDA44"
    />
    <path
      d="M26.4513 49.6465L25.9982 48.2518L27.1849 47.3897H25.718L25.2647 45.9946L24.8114 47.3896H23.3446L24.5313 48.2517L24.0781 49.6468L25.2648 48.7845L26.4513 49.6465Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgCapeVerde;

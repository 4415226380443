import { IIconProps } from '../interface';

const SvgBank = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7499 2.29295C11.9117 2.23568 12.0883 2.23568 12.2501 2.29295L21.7501 5.65395C22.0497 5.75994 22.25 6.04322 22.25 6.361V9C22.25 9.41421 21.9142 9.75 21.5 9.75H20.75V17.25H21C21.1989 17.25 21.3897 17.329 21.5303 17.4697L22.5303 18.4697C22.671 18.6103 22.75 18.8011 22.75 19V21C22.75 21.4142 22.4142 21.75 22 21.75H2C1.58579 21.75 1.25 21.4142 1.25 21V19C1.25 18.8011 1.32902 18.6103 1.46967 18.4697L2.46967 17.4697C2.61032 17.329 2.80109 17.25 3 17.25H3.25V9.75H2.5C2.08579 9.75 1.75 9.41421 1.75 9V6.361C1.75 6.04322 1.95027 5.75994 2.24985 5.65395L11.7499 2.29295ZM3.31066 18.75L2.75 19.3107V20.25H21.25V19.3107L20.6893 18.75H3.31066ZM7.25 17.25H4.75V9.75H7.25V17.25ZM8.75 17.25H11.25V9.75H8.75V17.25ZM12.75 17.25H15.25V9.75H12.75V17.25ZM16.75 17.25H19.25V9.75H16.75V17.25ZM20.75 8.25H3.25V6.89121L12 3.79555L20.75 6.89121V8.25Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgBank;

import { IIconProps } from '../interface';

const SvgBarbados = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1739 2.77834C36.4928 0.998375 32.363 0 28 0C23.6371 0 19.5073 0.998375 15.8262 2.77834L13.3914 28L15.8262 53.2217C19.5073 55.0016 23.6371 56 28 56C32.363 56 36.4928 55.0016 40.1739 53.2217L42.6087 28L40.1739 2.77834Z"
      fill="#FFDA44"
    />
    <path
      d="M15.8261 2.77933C6.46034 7.30811 0 16.8991 0 28C0 39.1009 6.46034 48.6919 15.8261 53.2207V2.77933Z"
      fill="#0052B4"
    />
    <path
      d="M40.1739 2.77933V53.2207C49.5397 48.6919 56 39.1008 56 28C56 16.8992 49.5397 7.30811 40.1739 2.77933Z"
      fill="#0052B4"
    />
    <path
      d="M36.5217 17.0435L38.155 17.8601L36.5217 17.0435L34.8883 16.2269C34.7899 16.4239 32.6572 20.7631 32.3088 27.3913H29.8261V17.0435L28 14.6087L26.1739 17.0435V27.3913H23.6912C23.3427 20.7631 21.2101 16.4239 21.1116 16.2269L17.845 17.8602C17.8675 17.905 20.087 22.4228 20.087 29.2175V31.0436H26.174V41.3914H29.8261V31.0436H35.9131V29.2173C35.9131 25.7044 36.5209 22.8078 37.0309 20.9963C37.5868 19.021 38.1515 17.8674 38.1571 17.8559L36.5217 17.0435Z"
      fill="black"
    />
  </svg>
);

export default SvgBarbados;

import { IIconProps } from '../interface';

const SvgAttachment = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2027_17637)">
        <path
          d="M7.33008 16.67L12.0001 12"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.67 7.33008L12 12.0001"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.908 13.092V13.092C9.45144 11.636 7.09053 11.636 5.63399 13.092L4.09198 14.634V14.634C2.636 16.0905 2.63601 18.4514 4.09198 19.908V19.908C5.54852 21.364 7.90944 21.364 9.36598 19.908L10.908 18.366V18.366C11.7719 17.5016 12.1562 16.2681 11.936 15.066"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.064 11.9363V11.9363C16.2661 12.1565 17.4996 11.7722 18.364 10.9083L19.906 9.36628V9.36628C21.3624 7.9099 21.3624 5.54865 19.906 4.09228C18.4496 2.63591 16.0884 2.63591 14.632 4.09228L13.092 5.63428H13.092C11.636 7.09082 11.636 9.45174 13.092 10.9083"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2027_17637">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgAttachment;

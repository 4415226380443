import { IIconProps } from '../interface';

const SvgDashboardMinimal = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4137 11.5863L18.5 6.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1877 9.31226C21.0175 11.1214 21.2185 13.1561 20.7589 15.0927C20.2992 17.0293 19.2053 18.7566 17.6511 19.9999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6877 4.81231C14.5306 4.28111 13.2731 4.00412 12 4C10.1385 3.99934 8.32256 4.57614 6.80261 5.65087C5.28266 6.7256 4.1335 8.24535 3.51356 10.0006C2.89361 11.7559 2.8334 13.6602 3.34123 15.4512C3.84905 17.2421 4.89991 18.8314 6.34893 20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgDashboardMinimal;

import { IIconProps } from '../interface';

const SvgIvoryCoast = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15900)">
      <path
        d="M28 56.418C31.425 56.418 34.7059 55.802 37.7391 54.6764L38.9565 28.418L37.7392 2.15955C34.7059 1.03408 31.425 0.417969 28 0.417969C24.575 0.417969 21.2941 1.03408 18.2609 2.15955L17.0435 28.418L18.2608 54.6764C21.2941 55.802 24.575 56.418 28 56.418Z"
        fill="#F0F0F0"
      />
      <path
        d="M0 28.418C0 40.457 7.5985 50.7202 18.2609 54.6764V2.15955C7.5985 6.11575 0 16.379 0 28.418Z"
        fill="#FF9811"
      />
      <path
        d="M37.7391 2.15955V54.6765C48.4016 50.7202 56.0001 40.457 56.0001 28.418C56.0001 16.379 48.4016 6.11575 37.7391 2.15955Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15900">
        <rect
          width="56"
          height="57"
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIvoryCoast;

export const payloadSkeletonMenu = {
  tatId: '',
  roadmap: {
    firstMenu: {
      job: false,
      unemployed: false,
      student: false,
      company: false,
    },
    secondMenu: {
      shares: false,
      precious_metals: false,
      real_estate: false,
      movable_property: false,
      crypto: false,
      other: false,
    },
    thirdMenu: {
      rent: false,
      severance: false,
      interest: false,
      dividends: false,
      royalties: false,
      pension_annuity: false,
      alimony_child_support: false,
      gambling: false,
      welfare_social_benefits: false,
      gifts: false,
      inheritance: false,
      other: false,
    },
  },
};

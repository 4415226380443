import { IIconProps } from '../interface';

const SvgCuba = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15504)">
      <path
        d="M0.001 4.86959H0V51.1304H0.001V28.0001V4.86959Z"
        fill="#FF9811"
      />
      <path
        d="M27.9999 55.9999C43.4638 55.9999 55.9998 43.4639 55.9998 28C55.9998 12.5361 43.4638 0.000106812 27.9999 0.000106812C12.536 0.000106812 0 12.5361 0 28C0 43.4639 12.536 55.9999 27.9999 55.9999Z"
        fill="#F0F0F0"
      />
      <path
        d="M28.0001 0.000221252C18.8398 0.000221252 10.7073 4.39961 5.59894 11.2002H50.4012C45.293 4.3995 37.1604 0.000221252 28.0001 0.000221252Z"
        fill="#0052B4"
      />
      <path
        d="M28.0001 55.9999C37.1605 55.9999 45.293 51.6005 50.4013 44.7999H5.59894C10.7072 51.6006 18.8398 55.9999 28.0001 55.9999Z"
        fill="#0052B4"
      />
      <path
        d="M0.00012207 28.0001C0.00012207 29.918 0.193497 31.7907 0.560888 33.6001H55.4395C55.8068 31.7907 56.0001 29.918 56.0001 28.0001C56.0001 26.0822 55.8067 24.2095 55.4395 22.4001H0.560888C0.193497 24.2095 0.00012207 26.0822 0.00012207 28.0001Z"
        fill="#0052B4"
      />
      <path
        d="M8.20103 8.20116C-2.73351 19.1357 -2.73351 36.8644 8.20103 47.7992C12.7196 43.2806 17.0653 38.9348 28 28.0002L8.20103 8.20116Z"
        fill="#D80027"
      />
      <path
        d="M11.3324 20.6958L13.1452 26.2756H19.0127L14.2662 29.7244L16.079 35.3043L11.3324 31.8557L6.58566 35.3043L8.39888 29.7244L3.65222 26.2756H9.51931L11.3324 20.6958Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15504">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCuba;

import { IIconProps } from '../interface';

const SvgAzoresIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M0 28C0 38.6132 5.90516 47.8466 14.6087 52.5955V3.40451C5.90516 8.15335 0 17.3868 0 28Z"
      fill="#D80027"
    />
    <path
      d="M0 28C0 40.9368 8.77417 51.8224 20.6956 55.0354V0.964569C8.77417 4.17757 0 15.0632 0 28Z"
      fill="#0052B4"
    />
    <path
      d="M10.0435 9.73907V14.3043C10.0435 16.5735 11.8829 18.4129 14.1521 18.4129C16.4213 18.4129 18.2608 16.5735 18.2608 14.3043V9.73907H10.0435Z"
      fill="#D80027"
    />
    <path
      d="M14.1521 15.9783C13.2291 15.9783 12.4783 15.2274 12.4783 14.3044V12.174H15.8261V14.3044C15.8261 15.2274 15.0752 15.9783 14.1521 15.9783Z"
      fill="#F0F0F0"
    />
    <path
      d="M30.4348 29.2173H23.1304C23.1304 27.8727 22.0403 26.7825 20.6956 26.7825C19.3509 26.7825 18.2608 27.8727 18.2608 29.2173H10.9564C10.9564 30.5621 12.1277 31.6521 13.4723 31.6521H13.3912C13.3912 32.9969 14.4811 34.0869 15.826 34.0869C15.826 35.4317 16.9159 36.5217 18.2608 36.5217H23.1304C24.4751 36.5217 25.5652 35.4317 25.5652 34.0869C26.9099 34.0869 28 32.9969 28 31.6521H27.9189C29.2635 31.6521 30.4348 30.5621 30.4348 29.2173Z"
      fill="#FFDA44"
    />
    <path
      d="M31.4354 25.3211L30.7962 26.3445L31.5716 27.2685L30.4008 26.977L29.7615 28L29.6772 26.7963L28.5065 26.5044L29.6255 26.0525L29.5413 24.8488L30.3168 25.7732L31.4354 25.3211Z"
      fill="#FFDA44"
    />
    <path
      d="M27.7054 22.1913L27.4549 23.3716L28.4997 23.9748L27.2997 24.1014L27.0489 25.2814L26.558 24.1791L25.358 24.3051L26.2548 23.4977L25.7641 22.3956L26.8089 22.9988L27.7054 22.1913Z"
      fill="#FFDA44"
    />
    <path
      d="M23.1302 20.5262L23.2981 21.7211L24.4862 21.9306L23.4019 22.4595L23.5698 23.6544L22.7317 22.7864L21.6472 23.3154L22.2136 22.2499L21.3755 21.3822L22.5638 21.5915L23.1302 20.5262Z"
      fill="#FFDA44"
    />
    <path
      d="M18.2612 20.5262L18.8277 21.5915L20.0159 21.382L19.1777 22.2502L19.7442 23.3155L18.6599 22.7864L17.8216 23.6542L17.9896 22.4595L16.9052 21.9307L18.0933 21.7211L18.2612 20.5262Z"
      fill="#FFDA44"
    />
    <path
      d="M13.6859 22.1913L14.5826 22.9988L15.6273 22.3956L15.1369 23.4981L16.0332 24.3052L14.8335 24.1791L14.3425 25.2814L14.092 24.1012L12.892 23.9752L13.9367 23.3717L13.6859 22.1913Z"
      fill="#FFDA44"
    />
    <path
      d="M9.95602 25.3211L11.075 25.7732L11.8502 24.8489L11.7664 26.0528L12.885 26.5048L11.7141 26.7964L11.6297 28L10.9908 26.977L9.82007 27.2687L10.5956 26.3445L9.95602 25.3211Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgAzoresIslands;

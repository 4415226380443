import { addBreadcrumb } from '@sentry/react';
import { AuthSession } from '@supabase/supabase-js';
import axios, { AxiosInstance } from 'axios';
import { AppsCategoryEnum, Level } from '../utils/sentry';
import env from '../env';

class AxiosClass {
  axiosInstance: AxiosInstance;

  sessionData: AuthSession | null;

  constructor(sessionData: AuthSession | null) {
    this.sessionData = sessionData;
    this.axiosInstance = axios.create({
      baseURL: env.APP_API,
      timeout: 20000,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.sessionData?.access_token}`,
        aid: localStorage.getItem('aid') || '',
      },
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        // Add the actual response data to Sentry breadcrumbs
        addBreadcrumb({
          category: 'api',
          message: `Axios successfull response: ${response.config.method?.toUpperCase()}: ${response.config.url}`,
          data: {
            status: response.status,
            responseData: response.data,  // Log actual response data here
          },
          level: Level.Info,
        });
        return response;
      },
      function (error) {
        addBreadcrumb({
          category: AppsCategoryEnum.APP,
          message: 'Axios error',
          level: Level.Error,
          data: { request: JSON.parse(error?.request), error: JSON.parse(error) },
        });

        if (error?.response?.status === 401) {
          localStorage.clear();

          if (window.location.pathname.search(/login/gi) === -1) {
            window.location.href = `/login?redirectTo=${window.location.href}`;
          }
        }
        return Promise.reject(error);
      },
    );
  }
}

export default AxiosClass;

import { IIconProps } from '../interface';

const SvgBelarus = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15118)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FCFCFC"
      />
      <path
        d="M11.5652 25.971L8.52173 20.4494L11.5652 15.0144L14.6087 20.4494L11.5652 25.971Z"
        fill="#A2001D"
      />
      <path
        d="M5.47828 25.971L2.43481 20.4494L5.47828 15.0144L8.52175 20.4494L5.47828 25.971Z"
        fill="#A2001D"
      />
      <path
        d="M11.5652 40.9856L8.52173 35.464L11.5652 30.029L14.6087 35.464L11.5652 40.9856Z"
        fill="#A2001D"
      />
      <path
        d="M5.47828 40.9856L2.43481 35.464L5.47828 30.029L8.52175 35.464L5.47828 40.9856Z"
        fill="#A2001D"
      />
      <path
        d="M14.6086 5.43505L13.747 3.89636C12.2015 4.81205 10.752 5.87222 9.41638 7.05828L11.5652 10.9565L14.6086 5.43505Z"
        fill="#A2001D"
      />
      <path
        d="M11.5652 45.0435L9.39368 48.9215C10.7281 50.1091 12.1766 51.1709 13.7214 52.0884L14.6088 50.4785L11.5652 45.0435Z"
        fill="#A2001D"
      />
      <path
        d="M17.0435 35.3044V53.7738C20.4088 55.2063 24.1115 56 28 56C40.039 56 50.3022 48.4015 54.2584 37.7391L17.0435 35.3044Z"
        fill="#6DA544"
      />
      <path
        d="M54.2584 37.7391C55.384 34.7058 56 31.425 56 28C56 12.5361 43.4639 0 28 0C24.1118 0 20.4088 0.793297 17.0435 2.22556V37.7392H54.2584V37.7391Z"
        fill="#A2001D"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15118">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBelarus;

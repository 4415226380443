import { IIconProps } from '../interface';

const SvgCameroon = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1739 2.77834C36.4928 0.998375 32.363 0 28 0C23.6371 0 19.5073 0.998375 15.8262 2.77834L13.3914 28L15.8262 53.2217C19.5073 55.0016 23.6371 56 28 56C32.363 56 36.4928 55.0016 40.1739 53.2217L42.6087 28L40.1739 2.77834Z"
      fill="#D80027"
    />
    <path
      d="M27.9998 18.2609L30.417 25.7005H38.2403L31.9115 30.2991L34.3288 37.7391L27.9998 33.1409L21.6708 37.7391L24.0885 30.2991L17.7596 25.7005H25.5825L27.9998 18.2609Z"
      fill="#FFDA44"
    />
    <path
      d="M15.8261 2.77933C6.46034 7.30811 0 16.8991 0 28C0 39.1009 6.46034 48.6919 15.8261 53.2207V2.77933Z"
      fill="#496E2D"
    />
    <path
      d="M40.1738 2.77933V53.2207C49.5396 48.6919 56 39.1008 56 28C56 16.8992 49.5396 7.30811 40.1738 2.77933Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgCameroon;

import { IIconProps } from '../interface';

const SvgRapaNui = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#FCFCFC"
    />
    <path
      d="M36.5217 23.1304V25.5652H39.8634C38.4688 27.5033 36.6113 29.0855 34.4531 30.151C33.8995 29.5758 33.1221 29.2173 32.2608 29.2173C30.8089 29.2173 29.5952 30.2344 29.2913 31.5949C28.8658 31.6323 28.4352 31.6521 28 31.6521C27.5648 31.6521 27.1342 31.6321 26.7087 31.5949C26.4048 30.2345 25.1912 29.2173 23.7392 29.2173C22.8779 29.2173 22.1008 29.5758 21.5469 30.151C19.3887 29.0857 17.5313 27.5032 16.1368 25.5652H19.4783V23.1304H10.9565C10.9565 32.5431 18.5873 40.1739 28 40.1739C37.4129 40.1739 45.0435 32.5431 45.0435 23.1304H36.5217Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgRapaNui;

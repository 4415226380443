import { IIconProps } from '../interface';

const SvgContract = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            strokeLinecap="round"
            strokeWidth="1.5"
            stroke={color}
            fill="none"
            strokeLinejoin="round"
          >
            <path d="M18,8v-3c0,-1.105 -0.895,-2 -2,-2h-11c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h3"></path>
            <path d="M7.5,17v0c-0.828,0 -1.5,-0.672 -1.5,-1.5v0c0,-0.828 0.672,-1.5 1.5,-1.5v0c0.828,0 1.5,0.672 1.5,1.5v0c0,0.828 -0.672,1.5 -1.5,1.5Z"></path>
            <path d="M21,19.586v-0.865c0,-0.43 -0.275,-0.813 -0.684,-0.949l-2.316,-0.772v-2l0.697,-0.446c0.513,-0.329 0.752,-0.955 0.587,-1.542v0c-0.168,-0.599 -0.713,-1.012 -1.335,-1.012h-2.898c-0.621,0 -1.167,0.413 -1.335,1.012v0c-0.165,0.587 0.074,1.213 0.587,1.542l0.697,0.446v2l-2.316,0.772c-0.409,0.136 -0.684,0.518 -0.684,0.949v0.865c0,0.265 0.105,0.52 0.293,0.707l0.414,0.414c0.188,0.188 0.442,0.293 0.707,0.293h6.172c0.265,0 0.52,-0.105 0.707,-0.293l0.414,-0.414c0.188,-0.188 0.293,-0.442 0.293,-0.707Z"></path>
            <path d="M6,7h8"></path>
            <path d="M6,10.5h4"></path>
          </g>
          <path fill="none" d="M0,0h24v24h-24v-24Z"></path>
        </svg>
      );
  }
};

export default SvgContract;

import { Suspense } from 'react';
import Loading from '../Loading';

const SuspenseLoading = ({
  children,
  variant,
}: {
  children: any;
  variant: 'FULL' | 'APP';
}) => {
  return (
    <Suspense fallback={<Loading variant={variant} />}>{children}</Suspense>
  );
};

export default SuspenseLoading;

import { IIconProps } from '../interface';

const SvgCostaRica = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M54.2584 18.2609H1.74158C0.616109 21.2942 0 24.575 0 28C0 31.425 0.616109 34.7058 1.74158 37.7391H54.2585C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2942 54.2584 18.2609Z"
      fill="#D80027"
    />
    <path
      d="M28 0C19.5134 0 11.9094 3.77606 6.77454 9.73908H49.2253C44.0906 3.77606 36.4865 0 28 0Z"
      fill="#0052B4"
    />
    <path
      d="M49.2254 46.2609H6.77454C11.9094 52.2239 19.5134 56 28 56C36.4865 56 44.0906 52.2239 49.2254 46.2609Z"
      fill="#0052B4"
    />
  </svg>
);

export default SvgCostaRica;

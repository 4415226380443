import { IIconProps } from '../interface';

const SvgSierraLeone = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.2584 37.7391C55.384 34.7058 56 31.425 56 28C56 24.575 55.384 21.2942 54.2584 18.2609L28 15.8261L1.74158 18.2609C0.616 21.2942 0 24.575 0 28C0 31.425 0.616 34.7058 1.74158 37.7391L28 40.1739L54.2584 37.7391Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 56C40.0389 56 50.3022 48.4015 54.2583 37.7391H1.74146C5.69777 48.4015 15.9609 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M28 0C15.9609 0 5.69777 7.5985 1.74146 18.2609H54.2584C50.3022 7.5985 40.0389 0 28 0Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgSierraLeone;

import { IIconProps } from '../interface';

const SvgTogo = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16800)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#FFDA44"
      />
      <path
        d="M28.0001 0C27.9797 0.00021875 26.7827 11.2 26.7827 11.2H50.4014C45.293 4.39939 37.1604 0 28.0001 0Z"
        fill="#496E2D"
      />
      <path
        d="M28.0001 56C37.1605 56 45.293 51.6006 50.4013 44.8H5.59888C10.7071 51.6006 18.8397 56 28.0001 56Z"
        fill="#496E2D"
      />
      <path
        d="M55.4394 22.4H26.7827L28.0001 33.6H55.4394C55.8069 31.7906 56.0001 29.9179 56.0001 28C56.0001 26.0821 55.8067 24.2094 55.4394 22.4Z"
        fill="#496E2D"
      />
      <path
        d="M28 33.6C28 27.7565 28 6.57387 28 0C12.5361 0 0 12.5361 0 28C0 29.9179 0.193375 31.7906 0.560656 33.6H28Z"
        fill="#D80027"
      />
      <path
        d="M15.4499 13.3913L17.2629 18.9711H23.1303L18.3837 22.42L20.1965 28L15.4499 24.5514L10.7032 28L12.5163 22.42L7.76978 18.9711H13.637L15.4499 13.3913Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16800">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTogo;

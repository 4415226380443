import { IIconProps } from '../interface';

const SvgLoadingCheck = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41120)">
        <path
          d="M4.67 17.209C5.25408 18.0291 5.97093 18.7459 6.791 19.33"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.135 10.5C2.95498 11.4919 2.95498 12.5081 3.135 13.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.67 6.79095C5.25378 5.97034 5.97067 5.25312 6.791 4.66895"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 20.865C15.396 21.7227 20.0603 18.449 20.918 13.553C21.7757 8.657 18.502 3.9927 13.606 3.135C12.5785 2.955 11.5275 2.955 10.5 3.135"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.512 10.707L11.373 13.846L9.48599 11.965"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41120">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgLoadingCheck;

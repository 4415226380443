import { IIconProps } from '../interface';

const SvgChess = ({ width = 24, height = 24, color, type }: IIconProps) => {
  const Icon = () => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="3.25" stroke={color} strokeWidth="1.5" />
      </svg>
    );
  };
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return <Icon />;
    case 'md':
      console.warn('Invalid option for this icon');
      return <Icon />;
    case 'sm':
      console.warn('Invalid option for this icon');
      return <Icon />;
    case 'filled':
      console.warn('Invalid option for this icon');
      return <Icon />;
    default:
      return <Icon />;
  }
};

export default SvgChess;

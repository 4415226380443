import { IIconProps } from '../interface';

const SvgTrailing = ({
  width = 16,
  height = 16,
  color = '#0E1FC1',
  type,
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3481_15524)">
        <path
          d="M4.88672 11.1133L8.00005 8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1133 4.88672L8 8.00005"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.27199 8.72799V8.72799C6.30096 7.75734 4.72702 7.75734 3.75599 8.72799L2.72799 9.75599V9.75599C1.75734 10.727 1.75734 12.301 2.72799 13.272V13.272C3.69902 14.2426 5.27296 14.2426 6.24399 13.272L7.27199 12.244V12.244C7.84793 11.6677 8.10412 10.8454 7.95732 10.044"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0427 7.95752V7.95752C10.844 8.10431 11.6664 7.84813 12.2427 7.27219L13.2707 6.24419V6.24419C14.2416 5.27327 14.2416 3.6991 13.2707 2.72818C12.2997 1.75727 10.7256 1.75727 9.75466 2.72819L8.72799 3.75619H8.72799C7.75734 4.72721 7.75734 6.30116 8.72799 7.27219"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3481_15524">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgTrailing;

import { IIconProps } from '../interface';

const SvgSolomonIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16616)">
      <path
        d="M47.7997 47.7997C58.3009 37.2986 58.7156 20.533 49.048 9.53442L28 27.9998L9.53442 49.048C20.533 58.7156 37.2985 58.3009 47.7997 47.7997Z"
        fill="#496E2D"
      />
      <path
        d="M8.20021 8.20023C18.7014 -2.30094 35.4669 -2.71558 46.4655 6.95194L27.9999 28.0002L6.95203 46.4655C-2.71561 35.4669 -2.30086 18.7014 8.20021 8.20023Z"
        fill="#0052B4"
      />
      <path
        d="M46.4654 6.95187L6.9519 46.4655C7.35134 46.9199 7.76609 47.3658 8.2002 47.7996C8.6342 48.2336 9.08012 48.6484 9.53468 49.048L49.048 9.53443C48.6485 9.07987 48.2337 8.63406 47.7996 8.19995C47.3658 7.76617 46.9199 7.35131 46.4654 6.95187Z"
        fill="#FFDA44"
      />
      <path
        d="M11.7972 9.73828L12.4017 11.5984H14.3574L12.7752 12.7478L13.3795 14.608L11.7972 13.4583L10.2149 14.608L10.8194 12.7478L9.23706 11.5984H11.1929L11.7972 9.73828Z"
        fill="#F0F0F0"
      />
      <path
        d="M21.7874 9.73828L22.3919 11.5984H24.3476L22.7654 12.7478L23.3698 14.608L21.7874 13.4583L20.2051 14.608L20.8096 12.7478L19.2273 11.5984H21.183L21.7874 9.73828Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.7972 19.4778L12.4017 21.3379H14.3574L12.7752 22.4875L13.3795 24.3476L11.7972 23.1979L10.2149 24.3476L10.8194 22.4875L9.23706 21.3379H11.1929L11.7972 19.4778Z"
        fill="#F0F0F0"
      />
      <path
        d="M21.7874 19.4778L22.3919 21.3379H24.3476L22.7654 22.4875L23.3698 24.3476L21.7874 23.1979L20.2051 24.3476L20.8096 22.4875L19.2273 21.3379H21.183L21.7874 19.4778Z"
        fill="#F0F0F0"
      />
      <path
        d="M16.7924 14.608L17.3969 16.4681H19.3526L17.7703 17.6176L18.3747 19.4778L16.7924 18.3281L15.2101 19.4778L15.8145 17.6176L14.2322 16.4681H16.188L16.7924 14.608Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16616">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSolomonIslands;

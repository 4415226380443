import { IIconProps } from '../interface';

const SvgCocosIsland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#6DA544"
    />
    <path
      d="M42.9826 40.1739L43.751 41.7805L45.4861 41.3795L44.7091 42.982L46.1044 44.0884L44.3671 44.48L44.372 46.2609L42.9826 45.1467L41.5934 46.2609L41.5982 44.48L39.861 44.0884L41.2563 42.982L40.4793 41.3795L42.2143 41.7805L42.9826 40.1739Z"
      fill="#FFDA44"
    />
    <path
      d="M35.8348 23.1304L36.6033 24.737L38.3384 24.3359L37.5613 25.9385L38.9566 27.0449L37.2194 27.4365L37.2242 29.2174L35.8348 28.1033L34.4455 29.2174L34.4502 27.4365L32.7131 27.0449L34.1084 25.9385L33.3313 24.3359L35.0666 24.737L35.8348 23.1304Z"
      fill="#FFDA44"
    />
    <path
      d="M42.9826 13.3913L43.751 14.9979L45.4861 14.5967L44.7092 16.1993L46.1044 17.3059L44.3671 17.6973L44.3718 19.4783L42.9826 18.3641L41.5934 19.4783L41.5982 17.6973L39.8611 17.3059L41.2563 16.1993L40.4793 14.5967L42.2143 14.9979L42.9826 13.3913Z"
      fill="#FFDA44"
    />
    <path
      d="M49.226 20.6956L49.9944 22.3022L51.7296 21.9011L50.9525 23.5037L52.3478 24.6101L50.6106 25.0017L50.6154 26.7827L49.226 25.6685L47.8368 26.7827L47.8415 25.0017L46.1044 24.6101L47.4997 23.5037L46.7226 21.9011L48.4577 22.3022L49.226 20.6956Z"
      fill="#FFDA44"
    />
    <path
      d="M44.7616 29.2174L45.3661 31.0775H47.3218L45.7395 32.227L46.344 34.0869L44.7616 32.9375L43.1795 34.0869L43.7838 32.227L42.2015 31.0775H44.1573L44.7616 29.2174Z"
      fill="#FFDA44"
    />
    <path
      d="M31.0435 35.9131C26.6732 35.9131 23.1304 32.3702 23.1304 28C23.1304 23.6298 26.6731 20.0869 31.0435 20.0869C32.4061 20.0869 33.6883 20.4316 34.8077 21.0381C33.0516 19.3207 30.6503 18.2608 27.9998 18.2608C22.6212 18.2608 18.2609 22.6212 18.2609 27.9999C18.2609 33.3786 22.6212 37.739 27.9998 37.739C30.6502 37.739 33.0516 36.6791 34.8076 34.9617C33.6883 35.5684 32.4061 35.9131 31.0435 35.9131Z"
      fill="#FFDA44"
    />
    <path
      d="M17.6078 10.9565C16.7656 9.50141 15.1934 8.52173 13.3913 8.52173C11.5893 8.52173 10.017 9.50141 9.1748 10.9565H17.6078Z"
      fill="#FFDA44"
    />
    <path
      d="M17.624 10.9846L13.3913 15.2173L9.15863 10.9846C8.75405 11.6946 8.52185 12.5157 8.52185 13.3913C8.52185 16.0807 10.702 18.2609 13.3914 18.2609C16.0809 18.2609 18.261 16.0809 18.261 13.3913C18.2609 12.5157 18.0286 11.6946 17.624 10.9846Z"
      fill="#FFDA44"
    />
    <path
      d="M12.1738 14V18.1075C12.5629 18.2075 12.9708 18.2608 13.3912 18.2608C13.8115 18.2608 14.2195 18.2074 14.6085 18.1075V14H12.1738Z"
      fill="#A2001D"
    />
  </svg>
);

export default SvgCocosIsland;

import React, { ReactElement } from 'react';
import Typography, { variantList } from '../Typography';

interface IToottip {
  label?: string;
  children?: any;
  icon?: ReactElement | Element;
  text: string;
  transparent?: boolean;
  variant?: variantList;
  autoWidthContent?: boolean;
  className?: string;
}

const Tooltip = ({
  children,
  label,
  icon,
  text,
  transparent,
  variant,
  autoWidthContent,
  className,
}: IToottip) => {
  return (
    <button
      className={`relative cursor-default inline-flex justify-center ${
        transparent ? null : 'bg-blue-100 text-blue-800'
      } ${className}`}
      onClick={(e) => e.preventDefault()}
    >
      <div
        className={`peer tooltip flex-1 ${
          transparent ? null : 'py-spacing-2 px-spacing-8'
        }`}
      >
        {icon && icon}
        {label && (
          <Typography
            variant={`${variant ? variant : 'BodyDefault500'}`}
            color="blue800"
          >
            {label}
          </Typography>
        )}
        {!!children && children}
      </div>
      <div
        className={`tooltipContent ${
          autoWidthContent ? null : 'w-spacing-192'
        }`}
      >
        <div className="arrow-up"></div>
        <Typography variant="BodyDefault500" color="white">
          {text}
        </Typography>
      </div>
    </button>
  );
};

export default Tooltip;

import { IIconProps } from '../interface';

const SvgCashBankNoteCoins = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_40760)">
        <path
          d="M15 18H18C18.7956 18 19.5587 17.6839 20.1213 17.1213C20.6839 16.5587 21 15.7956 21 15V7C21 6.20435 20.6839 5.44129 20.1213 4.87868C19.5587 4.31607 18.7956 4 18 4H6C5.20435 4 4.44129 4.31607 3.87868 4.87868C3.31607 5.44129 3 6.20435 3 7V9"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 15H17"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 7H6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7677 12.7678C14.2366 12.2989 14.5 11.663 14.5 11C14.5 10.337 14.2366 9.70108 13.7677 9.23223C13.2989 8.76339 12.663 8.5 11.9999 8.5C11.3369 8.5 10.701 8.76339 10.2322 9.23223"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 19C4.80777 19 4.13108 18.7947 3.55551 18.4101C2.97993 18.0256 2.53133 17.4789 2.26642 16.8394C2.00152 16.1999 1.9322 15.4961 2.06725 14.8172C2.2023 14.1383 2.53564 13.5146 3.02513 13.0251C3.51461 12.5356 4.13825 12.2023 4.81719 12.0673C5.49612 11.9322 6.19985 12.0015 6.83939 12.2664C7.47894 12.5313 8.02556 12.9799 8.41015 13.5555C8.79473 14.1311 9 14.8078 9 15.5C9.00009 15.9597 8.90961 16.4148 8.73375 16.8395C8.55789 17.2642 8.30008 17.65 7.97506 17.9751C7.65004 18.3001 7.26417 18.5579 6.83949 18.7338C6.41482 18.9096 5.95965 19.0001 5.5 19Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.34497 18.9921C5.60797 19.5522 6.01504 20.0324 6.52453 20.3835C7.03402 20.7347 7.62765 20.9442 8.24468 20.9906C8.86172 21.0371 9.48002 20.9188 10.0363 20.6479C10.5926 20.3769 11.067 19.9631 11.4109 19.4486C11.7548 18.9342 11.9558 18.3377 11.9935 17.72C12.0312 17.1024 11.9041 16.4859 11.6253 15.9334C11.3465 15.381 10.9259 14.9126 10.4067 14.5761C9.88742 14.2395 9.28808 14.047 8.66997 14.0181"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_40760">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCashBankNoteCoins;

import { IIconProps } from '../interface';

const SvgTrinidadAndTobago = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16826)">
      <path
        d="M15.1611 3.1091C12.6544 4.4029 10.3014 6.09876 8.2001 8.20007C6.09868 10.3015 4.40282 12.6545 3.10913 15.1611L21.113 34.8868L40.8387 52.8907C43.3455 51.5969 45.6984 49.901 47.7998 47.7998C49.9012 45.6984 51.5969 43.3454 52.8907 40.8388L34.8868 21.113L15.1611 3.1091Z"
        fill="#F0F0F0"
      />
      <path
        d="M47.7999 47.7998C48.9745 46.6253 50.022 45.3719 50.9443 44.0574L11.9428 5.05576C10.6282 5.9779 9.37491 7.02549 8.20033 8.20007C7.02575 9.37465 5.97816 10.628 5.05591 11.9427L44.0574 50.944C45.3719 50.0219 46.6253 48.9744 47.7999 47.7998Z"
        fill="black"
      />
      <path
        d="M8.20005 47.7998C17.0339 56.6337 30.3008 58.3294 40.8386 52.8907L3.10919 15.1611C-2.32948 25.6989 -0.633399 38.9659 8.20005 47.7998Z"
        fill="#D80027"
      />
      <path
        d="M47.7997 8.20016C38.9658 -0.633617 25.699 -2.32937 15.1611 3.10909L52.8906 40.8389C58.3294 30.301 56.6336 17.0339 47.7997 8.20016Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16826">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTrinidadAndTobago;

import { IIconProps } from '../interface';

const EmptyIcon = ({ width = 56, height = 56 }: IIconProps) => {
  return (
    <div
      className="rounded-full bg-base-gray25 border-1 border-solid border-base-gray500"
      style={{ width, height: width }}
    ></div>
  );
};

export default EmptyIcon;

import { IIconProps } from '../interface';

const SvgPenEdit = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2227_40552)">
        <path
          d="M19 12H20C20.552 12 21 12.448 21 13V19C21 19.552 20.552 20 20 20H4C3.448 20 3 19.552 3 19V13C3 12.448 3.448 12 4 12H5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 6L18 9"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.707 4.29301L9.241 11.759C9.053 11.947 8.948 12.201 8.948 12.466V15H11.585C11.85 15 12.105 14.895 12.292 14.707L19.706 7.29301C20.097 6.90201 20.097 6.26901 19.706 5.87901L18.12 4.29301C17.731 3.90201 17.098 3.90201 16.707 4.29301Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2227_40552">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgPenEdit;

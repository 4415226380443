import { IIconProps } from '../interface';

const SvgGreenland = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28C54.7827 28 28 31.6521 28 31.6521L0 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28"
      fill="#D80027"
    />
    <path
      d="M19.4782 41.3913C26.8741 41.3913 32.8696 35.3958 32.8696 28C32.8696 20.6042 26.8741 14.6087 19.4782 14.6087C12.0824 14.6087 6.08691 20.6042 6.08691 28C6.08691 35.3958 12.0824 41.3913 19.4782 41.3913Z"
      fill="#F0F0F0"
    />
    <path
      d="M6.08691 28C6.08691 20.6044 12.0824 14.6087 19.4782 14.6087C26.8742 14.6087 32.8696 20.6043 32.8696 28"
      fill="#D80027"
    />
  </svg>
);

export default SvgGreenland;

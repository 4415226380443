import { IIconProps } from '../interface';

const SvgMongolia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C31.425 56 34.7059 55.384 37.7391 54.2584L38.9565 28L37.7392 1.74158C34.7059 0.616109 31.425 0 28 0C24.575 0 21.2941 0.616109 18.2609 1.74158L17.0435 28L18.2608 54.2584C21.2941 55.384 24.575 56 28 56Z"
      fill="#0052B4"
    />
    <path
      d="M0 28C0 40.039 7.5985 50.3022 18.2609 54.2584V1.74158C7.5985 5.69778 0 15.961 0 28Z"
      fill="#A2001D"
    />
    <path
      d="M37.7391 1.74158V54.2585C48.4016 50.3022 56.0001 40.039 56.0001 28C56.0001 15.961 48.4016 5.69778 37.7391 1.74158Z"
      fill="#A2001D"
    />
    <path d="M13.3912 28H15.826V37.7391H13.3912V28Z" fill="#FFDA44" />
    <path d="M3.6521 28H6.0869V37.7391H3.6521V28Z" fill="#FFDA44" />
    <path
      d="M9.739 34.0869C11.0837 34.0869 12.1738 32.9968 12.1738 31.6521C12.1738 30.3074 11.0837 29.2173 9.739 29.2173C8.39429 29.2173 7.3042 30.3074 7.3042 31.6521C7.3042 32.9968 8.39429 34.0869 9.739 34.0869Z"
      fill="#FFDA44"
    />
    <path
      d="M9.73907 24.3477C10.4114 24.3477 10.9564 23.8027 10.9564 23.1304C10.9564 22.4581 10.4114 21.9131 9.73907 21.9131C9.06675 21.9131 8.52173 22.4581 8.52173 23.1304C8.52173 23.8027 9.06675 24.3477 9.73907 24.3477Z"
      fill="#FFDA44"
    />
    <path
      d="M7.30432 35.3044H12.1739V37.7392H7.30432V35.3044Z"
      fill="#FFDA44"
    />
    <path d="M7.30432 25.5652H12.1739V28H7.30432V25.5652Z" fill="#FFDA44" />
    <path
      d="M9.73905 14.6087L10.6014 17.2621H13.3913L11.1344 18.9021L11.996 21.5555L9.73905 19.9155L7.48221 21.5555L8.34387 18.9021L6.08691 17.2621H8.87685L9.73905 14.6087Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgMongolia;

import { IIconProps } from '../interface';

const SvgWeather3 = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_40575)">
        <path
          d="M8.38389 14.387C8.89889 14.387 9.38389 14.493 9.80789 14.706C10.1259 12.61 11.9449 11 14.1259 11C16.5049 11 18.4439 12.914 18.5049 15.283C19.9289 15.465 21.0349 16.68 21.0349 18.184C21.0349 19.794 19.7319 21.1 18.1259 21.1H8.38389C6.53589 21.1 5.03589 19.596 5.03589 17.744C5.03589 15.89 6.53589 14.387 8.38389 14.387Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.96 2.8V2"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0601 4.91009L16.6201 4.34009"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.95996 10H3.75996"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.31006 4.34009L5.87006 4.91009"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7929 7.172C13.0689 6.448 12.0689 6 10.9639 6C8.75487 6 6.96387 7.791 6.96387 10"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_40575">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgWeather3;

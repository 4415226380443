import { IIconProps } from '../interface';

const SvgMelilla = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M13.3914 30.4348H42.6087V34.0869H13.3914V30.4348Z"
      fill="#818085"
    />
    <path
      d="M18.261 19.4783L28.0001 20.6958L37.7391 19.4783V13.6348L33.8435 15.5827L28.0001 9.73907L22.1566 15.5827L18.261 13.6348V19.4783Z"
      fill="#FFDA44"
    />
    <path
      d="M15.8261 37.7393H12.1738V31.0436L13.3913 29.2174L12.1738 27.3913V23.1305H15.8261V37.7393Z"
      fill="#F0F0F0"
    />
    <path
      d="M43.8261 37.7393H40.1738V23.1305H43.8261V27.3912L42.6086 29.248L43.8261 31.0438V37.7393Z"
      fill="#F0F0F0"
    />
    <path d="M12.1738 27.3913H43.826V31.0435H12.1738V27.3913Z" fill="#ACABB1" />
    <path
      d="M28.0001 42.6087C22.6299 42.6087 18.261 38.2397 18.261 32.8696V19.4784H37.7393V32.8696C37.7391 38.2397 33.3703 42.6087 28.0001 42.6087Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 38.9565C24.6436 38.9565 21.9131 36.2259 21.9131 32.8696V23.1306H34.087V32.8696C34.087 36.2259 31.3564 38.9565 28 38.9565Z"
      fill="#0052B4"
    />
    <path d="M18.261 19.4783H21.9131V23.1304H18.261V19.4783Z" fill="#D80027" />
    <path
      d="M34.0869 19.4783H37.7391V23.1304H34.0869V19.4783Z"
      fill="#D80027"
    />
    <path
      d="M34.0869 27.3913H37.7391V31.0435H34.0869V27.3913Z"
      fill="#D80027"
    />
    <path d="M18.261 27.3913H21.9131V31.0435H18.261V27.3913Z" fill="#D80027" />
    <path d="M26.1738 19.4783H29.826V23.1304H26.1738V19.4783Z" fill="#D80027" />
    <path
      d="M28 38.9566C27.3638 38.9566 26.7506 38.8581 26.1738 38.6763V42.4346C26.7658 42.5474 27.3756 42.6088 28 42.6088C28.6243 42.6088 29.2341 42.5474 29.8261 42.4346V38.6763C29.2493 38.858 28.6361 38.9566 28 38.9566Z"
      fill="#D80027"
    />
    <path
      d="M21.9945 33.8577L19.0798 36.7723C19.5851 37.9228 20.3061 38.9574 21.1923 39.8248L23.7736 37.2435C22.8524 36.3535 22.2107 35.1766 21.9945 33.8577Z"
      fill="#D80027"
    />
    <path
      d="M36.9202 36.7725L34.0055 33.8579C33.7892 35.1768 33.1476 36.3535 32.2266 37.2438L34.8078 39.8251C35.6938 38.9576 36.4149 37.923 36.9202 36.7725Z"
      fill="#D80027"
    />
  </svg>
);

export default SvgMelilla;

const partnerinfo = {
  inactive: {
    title: 'Complete your advisor profile',
    text: 'Welcome to your advisor profile. Take a moment to introduce yourself. Share your experience,\nqualifications, and expertise in the tax advisory field, so potential clients can get to know you better\nwhen seeking your services.',
  },
  active: {
    title: 'Update your advisor profile',
    text: 'It looks like you want to make changes to your advisor profile. Here you can update your experience,\nqualifications, and expertise in the tax advisory field to ensure potential clients have the most accurate\ninformation.',
  },
  profile_pic: 'Profile pic',
  first_name: 'First name',
  enter_first_name: 'Enter first name',
  invalid_first_name: 'Invalid first name',
  last_name: 'Last name',
  enter_last_name: 'Enter last name',
  invalid_last_name: 'Invalid last name',
  bio: 'Short professional bio',
  enter_bio: 'Short professional bio',
  invalid_bio: 'Invalid short professional bio',
  languages: 'Languages',
  add_more_languages: 'Add more languages',
  invalid_languages: 'Invalid languages',
  countries: 'Countries of expertise',
  invalid_countries: 'Invalid countries of expertise',
  thank_you: {
    title: 'Thank you, all done!',
    inactive:
      'Do you notice a mistake or omission after submission? You can always click the blue button in the bottom left corner to update your profile.',
    active:
      'You can always click the blue button in the bottom left corner to make further changes whenever needed.',
    go_advices: 'Go to advices',
  },
  novuToggleText: 'I want to receive email notification about client activities'
};

export default partnerinfo;

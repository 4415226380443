import { IIconProps } from '../interface';

const SvgLibya = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.77834 15.8261C0.998375 19.5072 0 23.6369 0 28C0 32.3631 0.998375 36.4927 2.77834 40.1739L28 42.6087L53.2217 40.1739C55.0016 36.4927 56 32.3631 56 28C56 23.6369 55.0016 19.5072 53.2217 15.8261L28 13.3913L2.77834 15.8261Z"
      fill="black"
    />
    <path
      d="M2.7793 40.1739C7.30808 49.5398 16.8991 56 28 56C39.1008 56 48.6919 49.5398 53.2206 40.1739H2.7793Z"
      fill="#496E2D"
    />
    <path
      d="M2.7793 15.8261H53.2206C48.6919 6.46023 39.1008 0 28 0C16.8991 0 7.30808 6.46023 2.7793 15.8261Z"
      fill="#D80027"
    />
    <path
      d="M34.5172 22.8797L36.8145 26.0456L40.5351 24.8392L38.2341 28.0023L40.5312 31.168L36.812 29.957L34.5109 33.1202L34.5134 29.2088L30.7939 27.9978L34.5147 26.7914L34.5172 22.8797Z"
      fill="#F0F0F0"
    />
    <path
      d="M28.2473 35.9131C23.8771 35.9131 20.3342 32.3702 20.3342 28C20.3342 23.6298 23.8771 20.0869 28.2473 20.0869C29.6099 20.0869 30.8921 20.4315 32.0114 21.0381C30.2553 19.3208 27.8538 18.2608 25.2038 18.2608C19.825 18.2608 15.4647 22.6211 15.4647 27.9999C15.4647 33.3786 19.8251 37.739 25.2038 37.739C27.854 37.739 30.2554 36.679 32.0114 34.9617C30.892 35.5685 29.61 35.9131 28.2473 35.9131Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgLibya;

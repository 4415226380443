import { IIconProps } from '../interface';

const SvgKiribati = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15942)">
      <path
        d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28"
        fill="#D80027"
      />
      <path
        d="M37.739 26.0398L33.7594 27.9115L35.8787 31.7658L31.5574 30.939L31.0098 35.3044L27.9999 32.0937L24.9899 35.3044L24.4425 30.939L20.1212 31.7655L22.2404 27.9115L18.2609 26.0398L22.2404 24.1677L20.1212 20.3139L24.4424 21.1403L24.9901 16.7751L27.9999 19.9856L31.0099 16.7751L31.5574 21.1403L35.8788 20.3139L33.7597 24.168L37.739 26.0398Z"
        fill="#FFDA44"
      />
      <path
        d="M35.3043 9.13039H29.8261C29.8261 8.12206 29.0084 7.30426 27.9999 7.30426C26.9915 7.30426 26.1738 8.12195 26.1738 9.13039H20.6956C20.6956 10.1388 21.5741 10.9565 22.5825 10.9565H22.5216C22.5216 11.9649 23.339 12.7826 24.3477 12.7826C24.3477 13.7911 25.1652 14.6088 26.1738 14.6088H29.826C30.8343 14.6088 31.6521 13.7912 31.6521 12.7826C32.6604 12.7826 33.4782 11.9651 33.4782 10.9565H33.4173C34.4258 10.9565 35.3043 10.1389 35.3043 9.13039Z"
        fill="#FFDA44"
      />
      <path
        d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28H56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 56C36.7525 56 44.5655 51.9833 49.7003 45.6938C48.7767 45.0641 48.2781 44.0463 46.6663 44.0463C44.333 44.0463 44.333 46.1797 41.9998 46.1797C39.6666 46.1797 39.6665 44.0463 37.3333 44.0463C35 44.0463 35 46.1797 32.6666 46.1797C30.3332 46.1797 30.3331 44.0463 27.9996 44.0463C25.6663 44.0463 25.6663 46.1797 23.3331 46.1797C21.0002 46.1797 21.0002 44.0463 18.6669 44.0463C16.3333 44.0463 16.3333 46.1797 13.9998 46.1797C11.6663 46.1797 11.6663 44.0463 9.3328 44.0463C7.72127 44.0463 7.22262 45.0639 6.2995 45.6936C11.434 51.9833 19.2475 56 28 56Z"
        fill="#0052B4"
      />
      <path
        d="M51.333 31.5711C48.9997 31.5711 48.9997 29.4376 46.6663 29.4376C44.333 29.4376 44.333 31.5711 41.9998 31.5711C39.6666 31.5711 39.6665 29.4376 37.3333 29.4376C35 29.4376 35 31.5711 32.6666 31.5711C30.3332 31.5711 30.3331 29.4376 27.9996 29.4376C25.6663 29.4376 25.6663 31.5711 23.3331 31.5711C21.0001 31.5711 21.0001 29.4376 18.6668 29.4376C16.3333 29.4376 16.3333 31.5711 13.9998 31.5711C11.6663 31.5711 11.6663 29.4376 9.33277 29.4376C6.9998 29.4376 6.9998 31.5711 4.6665 31.5711C2.34578 31.5711 2.33233 29.4611 0.036438 29.4386C0.101079 30.7194 0.253985 31.9768 0.485204 33.2064C2.34502 33.4797 2.50788 35.3044 4.66661 35.3044C6.99991 35.3044 6.99991 33.1711 9.33287 33.1711C11.6664 33.1711 11.6664 35.3044 13.9999 35.3044C16.3334 35.3044 16.3334 33.1711 18.6669 33.1711C21.0002 33.1711 21.0002 35.3044 23.3332 35.3044C25.6665 35.3044 25.6665 33.1711 27.9997 33.1711C30.3332 33.1711 30.3332 35.3044 32.6667 35.3044C35.0002 35.3044 35.0002 33.1711 37.3334 33.1711C39.6667 33.1711 39.6667 35.3044 41.9999 35.3044C44.3331 35.3044 44.3332 33.1711 46.6664 33.1711C48.9999 33.1711 48.9999 35.3044 51.3331 35.3044C53.492 35.3044 53.6549 33.4798 55.5148 33.2065C55.7461 31.9768 55.8988 30.7195 55.9638 29.4387C53.6675 29.4614 53.6538 31.5711 51.333 31.5711Z"
        fill="#0052B4"
      />
      <path
        d="M46.6663 36.742C44.333 36.742 44.333 38.8755 41.9998 38.8755C39.6666 38.8755 39.6665 36.742 37.3333 36.742C35 36.742 35 38.8755 32.6666 38.8755C30.3331 38.8755 30.3331 36.742 27.9996 36.742C25.6663 36.742 25.6663 38.8755 23.3331 38.8755C21.0001 38.8755 21.0001 36.742 18.6668 36.742C16.3333 36.742 16.3333 38.8755 13.9998 38.8755C11.6663 38.8755 11.6663 36.742 9.33275 36.742C6.99978 36.742 6.99978 38.8755 4.66648 38.8755C2.99928 38.8755 2.52306 37.7865 1.53595 37.1644C2.19307 39.0631 3.04795 40.8692 4.0775 42.5575C4.25654 42.5905 4.45145 42.6088 4.66648 42.6088C6.99978 42.6088 6.99978 40.4755 9.33275 40.4755C11.6663 40.4755 11.6663 42.6088 13.9998 42.6088C16.3333 42.6088 16.3333 40.4755 18.6668 40.4755C21.0001 40.4755 21.0001 42.6088 23.3331 42.6088C25.6664 42.6088 25.6664 40.4755 27.9996 40.4755C30.3331 40.4755 30.3331 42.6088 32.6666 42.6088C35.0001 42.6088 35.0001 40.4755 37.3333 40.4755C39.6666 40.4755 39.6666 42.6088 41.9998 42.6088C44.333 42.6088 44.3331 40.4755 46.6663 40.4755C48.9998 40.4755 48.9998 42.6088 51.333 42.6088C51.5482 42.6088 51.7431 42.5905 51.9222 42.5574C52.9517 40.8692 53.8065 39.0631 54.4639 37.1645C53.4767 37.7863 53.0004 38.8755 51.333 38.8755C48.9998 38.8755 48.9998 36.742 46.6663 36.742Z"
        fill="#0052B4"
      />
      <path
        d="M55.9132 25.7879C53.6677 25.8409 53.6367 27.9189 51.333 27.9189C48.9997 27.9189 48.9997 25.7855 46.6663 25.7855C44.333 25.7855 44.333 27.9189 41.9998 27.9189C39.6666 27.9189 39.6665 25.7855 37.3333 25.7855C35 25.7855 35 27.9189 32.6666 27.9189C30.3332 27.9189 30.3331 25.7855 27.9996 25.7855C25.6663 25.7855 25.6663 27.9189 23.3331 27.9189C21.0001 27.9189 21.0001 25.7855 18.6668 25.7855C16.3333 25.7855 16.3333 27.9189 13.9998 27.9189C11.6663 27.9189 11.6663 25.7855 9.33275 25.7855C6.99978 25.7855 6.99978 27.9189 4.66648 27.9189C2.36316 27.9189 2.33198 25.8411 0.0867344 25.7879C0.02975 26.5178 0 27.2555 0 28C0 28.51 0.0144375 29.0167 0.0416719 29.52C2.33231 29.5457 2.34752 31.6521 4.66659 31.6521C6.99989 31.6521 6.99989 29.5189 9.33286 29.5189C11.6664 29.5189 11.6664 31.6521 13.9999 31.6521C16.3334 31.6521 16.3334 29.5189 18.6669 29.5189C21.0002 29.5189 21.0002 31.6521 23.3332 31.6521C25.6665 31.6521 25.6665 29.5189 27.9997 29.5189C30.3332 29.5189 30.3332 31.6521 32.6667 31.6521C35.0002 31.6521 35.0002 29.5189 37.3334 29.5189C39.6667 29.5189 39.6667 31.6521 41.9999 31.6521C44.3331 31.6521 44.3332 29.5189 46.6664 29.5189C48.9999 29.5189 48.9999 31.6521 51.3331 31.6521C53.6524 31.6521 53.6676 29.5457 55.9587 29.52C55.9856 29.0166 56 28.51 56 28C56 27.2555 55.9702 26.5178 55.9132 25.7879Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15942">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgKiribati;

import { IIconProps } from '../interface';

const SvgBotswana = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15184)">
      <path
        d="M1.32225 19.4781C0.464528 22.1654 1.27146e-05 25.0281 0.00012209 27.9999C1.27146e-05 30.9715 0.464528 33.8343 1.32236 36.5216L28.0001 37.7392L54.6778 36.5216C55.5354 33.8343 56 30.9714 56 28C56 25.0283 55.5355 22.1654 54.6778 19.4781L28 18.2608L1.32225 19.4781Z"
        fill="#F0F0F0"
      />
      <path
        d="M56 28C56 26.3389 55.8545 24.7119 55.577 23.1303H0.423063C0.145469 24.7119 0 26.3387 0 27.9999C0 29.6611 0.145578 31.2879 0.423063 32.8695H55.577C55.8544 31.2878 56 29.661 56 28Z"
        fill="black"
      />
      <path
        d="M28.0001 55.9999C40.4925 55.9999 51.0723 47.8183 54.6777 36.5215H1.32233C4.92787 47.8182 15.5078 55.9997 28.0001 55.9999Z"
        fill="#338AF3"
      />
      <path
        d="M28.0001 0.000106816C15.5078 0.000106816 4.92797 8.18168 1.3222 19.4781L54.6775 19.4783C51.0722 8.18168 40.4922 -0.000111934 28.0001 0.000106816Z"
        fill="#338AF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15184">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBotswana;

import { IIconProps } from '../interface';

const SvgDollarSend = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2213_41025)">
        <path
          d="M18 17L20 19L18 21"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 19H20"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.99 8.97484V8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.99 15V16"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2774 14.2856C10.4008 14.5004 10.5781 14.6792 10.7919 14.8044C11.0056 14.9297 11.2484 14.9969 11.4961 14.9995H12.5824C12.9276 15.0035 13.2621 14.88 13.5218 14.6525C13.7816 14.4251 13.9482 14.1098 13.9898 13.7671C14.0314 13.4244 13.9451 13.0784 13.7473 12.7955C13.5496 12.5125 13.2543 12.3125 12.9182 12.2338L11.0675 11.7628C10.7084 11.6751 10.3987 11.449 10.2056 11.1339C10.0125 10.8188 9.95183 10.4401 10.0367 10.0804C10.1064 9.77225 10.2793 9.4971 10.5267 9.30061C10.7742 9.10411 11.0813 8.99806 11.3973 9.00003H12.4837C12.7316 9.00229 12.9746 9.06937 13.1885 9.19461C13.4025 9.31985 13.58 9.4989 13.7033 9.71396"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.7676 14C20.9207 13.3443 20.9987 12.6733 21 12C21 10.22 20.4722 8.47991 19.4832 6.99987C18.4943 5.51983 17.0887 4.36628 15.4442 3.68509C13.7996 3.0039 11.99 2.82567 10.2442 3.17294C8.49836 3.5202 6.89472 4.37737 5.63604 5.63604C4.37737 6.89472 3.5202 8.49836 3.17294 10.2442C2.82567 11.99 3.0039 13.7996 3.68509 15.4442C4.36628 17.0887 5.51983 18.4943 6.99987 19.4832C8.47991 20.4722 10.22 21 12 21"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_41025">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgDollarSend;

import { IIconProps } from '../interface';

const SvgNiger = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.77834 15.8261C0.998375 19.5072 0 23.6369 0 28C0 32.3631 0.998375 36.4928 2.77834 40.1739L28 42.6087L53.2217 40.1739C55.0016 36.4928 56 32.3631 56 28C56 23.6369 55.0016 19.5072 53.2217 15.8261L28 13.3913L2.77834 15.8261Z"
      fill="#F0F0F0"
    />
    <path
      d="M2.7793 40.1739C7.30808 49.5398 16.8991 56 28 56C39.1009 56 48.6919 49.5398 53.2206 40.1739H2.7793Z"
      fill="#6DA544"
    />
    <path
      d="M2.7793 15.8261H53.2206C48.6919 6.46023 39.1008 0 28 0C16.8992 0 7.30808 6.46023 2.7793 15.8261Z"
      fill="#FF9811"
    />
    <path
      d="M28.0001 37.7391C33.3788 37.7391 37.7391 33.3787 37.7391 28C37.7391 22.6213 33.3788 18.2609 28.0001 18.2609C22.6213 18.2609 18.261 22.6213 18.261 28C18.261 33.3787 22.6213 37.7391 28.0001 37.7391Z"
      fill="#FF9811"
    />
  </svg>
);

export default SvgNiger;

import { IIconProps } from '../interface';

const SvgUserProfile = ({ width, height, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      return (
        <svg
          width={width || 32}
          height={height || 32}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1109 9.61091C14.2588 7.46303 17.7412 7.46303 19.8891 9.61091C22.037 11.7588 22.037 15.2412 19.8891 17.3891C17.7412 19.537 14.2588 19.537 12.1109 17.3891C9.96303 15.2412 9.96303 11.7588 12.1109 9.61091ZM17.7678 11.7322C16.7915 10.7559 15.2085 10.7559 14.2322 11.7322C13.2559 12.7085 13.2559 14.2915 14.2322 15.2678C15.2085 16.2441 16.7915 16.2441 17.7678 15.2678C18.7441 14.2915 18.7441 12.7085 17.7678 11.7322Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM21.2825 25.6509C19.7147 26.5109 17.9144 27 16 27C14.0879 27 12.2897 26.5121 10.7231 25.654C11.345 24.4199 12.8951 23 16 23C19.178 23 20.678 24.4119 21.2825 25.6509ZM23.7133 23.8425C22.4989 21.7903 20.0286 20 16 20C12.0519 20 9.53247 21.7894 8.29382 23.8495C6.26094 21.8535 5 19.074 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16C27 19.0704 25.742 21.8471 23.7133 23.8425Z"
            fill={color}
          />
        </svg>
      );
    case 'md':
      return (
        <svg
          width={width || 24}
          height={height || 24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.75C9.95445 3.75 8.267 5.45594 8.267 7.517C8.267 9.57068 9.9471 11.25 12 11.25C14.0456 11.25 15.733 9.54406 15.733 7.483C15.733 5.42932 14.0529 3.75 12 3.75ZM6.767 7.517C6.767 4.64206 9.11155 2.25 12 2.25C14.8811 2.25 17.233 4.60068 17.233 7.483C17.233 10.3579 14.8884 12.75 12 12.75C9.1189 12.75 6.767 10.3993 6.767 7.517ZM8 15.75C6.21421 15.75 4.75 17.2142 4.75 19V20C4.75 20.1358 4.86421 20.25 5 20.25H19C19.1358 20.25 19.25 20.1358 19.25 20V19C19.25 17.2142 17.7858 15.75 16 15.75H8ZM3.25 19C3.25 16.3858 5.38579 14.25 8 14.25H16C18.6142 14.25 20.75 16.3858 20.75 19V20C20.75 20.9642 19.9642 21.75 19 21.75H5C4.03579 21.75 3.25 20.9642 3.25 20V19Z"
            fill={color}
          />
        </svg>
      );
    case 'sm':
      return (
        <svg
          width={width || 24}
          height={height || 24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.75C9.95445 3.75 8.267 5.45594 8.267 7.517C8.267 9.57068 9.9471 11.25 12 11.25C14.0456 11.25 15.733 9.54406 15.733 7.483C15.733 5.42932 14.0529 3.75 12 3.75ZM6.767 7.517C6.767 4.64206 9.11155 2.25 12 2.25C14.8811 2.25 17.233 4.60068 17.233 7.483C17.233 10.3579 14.8884 12.75 12 12.75C9.1189 12.75 6.767 10.3993 6.767 7.517ZM8 15.75C6.21421 15.75 4.75 17.2142 4.75 19V20C4.75 20.1358 4.86421 20.25 5 20.25H19C19.1358 20.25 19.25 20.1358 19.25 20V19C19.25 17.2142 17.7858 15.75 16 15.75H8ZM3.25 19C3.25 16.3858 5.38579 14.25 8 14.25H16C18.6142 14.25 20.75 16.3858 20.75 19V20C20.75 20.9642 19.9642 21.75 19 21.75H5C4.03579 21.75 3.25 20.9642 3.25 20V19Z"
            fill={color}
          />
        </svg>
      );
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width || 24}
          height={height || 24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.75C9.95445 3.75 8.267 5.45594 8.267 7.517C8.267 9.57068 9.9471 11.25 12 11.25C14.0456 11.25 15.733 9.54406 15.733 7.483C15.733 5.42932 14.0529 3.75 12 3.75ZM6.767 7.517C6.767 4.64206 9.11155 2.25 12 2.25C14.8811 2.25 17.233 4.60068 17.233 7.483C17.233 10.3579 14.8884 12.75 12 12.75C9.1189 12.75 6.767 10.3993 6.767 7.517ZM8 15.75C6.21421 15.75 4.75 17.2142 4.75 19V20C4.75 20.1358 4.86421 20.25 5 20.25H19C19.1358 20.25 19.25 20.1358 19.25 20V19C19.25 17.2142 17.7858 15.75 16 15.75H8ZM3.25 19C3.25 16.3858 5.38579 14.25 8 14.25H16C18.6142 14.25 20.75 16.3858 20.75 19V20C20.75 20.9642 19.9642 21.75 19 21.75H5C4.03579 21.75 3.25 20.9642 3.25 20V19Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgUserProfile;

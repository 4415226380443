import { IIconProps } from '../interface';

const SvgSamoa = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C9.61734 18.3827 17.7739 10.2261 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#D80027"
    />
    <path
      d="M28 28C28 12.5361 28 10.7131 28 0C12.5361 0 0 12.5361 0 28H28Z"
      fill="#0052B4"
    />
    <path
      d="M22.4279 18.2609L22.8813 19.656H24.348L23.1613 20.518L23.6146 21.9131L22.4279 21.0509L21.2412 21.9131L21.6945 20.518L20.5078 19.656H21.9745L22.4279 18.2609Z"
      fill="#F0F0F0"
    />
    <path
      d="M15.0607 7.30439L15.8161 9.62959H18.2609L16.283 11.0662L17.0386 13.3913L15.0607 11.9542L13.0829 13.3913L13.8384 11.0662L11.8606 9.62959H14.3051L15.0607 7.30439Z"
      fill="#F0F0F0"
    />
    <path
      d="M22.3651 9.73907L23.1206 12.0644H25.5652L23.5874 13.501L24.3429 15.8261L22.3651 14.389L20.3873 15.8261L21.1429 13.501L19.165 12.0644H21.6096L22.3651 9.73907Z"
      fill="#F0F0F0"
    />
    <path
      d="M16.5915 19.4783L17.347 21.8035H19.7915L17.8137 23.2401L18.5693 25.5652L16.5915 24.1282L14.6136 25.5652L15.3692 23.2401L13.3914 21.8035H15.836L16.5915 19.4783Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.1912 13.3913L10.9468 15.7165H13.3914L11.4135 17.1532L12.169 19.4783L10.1912 18.0413L8.21348 19.4783L8.96893 17.1532L6.99121 15.7165H9.43574L10.1912 13.3913Z"
      fill="#F0F0F0"
    />
  </svg>
);

export default SvgSamoa;

import { IIconProps } from '../interface';

const SvgKeyEnter = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 17L5.29289 16.2929C4.90237 16.6834 4.90237 17.3166 5.29289 17.7071L6 17ZM24 8V15H26V8H24ZM23 16H6V18H23V16ZM12.2929 9.29289L5.29289 16.2929L6.70711 17.7071L13.7071 10.7071L12.2929 9.29289ZM5.29289 17.7071L12.2929 24.7071L13.7071 23.2929L6.70711 16.2929L5.29289 17.7071ZM24 15C24 15.5523 23.5523 16 23 16V18C24.6569 18 26 16.6569 26 15H24Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgKeyEnter;

import { IIconProps } from '../interface';

const SvgCorsica = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#FCFCFC"
    />
    <path
      d="M37.7492 23.7161C37.7492 17.7941 32.9485 12.9935 27.0267 12.9935C22.6567 12.9935 18.9 15.6087 17.2296 19.3585L15.9072 24.153L17.1245 25.3703L14.6897 30.2399L17.1245 31.4572L15.9072 32.6746V33.8919L17.1245 35.1093V37.5441L18.3418 38.7614L21.994 37.5441C21.994 37.5441 21.994 38.7614 23.2113 39.9789C24.4287 41.1962 25.6462 43.6311 25.6462 43.6311C25.6462 43.6311 29.2984 44.8484 31.7332 41.1963C34.168 37.5442 36.6028 36.3267 36.6028 36.3267L34.256 31.633C36.4022 29.6727 37.7492 26.8522 37.7492 23.7161Z"
      fill="black"
    />
    <path
      d="M38.6232 27.8165C43.5701 26.982 44.3941 17.2951 44.3941 17.2951H37.5768C41.1072 25.8169 37.4119 26.3901 37.4119 26.3901C36.9113 26.0177 18.9959 17.5306 18.4281 17.3148C18.2912 17.4979 17.7876 17.9788 17.1753 19.4762C16.5629 20.9735 16.4126 22.3206 16.4126 22.3206C16.6994 22.5075 35.7255 29.1511 36.2284 29.2064C37.2264 29.8589 38.4545 31.8103 37.7682 37.596L44.33 35.7467C44.3302 35.7467 42.1033 29.8226 38.6232 27.8165Z"
      fill="#ACABB1"
    />
  </svg>
);

export default SvgCorsica;

import { IIconProps } from '../interface';

const SvgEquatorialGuinea = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15615)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.0434 18.2609H54.2583C50.3022 7.5985 40.0389 0 28 0C20.2678 0 13.2686 3.13458 8.2019 8.20192L17.0434 18.2609Z"
        fill="#6DA544"
      />
      <path
        d="M17.0434 37.7391H54.2583C50.3022 48.4015 40.0389 56 28 56C20.2678 56 13.2686 52.8654 8.2019 47.7981L17.0434 37.7391Z"
        fill="#D80027"
      />
      <path
        d="M8.20099 8.20094C-2.73366 19.1356 -2.73366 36.8643 8.20099 47.7991C12.7196 43.2804 17.0654 38.9347 28 28L8.20099 8.20094Z"
        fill="#0052B4"
      />
      <path
        d="M32.8696 23.1304V29.2173C32.8696 32.9448 37.7392 34.087 37.7392 34.087C37.7392 34.087 42.6088 32.9448 42.6088 29.2173V23.1304H32.8696Z"
        fill="#DEDDE0"
      />
      <path
        d="M36.5217 28.1217H38.9565V31.6521H36.5217V28.1217Z"
        fill="#786145"
      />
      <path
        d="M40.1738 26.7827C40.1738 25.4379 39.0838 24.3479 37.7391 24.3479C36.3943 24.3479 35.3043 25.4379 35.3043 26.7827C34.6319 26.7827 34.0869 27.3277 34.0869 28C34.0869 28.6723 34.6319 29.2173 35.3043 29.2173H40.1738C40.8462 29.2173 41.3912 28.6723 41.3912 28C41.3912 27.3277 40.8463 26.7827 40.1738 26.7827Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15615">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEquatorialGuinea;

import { IIconProps } from '../interface';

const SvgNicaragua = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 0C16.8991 0 7.30732 6.46023 2.77832 15.8261H53.2215C48.6926 6.46023 39.1008 0 28 0Z"
      fill="#338AF3"
    />
    <path
      d="M28 56C39.1008 56 48.6926 49.5398 53.2216 40.1739H2.77832C7.30732 49.5398 16.8991 56 28 56Z"
      fill="#338AF3"
    />
    <path
      d="M28 19.4783C23.2936 19.4783 19.4783 23.2936 19.4783 28C19.4783 32.7064 23.2936 36.5217 28 36.5217C32.7064 36.5217 36.5217 32.7064 36.5217 28C36.5217 23.2936 32.7064 19.4783 28 19.4783ZM28 32.8696C25.3107 32.8696 23.1304 30.6895 23.1304 28C23.1304 25.3105 25.3106 23.1304 28 23.1304C30.6894 23.1304 32.8696 25.3105 32.8696 28C32.8696 30.6895 30.6893 32.8696 28 32.8696Z"
      fill="#FFDA44"
    />
    <path
      d="M32.2172 29.2173L27.9999 28L23.7826 29.2173L22.377 31.6521H33.6228L32.2172 29.2173Z"
      fill="#0052B4"
    />
    <path
      d="M28 21.9131L25.1885 26.7826L28 28L30.8114 26.7826L28 21.9131Z"
      fill="#338AF3"
    />
    <path
      d="M23.7827 29.2173H32.2173L30.8115 26.7827H25.1885L23.7827 29.2173Z"
      fill="#6DA544"
    />
  </svg>
);

export default SvgNicaragua;

import { IIconProps } from '../interface';

const SvgGuyana = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15806)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 0C23.0496 0 18.4003 1.28647 14.3652 3.54069V52.4593C18.4004 54.7135 23.0496 56 28 56C43.4639 56 56 43.4639 56 28C56 12.5361 43.4639 0 28 0Z"
        fill="#6DA544"
      />
      <path
        d="M14.4476 3.49463C12.6861 4.4708 11.0413 5.63105 9.53862 6.95011C9.69054 6.8169 9.844 6.68521 9.99865 6.55527L31.4433 28L9.99854 49.4447C9.84378 49.3148 9.69044 49.1831 9.53851 49.0499C11.0412 50.3689 12.6861 51.5291 14.4475 52.5054L56 28L14.4476 3.49463Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0827 4.96365C11.2288 5.55493 10.4087 6.19117 9.62718 6.87115L28 28L9.62708 49.1289C10.4086 49.8088 11.2287 50.4452 12.0825 51.0364L51.207 28L12.0827 4.96365Z"
        fill="#FFDA44"
      />
      <path
        d="M9.9985 6.55527C9.37605 7.0783 8.77536 7.6266 8.20093 8.20092L25.5652 28L8.20093 47.7991C8.77536 48.3735 9.37594 48.9219 9.9985 49.4447L31.4432 28L9.9985 6.55527Z"
        fill="black"
      />
      <path
        d="M8.20093 8.20093C-2.73372 19.1356 -2.73372 36.8643 8.20093 47.799C12.7195 43.2804 17.0653 38.9346 28 28L8.20093 8.20093Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15806">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGuyana;

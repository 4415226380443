import { IIconProps } from '../interface';

const SvgSardinia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16511)">
      <path
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
        fill="#F0F0F0"
      />
      <path
        d="M55.763 24.3479H31.6524H31.6523V0.237016C30.4567 0.081375 29.2378 0 28 0C26.7622 0 25.5433 0.081375 24.3479 0.237016V24.3476V24.3477H0.237016C0.081375 25.5433 0 26.7622 0 28C0 29.2378 0.081375 30.4567 0.237016 31.6521H24.3476H24.3477V55.763C25.5433 55.9186 26.7622 56 28 56C29.2378 56 30.4567 55.9187 31.6521 55.763V31.6525V31.6523H55.763C55.9186 30.4567 56 29.2378 56 28C56 26.7622 55.9186 25.5433 55.763 24.3479Z"
        fill="#D80027"
      />
      <path
        d="M41.3913 9.73907C39.1222 9.73907 37.2157 11.291 36.6752 13.3912L41.3913 14.6086L46.1074 13.3912C45.5669 11.2911 43.6604 9.73907 41.3913 9.73907Z"
        fill="black"
      />
      <path
        d="M36.5218 20.6956L42.6087 21.913V19.3248C44.3199 18.8843 45.6667 17.5371 46.1072 15.826L41.3914 14.6087H35.3044L36.5218 18.2608V20.6956Z"
        fill="black"
      />
      <path
        d="M46.1075 13.3913H35.3044V15.8261V18.2609H36.5218V15.8261H46.1072C46.2074 15.4371 46.261 15.0291 46.261 14.6088C46.261 14.1883 46.2077 13.7804 46.1075 13.3913Z"
        fill="#ACABB1"
      />
      <path
        d="M15.8261 9.73907C13.557 9.73907 11.6505 11.291 11.11 13.3912L15.8261 14.6086L20.5423 13.3912C20.0017 11.2911 18.0952 9.73907 15.8261 9.73907Z"
        fill="black"
      />
      <path
        d="M10.9566 20.6956L17.0435 21.913V19.3248C18.7547 18.8843 20.1015 17.5371 20.542 15.826L15.8262 14.6087H9.73914L10.9565 18.2608V20.6956H10.9566Z"
        fill="black"
      />
      <path
        d="M20.5423 13.3913H9.73914V15.8261V18.2609H10.9565V15.8261H20.5419C20.6421 15.4371 20.6957 15.0291 20.6957 14.6088C20.6957 14.1883 20.6425 13.7804 20.5423 13.3913Z"
        fill="#ACABB1"
      />
      <path
        d="M41.3913 34.0869C39.1222 34.0869 37.2157 35.6389 36.6752 37.7391L41.3913 38.9564L46.1074 37.7391C45.5669 35.6389 43.6604 34.0869 41.3913 34.0869Z"
        fill="black"
      />
      <path
        d="M36.5218 45.0435L42.6087 46.2608V43.6727C44.3199 43.2321 45.6667 41.8849 46.1072 40.1739L41.3914 38.9565H35.3044L36.5218 42.6087V45.0435Z"
        fill="black"
      />
      <path
        d="M46.1075 37.7391H35.3044V40.1739V42.6087H36.5218V40.1739H46.1072C46.2074 39.7848 46.261 39.3769 46.261 38.9565C46.261 38.5362 46.2077 38.1282 46.1075 37.7391Z"
        fill="#ACABB1"
      />
      <path
        d="M15.8261 34.0869C13.557 34.0869 11.6505 35.6389 11.11 37.7391L15.8261 38.9564L20.5423 37.7391C20.0017 35.6389 18.0952 34.0869 15.8261 34.0869Z"
        fill="black"
      />
      <path
        d="M10.9566 45.0435L17.0435 46.2608V43.6727C18.7547 43.2321 20.1015 41.8849 20.542 40.1739L15.8262 38.9565H9.73914L10.9565 42.6087V45.0435H10.9566Z"
        fill="black"
      />
      <path
        d="M20.5423 37.7391H9.73914V40.1739V42.6087H10.9565V40.1739H20.5419C20.6421 39.7848 20.6957 39.3769 20.6957 38.9565C20.6957 38.5362 20.6425 38.1282 20.5423 37.7391Z"
        fill="#ACABB1"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16511">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSardinia;

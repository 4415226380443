import { IIconProps } from '../interface';

const SvgDollarCurrencyShield = ({
  width = 24,
  height = 24,
  color = '#323232',
}: IIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9409 20.8951C8.70166 20.4473 6.68674 19.2374 5.23896 17.4714C3.79119 15.7054 3 13.4924 3 11.2088V8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3H16C17.3261 3 18.5979 3.52678 19.5355 4.46447C20.4732 5.40215 21 6.67392 21 8V11.2088C21 13.4923 20.2088 15.7053 18.7611 17.4713C17.3134 19.2373 15.2985 20.4472 13.0593 20.8951C12.3602 21.0349 11.64 21.0349 10.9409 20.8951Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7V8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.8587 13.7499C10.052 14.1466 10.3586 14.4772 10.7398 14.6997C11.1209 14.9222 11.5595 15.0266 12 14.9999C12.5289 15.0576 13.0595 14.9073 13.4795 14.5806C13.8994 14.254 14.1758 13.7767 14.25 13.2499C14.25 12.8833 14.123 12.528 13.8906 12.2445C13.6582 11.9611 13.3347 11.7668 12.9753 11.6949L11.0247 11.3048C10.6653 11.2329 10.3418 11.0387 10.1094 10.7552C9.87701 10.4717 9.75 10.1165 9.75 9.74989C9.82423 9.22306 10.1006 8.7458 10.5205 8.41916C10.9405 8.09253 11.4711 7.94216 12 7.99989C12.4405 7.97314 12.8791 8.07762 13.2602 8.30011C13.6414 8.52261 13.948 8.85314 14.1413 9.24989"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgDollarCurrencyShield;

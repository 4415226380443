import { IIconProps } from '../interface';

const SvgTajikistan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.77834 15.8261C0.998375 19.5072 0 23.637 0 28C0 32.363 0.998375 36.4928 2.77834 40.1739L28 42.6087L53.2217 40.1739C55.0016 36.4928 56 32.363 56 28C56 23.637 55.0016 19.5072 53.2217 15.8261L28 13.3913L2.77834 15.8261Z"
      fill="#F0F0F0"
    />
    <path
      d="M2.7793 40.1739C7.30819 49.5397 16.8992 56 28 56C39.1008 56 48.6919 49.5397 53.2206 40.1739H2.7793Z"
      fill="#6DA544"
    />
    <path
      d="M2.7793 15.8261H53.2206C48.6919 6.46034 39.1008 0 28 0C16.8992 0 7.30819 6.46034 2.7793 15.8261Z"
      fill="#D80027"
    />
    <path
      d="M23.1304 35.3044H32.8696V31.1652L30.9217 32.1391L28 29.2173L25.0782 32.1391L23.1304 31.1652V35.3044Z"
      fill="#FFDA44"
    />
    <path
      d="M17.5581 30.4348L18.0115 31.8298H19.4782L18.2915 32.692L18.7449 34.0869L17.5581 33.2248L16.3714 34.0869L16.8248 32.692L15.6381 31.8298H17.1048L17.5581 30.4348Z"
      fill="#FFDA44"
    />
    <path
      d="M18.9635 25.5652L19.4169 26.9602H20.8836L19.6969 27.8224L20.1501 29.2173L18.9635 28.3552L17.7768 29.2173L18.2302 27.8224L17.0435 26.9602H18.5102L18.9635 25.5652Z"
      fill="#FFDA44"
    />
    <path
      d="M22.9811 21.9131L23.4345 23.308H24.9011L23.7146 24.1702L24.1677 25.5652L22.9811 24.7031L21.7945 25.5652L22.2478 24.1702L21.061 23.308H22.5279L22.9811 21.9131Z"
      fill="#FFDA44"
    />
    <path
      d="M38.4418 30.4348L37.9884 31.8298H36.5217L37.7084 32.692L37.2551 34.0869L38.4418 33.2248L39.6284 34.0869L39.1752 32.692L40.3619 31.8298H38.8952L38.4418 30.4348Z"
      fill="#FFDA44"
    />
    <path
      d="M37.0364 25.5652L36.5831 26.9602H35.1163L36.3031 27.8224L35.8497 29.2173L37.0364 28.3552L38.2231 29.2173L37.7698 27.8224L38.9565 26.9602H37.4898L37.0364 25.5652Z"
      fill="#FFDA44"
    />
    <path
      d="M33.0188 21.9131L32.5655 23.308H31.0989L32.2854 24.1702L31.8321 25.5652L33.0188 24.7031L34.2055 25.5652L33.7522 24.1702L34.9388 23.308H33.4721L33.0188 21.9131Z"
      fill="#FFDA44"
    />
    <path
      d="M27.9999 20.0868L28.4533 21.4818H29.92L28.7333 22.344L29.1866 23.739L27.9999 22.8769L26.8132 23.739L27.2666 22.344L26.0798 21.4818H27.5466L27.9999 20.0868Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgTajikistan;

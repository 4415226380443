import { IIconProps } from '../interface';

const SvgAfghanistan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1739 2.77834C36.4928 0.998375 32.363 0 28 0C23.6371 0 19.5073 0.998375 15.8262 2.77834L13.3914 28L15.8262 53.2217C19.5073 55.0016 23.6371 56 28 56C32.363 56 36.4928 55.0016 40.1739 53.2217L42.6087 28L40.1739 2.77834Z"
      fill="#D80027"
    />
    <path
      d="M15.8261 2.77933C6.46034 7.30822 0 16.8992 0 28C0 39.1008 6.46034 48.6918 15.8261 53.2207V2.77933Z"
      fill="black"
    />
    <path
      d="M40.1739 2.77933V53.2207C49.5397 48.6918 56 39.1008 56 28C56 16.8992 49.5397 7.30822 40.1739 2.77933Z"
      fill="#496E2D"
    />
    <path
      d="M28 18.2609C22.6212 18.2609 18.2609 22.6213 18.2609 28C18.2609 33.3787 22.6213 37.7391 28 37.7391C33.3787 37.7391 37.7391 33.3787 37.7391 28C37.7391 22.6213 33.3788 18.2609 28 18.2609ZM28 34.0869C24.6383 34.0869 21.9131 31.3616 21.9131 28C21.9131 24.6384 24.6384 21.9131 28 21.9131C31.3616 21.9131 34.0869 24.6384 34.0869 28C34.0869 31.3616 31.3618 34.0869 28 34.0869Z"
      fill="#FFDA44"
    />
    <path
      d="M28 24.3479C26.6552 24.3479 25.5652 25.4379 25.5652 26.7827V30.4348H30.4348V26.7827C30.4348 25.4379 29.3447 24.3479 28 24.3479V24.3479Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgAfghanistan;

const defaultQuestionsCountry = [
  'stay',
  'employment',
  'ties',
  'residency',
  'permanent_home_country',
  'visa',
];

export default defaultQuestionsCountry;

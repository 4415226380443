import { IIconProps } from '../interface';

const SvgNiue = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28C0 28.0067 28 0.0030625 28 0C43.4639 0 56 12.5361 56 28Z"
      fill="#FFDA44"
    />
    <path d="M28.0001 13.3913V22.8351L18.261 13.3913H28.0001Z" fill="#0052B4" />
    <path
      d="M14.6087 28H22.8349L14.6087 17.0435L13.3914 25.5652L14.6087 28Z"
      fill="#0052B4"
    />
    <path
      d="M27.925 28H28C28 27.9748 28 27.9503 28 27.9251C27.975 27.9501 27.9501 27.9751 27.925 28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 14.6087C28 9.68188 28 6.45367 28 0H27.9953C12.5334 0.002625 0 12.5375 0 28H14.6087V19.7738L22.8349 28H27.9252C27.9501 27.9751 27.9751 27.9501 28.0001 27.9251C28.0001 26.0387 28.0001 24.3555 28.0001 22.8351L19.7737 14.6087H28Z"
      fill="#F0F0F0"
    />
    <path
      d="M28 24.5568L18.0518 14.6088H14.6086V14.609L27.9998 28H28C28 28 28 25.6258 28 24.5568Z"
      fill="#D80027"
    />
    <path
      d="M14.1657 3.65215C13.0862 4.26683 12.0521 4.95185 11.0686 5.69965H12.2175L9.18101 7.90618L10.3406 11.4759L7.30413 9.26976L6.98104 9.50458C5.72027 10.9363 4.60246 12.4969 3.6521 14.1657V28H10.9565V10.9568V10.9565H28C28 8.65277 28 6.45805 28 3.65215H14.1657Z"
      fill="#D80027"
    />
    <path
      d="M11.9613 5.04788C11.6594 5.2593 11.3616 5.47663 11.0684 5.69965L9.18113 7.90618L7.30426 9.26965L6.97843 9.50634C6.2909 10.2873 5.64613 11.1067 5.04785 11.9613C5.7298 12.2922 6.49532 12.4783 7.30426 12.4783C10.1618 12.4783 12.4781 10.1619 12.4781 7.3044C12.4784 6.49524 12.2924 5.72994 11.9613 5.04788Z"
      fill="#0052B4"
    />
    <path
      d="M6.97827 9.50633L7.3041 9.26965L10.3406 11.4758L9.18087 7.90618L12.2171 5.69965H11.068C9.58118 6.83047 8.21104 8.10622 6.97827 9.50633Z"
      fill="#FFDA44"
    />
    <path
      d="M19.353 4.8696L19.9574 6.72952H21.913L20.3309 7.87916L20.935 9.73908L19.353 8.58955L17.7709 9.73908L18.3748 7.87916L16.7927 6.72952H18.7485L19.353 4.8696Z"
      fill="#FFDA44"
    />
    <path
      d="M7.30428 17.0435L7.90869 18.9034H9.86431L8.28231 20.0531L8.88639 21.9131L7.30428 20.7634L5.72206 21.9131L6.32614 20.0531L4.74414 18.9034H6.69977L7.30428 17.0435Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgNiue;

import { IIconProps } from '../interface';

const SvgEcuador = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28L28 30.4348L0 28Z"
      fill="#FFDA44"
    />
    <path
      d="M3.74677 42C8.58825 50.3689 17.6362 56 28 56C38.3638 56 47.4118 50.3689 52.2533 42L28 40.1739L3.74677 42Z"
      fill="#D80027"
    />
    <path
      d="M52.2533 42C54.6357 37.8816 56 33.1003 56 28H0C0 33.1003 1.36434 37.8816 3.74675 42H52.2533Z"
      fill="#0052B4"
    />
    <path
      d="M28 37.7391C33.3787 37.7391 37.7391 33.3787 37.7391 28C37.7391 22.6213 33.3787 18.2609 28 18.2609C22.6213 18.2609 18.2609 22.6213 18.2609 28C18.2609 33.3787 22.6213 37.7391 28 37.7391Z"
      fill="#FFDA44"
    />
    <path
      d="M28 34.0869C24.6436 34.0869 21.9131 31.3563 21.9131 28V24.3479C21.9131 20.9915 24.6437 18.2609 28 18.2609C31.3563 18.2609 34.087 20.9916 34.087 24.3479V28C34.087 31.3564 31.3564 34.0869 28 34.0869Z"
      fill="#338AF3"
    />
    <path
      d="M37.7391 13.3913H30.4347C30.4347 12.0467 29.3445 10.9565 27.9999 10.9565C26.6552 10.9565 25.5651 12.0467 25.5651 13.3913H18.2607C18.2607 14.7361 19.432 15.8261 20.7765 15.8261H20.6955C20.6955 17.1709 21.7855 18.2609 23.1303 18.2609C23.1303 19.6057 24.2203 20.6957 25.5651 20.6957H30.4347C31.7794 20.6957 32.8695 19.6057 32.8695 18.2609C34.2142 18.2609 35.3043 17.1709 35.3043 15.8261H35.2232C36.5679 15.8261 37.7391 14.736 37.7391 13.3913Z"
      fill="black"
    />
  </svg>
);

export default SvgEcuador;

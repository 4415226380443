import { IIconProps } from '../interface';

const SvgBurkinaFaso = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 28C0 12.5361 12.5361 0 28 0C43.4639 0 56 12.5361 56 28C54.7827 28 28 31.6521 28 31.6521L0 28Z"
      fill="#D80027"
    />
    <path
      d="M56 28C56 43.4639 43.4639 56 28 56C12.5361 56 0 43.4639 0 28"
      fill="#6DA544"
    />
    <path
      d="M27.9999 18.2609L30.1149 24.7707H36.9603L31.4224 28.7942L33.5375 35.3044L27.9999 31.281L22.4621 35.3044L24.5775 28.7942L19.0396 24.7707H25.8847L27.9999 18.2609Z"
      fill="#FFDA44"
    />
  </svg>
);

export default SvgBurkinaFaso;
